// Helpers
import { camelCase, find, first, get, keys } from "@mefisto/utils";

/**
 * Returns name of the operation
 */
export const operationName = (operation, transform = true) => {
  const name = get(
    find(
      get(operation, "definitions"),
      (definition) => definition.kind === "OperationDefinition"
    ),
    "name.value"
  );
  return transform ? camelCase(name) : name;
};

/**
 * Returns query data for the given operation
 */
export const getData = (query, operation) => {
  return (
    get(
      query,
      ["data", operation ? operationName(operation) : first(keys(query.data))],
      {}
    ) || {}
  );
};

/**
 * Returns `true` if the query has returned data
 */
export const hasData = (query) => find(query.data, (value) => value);

export const paginatedList = (keyArgs = false) => {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      // First run
      if (!existing || !args) {
        return incoming;
      }
      // Refresh
      if (!(args.pagination || {}).pointer) {
        return incoming;
      }
      // Fetch more
      return {
        ...existing,
        ...incoming,
        data: [...existing.data, ...incoming.data],
      };
    },
  };
};
