// React
import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";
// Context
import { NavigationContext } from "./NavigationContext";
// Components
import { Router } from "react-router-dom";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const LocationListener = ({ children }) => {
  // Context
  const { navigation } = usePortal();
  // Effects
  useEffect(() => {
    // Lazy load the history listener. Call the functions after
    // the Router is mounted, so we get the callback after the route
    // is rendered.
    navigation.startLocationListener();
  }, [navigation]);
  // Render
  return <>{children}</>;
};

const Component = ({ children }) => {
  // Framework
  const { navigation } = usePortal();
  // Context
  const options = useContext(NavigationContext);
  // Init
  useStackDependency(navigation, options);
  // Render
  return (
    <Router history={navigation.history}>
      <LocationListener>{children}</LocationListener>
    </Router>
  );
};

const NavigationProvider = ({ options, children }) => (
  <NavigationContext.Provider
    value={{
      type: "browser",
      basename: "",
      nullParameter: "-",
    }}
  >
    {options}
    <Component>{children}</Component>
  </NavigationContext.Provider>
);

NavigationProvider.propTypes = {
  options: PropTypes.element,
};

export { NavigationProvider };
