// React
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
// Framework
import { UserInteraction } from "analytics";
import { Section } from "ui/section";
import { ListItem as Component } from "@material-ui/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ListItem = forwardRef(
  ({ context = "item", value, children, ...props }, ref) => (
    <UserInteraction
      ref={ref}
      element="button"
      value={value}
      component={Component}
      componentProps={props}
    >
      <Section context={context} value={value}>
        {children}
      </Section>
    </UserInteraction>
  )
);

ListItem.propTypes = {
  /**
   * Section context name
   */
  context: PropTypes.string,
  /**
   * Element specification value
   */
  value: PropTypes.string,
  /**
   * Defines the `align-items` style property.
   */
  alignItems: PropTypes.oneOf(["flex-start", "center"]),
  /**
   * If `true`, the list item will be focused during the first mount.
   * Focus will also be triggered if the value changes from false to true.
   */
  autoFocus: PropTypes.bool,
  /**
   * If `true`, the list item will be a button (using `ButtonBase`). Props intended
   * for `ButtonBase` can then be applied to `ListItem`.
   */
  button: PropTypes.bool,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   * By default, it's a `li` when `button` is `false` and a `div` when `button` is `true`.
   */
  component: PropTypes.elementType,
  /**
   * The container component used when a `ListItemSecondaryAction` is the last child.
   */
  ContainerComponent: PropTypes.elementType,
  /**
   * Props applied to the container component if used.
   */
  ContainerProps: PropTypes.object,
  /**
   * If `true`, compact vertical padding designed for keyboard and mouse input will be used.
   */
  dense: PropTypes.bool,
  /**
   * If `true`, the list item will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the left and right padding is removed.
   */
  disableGutters: PropTypes.bool,
  /**
   * If `true`, a 1px light border is added to the bottom of the list item.
   */
  divider: PropTypes.bool,
  /**
   * Use to apply selected styling.
   */
  selected: PropTypes.bool,
  /**
   * The content of the list item.
   */
  children: PropTypes.node,
};

export default ListItem;
