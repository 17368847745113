// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { classnames, makeStyles, alpha, Typography } from "ui";
import { useTranslate } from "localization";
// Drop Zone
import { useDropzone } from "react-dropzone";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    borderWidth: 2,
    borderRadius: theme.radius.small,
    borderColor: theme.palette.divider,
    borderStyle: "dashed",
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.secondary,
    cursor: "pointer",
    outline: "none",
    transition: theme.transitions.create(["border", "color"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      borderColor: theme.palette.text.primary,
      color: theme.palette.text.primary,
    },
    "&:focus": {
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
  },
  active: ({ label, image }) => ({
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    "&:after": label &&
      image && {
        content: `"${label}"`,
        textAlign: "center",
        background: alpha(theme.palette.grey[300], 0.8),
        borderRadius: "inherit",
        width: "100%",
        height: "100%",
        padding: theme.spacing(5),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.text.primary,
        ...theme.typography.subtitle2,
      },
  }),
  error: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  accept: {
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
  reject: {
    borderColor: theme.palette.error.light,
    color: theme.palette.error.main,
  },
  image: ({ image }) => ({
    borderStyle: "solid",
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
  }),
  circle: {
    borderRadius: theme.radius.rounded,
  },
  square: {
    paddingTop: "100%",
  },
  label: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    padding: theme.spacing(2),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Dropzone = ({
  display = "default",
  multiple = false,
  label,
  accept,
  borderRadius,
  image,
  error,
  width,
  height = 120,
  maxFiles,
  maxSize,
  minSize,
  autoFocus,
  disabled,
  disableDrag,
  disableClick,
  disableKeyboard,
  onDrop,
  onDropAccepted,
  onDropRejected,
  onError,
}) => {
  // Styles
  const classes = useStyles({ label, image });
  // Framework
  const { translate } = useTranslate();
  // Drop zone
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    autoFocus,
    disabled,
    multiple,
    maxFiles,
    maxSize,
    minSize,
    noDrag: disableDrag,
    noClick: disableClick,
    noKeyboard: disableKeyboard,
    onDrop,
    onDropAccepted,
    onDropRejected,
    onError,
  });
  return (
    <section
      {...getRootProps({
        className: classnames(classes.root, {
          [classes.circle]: display === "circle",
          [classes.square]: display === "square",
          [classes.error]: error,
          [classes.image]: !!image,
          [classes.active]: isDragActive,
          [classes.accept]: isDragAccept,
          [classes.reject]: isDragReject,
        }),
        style: { width, height, borderRadius },
      })}
    >
      <input {...getInputProps()} />
      {!image && (
        <Typography
          variant="subtitle2"
          color="inherit"
          align="center"
          className={classes.label}
        >
          {label ?? translate("core:storage.dropzone.label")}
        </Typography>
      )}
    </section>
  );
};

Dropzone.propTypes = {
  /**
   * Dropzone display type
   */
  display: PropTypes.oneOf(["default", "square", "circle"]),
  /**
   * Allow drag 'n' drop (or selection from the file dialog) of multiple files
   * Default: false
   */
  multiple: PropTypes.bool,
  /**
   * Dropzone label inside the area
   */
  label: PropTypes.string,
  /**
   * Set accepted file types.
   * Checkout https://developer.mozilla.org/en-US/docs/Web/API/window/showOpenFilePicker types
   * option for more information. Keep in mind that mime type determination is not
   *  reliable across platforms. CSV files, for example, are reported as
   *  text/plain under macOS but as application/vnd.ms-excel under Windows.
   *  In some cases there might not be a mime type
   *  set at all (https://github.com/react-dropzone/react-dropzone/issues/276).
   */
  accept: PropTypes.object,
  /**
   * Border radius of the dropzone area
   */
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Adds image to background.
   * This is useful when the dropzone accepts images and the image
   * was already uploaded.
   */
  image: PropTypes.any,
  /**
   * Set to `true` to display dropzone in error mode
   */
  error: PropTypes.bool,
  /**
   * Dropzone area width
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Dropzone area height
   * Default: 120
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Maximum accepted number of files The default value is 0
   * which means there is no limitation to how many files are accepted.
   */
  maxFiles: PropTypes.number,
  /**
   * Maximum file size (in bytes)
   */
  maxSize: PropTypes.number,
  /**
   *
   * Minimum file size (in bytes)
   */
  minSize: PropTypes.number,
  /**
   * Set to `true` to focus the root element on render
   */
  autoFocus: PropTypes.bool,
  /**
   * Enable/disable the dropzone
   */
  disabled: PropTypes.bool,
  /**
   * If true, disables drag 'n' drop
   */
  disableDrag: PropTypes.bool,
  /**
   * If true, disables click to open the native file selection dialog
   */
  disableClick: PropTypes.bool,
  /**
   * If true, disables SPACE/ENTER to open the native file selection dialog.
   * Note that it also stops tracking the focus state.
   */
  disableKeyboard: PropTypes.bool,
  /**
   *
   * Callback for when the drop event occurs.
   * Files are accepted or rejected based on accept, multiple, minSize and maxSize props.
   * accept must be a valid MIME type according to input element specification or a valid file extension.
   *
   * If multiple is set to false and additional files are dropped,
   * all files besides the first will be rejected.
   * Any file which does not have a size in the [minSize, maxSize] range,
   * will be rejected as well.
   *
   * Note that the onDrop callback will always be invoked regardless if the
   * dropped files were accepted or rejected. If you'd like to react to a
   * specific scenario, use the onDropAccepted/onDropRejected props.
   *
   * `onDrop` will provide you with an array of File objects which you can
   *  then process and send to a server. For example, with SuperAgent
   *   as a http/ajax library:
   *
   * function onDrop(acceptedFiles) {
   *  const req = request.post('/upload')
   *  acceptedFiles.forEach(file => {
   *    req.attach(file.name, file)
   *  })
   *  req.end(callback)
   * }
   */
  onDrop: PropTypes.func,
  /**
   * Callback for when the drop event occurs.
   * Note that if no files are accepted, this callback is not invoked.
   */
  onDropAccepted: PropTypes.func,
  /**
   * Callback for when the drop event occurs.
   * Note that if no files are rejected, this callback is not invoked.
   */
  onDropRejected: PropTypes.func,
  /**
   * Callback for when there's some error from any of the promises.
   */
  onError: PropTypes.func,
};

export default Dropzone;
