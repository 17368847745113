// React
import React, {
  Children,
  cloneElement,
  useMemo,
  useCallback,
  useState,
} from "react";
import PropTypes from "prop-types";
// Helpers
import {
  compact,
  get,
  isEmpty,
  includes,
  findIndex,
  slice,
} from "@mefisto/utils";
// Framework
import { Grid, IconButton, MenuList, Popover } from "ui";
import { MoreHorizRounded as MoreIcon } from "icon/material";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const SceneOptions = ({ options = [] }) => {
  // State
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // Handlers
  const handleOpen = useCallback((event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  // Memo
  const filteredOptions = useMemo(() => {
    return compact(options);
  }, [options]);
  const menuOptions = useMemo(() => {
    const index = findIndex(
      filteredOptions,
      (option) => get(option, "props.type") === "menu"
    );
    const result = slice(filteredOptions, index);
    const menuTypes = ["menu", "divider"];
    return Children.map(result, (child) => {
      const isMenuOption = includes(menuTypes, child?.props?.type);
      return isMenuOption
        ? cloneElement(child, {
            __subtype: "menu",
            onClose: handleClose,
          })
        : null;
    });
  }, [filteredOptions, handleClose]);
  const standaloneOptions = useMemo(() => {
    const index = findIndex(
      filteredOptions,
      (option) => get(option, "props.type") === "menu"
    );
    const result =
      index === -1 ? filteredOptions : slice(filteredOptions, 0, index);
    const standaloneTypes = [undefined, "icon", "divider", "button"];
    return Children.map(result, (child) => {
      const isStandaloneType = includes(standaloneTypes, child?.props?.type);
      return isStandaloneType
        ? cloneElement(child, {
            __subtype: "standalone",
            onClose: handleClose,
          })
        : null;
    });
  }, [filteredOptions, handleClose]);
  // Render
  return (
    <>
      {Children.count(compact(options)) > 0 && (
        <Grid
          container
          wrap="nowrap"
          justifyContent="flex-end"
          alignItems="center"
        >
          {Children.map(standaloneOptions, (option) => (
            <Grid item>{option}</Grid>
          ))}
          {!isEmpty(menuOptions) && (
            <Grid item>
              <IconButton value="more" color="inherit" onClick={handleOpen}>
                <MoreIcon />
              </IconButton>
              <Popover
                keepMounted
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                onClose={handleClose}
              >
                <MenuList dense>{menuOptions}</MenuList>
              </Popover>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

SceneOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.node),
};

export default SceneOptions;
