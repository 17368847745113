// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { ButtonBase } from "ui/components";
import { useImage } from "react-image";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({ srcList, alt, className, onClick, ...props }) => {
  // Framework
  const { src } = useImage({ srcList });
  // Render
  return (
    <img
      src={src}
      className={className}
      alt={alt}
      onClick={onClick}
      {...props}
    />
  );
};

const ImageComponent = ({ onClick, ...props }) => {
  // Render
  return onClick ? (
    <ButtonBase component="div" onClick={onClick}>
      <Component {...props} />
    </ButtonBase>
  ) : (
    <Component {...props} />
  );
};

ImageComponent.propTypes = {
  srcList: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  alt: PropTypes.string,
  onClick: PropTypes.func,
};

export default ImageComponent;
