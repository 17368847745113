// React
import React from "react";
// Framework
import { makeStyles, Grid } from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles({
  root: {
    // This is a quick fix to avoid issue with Chrome 77
    // https://github.com/mui-org/material-ui/issues/17346
    width: "100%",
  },
});

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const FormItem = ({ children, ...rest }) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Grid item className={classes.root} {...rest}>
      {children}
    </Grid>
  );
};

export default FormItem;
