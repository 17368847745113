// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { useTranslate } from "localization/hooks";
import { classnames } from "ui/classnames";
import { makeStyles, Grid, Typography, Button } from "@material-ui/core";
import { InboxTwoTone as Icon } from "icon/material";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    maxWidth: 300,
    color: theme.palette.action.active,
  },
  gutter: {
    padding: theme.spacing(4),
  },
  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    zIndex: 1,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const EmptyPlaceholder = ({
  position = "default",
  disabled,
  gutter,
  icon,
  title,
  subtitle,
  action,
  onAction,
}) => {
  // Styles
  const classes = useStyles();
  // Framework
  const { translate } = useTranslate();
  // Render
  return (
    <>
      {!disabled && (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          wrap="nowrap"
          spacing={2}
          className={classnames(
            classes.root,
            gutter && classes.gutter,
            position === "center" && classes.center
          )}
        >
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                {icon ?? <Icon color="inherit" display="block" />}
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="inherit"
                  align="center"
                >
                  {title ?? translate("core:ui.emptyPlaceholder.title")}
                </Typography>
                {subtitle && (
                  <Typography
                    variant="caption"
                    component="div"
                    color="inherit"
                    align="center"
                  >
                    {subtitle}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          {onAction && (
            <Grid item>
              <Button variant="contained" size="small" onClick={onAction}>
                {action ?? translate("core:ui.emptyPlaceholder.action")}
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

EmptyPlaceholder.propTypes = {
  position: PropTypes.oneOf(["default", "center"]),
  gutter: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  action: PropTypes.any,
  onAction: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([false])]),
};

export default EmptyPlaceholder;
