// React
import React from "react";
// Framework
import { Twitter as Icon } from "icon/social";
// Components
import ProviderButton from "../ProviderButton";

const TwitterButton = (props) => (
  <ProviderButton
    name="Twitter"
    color="#5aa4eb"
    icon={<Icon fontSize="inherit" />}
    {...props}
  />
);

export default TwitterButton;
