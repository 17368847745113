// React
import React from "react";
// Framework
import { Github as Icon } from "icon/social";
// Components
import ProviderButton from "../ProviderButton";

const GithubButton = (props) => (
  <ProviderButton
    name="GitHub"
    color="#333333"
    icon={<Icon fontSize="inherit" />}
    {...props}
  />
);

export default GithubButton;
