// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { Tab, Skeleton } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelDialogTab = ({
  label,
  icon,
  disabled,
  loading,
  skeleton,
  ...props
}) => (
    <Tab
      label={
        label && (
          <>
            {loading && skeleton ? <Skeleton width={50} /> : label}
          </>
        )
      }
      icon={
        icon && (
          <>
            {loading && skeleton ? (
              <Skeleton variant="rect" width={20} height={20} />
            ) : (
              icon
            )}
          </>
        )
      }
      disabled={loading || disabled}
      {...props}
    />
  );

ModelDialogTab.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  skeleton: PropTypes.bool,
};

export default ModelDialogTab;
