// React
import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
// Helpers
import { forEach } from "@mefisto/utils";
// Components
import { InterceptorContext } from "interceptor/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Interceptors = ({ children, ...props }) => {
  // Context
  const context = useContext(InterceptorContext);
  // Set values
  context.interceptors = children;
  forEach(props, (value, key) => {
    context[key] = value;
  });
  // Render
  return <Fragment />;
};

Interceptors.defaultProps = {
  __TYPE: "Interceptors",
};

Interceptors.propTypes = {
  loaderId: PropTypes.string,
  children: PropTypes.node,
};

export default Interceptors;
