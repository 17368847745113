// Framework
import { Permission } from "@mefisto/web";
// Helpers
import { map } from "@mefisto/utils";
// Components
import permissions from "./permissions.json";

export const portal = () => {
  return map(permissions, ({ key, description, scopes }) =>
    Permission(key, description, scopes)
  );
};
