// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, classnames, Button, Box, IconButton, Cloak } from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  button: {
    height: 40,
  },
  iconButton: {
    background: theme.palette.grey[200],
    borderRadius: theme.radius.small,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const CoverTabsButton = ({
  context,
  value,
  title,
  icon,
  color,
  selected,
  loading,
  onClick,
  ...props
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <>
      {icon && !title && (
        <Box color={selected ? "primary.main" : "action.active"}>
          <IconButton
            context={context}
            value={value}
            color="inherit"
            className={classnames(classes.button, classes.iconButton)}
            onClick={onClick}
            {...props}
          >
            {icon}
          </IconButton>
        </Box>
      )}
      {title && (
        <Button
          context={context}
          value={value}
          color={color}
          size="large"
          variant="contained"
          className={classes.button}
          startIcon={icon}
          loading={loading}
          onClick={onClick}
          {...props}
        >
          {title}
        </Button>
      )}
      <Cloak enabled={!selected}>
        <Box width="100%" height={2} mt={0.75} bgcolor="primary.main" />
      </Cloak>
    </>
  );
};

CoverTabsButton.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.node,
  color: PropTypes.node,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CoverTabsButton;
