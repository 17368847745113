// React
import React, { Component } from "react";
// Framework
import { withStyles, Grid } from "ui/components";
import { BrokenImageRounded as BrokenImage } from "icon/material";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const styles = (theme) => ({
  root: {
    height: "100%",
    color: theme.palette.grey[300],
    lineHeight: 0,
  },
});

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children, classes } = this.props;
    return hasError ? (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.root}
      >
        <Grid item>
          <BrokenImage color="inherit" />
        </Grid>
      </Grid>
    ) : (
      children
    );
  }
}

export default withStyles(styles)(ErrorBoundary);
