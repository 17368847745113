// React
import React from "react";
// Framework
import { useLazy, usePortal } from "@mefisto/web";
// Context
import { TagManagerContext } from "../TagManagerContext";
import { TagManager } from "../TagManager";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TagManagerInterceptor = ({ children }) => {
  // Framework
  const { config, log } = usePortal();
  // Dependency
  const tagManager = useLazy(TagManager, config, log);
  // Render
  return (
    <TagManagerContext.Provider value={{ tagManager }}>
      {children(true)}
    </TagManagerContext.Provider>
  );
};

export default TagManagerInterceptor;
