// Helpers
import {
  forEach,
  keys,
  join,
  get,
  split,
  compact,
  startsWith,
} from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";

export class Cache extends StackDependency {
  #name;

  onInitialized() {
    const { name } = this.options;
    this.#name = name;
    // Clean expired items
    this.clean();
  }

  /**
   * Composes key in a form of name:namespace:key
   * @param namespace {string}
   * @param key {string}
   * @return {string}
   */
  #makeKey = (namespace = "Global", key) => {
    return join(compact([this.#name, namespace, key]), ":");
  };

  /**
   * Retrieves namespace from the key
   * @param key {string}
   * @return {string|null}
   */
  #getNamespace = (key) => {
    return get(split(key, ":"), "[1]", null);
  };

  /**
   * Store item in the cache
   * @param key {string} Cached item key
   * @param value {string} Cached item value
   * @param namespace {string=} Cached item namespace
   * @param maxAge {number=} Max age of value in seconds until deleted
   */
  store(key, value, { namespace, maxAge } = {}) {
    const now = new Date();
    // Store item
    let item = { value: value };
    if (maxAge) {
      item.expiry = now.getTime() + maxAge * 1000;
    }
    localStorage.setItem(this.#makeKey(namespace, key), JSON.stringify(item));
  }

  /**
   * Retrieve item from the cache. If the expired returns null.
   * @param key {string} Cached item key
   * @param namespace {string} Cached item namespace
   * @return {null|string}
   */
  retrieve(key, { namespace } = {}) {
    const value = localStorage.getItem(this.#makeKey(namespace, key));
    // Nothing stored under the key
    if (!value) {
      return null;
    }
    // Parse, because it was stored as string
    const item = JSON.parse(value);
    const now = new Date();
    // Compare the expiry time of the item with the current time
    if (item.expiry && now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(`${this.#name}:${key}`);
      return null;
    }
    return item.value;
  }

  /**
   * Remove item from the cache.
   * @param key {string} Cached item key
   * @param namespace {string} Cached item namespace
   */
  remove(key, { namespace } = {}) {
    localStorage.removeItem(this.#makeKey(namespace, key));
  }

  /**
   * Cleans expired items
   */
  clean({ namespace, force = false } = {}) {
    const now = new Date();
    forEach(keys(localStorage), (key) => {
      if (startsWith(key, this.#name)) {
        const item = JSON.parse(localStorage.getItem(key));
        if (force) {
          // Delete all when forced
          localStorage.removeItem(key);
        } else if (namespace) {
          // Delete items under namespace only
          if (namespace === this.#getNamespace(key)) {
            localStorage.removeItem(key);
          }
        } else if (item.expiry && now.getTime() > item.expiry) {
          // Delete expired
          localStorage.removeItem(key);
        }
      }
    });
  }
}
