// Helpers
import { filter, forEach } from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";
import { AppEventBus } from "sw";

export class PWA extends StackDependency {
  #eventBus;
  #version;
  #log;
  #subscribers = [];
  #isReloading = false;
  #state;

  onInitialized() {
    const eventBus = new AppEventBus();
    eventBus.addMessageHandler(this.#messageHandler);
    this.#eventBus = eventBus;
    this.#log = this.context.log;
  }

  /**
   * Subscribe to pwa changes.
   * Returns unsubscribe function you need to call in order to
   * stop the event observation in e.g. `useEffect` function.
   * @param callback {function} Callback called when state changes
   * @param callOnBind {boolean} If `true` the callback is called right after the callback is bound.
   * @return {function}
   */
  onChange(callback, callOnBind = false) {
    this.#subscribers.push(callback);
    if (callOnBind) {
      callback(this.#state);
    }
    return () => {
      this.#subscribers = filter(this.#subscribers, (s) => s !== callback);
    };
  }

  /**
   * Called when pwa state changes
   */
  #handleChange = () => {
    forEach(this.#subscribers, (callback) => callback(this.#state));
  };

  #messageHandler = (message) => {
    const { type, payload = {} } = message;
    switch (type) {
      case "RELOAD":
        if (!this.#isReloading) {
          this.#isReloading = true;
          window.location.reload();
        }
        break;
      case "VERSION":
        const { version } = payload;
        this.#log.info("🛠️", "SW Version", version);
        this.#version = version;
        break;
      case "WAITING":
        this.#state = "waiting";
        // Notify all subscribers
        this.#handleChange();
        break;
    }
  };

  /**
   * Returns service worker version
   */
  get version() {
    return this.#version;
  }

  /**
   * Updates service worker version
   */
  update = () => {
    return this.#eventBus.postMessage({ type: "UPDATE" });
  };
}
