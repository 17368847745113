import { useRef } from "react";

/**
 * Initializes stack dependency. Injects `options` and calls `onInitialized` callback.
 * Holds the reference to the given dependency, so the initialization
 * happens only once.
 * @param dependency
 * @param options
 */
export const useStackDependency = (dependency, options) => {
  const ref = useRef();
  if (!ref.current) {
    dependency.setOptions(options);
    dependency.onInitialized();
    ref.current = dependency;
  }
  return ref.current;
};
