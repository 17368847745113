// Helpers
import { first } from "@mefisto/utils";
// Components
import { onError } from "@apollo/client/link/error";

export const ErrorLink = ({ context }) => {
  const { snackbar, log, analytics } = context;
  return onError(({ networkError, graphQLErrors }) => {
    if (networkError) {
      snackbar.showError(networkError);
      analytics.logError(networkError);
    } else if (graphQLErrors) {
      const error = first(graphQLErrors);
      snackbar.showError(error);
      analytics.logError(error);
      log.error(graphQLErrors);
    }
  });
};
