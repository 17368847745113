// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { FormControlLabel, Radio } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const FormRadioGroupFieldOption = ({
  label,
  labelPlacement,
  value,
  control,
  disabled,
}) => (
  <FormControlLabel
    label={label}
    labelPlacement={labelPlacement}
    value={value}
    control={control ?? <Radio />}
    disabled={disabled}
  />
);

FormRadioGroupFieldOption.propTypes = {
  /**
   * The text to be used in an enclosing label element.
   */
  label: PropTypes.node,
  /**
   * The value of the component.
   */
  value: PropTypes.any,
  /**
   * The position of the label.
   * Default: `end`
   */
  labelPlacement: PropTypes.oneOf(["bottom", "end", "start", "top"]),
  /**
   * A control element. For instance, it can be a Radio, a Switch or a Checkbox.
   */
  control: PropTypes.element,
  /**
   * If true, the control will be disabled.
   */
  disabled: PropTypes.bool,
};

export default FormRadioGroupFieldOption;
