// React
import React, { useCallback } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal, FlatIcon } from "@mefisto/web";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const NavbarItem = ({ navbarItem, onOpen, onClose }) => {
  // Framework
  const { navigation } = usePortal();
  // Handlers
  const handleSelection = useCallback(
    (event) => {
      if (navbarItem.path) {
        navigation.goTo(navbarItem.path);
      } else {
        onOpen(event);
      }
    },
    [navigation, navbarItem, onOpen]
  );
  // Render
  return (
    <div onMouseEnter={onOpen} onMouseLeave={onClose}>
      <FlatIcon
        selected
        size="medium"
        icon={navbarItem.icon}
        color={navbarItem.color}
        onClick={handleSelection}
      />
    </div>
  );
};
NavbarItem.propTypes = {
  navbarItem: PropTypes.object.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

export default NavbarItem;
