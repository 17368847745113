// React
import React, { memo, forwardRef } from "react";
// Framework
import { SvgIcon } from "ui";

/**
 * Private module reserved for @material-ui packages.
 */
export const createSvgIcon = (
  path,
  displayName,
  {
    width,
    height,
    viewBox = "0 0 512 512",
    enableBackground,
    fill,
    fillRule,
  } = {}
) => {
  const Component = (props, ref) => (
    <SvgIcon
      width={width}
      height={height}
      viewBox={viewBox}
      data-testid={`${displayName}Icon`}
      ref={ref}
      fill={fill}
      fillRule={fillRule}
      enableBackground={enableBackground}
      style={{ fill }}
      {...props}
    >
      {path}
    </SvgIcon>
  );
  if (process.env.NODE_ENV !== "production") {
    // Need to set `displayName` on the inner component for React.memo.
    // React prior to 16.14 ignores `displayName` on the wrapper.
    Component.displayName = `${displayName}Icon`;
  }
  Component.muiName = SvgIcon.muiName;
  return memo(forwardRef(Component));
};
