// React
import React, {
  memo,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { upperCase, camelCase, noop, join, compact, has } from "@mefisto/utils";
// Framework
import { usePortal } from "stack/core";
import { Table } from "ui/table";
import { useResources } from "resource";
import { useEntityListLazy } from "model/hooks";
import { EntityPropType } from "model/utils";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelTable = forwardRef(
  (
    {
      entity: Entity,
      relation,
      input,
      resources: customResources,
      languages,
      search,
      pagination: customPagination,
      fetchPolicy,
      nextFetchPolicy,
      tags,
      columns,
      disableStateStorage,
      tableProps,
      emptyPlaceholderProps,
      children,
      onChange,
    },
    ref
  ) => {
    // Framework
    const { ui } = usePortal();
    // Resources
    const { resources: defaultResources } = useResources();
    const resources = useMemo(() => {
      return customResources ?? defaultResources;
    }, [customResources, defaultResources]);
    // Pagination
    const getPagination = useCallback(
      (state) => {
        return camelCase(
          join(compact([Entity.TypeName, relation, state]), " ")
        );
      },
      [Entity, relation]
    );
    const [orderBy, setOrderBy] = useState(
      ui.get(getPagination("orderBy")) ?? customPagination?.orderBy ?? "key"
    );
    const [direction, setDirection] = useState(
      upperCase(
        ui.get(getPagination("direction")) ??
          customPagination?.direction ??
          "ASC"
      )
    );
    const pagination = useMemo(() => {
      return {
        ...customPagination,
        ...(!has(customPagination, "start") && { orderBy, direction }),
      };
    }, [customPagination, orderBy, direction]);
    // Model
    const { error, data: list, fetch, fetchMore, loading } = useEntityListLazy(
      Entity,
      {
        input,
        resources,
        languages,
        search,
        relation,
        pagination,
        fetchPolicy,
        nextFetchPolicy,
        tags,
      }
    );
    // Ref
    useImperativeHandle(ref, () => ({
      refresh() {
        fetch().catch(noop);
      },
    }));
    // Effects
    useEffect(() => {
      fetch().catch(noop);
    }, [fetch]);
    useEffect(() => {
      onChange && onChange(list);
    }, [list, onChange]);
    // Callbacks
    const handleRefresh = useCallback(() => {
      fetch().catch(noop);
    }, [fetch]);
    const handleLoadMore = useCallback(() => {
      fetchMore().catch(noop);
    }, [fetchMore]);
    const handleSortChange = useCallback(
      (orderBy, direction) => {
        setOrderBy(orderBy);
        setDirection(direction);
        if (!disableStateStorage) {
          ui.set(getPagination("orderBy"), orderBy);
          ui.set(getPagination("direction"), direction);
        }
      },
      [ui, getPagination, disableStateStorage]
    );
    // Render
    return (
      <Table
        loading={loading}
        error={error}
        hasMore={list.hasMore}
        data={list.data}
        direction={direction}
        orderBy={orderBy}
        columns={columns}
        emptyTitle={emptyPlaceholderProps?.title}
        emptySubtitle={emptyPlaceholderProps?.subtitle}
        onLoadMore={handleLoadMore}
        onSortChange={handleSortChange}
        onRefresh={handleRefresh}
        {...tableProps}
      >
        {children}
      </Table>
    );
  }
);

ModelTable.propTypes = {
  /**
   * Model entity used for the feed
   */
  entity: EntityPropType,
  /**
   * Entity relation
   */
  relation: PropTypes.string,
  /**
   * Input data
   */
  input: PropTypes.object,
  /**
   * Resources data
   */
  resources: PropTypes.object,
  /**
   * List of requested languages
   */
  languages: PropTypes.array,
  /**
   * Search query
   */
  search: PropTypes.string,
  /**
   * Pagination props
   */
  pagination: PropTypes.oneOfType([
    PropTypes.shape({
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      pointer: PropTypes.string,
      limit: PropTypes.number,
      direction: PropTypes.oneOf(["ASC", "DESC"]),
      orderBy: PropTypes.string,
      equalTo: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string,
      ]),
    }),
  ]),
  /**
   * Fetch policy of the list request
   */
  fetchPolicy: PropTypes.string,
  /**
   * Next fetch policy of the list request
   */
  nextFetchPolicy: PropTypes.string,
  /**
   * Custom tags used to list the data
   */
  tags: PropTypes.any,
  /**
   * Array with `TableColumn` elements
   */
  columns: PropTypes.arrayOf(PropTypes.node),
  /**
   * If set to `true`, table state is not stored in UI
   */
  disableStateStorage: PropTypes.bool,
  /**
   * Props passed to Table
   */
  tableProps: PropTypes.object,
  /**
   * Empty placeholder data
   */
  emptyPlaceholderProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  }),
  /**
   * Children node or func. Use to display each feed item.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Called when data in feed change
   */
  onChange: PropTypes.func,
};

export default memo(ModelTable);
