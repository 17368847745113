// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { noop } from "@mefisto/utils";
// Framework
import ContainerDimensions from "react-container-dimensions";
import { classnames } from "ui/classnames";
import { makeStyles, Box } from "ui/components";
// Components
import InfiniteScroll from "./components/InfiniteScroll";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles({
  empty: {
    height: "100%",
  },
});

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const InfiniteList = ({
  children,
  isEmpty,
  hasMore,
  loadMore,
  pageStart,
  initialLoad,
  threshold,
  isReverse,
  onScroll,
  overflowX = "auto",
  overflowY = "auto",
  heightPadding,
  scrollToBottom,
  contentProps,
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <ContainerDimensions>
      {({ height }) => (
        <div
          style={{
            overflowX,
            overflowY,
            height: height + heightPadding,
          }}
        >
          <InfiniteScroll
            pageStart={pageStart}
            initialLoad={initialLoad}
            hasMore={hasMore}
            loadMore={loadMore}
            threshold={threshold}
            isReverse={isReverse}
            lockScroll={true}
            onScroll={onScroll}
            scrollToBottom={scrollToBottom}
          >
            <Box
              className={classnames({ [classes.empty]: isEmpty })}
              {...contentProps}
            >
              {children}
            </Box>
          </InfiniteScroll>
        </div>
      )}
    </ContainerDimensions>
  );
};

InfiniteList.propTypes = {
  isEmpty: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  pageStart: PropTypes.number,
  initialLoad: PropTypes.bool,
  threshold: PropTypes.number,
  isReverse: PropTypes.bool,
  heightPadding: PropTypes.number,
  onScroll: PropTypes.func,
  scrollToBottom: PropTypes.func,
};

InfiniteList.defaultProps = {
  pageStart: 0,
  initialLoad: false,
  threshold: 100,
  isReverse: false,
  heightPadding: 0,
  onScroll: noop,
  scrollToBottom: noop,
};

export default InfiniteList;
