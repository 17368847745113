import { useRef, useEffect, useCallback } from "react";

/**
 * Returns function with one parameter callback.
 * The callback is only called when the component is mounted.
 * @returns {{callWhenMounted: (function(*): function(...[*]): void)}}
 */
export const useMounted = () => {
  // Ref
  const isMounted = useRef(false);
  // Effects
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  // Callbacks
  const callWhenMounted = useCallback(
    (callback) => (...params) => {
      if (isMounted.current) {
        callback(...params);
      }
    },
    []
  );
  // Render
  return { callWhenMounted };
};
