// Helpers
import {
  filter,
  forEach,
  get,
  has,
  isEmpty,
  isObject,
  lowerFirst,
  startsWith,
  endsWith,
  reduce,
  pick,
  uniqBy,
} from "@mefisto/utils";
import { makeVar } from "@apollo/client";
// Components
import { operationName, paginatedList } from "../helpers/query";

/**
 * Bag with all refresh tokens related to all entities defined in the app
 * @type {object}
 */
const refreshTokens = {};

/**
 * Generates random refresh token
 * @return {string}
 */
const generateRefreshToken = () => {
  return Math.random().toString(36).slice(2);
};

class Entity {
  #queue;
  #client;
  #cache;

  /**
   * Single model entity
   * @param queue Queue
   * @param client ApolloClient
   */
  constructor(client, { queue } = {}) {
    if (!client) {
      throw TypeError("Cannot create instance of Entity without client");
    }
    this.#client = client;
    this.#cache = client.cache;
    this.#queue = queue;
  }

  /**
   * Name of the entity type
   * @required
   */
  static get TypeName() {
    throw TypeError("Entity must override the `TypeName` getter");
  }

  /**
   * Entity schema
   * @required
   * @return {object|null}
   */
  static get Schema() {
    throw TypeError("Entity must override the `Schema` getter");
  }

  /**
   * Entity tags
   * @return {object|null}
   */
  static get Tags() {
    return null;
  }

  /**
   * Reactive refresh token related to the entity
   * @return {function}
   */
  static get refreshToken() {
    const key = this.TypeName;
    if (!refreshTokens[key]) {
      refreshTokens[key] = makeVar(generateRefreshToken());
    }
    return refreshTokens[key];
  }

  /**
   * Refreshes entity data. Triggers refreshToken update which
   * will result in "refresh" action on all Entity UI elements.
   */
  static refresh() {
    this.refreshToken(generateRefreshToken());
  }

  /**
   * Returns entity fields
   * @return {object}
   */
  get fields() {
    const schema = this.constructor.Schema;
    return get(schema, [this.singular, "fields"]);
  }

  /**
   * Specify which keys should be assigned to each action.
   * Replacement for `keyFields`
   * @return {{read: (*|string[]), update: (*|string[])}}
   */
  get keys() {
    const schema = this.constructor.Schema;
    const keys = get(schema, [this.singular, "keys"]);
    return {
      read: keys?.read ?? this.keyFields ?? [this.singular],
      update: keys?.update ?? this.keyFields ?? [this.singular],
    };
  }

  /**
   * Entity relations take from schema
   * @return {object}
   */
  get relations() {
    const schema = this.constructor.Schema;
    return get(schema, [this.singular, "relations"]);
  }

  /**
   * Entity singular name
   * @return {string}
   */
  get singular() {
    return lowerFirst(this.constructor.TypeName);
  }

  /**
   * Entity plural name
   * @return {string}
   */
  get plural() {
    const schema = this.constructor.Schema;
    return get(schema, [this.singular, "plural"], `${this.singular}s`);
  }

  /**
   * List of entity key names
   * @return {string[]}
   * @deprecated Use schema "keys" field
   */
  get keyFields() {
    // Entity name is also the entity key
    return [this.singular];
  }

  // KeyArgs specifies which of the field's arguments cause the cache to store
  // a separate value for each unique combination of those arguments.
  get keyArgs() {
    return false;
  }

  get omitKeys() {
    return false;
  }

  /**
   * Returns `true` if the type policies should be added to Model
   */
  static get hasTypePolicies() {
    return true;
  }

  /**
   * Entity type policies
   * @return {object}
   */
  get typePolicies() {
    const typeName = this.constructor.TypeName;
    return {
      [typeName]: {
        keyFields: this.omitKeys ? ["key"] : this.keys.read,
        fields: {
          ...reduce(
            this.relations,
            (result, { plural, type }, name) => {
              if (type === "1:N" || type === "M:N") {
                result[plural || `${name}s`] = paginatedList(this.keyArgs);
              }
              return result;
            },
            {}
          ),
          ...reduce(
            this.fields,
            (result, field, key) => {
              if (isObject(field)) {
                const { type, merge = true } = field;
                if (startsWith(type, "[") && endsWith(type, "]")) {
                  result[key] = { merge: false };
                } else {
                  result[key] = { merge };
                }
              } else if (field === "array") {
                result[key] = {
                  merge: false,
                };
              }
              return result;
            },
            {}
          ),
        },
      },
      Query: {
        fields: {
          [`${this.singular}List`]: paginatedList(this.keyArgs),
          [`${this.singular}Read`]: (entity, { args, toReference }) => {
            // Entity is already found
            if (entity) {
              return entity;
            }
            // Get input from args
            const input = get(args, "input", {});
            const keys = pick(input, this.keys.read);
            return isEmpty(keys)
              ? entity
              : toReference({ __typename: typeName, ...keys });
          },
        },
      },
    };
  }

  /**
   * Optimistic response for the given tag name.
   * @param tagName {string}
   * @param data {object}
   * @return {object}
   */
  optimisticResponse(tagName, data) {
    return {
      __typename: "Mutation",
      [tagName]: {
        __typename: this.constructor.TypeName,
        ...data,
      },
    };
  }

  /**
   * Returns string name of the tag.
   * @example For `OrganizationCreate` tag returns `organizationCreate`.
   * @param tag {object}
   * @return {string}
   */
  tagName(tag) {
    return operationName(tag);
  }

  /**
   * Takes each file and adds it to the upload queue.
   * Modifies the cache accordingly.
   */
  #uploadFiles = ({ entity, resources, files, silent }) => {
    const { UPDATE } = this.constructor.Tags;
    if (!UPDATE || !this.#queue) {
      return;
    }
    forEach(files, (file, fileEntity) => {
      // There is no File in entity
      if (!entity[fileEntity]) {
        return;
      }
      const {
        [fileEntity]: { path, signedUrl },
      } = entity;
      // Signed url must be provided by the server
      if (!signedUrl) {
        return;
      }
      // Get the list of keys and their values from entity
      const keys = pick(entity, this.keys.update);
      // Get the id of the cached file entity
      const id = this.#cache.identify(entity[fileEntity]);
      // Modify the entity because in this stage we received
      // url from the server but the file is not uploaded yet
      // thus if we display the image we'll get the 403 error.
      this.#cache.modify({
        id,
        fields: {
          contentType: () => file.type,
          size: () => file.size,
          url: () => URL.createObjectURL(file),
        },
      });
      // Start the upload
      this.#queue.addJob({
        file,
        signedUrl,
        silent,
        onFinished: async ({ name, contentType, generation, size }) => {
          await this.#client.mutate({
            mutation: UPDATE,
            variables: {
              input: {
                ...keys,
                [fileEntity]: {
                  // This is a flag that will let the server know this is a
                  // entity metadata update request
                  _finish: null,
                  name,
                  path,
                  contentType,
                  generation,
                  size,
                },
              },
              ...(resources
                ? {
                    // When we create the organization for the first time we don't have
                    // the organization resource key yet, thus we can't update the resource
                    // after the cover or profile image upload. However, we can taken the key
                    // from the entity returned from the backend.
                    resources:
                      !get(resources, "organization") &&
                      has(entity, "organization")
                        ? { organization: entity.organization }
                        : resources,
                  }
                : {}),
            },
          });
        },
      });
    });
  };

  /**
   * Adds or removes entity from the list
   * @param action {"append"|"prepend"|"remove"} Position of the added entity
   * @param list {[]} Current list
   * @param toReference {function} Function that can define reference
   * @param data {object} entity data
   * @param tag {string} entity tag name
   * @return {[]} Updated list
   */
  #getListData = (list, { action, toReference, data, tag }) => {
    const cache = this.#cache;
    switch (action) {
      case "append":
        return uniqBy([...list, toReference(data[tag])], (item) =>
          cache.identify(item)
        );
      case "prepend":
        return uniqBy([toReference(data[tag]), ...list], (item) =>
          cache.identify(item)
        );
      case "remove":
        return filter(
          list,
          (entity) => cache.identify(data[tag]) !== cache.identify(entity)
        );
      default:
        throw Error("Unknown action");
    }
  };

  /**
   * Adds or removes entity from all the lists where
   * the entity should appear.
   */
  #modifyList = ({ action, data, tag }) => {
    const { LIST } = this.constructor.Tags;
    if (!LIST) {
      return;
    }
    this.#cache.modify({
      fields: {
        [this.tagName(LIST)]: (list, { toReference }) => {
          return {
            ...list,
            data: this.#getListData(list.data, {
              action,
              toReference,
              data,
              tag,
            }),
          };
        },
      },
    });
  };

  /**
   * Adds or removes entity from all the relation lists where
   * the entity should appear.
   */
  #modifyRelationLists = ({ action, data, tag }) => {
    forEach(this.relations, ({ type }, key) => {
      // Only reverse relations
      if (type === "N:1") {
        const relation = get(data, [tag, key]);
        // Only if the relation is not null
        if (relation) {
          const relationId = this.#cache.identify(relation);
          // Only if the relation entity exists in the cache
          if (relationId) {
            this.#cache.modify({
              id: relationId,
              fields: {
                [this.plural]: (list, { toReference }) => {
                  return {
                    ...list,
                    data: this.#getListData(list.data, {
                      action,
                      toReference,
                      data,
                      tag,
                    }),
                  };
                },
              },
            });
          }
        }
      }
    });
  };

  /**
   * Called after entity is created on server.
   * Modifies cache accordingly.
   * If there are any files to be uploaded adds them to the upload queue.
   */
  onCreate({ resources, data, files, silent } = {}) {
    const { CREATE } = this.constructor.Tags;
    const tag = this.tagName(CREATE);
    const entity = data[tag];
    if (!entity) {
      return;
    }
    // Modify lists
    const action = "prepend";
    this.#modifyList({ action, data, tag });
    this.#modifyRelationLists({ action, data, tag });
    // Upload files
    if (!isEmpty(files)) {
      this.#uploadFiles({
        entity,
        resources,
        files,
        silent,
      });
    }
  }

  /**
   * Called after entity is successfully updated on server.
   * Modifies cache accordingly.
   * If there are any files to be uploaded adds them to the upload queue.
   */
  onUpdate({ resources, data, files, silent } = {}) {
    const { UPDATE } = this.constructor.Tags;
    const tag = this.tagName(UPDATE);
    const entity = data[tag];
    if (!entity) {
      return;
    }
    // Upload files
    if (!isEmpty(files)) {
      this.#uploadFiles({
        entity,
        resources,
        files,
        silent,
      });
    }
  }

  /**
   * Called after entity is successfully deleted on server.
   * Modifies cache accordingly.
   */
  onDelete({ data } = {}) {
    const { DELETE } = this.constructor.Tags;
    const tag = this.tagName(DELETE);
    const entity = data[tag];
    if (!entity) {
      return;
    }
    // Modify lists
    const action = "remove";
    this.#modifyList({ action, data, tag });
    this.#modifyRelationLists({ action, data, tag });
  }
}

export default Entity;
