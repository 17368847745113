// React
import React, { isValidElement, cloneElement } from "react";
import PropTypes from "prop-types";
// Helpers
import { map, compact } from "@mefisto/utils";
// Framework
import { makeStyles, TableHead } from "ui/components";
import { TableRow } from "@material-ui/core";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  head: {
    "&:after": {
      height: theme.spacing(1.5),
      display: "table-row",
      content: "' '",
    },
  },
  row: {
    height: theme.spacing(5),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TableHeader = ({ columns, direction, orderBy, onSort }) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <TableHead className={classes.head}>
      <TableRow className={classes.row}>
        {map(compact(columns), (column, index) => {
          if (isValidElement(column)) {
            return cloneElement(column, {
              key: index,
              __renderProps: {
                index,
                columns: compact(columns),
                isFirst: index === 0,
                isLast: index === compact(columns).length - 1,
                orderBy,
                direction,
                onSort,
              },
            });
          }
        })}
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.array,
  direction: PropTypes.string,
  orderBy: PropTypes.string,
  onSort: PropTypes.func,
};

export default TableHeader;
