// React
import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
// Helpers
import { forEach } from "@mefisto/utils";
// Components
import { FirebaseContext } from "firebase/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Firebase = (props) => {
  // Context
  const context = useContext(FirebaseContext);
  // Set values
  forEach(props, (value, key) => {
    context[key] = value;
  });
  // Render
  return <Fragment />;
};

Firebase.defaultProps = {
  __TYPE: "Firebase",
};

Firebase.propTypes = {
  modules: PropTypes.arrayOf(
    PropTypes.oneOf([
      "analytics",
      "auth",
      "database",
      "firestore",
      "messaging",
      "performance",
      "remoteConfig",
      "storage",
    ])
  ),
};

export default Firebase;
