import { createFilter } from "../helpers";

/**
 * Ocean filter manipulation
 */
export const ocean = () => async (canvasImage) =>
  createFilter(canvasImage, (imageData) => {
    for (let i = 0; i < imageData.data.length; i += 4) {
      imageData.data[i] += 10;
      imageData.data[i + 1] += 20;
      imageData.data[i + 2] += 90;
    }
    return imageData;
  });
