// React
import React, {
  cloneElement,
  isValidElement,
  useCallback,
  useMemo,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty, get } from "@mefisto/utils";
// Framework
import { usePortal } from "stack";
import { InputAdornment } from "ui/components";
import { FormField } from "form/components";
// Components
import KeyboardDatePicker from "../KeyboardDatePicker";
import KeyboardTimePicker from "../KeyboardTimePicker";
import KeyboardDateTimePicker from "../KeyboardDateTimePicker";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const DatePicker = (props) => <KeyboardDatePicker {...props} />;
const TimePicker = (props) => <KeyboardTimePicker {...props} />;
const DateTimePicker = (props) => <KeyboardDateTimePicker {...props} />;

const Component = ({
  picker: Picker,
  PickerProps = {},
  fullWidth = true,
  autoOk = true,
  clearable = true,
  scope,
  label,
  helperText,
  placeholder,
  form,
  field,
  icon,
  format = "MM/DD/YYYY",
  clockTime = false,
  minDate,
  maxDate,
  disablePast,
  disableFuture,
}) => {
  // Params
  const { name, value } = field;
  const { touched, errors, setFieldValue } = form;
  const fieldError = get(errors, name);
  const showError = get(touched, name) && !!fieldError;
  // Framework
  const { time } = usePortal();
  // Value
  const mappedValue = useMemo(() => {
    return !isEmpty(value)
      ? time.fromUtcTimestamp(value, {
          clockTime,
        })
      : null;
  }, [time, clockTime, value]);
  // Handlers
  const handleChange = useCallback(
    (date) => {
      let result = date;
      if (!isEmpty(date) && scope === "date") {
        result = time.day(date);
      }
      const value = !isEmpty(date)
        ? time.toUtcTimestamp(result, {
            clockTime,
          })
        : "";
      setFieldValue(name, value, true);
    },
    [time, name, setFieldValue, clockTime, scope]
  );
  // Render
  return (
    <Picker
      clearable={clearable}
      autoOk={autoOk}
      fullWidth={fullWidth}
      format={format}
      value={mappedValue}
      label={label}
      placeholder={placeholder}
      helperText={showError ? fieldError : helperText}
      error={showError}
      inputVariant="outlined"
      KeyboardButtonProps={{ variant: "outlined" }}
      InputProps={{
        startAdornment: isValidElement(icon) ? (
          <InputAdornment position="start">
            {cloneElement(icon, {
              color: showError ? "error" : "action",
              fontSize: "small",
            })}
          </InputAdornment>
        ) : null,
      }}
      InputLabelProps={{ shrink: true }}
      onChange={handleChange}
      minDate={minDate}
      maxDate={maxDate}
      disablePast={disablePast}
      disableFuture={disableFuture}
      {...PickerProps}
    />
  );
};

const pickerFromScope = (scope) => {
  switch (scope) {
    case "date":
      return DatePicker;
    case "time":
      return TimePicker;
    case "dateTime":
      return DateTimePicker;
  }
};

const DateFormField = ({ scope = "date", ...rest }) => (
  <FormField
    component={Component}
    picker={pickerFromScope(scope)}
    scope={scope}
    {...rest}
  />
);

DateFormField.propTypes = {
  scope: PropTypes.oneOf(["date", "time", "dateTime"]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.element,
  fullWidth: PropTypes.bool,
  autoOk: PropTypes.bool,
  clockTime: PropTypes.bool,
  format: PropTypes.string,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
};

export default DateFormField;
