// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { isString } from "@mefisto/utils";
// Framework
import { makeStyles, classnames, Container, Box } from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  rounded: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderOuterContainer = ({
  display,
  horizontalFill,
  height,
  children,
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Box display="flex" alignItems="center" height={height}>
      <Container
        maxWidth={isString(horizontalFill) ? horizontalFill : !horizontalFill}
        className={classnames({ [classes.rounded]: display === "rounded" })}
      >
        <Box display="flex" alignItems="center" flexWrap="nowrap">
          {children}
        </Box>
      </Container>
    </Box>
  );
};

HeaderOuterContainer.propTypes = {
  horizontalFill: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
};

export default HeaderOuterContainer;
