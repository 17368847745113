// React
import { useMemo } from "react";
import { getChildrenByTypeDeep } from "react-nanny";
// Helpers
import { reduce } from "@mefisto/utils";

/**
 * Filters types from the given children
 * @param children
 * @param types
 * @return {{}} Object with filtered types
 */
export const useChildrenTypes = (children, types = []) => {
  return useMemo(() => {
    return reduce(
      getChildrenByTypeDeep(children, types),
      (result, child) => ({
        ...result,
        ...(child.props.__TYPE && {
          [child.props.__TYPE]: [
            ...(result[child.props.__TYPE] ? result[child.props.__TYPE] : []),
            child,
          ],
        }),
      }),
      {}
    );
  }, [children, types]);
};
