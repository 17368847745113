// React
import React, { createContext, useContext } from "react";

export const TagManagerContext = createContext({});

/**
 * @return {{ tagManager: import("../TagManager").TagManager }}
 */
export const useTagManager = () => {
  return useContext(TagManagerContext);
};
