import { createFilter } from "../helpers";

/**
 * Wood filter manipulation
 */
export const wood = () => async (canvasImage) =>
  createFilter(canvasImage, (imageData) => {
    for (let i = 0; i < imageData.data.length; i += 4) {
      imageData.data[i] = imageData.data[i] + 30;
      imageData.data[i + 1] = imageData.data[i + 1] + 12;
    }
    return imageData;
  });
