// React
import React, { memo, useMemo } from "react";
// Helpers
import { map, range } from "@mefisto/utils";
// Framework
import { Skeleton, Box, Avatar } from "ui/components";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TableRowSkeleton = ({ x, y }) => {
  // Memo
  const width = useMemo(() => `${x > 1 ? 100 / (x + 1) : 100}%`, [x]);
  const mr = useMemo(() => `${x > 1 ? 100 / (x + 1) / x : 0}%`, [x]);
  // Render
  return (
    <Box
      position="absolute"
      overflow="hidden"
      width="100%"
      height="100%"
      bgcolor="common.white"
      zIndex="tooltip"
    >
      <Box px={3}>
        {map(range(y), (_, key) => (
          <Box key={key} display="flex" alignItems="center" mt={1} mb={2}>
            {map(range(x), (_, key) => (
              <Box
                key={key}
                display="flex"
                alignItems="center"
                width={width}
                mr={mr}
              >
                {key === 0 && (
                  <Box mr="10%">
                    <Skeleton variant="circle">
                      <Avatar />
                    </Skeleton>
                  </Box>
                )}
                <Skeleton width="100%" />
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default memo(TableRowSkeleton);
