// React
import React, { Children } from "react";
// Framework
import { makeStyles, List } from "ui/components";
import { Section } from "ui/section";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, -2),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileInfoLayout = ({ context = "infoLayout", value, children }) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Section context={context} value={value}>
      {Children.count(children) > 0 && (
        <List dense className={classes.root}>
          {children}
        </List>
      )}
    </Section>
  );
};

export default TileInfoLayout;
