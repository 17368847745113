// React
import React from "react";
// Helpers
import {
  reduce,
  first,
  forEach,
  entries,
  keys,
  set,
  filter,
  compact,
  values,
  isEqual,
  map,
  pickBy,
  includes,
} from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";
import { AuthState } from "authentication";

export class Authorization extends StackDependency {
  #authentication;
  #database;
  #cache;
  #log;
  #subscribers = [];
  #roles = {};
  #permissions = {};
  #userRoles = {};
  #userPermissions = {};
  #userResources = {};

  onInitialized() {
    const { authentication, firebase, cache, log } = this.context;
    this.#authentication = authentication;
    this.#database = firebase.database();
    this.#cache = cache;
    this.#log = log;
    this.#makePermissions();
  }

  /**
   * Subscribe to change.
   * Returns unsubscribe function you need to call in order to
   * stop the event observation in e.g. `useEffect` function.
   * @param callback {function} Callback called when state changes
   * @param callOnBind {boolean} If `true` the callback is called right after the callback is bound.
   * @return {function}
   */
  onChange(callback, callOnBind = false) {
    this.#subscribers.push(callback);
    if (callOnBind) {
      callback(this);
    }
    return () => {
      this.#subscribers = filter(this.#subscribers, (s) => s !== callback);
    };
  }

  /**
   * Call to notify subscribers
   */
  #handleChange = ({
    permissions = {},
    roles = {},
    userRoles = {},
    userPermissions = {},
    userResources = {},
  } = {}) => {
    if (
      isEqual(this.#permissions, permissions) &&
      isEqual(this.#roles, roles) &&
      isEqual(this.#userPermissions, userPermissions) &&
      isEqual(this.#userResources, userResources) &&
      isEqual(this.#userRoles, userRoles)
    ) {
      // If there's no change, there's no need to notify subscribers
      return;
    }
    this.#permissions = permissions;
    this.#roles = roles;
    this.#userPermissions = userPermissions;
    this.#userResources = userResources;
    this.#userRoles = userRoles;
    this.#log.info("🛡️", {
      authorization: {
        permissions,
        roles,
        userPermissions,
        userResources,
        userRoles,
      },
    });
    // Notify subscribers
    forEach(this.#subscribers, (callback) => callback(this));
  };

  /**
   * Starts user permission sync
   */
  syncUserPermissions = () => {
    let ref;
    let callback;
    this.#authentication.onChange(({ state, data }) => {
      // Remove previous callback
      if (ref && callback) {
        ref.off("value", callback);
      }
      switch (state) {
        // Authenticated
        case AuthState.authenticated: {
          this.#syncRoles().then((roles) => {
            // Read user permissions
            ref = this.#database.ref(`userPermissions/${data.uid}`);
            callback = ref.on("value", (snapshot) => {
              // Map data
              const userPermissions = this.#makeUserPermissions(
                snapshot.val(),
                roles
              );
              const userRoles = this.#makeUserRoles(snapshot.val(), roles);
              const userResources = this.#makeUserResources(
                snapshot.val(),
                roles
              );
              // Broadcast
              this.#handleChange({
                roles,
                permissions: this.#permissions,
                userRoles,
                userPermissions,
                userResources,
              });
            });
          });
          break;
        }
        // Not Authenticated
        case AuthState.notAuthenticated:
          this.#handleChange();
          break;
        default:
          break;
      }
    }, true);
  };

  /**
   * Starts sync roles process
   */
  #syncRoles = () => {
    return new Promise((resolve) => {
      // TODO: Read from cache #313
      this.#database.ref(`roles`).once("value", (snapshot) => {
        const value = snapshot.exists() ? snapshot.val() : {};
        resolve(value);
      });
    });
  };

  /**
   * Maps permissions in a form of {[permission.key]: boolean}
   */
  #makePermissions = () => {
    const { apps } = this.context;
    forEach(apps.permissions, (app, key) => {
      this.#permissions[key] = reduce(
        app,
        (result, permission) => {
          result[permission.key] = permission;
          return result;
        },
        {}
      );
    });
  };

  /**
   * Maps user roles to the form of {[resourceKey]: {[permission]: true}}
   */
  #makeUserPermissions = (permissions, roles) => {
    let result = {};
    for (const [resource, userRoles] of entries(permissions)) {
      for (const roleKey of keys(userRoles)) {
        const role = roles[roleKey];
        if (role) {
          set(
            result,
            [role.app, resource],
            reduce(
              compact(role.permissions),
              (result, permission) => {
                result[permission] = true;
                return result;
              },
              {}
            )
          );
        }
      }
    }
    return result;
  };

  /**
   * Maps user roles to the form of {[roleKey]: role}
   */
  #makeUserRoles = (permissions, roles) => {
    return reduce(
      values(permissions),
      (result, values) => {
        for (const role of keys(values)) {
          result[role] = roles[role];
        }
        return result;
      },
      {}
    );
  };

  /**
   * Maps user resources roles to the form of {[resource]: role}
   */
  #makeUserResources = (permissions, roles) => {
    return reduce(
      permissions,
      (result, userRoles, resource) => {
        for (const role of keys(userRoles)) {
          const descendants = keys(permissions[resource][role]);
          result[resource] = {
            ...result[resource],
            [role]: {
              ...roles[role],
              descendants,
              ...reduce(
                userRoles[role],
                (result, { state, apps }, key) => {
                  result = {
                    states: {
                      ...result.states,
                      [key]: state,
                    },
                    apps: {
                      ...result.apps,
                      [key]: compact(
                        map(
                          apps,
                          (value, key) => value?.enabled && key?.substring(2)
                        )
                      ),
                    },
                    preferences: {
                      ...result.preferences,
                      [key]: reduce(
                        apps,
                        (result, value, key) => {
                          if (key) {
                            result[key.substring(2)] = value;
                          }
                          return result;
                        },
                        {}
                      ),
                    },
                  };
                  return result;
                },
                {}
              ),
            },
          };
        }
        return result;
      },
      {}
    );
  };

  /**
   * Returns locations that are authorized for the given app
   * @param organization {string} Organization key
   * @param app {string} App ID
   * @return {object}
   */
  findLocationsByApp(organization, app) {
    const superior = this.findRole(organization);
    return pickBy(superior?.apps, (bag) => {
      return includes(bag, app);
    });
  }

  /**
   * Returns user's superior role in organization
   * @param resource
   * @return {object|null}
   */
  findRole(resource) {
    return first(values(this.#userResources[resource])) ?? null;
  }

  /**
   * Returns `true` if the given role is "superior".
   * Superior role contains all permissions.
   * @param role {object}
   * @return {boolean}
   */
  isSuperior(role) {
    return role.app === "PORTAL" && !role.descendant;
  }

  /**
   * Returns all the roles in the system
   */
  get roles() {
    return this.#roles;
  }

  /**
   * Returns all the permissions in the system
   */
  get permissions() {
    return this.#permissions;
  }

  /**
   * Returns user's roles
   */
  get userRoles() {
    return this.#userRoles;
  }

  /**
   * Returns user's permissions
   */
  get userPermissions() {
    return this.#userPermissions;
  }

  /**
   * Returns user's resources
   */
  get userResources() {
    return this.#userResources;
  }
}
