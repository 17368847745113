// React
import React, { useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { reduce } from "@mefisto/utils";
// Framework
import { Grid, makeStyles } from "ui";
import { filterType } from "type";
// Components
import PortalLayoutProvider from "../PortalLayoutContext";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles(() => ({
  outer: {
    // Make the portal layout fill the whole screen.
    // We are using "dvh" unit, so it adopts to different layout
    // additions such as dynamic toolbars, etc.
    height: "100dvh",
    // Keep the "vh" as fallback for browser that do not support "dvh".
    fallbacks: [{ height: "100vh" }],
  },
  inner: {
    height: "100%",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const PortalLayout = ({ layoutPlugins, children }) => {
  // Styles
  const classes = useStyles();
  const plugins = useMemo(() => {
    return reduce(
      ["top", "bottom", "leading", "trailing"],
      (result, position) => ({
        ...result,
        [position]: filterType(layoutPlugins, { position }),
      }),
      {}
    );
  }, [layoutPlugins]);
  // Render
  return (
    <PortalLayoutProvider layoutProps={{ plugins }}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        justifyContent="space-between"
        className={classes.outer}
      >
        {plugins.top && <Grid item>{plugins.top}</Grid>}
        <Grid flex item>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            className={classes.inner}
          >
            {plugins.leading && <Grid item>{plugins.leading}</Grid>}
            <Grid flex item>
              {children}
            </Grid>
            {plugins.trailing && <Grid item>{plugins.trailing}</Grid>}
          </Grid>
        </Grid>
        {plugins.bottom && <Grid item>{plugins.bottom}</Grid>}
      </Grid>
    </PortalLayoutProvider>
  );
};

PortalLayout.propTypes = {
  layoutPlugins: PropTypes.node,
};

export default PortalLayout;
