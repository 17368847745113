// Helpers
import { isNil } from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";
// Components
import RemoteLogger from "stackdriver-errors-js";

export class Log extends StackDependency {
  #remoteLogger;
  #localEnabled;
  #remoteEnabled;

  onInitialized() {
    const { config } = this.context;
    const {
      logLocalEnabled,
      logRemoteEnabled,
      logRemoteKey,
      logRemoteProjectId,
    } = config.values;
    // Remote Logger
    if (logRemoteEnabled) {
      const remoteLogger = new RemoteLogger();
      remoteLogger.start({
        key: logRemoteKey,
        projectId: logRemoteProjectId,
        service: "webapp",
      });
      this.#remoteLogger = remoteLogger;
    }
    this.#localEnabled = logLocalEnabled;
    this.#remoteEnabled = logRemoteEnabled;
    // Log config
    this.info("🔧", { config: { ...config.values } });
  }

  /**
   * Stores user UID along with the error reports
   */
  setUser(user) {
    if (!isNil(this.#remoteLogger)) {
      this.#remoteLogger.setUser(user);
    }
  }

  /**
   * Logs info message to console if enabled
   */
  info(message, ...params) {
    if (this.#localEnabled) {
      console.info(message, ...params);
    }
  }

  /**
   * Logs warning message to console
   */
  warning(message, ...params) {
    if (this.#localEnabled) {
      console.warn(message, ...params);
    }
  }

  /**
   * Logs error message to console and remote server if enabled
   */
  error(error) {
    if (this.#localEnabled) {
      console.error(error);
    }
    if (this.#remoteEnabled && !isNil(this.#remoteLogger)) {
      // noinspection JSIgnoredPromiseFromCall
      this.#remoteLogger.report(error, {}).catch((error) => {
        console.log(error);
      });
    }
  }
}
