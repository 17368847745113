// React
import React from "react";
import PropTypes from "prop-types";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const PortalLayoutPlugin = ({ children }) => <>{children}</>;

PortalLayoutPlugin.defaultProps = {
  __TYPE: "PortalLayoutPlugin",
};

PortalLayoutPlugin.propTypes = {
  position: PropTypes.oneOf(["top", "bottom", "leading", "trailing"])
    .isRequired,
  children: PropTypes.node,
};

export default PortalLayoutPlugin;
