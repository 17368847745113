// React
import React, { useEffect, useState } from "react";
// Framework
import { useTranslate } from "localization";
import { Button } from "@material-ui/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const MarkdownEditorSavePlugin = ({ editor }) => {
  const { handleSave } = editor.props;
  // Framework
  const { t } = useTranslate();
  // State
  const [disabled, setDisabled] = useState(true);
  // Handlers
  const handleClick = () => {
    setDisabled(true);
    handleSave({ value: editor.getMdValue() });
  };
  // Effect
  useEffect(() => {
    const handleChange = () => {
      setDisabled(false);
    };
    editor.on("change", handleChange);
    return () => editor.off("change", handleChange);
  });
  return (
    <>
      {handleSave && (
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={handleClick}
        >
          {t("core:layout.markdown.editor.save.button")}
        </Button>
      )}
    </>
  );
};

MarkdownEditorSavePlugin.pluginName = "save";
MarkdownEditorSavePlugin.align = "right";

export default MarkdownEditorSavePlugin;
