// Service
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";

export const AuthorizationLink = ({ context }) => {
  const { authentication } = context;
  let forceRefresh = false;

  const tokenLink = setContext(async (request, { headers }) => {
    // Get the ID token.
    // When forceRefresh is set to `false` we get the token
    // that is cached, so there's no extra overhead.
    // When forceRefresh is set to `true` a request is sent to
    // the authentication service w/ refresh token n that should return fresh id token.
    const idToken = await authentication.getIdToken(forceRefresh);
    forceRefresh = false;
    return {
      headers: {
        ...headers,
        authorization: idToken,
      },
    };
  });

  const errorLink = onError(({ networkError = {} }) => {
    const { name, statusCode } = networkError;
    if (name === "ServerError" && statusCode === 401) {
      // Refresh ID token on authentication error
      forceRefresh = true;
    }
  });

  const retryLink = new RetryLink({
    attempts: {
      max: 2, // Retry the refresh token just once
      retryIf: (error) => {
        return error && error.statusCode === 401;
      },
    },
  });

  return retryLink.concat(errorLink).concat(tokenLink);
};
