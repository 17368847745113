// Framework
import { StackDependency } from "stack/dependency";
// Components
import UserAgent from "ua-parser-js";

export class Agent extends StackDependency {
  #userAgent;

  onInitialized() {
    this.#userAgent = new UserAgent();
  }

  /**
   * Possible 'browser.name':
   * 2345Explorer, 360 Browser, Amaya, Android Browser, Arora, Avant, Avast, AVG,
   * BIDUBrowser, Baidu, Basilisk, Blazer, Bolt, Brave, Bowser, Camino, Chimera,
   * Chrome Headless, Chrome WebView, Chrome, Chromium, Comodo Dragon, Dillo,
   * Dolphin, Doris, DuckDuckGo, Edge, Electron, Epiphany, Facebook, Falkon, Fennec,
   * Firebird, Firefox [Focus/Reality], Flock, Flow, GSA, GoBrowser, Huawei Browser,
   * ICE Browser, IE, IEMobile, IceApe, IceCat, IceDragon, Iceweasel, Instagram,
   * Iridium, Iron, Jasmine, K-Meleon, Kindle, Klar, Konqueror, LBBROWSER, Line,
   * LinkedIn, Links, Lunascape, Lynx, MIUI Browser, Maemo Browser, Maemo, Maxthon,
   * MetaSr Midori, Minimo, Mobile Safari, Mosaic, Mozilla, NetFront, NetSurf, Netfront,
   * Netscape, NokiaBrowser, Obigo, Oculus Browser, OmniWeb, Opera Coast,
   * Opera [Mini/Mobi/Tablet], PaleMoon, PhantomJS, Phoenix, Polaris, Puffin, QQ,
   * QQBrowser, QQBrowserLite, Quark, QupZilla, RockMelt, Safari, Sailfish Browser,
   * Samsung Browser, SeaMonkey, Silk, Skyfire, Sleipnir, Slim, SlimBrowser, Swiftfox,
   * Tesla, Tizen Browser, UCBrowser, UP.Browser, Vivaldi, Waterfox, WeChat, Weibo,
   * Yandex, baidu, iCab, w3m, Whale Browser...
   *
   * @returns {{ name: string, version: string }}
   */
  get browser() {
    return this.#userAgent.getBrowser();
  }

  /**
   * Possible 'device.type':
   * console, mobile, tablet, smarttv, wearable, embedded
   *
   * Possible 'device.vendor':
   * Acer, Alcatel, Amazon, Apple, Archos, ASUS, AT&T, BenQ, BlackBerry, Dell,
   * Essential, Fairphone, GeeksPhone, Google, HP, HTC, Huawei, Jolla, Lenovo, LG,
   * Meizu, Microsoft, Motorola, Nexian, Nintendo, Nokia, Nvidia, OnePlus, OPPO, Ouya,
   * Palm, Panasonic, Pebble, Polytron, Realme, RIM, Roku, Samsung, Sharp, Siemens,
   * Sony[Ericsson], Sprint, Tesla, Vivo, Vodafone, Xbox, Xiaomi, Zebra, ZTE, ...
   *
   * @returns {{ model: string, type: string, vendor: string }}
   */
  get device() {
    return this.#userAgent.getDevice();
  }

  /**
   * Possible 'engine.name'
   * Amaya, Blink, EdgeHTML, Flow, Gecko, Goanna, iCab, KHTML, Links, Lynx, NetFront,
   * NetSurf, Presto, Tasman, Trident, w3m, WebKit
   *
   * @returns {{ name: string, version: string }}
   */
  get engine() {
    return this.#userAgent.getEngine();
  }

  /**
   * Possible 'os.name'
   * AIX, Amiga OS, Android[-x86], Arch, Bada, BeOS, BlackBerry, CentOS, Chromium OS,
   * Contiki, Fedora, Firefox OS, FreeBSD, Debian, Deepin, DragonFly, elementary OS,
   * Fuchsia, Gentoo, GhostBSD, GNU, Haiku, HarmonyOS, HP-UX, Hurd, iOS, Joli, KaiOS,
   * Linpus, Linspire, Linux, Mac OS, Maemo, Mageia, Mandriva, Manjaro, MeeGo, Minix,
   * Mint, Morph OS, NetBSD, Nintendo, OpenBSD, OpenVMS, OS/2, Palm, PC-BSD, PCLinuxOS,
   * Plan9, PlayStation, QNX, Raspbian, RedHat, RIM Tablet OS, RISC OS, Sabayon,
   * Sailfish, Series40, Slackware, Solaris, SUSE, Symbian, Tizen, Ubuntu, Unix,
   * VectorLinux, WebOS, Windows [Phone/Mobile], Zenwalk, ...
   *
   * 'os.version' determined dynamically
   *
   * @returns {{ name: string, version: string }}
   */
  get os() {
    return this.#userAgent.getOS();
  }

  /**
   * Possible 'cpu.architecture'
   * 68k, amd64, arm[64/hf], avr, ia[32/64], irix[64], mips[64], pa-risc, ppc, sparc[64]
   *
   * @returns {{ architecture: string }}
   */
  get cpu() {
    return this.#userAgent.getCPU();
  }

  /**
   * Returns all available
   * @returns {object}
   */
  get info() {
    return this.#userAgent.getResult();
  }
}
