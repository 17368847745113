// React
import React, { useState } from "react";
// Helpers
import { get } from "@mefisto/utils";
// Framework
import {
  Tooltip,
  IconButton,
  TextField as MuiTextField,
  InputAdornment,
} from "ui";
import { useTranslate } from "localization";
import {
  Visibility as ShowPasswordIcon,
  VisibilityOff as HidePasswordIcon,
} from "icon/material";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = (props) => {
  const {
    form,
    field,
    format,
    icon: Icon,
    disabled,
    label,
    helperText,
    placeholder,
    ...rest
  } = props;
  const { touched, errors, isSubmitting } = form;
  const { name, value } = field;
  const fieldError = get(errors, name);
  const showError = get(touched, name) && !!fieldError;
  // Framework
  const { t } = useTranslate();
  // State
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // Handlers
  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);
  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleChange = (event) => {
    const { value } = event.target;
    form.setFieldValue(name, value);
  };
  // Render
  return (
    <MuiTextField
      fullWidth
      variant="outlined"
      name={name}
      value={value}
      label={label}
      placeholder={placeholder}
      helperText={showError ? fieldError : helperText}
      error={showError}
      disabled={disabled ?? isSubmitting}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        ...(format && { inputComponent: format }),
        type: showPassword ? "text" : "password",
        startAdornment: Icon ? (
          <InputAdornment position="start">
            <Icon color={showError ? "error" : "action"} fontSize="small" />
          </InputAdornment>
        ) : null,
        endAdornment: (
          <InputAdornment
            position="end"
            style={{ visibility: focus || hover ? "visible" : "hidden" }}
          >
            <Tooltip
              enterDelay={800}
              placement="bottom"
              title={
                showPassword
                  ? t("core:form.passwordField.tooltip.hide")
                  : t("core:form.passwordField.tooltip.show")
              }
            >
              <IconButton onClick={handleShowPassword}>
                {showPassword ? (
                  <HidePasswordIcon color="action" />
                ) : (
                  <ShowPasswordIcon color="action" />
                )}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onChange={handleChange}
      {...rest}
    />
  );
};

const FormPasswordField = (props) => {
  return <FormField component={Component} {...props} />;
};

export default FormPasswordField;
