// React
import React, { useCallback } from "react";
import PropTypes from "prop-types";
// Helpers
import { compact, map, isString, isNil } from "@mefisto/utils";
// Framework
import { classnames } from "ui/classnames";
import { useTranslate } from "localization/hooks";
import { useTheme } from "theme";
import {
  makeStyles,
  useMediaQuery,
  Typography,
  Box,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {
  WarningRounded as WarningIcon,
  Close as CloseIcon,
} from "icon/material";
// Components
import Grid from "../Grid";
import Dialog from "../Dialog";
import Button from "../Button";
import TooltipButton from "../TooltipButton";
import CoverSpinner from "../CoverSpinner";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  scrollPaper: ({ positionX, positionY }) => ({
    ...(positionX === "left" && { justifyContent: "flex-start" }),
    ...(positionX === "right" && { justifyContent: "flex-end" }),
    ...(positionY === "top" && { alignItems: "flex-start" }),
    ...(positionY === "bottom" && { alignItems: "flex-end" }),
  }),
  warning: {
    color: "#ef9301",
  },
  danger: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  option: {
    color: theme.palette.grey[500],
  },
  hidden: {
    visibility: "hidden",
  },
  flex: {
    flex: 1,
  },
  minHeight: {
    minHeight: 200,
  },
  text: {
    lineHeight: 1.2,
    display: "inline-block",
  },
  actions: {
    display: "block",
  },
  progress: {
    marginBottom: theme.spacing(2),
  },
  prependedActions: {
    marginLeft: theme.spacing(-1.5),
  },
  buttonIcon: {
    lineHeight: 1,
    margin: theme.spacing(0, 1, -1 / 8, -0.5),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModalDialog = ({
  open,
  title,
  fullWidth,
  maxWidth,
  backdropClose,
  positionX = "center",
  positionY = "center",
  actions = [],
  options = [],
  fullScreen: forceFullScreen,
  disableTitle,
  disableContent,
  disableActions,
  disableClose,
  disableEscapeKeyDown,
  prependedActions,
  warning,
  loading,
  progress,
  children,
  onClose,
}) => {
  // Framework
  const { t } = useTranslate();
  // Styles
  const classes = useStyles({ positionX, positionY });
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));
  // Handlers
  const handleClose = useCallback(
    (event, reason) => {
      if (!backdropClose && reason === "backdropClick") {
        return;
      }
      onClose();
    },
    [onClose, backdropClose]
  );
  // Render
  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={!isNil(forceFullScreen) ? forceFullScreen : isSmall}
      onClose={handleClose}
      classes={{
        paper: classnames({
          [classes.minHeight]: loading,
        }),
        scrollPaper: classes.scrollPaper,
      }}
    >
      {!disableTitle && (
        <DialogTitle disableTypography>
          <Grid container spacing={1} alignItems="center" wrap="nowrap">
            <Box clone flex={1}>
              <Grid item>
                {isString(title) ? (
                  <Typography variant="h6">{title}</Typography>
                ) : (
                  title
                )}
              </Grid>
            </Box>
            {map(options, ({ title, icon, disabled, onClick }, index) => (
              <Grid item key={index}>
                <TooltipButton
                  disabled={disabled}
                  tooltip={title}
                  onClick={onClick}
                >
                  {icon}
                </TooltipButton>
              </Grid>
            ))}
            {!disableClose && (
              <Grid item>
                <TooltipButton edge="end" onClick={onClose}>
                  <CloseIcon color="action" />
                </TooltipButton>
              </Grid>
            )}
          </Grid>
        </DialogTitle>
      )}
      {loading && <CoverSpinner size="small" />}
      {disableContent ? (
        children
      ) : (
        <DialogContent>
          {isString(children) ? <Typography>{children}</Typography> : children}
        </DialogContent>
      )}
      {!disableActions && (
        <DialogActions className={classes.actions}>
          {progress !== undefined && (
            <LinearProgress
              variant="determinate"
              value={progress ? progress : 0}
              className={classnames(
                classes.progress,
                isNil(progress) && classes.hidden
              )}
            />
          )}
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            {prependedActions && (
              <Grid item flex className={classes.prependedActions}>
                {prependedActions}
              </Grid>
            )}
            {warning && (
              <>
                <Grid item>
                  <Typography variant="caption">
                    <WarningIcon className={classes.warning} />
                  </Typography>
                </Grid>
                <Grid item className={classes.flex}>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    className={classes.text}
                  >
                    {warning}
                  </Typography>
                </Grid>
              </>
            )}
            {!disableClose && (
              <Grid item>
                <Box color="action.active">
                  <Button color="inherit" onClick={onClose}>
                    {t("core:ui.dialog.modal.action.close")}
                  </Button>
                </Box>
              </Grid>
            )}
            {map(
              compact(actions),
              (
                {
                  title,
                  icon,
                  submit,
                  disabled,
                  loading,
                  danger,
                  custom,
                  color = "primary",
                  variant = "contained",
                  onClick,
                },
                index
              ) => (
                <Grid key={index} item>
                  <>
                    {custom ? (
                      custom
                    ) : (
                      <>
                        {submit ? (
                          <Button
                            type="submit"
                            size="medium"
                            variant={variant}
                            color={color}
                            disabled={disabled}
                            loading={loading}
                          >
                            {title}
                          </Button>
                        ) : (
                          <Button
                            variant={variant}
                            color={color}
                            disabled={disabled}
                            loading={loading}
                            onClick={onClick}
                            className={classnames(danger && classes.danger)}
                          >
                            {icon && (
                              <span className={classes.buttonIcon}>{icon}</span>
                            )}
                            {title}
                          </Button>
                        )}
                      </>
                    )}
                  </>
                </Grid>
              )
            )}
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
};

ModalDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.any,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  backdropClose: PropTypes.bool,
  positionX: PropTypes.oneOf(["center", "left", "right"]),
  positionY: PropTypes.oneOf(["center", "top", "bottom"]),
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        title: PropTypes.string,
        submit: PropTypes.bool,
        danger: PropTypes.bool,
        disabled: PropTypes.bool,
        custom: PropTypes.element,
        onClick: PropTypes.func,
      }),
    ])
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.element.isRequired,
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ),
  closeTitle: PropTypes.string,
  disableTitle: PropTypes.bool,
  disableContent: PropTypes.bool,
  disableActions: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  loading: PropTypes.bool,
  progress: PropTypes.number,
  warning: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default ModalDialog;
