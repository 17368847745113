// React
import React, { useEffect } from "react";
// Framework
import { usePortal, AuthState } from "@mefisto/web";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const DashboardInterceptor = ({ children }) => {
  // Framework
  const { authentication, navigation } = usePortal();
  // Effect
  useEffect(() => {
    const isHome = navigation.path === "/";
    const isAuthenticated =
      authentication.currentUser.state === AuthState.authenticated;
    if (isHome && isAuthenticated) {
      navigation.goTo(navigation.routes.dashboard, {
        replace: true,
      });
    }
  }, [authentication, navigation]);
  // Render
  return <>{children(true)}</>;
};

export default DashboardInterceptor;
