// React
import React from "react";
// Framework
import { usePortal } from "stack";
import { useUser, AuthState } from "authentication";
// Components
import Redirect from "../Redirect";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

/**
 * Routes to given component if the user is authenticated.
 * Otherwise, redirects to a sign in scene.
 */
const RouteAuthenticated = ({ children }) => {
  // Framework
  const { navigation } = usePortal();
  const {
    user: { state },
  } = useUser();
  // If the user is not authenticated, redirect to sign in page
  if (state === AuthState.notAuthenticated) {
    return (
      <Redirect replace continueUrl to={navigation.routes.notAuthenticated} />
    );
  }
  // Do not redirect
  return children;
};

export default RouteAuthenticated;
