// Service
import { BroadcastChannel } from "broadcast-channel";

class AppEventBus {
  #bc;
  #handlers = [];

  constructor() {
    const bc = new BroadcastChannel("service-worker-channel");
    bc.onmessage = this.#eventListener;
    this.#bc = bc;
  }

  addMessageHandler = (handler) => {
    this.#handlers.push(handler);
  };

  removeMessageHandler = (handler) => {
    this.#handlers = this.#handlers.filter(
      (existingHandler) => existingHandler !== handler
    );
  };

  postMessage = async ({ type, payload, meta } = {}) => {
    this.#bc.postMessage({ type, payload, meta });
  };

  #eventListener = (event) => {
    this.#handlers.forEach((handler) => handler(event));
  };
}

export default AppEventBus;
