// React
import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
// Framework
import { FormControl, FormLabel, RadioGroup, FormHelperText } from "ui";
// Helpers
import { isNil, get } from "@mefisto/utils";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({
  row,
  label,
  helperText,
  children,
  field: { name, value },
  form: { touched, errors, setFieldValue },
}) => {
  // Memo
  const errorText = useMemo(() => {
    return get(errors, name);
  }, [errors, name]);
  const hasError = useMemo(() => {
    return get(touched, name) && Boolean(errorText);
  }, [touched, name, errorText]);
  // Handlers
  const handleChange = useCallback(
    (event) => {
      const { value } = event.target;
      setFieldValue(name, isNil(value) ? "" : value);
    },
    [setFieldValue, name]
  );
  // Render
  return (
    <FormControl component="fieldset" error={hasError}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup row={row} name={name} value={value} onChange={handleChange}>
        {children}
      </RadioGroup>
      <FormHelperText>{hasError ? errorText : helperText}</FormHelperText>
    </FormControl>
  );
};

const FormRadioGroupField = (props) => (
  <FormField component={Component} {...props} />
);

FormRadioGroupField.propTypes = {
  /**
   * The name used to reference the value of the control.
   * If you don't provide this prop, it falls back to a randomly generated name.
   */
  name: PropTypes.string,
  /**
   * Value of the selected radio button. The DOM API casts this to a string.
   */
  value: PropTypes.any,
  /**
   * Display group of elements in a compact row.
   */
  row: PropTypes.bool,
  /**
   * Label passed to FormLabel
   */
  label: PropTypes.node,
  /**
   * The helper text content.
   */
  helperText: PropTypes.node,
  /**
   * Pass any number of  FormRadioGroupFieldOption
   */
  children: PropTypes.node,
};

export default FormRadioGroupField;
