// React
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
// Helpers
import { get, first } from "@mefisto/utils";
// Framework
import { Box, Typography } from "ui";
import { ImageDialog } from "image";
import { Dropzone } from "storage";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({
  form,
  field,
  label,
  aspect,
  display,
  dropzoneWidth = "100%",
  dropzoneHeight = "100%",
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
  zoneWidth,
  zoneHeight,
  borderRadius,
}) => {
  const { name, value } = field;
  const { touched, errors } = form;
  const fieldError = get(errors, name);
  const showError = get(touched, name) && !!fieldError;
  // Ref
  const dialogRef = useRef(null);
  // State
  const [objectUrl, setObjectUrl] = useState(null);
  // Effects
  useEffect(() => {
    if (value === "") {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
      setObjectUrl(null);
    }
  }, [value, objectUrl]);
  // Handlers
  const handleDrop = (files) => {
    const file = first(files);
    if (file) {
      if (file.type === "image/gif") {
        form.setFieldValue(name, file);
        setObjectUrl(URL.createObjectURL(file));
      } else {
        // Let the user edit the file
        dialogRef.current.open(file);
      }
    }
  };
  const handleFinished = (file) => {
    form.setFieldValue(name, file);
    setObjectUrl(URL.createObjectURL(file));
  };
  // Render
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box width={dropzoneWidth} height={dropzoneHeight}>
        <ImageDialog
          ref={dialogRef}
          aspect={aspect}
          display={display}
          maxWidth={maxWidth}
          minWidth={minWidth}
          maxHeight={maxHeight}
          minHeight={minHeight}
          zoneWidth={zoneWidth}
          zoneHeight={zoneHeight}
          onFinished={handleFinished}
        />
        <Dropzone
          accept={{ "image/*": [] }}
          image={objectUrl || get(value, "url")}
          label={label}
          error={showError}
          display={display}
          width={dropzoneWidth}
          height={dropzoneHeight}
          borderRadius={borderRadius}
          onDrop={handleDrop}
        />
      </Box>
      {showError && (
        <Typography color="error" variant="caption">
          {fieldError}
        </Typography>
      )}
    </Box>
  );
};

const FormImageField = (props) => {
  return <FormField component={Component} {...props} />;
};

FormImageField.propTypes = {
  label: PropTypes.string,
  aspect: PropTypes.number,
  display: PropTypes.oneOf(["default", "square", "circle"]),
  dropzoneWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dropzoneHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  borderRadius: PropTypes.any,
};

export default FormImageField;
