// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { Box, Typography } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const CoverSubtitle = ({ subtitle, subtitleProps }) => (
  <>
    {subtitle && (
      <Typography
        variant="subtitle2"
        component="div"
        align="center"
        color="textSecondary"
        {...subtitleProps}
      >
        <Box lineHeight="normal" px={5}>
          {subtitle}
        </Box>
      </Typography>
    )}
  </>
);

CoverSubtitle.propTypes = {
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitleProps: PropTypes.object,
};

export default CoverSubtitle;
