// React
import React from "react";
// Framework
import { useInjectStyle } from "hooks";
// Components
import Crop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import overrides from "./styles/overrides.css";

const ImageCrop = ({ ...props }) => {
  // Styles
  useInjectStyle("image-crop", overrides);
  // Render
  return <Crop {...props} />;
};

export default ImageCrop;
