// React
import React, { useMemo } from "react";
import PropTypes from "prop-types";
// Framework
import { useInjectStyle } from "hooks";
// Styles
import "react-markdown-editor-lite/lib/index.css";
import overrides from "./styles/overrides.css";
// Plugins
import MarkdownEditorSavePlugin from "./components/MarkdownEditorSavePlugin";
// Components
import MarkdownIt from "markdown-it";
import Editor from "react-markdown-editor-lite";

////////////////////////////////////////////////////
/// Plugins
////////////////////////////////////////////////////

Editor.use(MarkdownEditorSavePlugin);

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const MarkdownEditor = ({ value, height, fullScreen, onChange, onSave }) => {
  // Styles
  useInjectStyle("text-editor", overrides);
  // Memo
  const parser = useMemo(() => new MarkdownIt(), []);
  // Render
  return (
    <Editor
      style={{ height }}
      config={{ canView: { fullScreen } }}
      renderHTML={(text) => parser.render(text)}
      handleSave={onSave}
      onChange={onChange}
      value={value ?? ""}
    />
  );
};

MarkdownEditor.propTypes = {
  value: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fullScreen: PropTypes.bool,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

export default MarkdownEditor;
