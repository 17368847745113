// React
import React, { useContext } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";
// Components
import { AppsContext } from "./AppsContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({ children }) => {
  // Framework
  const { apps } = usePortal();
  // Context
  const options = useContext(AppsContext);
  // Init
  useStackDependency(apps, options);
  // Render
  return <>{children}</>;
};

const AppsProvider = ({ options, children }) => (
  <AppsContext.Provider value={{ apps: [] }}>
    {options}
    <Component>{children}</Component>
  </AppsContext.Provider>
);

AppsProvider.propTypes = {
  options: PropTypes.node,
};

export { AppsProvider };
