// React
import React, { useEffect, useState } from "react";
// Helpers
import { size, min } from "@mefisto/utils";
// Framework
import { useTranslate } from "localization";
import { usePortal } from "stack";
import {
  makeStyles,
  alpha,
  classnames,
  Grid,
  Typography,
  CircularProgress,
} from "ui";
import {
  CloudUpload as UploadIcon,
  CheckCircleRounded as SuccessIcon,
} from "icon/material";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  progress: {
    position: "relative",
    color: theme.palette.action.active,
    height: 36,
    fontWeight: 500,
  },
  icon: {
    display: "flex",
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
    borderRadius: theme.radius.rounded,
    background: alpha(theme.palette.info.main, 0.15),
    color: theme.palette.info.main,
  },
  successIcon: {
    background: alpha(theme.palette.success.main, 0.15),
    color: theme.palette.success.main,
  },
  close: {
    marginLeft: theme.spacing(1),
  },
  centered: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  determinate: {
    position: "absolute",
    color: alpha(theme.palette.grey[100], 0.28),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ProgressAction = ({ queue }) => {
  // Styles
  const classes = useStyles();
  // State
  const [progress, setProgress] = useState(null);
  // Effects
  useEffect(() => {
    let handle;
    const handleProgressChange = (progress) => {
      if (handle) {
        clearTimeout(handle);
      }
      setProgress(progress);
    };
    const unsubscribe = queue.onProgressChange(handleProgressChange);
    return () => unsubscribe();
  }, [queue]);
  return (
    <>
      {progress && (
        <div key="progress" className={classes.progress}>
          {!progress.done && (
            <>
              <Typography
                variant="caption"
                color="inherit"
                className={classes.centered}
              >
                {progress.total}%
              </Typography>
              <CircularProgress
                variant="determinate"
                value={100}
                className={classes.determinate}
                size={36}
              />
              <CircularProgress
                size={36}
                color="inherit"
                variant="determinate"
                value={progress.total}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

const ProgressMessage = ({ queue, translate }) => {
  // Styles
  const classes = useStyles();
  // State
  const [progress, setProgress] = useState(null);
  // Effects
  useEffect(() => {
    let handle;
    const handleProgressChange = (progress) => {
      if (handle) {
        clearTimeout(handle);
      }
      setProgress(progress);
    };
    const unsubscribe = queue.onProgressChange(handleProgressChange);
    return () => unsubscribe();
  }, [queue]);
  return (
    <>
      {progress && (
        <Grid container spacing={2} alignItems="center">
          {progress.done ? (
            <>
              <Grid item>
                <div className={classnames(classes.icon, classes.successIcon)}>
                  <SuccessIcon />
                </div>
              </Grid>
              <Grid item>
                {translate("core:storage.uploadSnackbar.text.success")}
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <div className={classes.icon}>
                  <UploadIcon />
                </div>
              </Grid>
              <Grid item>
                {translate("core:storage.uploadSnackbar.text.progress", {
                  params: {
                    current: min([
                      size(progress.completedJobs) + 1,
                      progress.batchSize,
                    ]),
                    total: progress.batchSize,
                  },
                })}
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

const UploadSnackbar = () => {
  // Framework
  const { uploadQueue: queue, snackbar } = usePortal();
  const { translate } = useTranslate();
  // State
  const [show, setShow] = useState(false);
  const [key, setKey] = useState(null);
  // Effects
  useEffect(() => {
    let handle;
    const handleProgressChange = (progress) => {
      if (handle) {
        clearTimeout(handle);
      }
      if (progress.currentJob?.silent) {
        setShow(false);
      } else if (progress.done === true) {
        handle = setTimeout(() => {
          setShow(false);
        }, 3000);
      } else if (progress.isUploading === true) {
        setShow(true);
      } else {
        setShow(false);
      }
    };
    const unsubscribe = queue.onProgressChange(handleProgressChange);
    return () => unsubscribe();
  }, [queue]);
  useEffect(() => {
    const handleError = () => setShow(false);
    const unsubscribe = queue.onError(handleError);
    return () => unsubscribe();
  }, [queue]);
  useEffect(() => {
    if (show) {
      const key = snackbar.enqueueSnackbar(
        <ProgressMessage queue={queue} translate={translate} />,
        {
          persist: true,
          action: <ProgressAction queue={queue} />,
        }
      );
      setKey(key);
    }
  }, [translate, queue, show, snackbar]);
  useEffect(() => {
    if (key && !show) {
      snackbar.closeSnackbar(key);
      setKey(null);
    }
  }, [key, show, snackbar]);
  // Render
  return null;
};

export default UploadSnackbar;
