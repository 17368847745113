// React
import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
// Helpers
import { forEach } from "@mefisto/utils";
// Components
import { LocalizationContext } from "localization/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Localization = (props) => {
  // Context
  const context = useContext(LocalizationContext);
  // Set values
  forEach(props, (value, key) => {
    context[key] = value;
  });
  // Render
  return <Fragment />;
};

Localization.defaultProps = {
  __TYPE: "Localization",
};

Localization.propTypes = {
  app: PropTypes.string,
  languages: PropTypes.array,
  fallback: PropTypes.string,
  localizations: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      file: PropTypes.func.isRequired,
    })
  ),
};

export default Localization;
