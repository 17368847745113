import React from "react";
import { createSvgIcon } from "icon/utils";

export default createSvgIcon(
  <path
    fill="#546e7a"
    d="M10.5,1H3A1,1,0,0,0,2,2V14a1,1,0,0,0,1,1H13a1,1,0,0,0,1-1V4.5ZM13,14H3V2H6V3H7V2h3l3,3v9ZM7,4V3H8V4H7ZM6,4H7V5H6V4ZM7,6V5H8V6H7ZM6,6H7V7H6V6ZM7,8V7H8V8H7ZM6,9.28A2,2,0,0,0,5,11v1H9V11A2,2,0,0,0,7,9V8H6V9.28ZM8,10v1H6V10H8Z"
    transform="translate(0 -1)"
  />,
  "ArchiveIcon",
  { viewBox: "-1 -2 18 18" }
);
