// React
import React from "react";
// Framework
import { forwardType, App } from "@mefisto/web";
// Loader
import { Loader } from "loader";
// Paths
import { paths as supportPaths } from "@plugins/support";

export default forwardType(App, () => (
  <App
    enabled
    name="PRESENTATION"
    type="NATIVE"
    title="Presentation"
    priority={0}
    loader={<Loader />}
    navigation={{
      basename: "/",
      routes: {
        default: "/",
        notAuthenticated: "/",
      },
      paths: {
        ...supportPaths,
        landing: "/",
        macros: "/macros",
        terms: "/terms-of-service",
        privacy: "/privacy-policy",
      },
    }}
    resource={{
      source: "ui",
    }}
  />
));
