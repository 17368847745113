// React
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
// Helpers
import { map } from "@mefisto/utils";
// Framework
import {
  useUser,
  forwardType,
  makeStyles,
  AuthState,
  Grid,
  PortalLayoutPlugin,
} from "@mefisto/web";
// View Model
import { useNavbarItems } from "viewModel";
// Components
import NavbarItem from "./components/NavbarItem";
import NavbarPopover from "./components/NavbarPopover";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    width: "100%",
    height: theme.spacing(9.5),
    overflow: "hidden",
    background: theme.palette.secondary.main,
  },
  content: {
    display: "flex",
    height: "100%",
    padding: theme.spacing(0, 2, 1.5),
  },
  divider: {
    width: 2,
    height: 20,
    margin: theme.spacing(0, 0.5),
    background: theme.palette.common.white,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const NavbarPortalLayoutPlugin = forwardType(
  PortalLayoutPlugin,
  ({ position }) => {
    // Framework
    const {
      user: { state },
    } = useUser();
    // Styles
    const classes = useStyles();
    // View Model
    const { navbarItems } = useNavbarItems();
    // State
    const [open, setOpen] = useState(false);
    const [handle, setHandle] = useState(0);
    const [navbarItem, setNavbarItem] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    // Handlers
    const handleOpen = useCallback(
      (navbarItem) => (event) => {
        clearTimeout(handle);
        if (event) {
          setNavbarItem(navbarItem);
          if (navbarItem.menu) {
            setAnchorEl(event.currentTarget);
            setOpen(true);
          } else {
            setOpen(false);
          }
        }
      },
      [handle]
    );
    const handleClose = useCallback(
      (event, immediate) => {
        clearTimeout(handle);
        if (immediate) {
          setOpen(false);
        } else {
          setHandle(() =>
            setTimeout(() => {
              setOpen(false);
            }, 1500)
          );
        }
      },
      [handle]
    );
    // Render
    return (
      <>
        {state === AuthState.authenticated && (
          <PortalLayoutPlugin position={position}>
            <div className={classes.root}>
              <div className={classes.content}>
                <NavbarPopover
                  open={open}
                  anchorEl={anchorEl}
                  navbarItem={navbarItem}
                  onOpen={handleOpen}
                  onClose={handleClose}
                />
                <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                  {map(navbarItems, (navbarItem, key) => (
                    <Grid item key={key}>
                      {navbarItem.type === "divider" ? (
                        <div className={classes.divider} />
                      ) : (
                        <NavbarItem
                          navbarItem={navbarItem}
                          onOpen={handleOpen(navbarItem)}
                          onClose={handleClose}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          </PortalLayoutPlugin>
        )}
      </>
    );
  }
);

NavbarPortalLayoutPlugin.propTypes = {
  position: PropTypes.oneOf(["leading", "trailing", "bottom", "top"])
    .isRequired,
};

export default NavbarPortalLayoutPlugin;
