// React
import React, { useEffect, useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { isFunction, toLower } from "@mefisto/utils";
// Framework
import {
  Section,
  DialogContentText,
  CoverSpinner,
  TextField,
  Grid,
  Typography,
} from "ui";
import { useTranslate } from "localization";
import { FormSubmitButton } from "form";
import { useDeepMemo } from "hooks";
import { useEntityDelete, useEntityRead } from "model/hooks";
import { EntityPropType, getInput } from "model/utils";
// Components
import ModelDialogButtons from "../ModelDialogButtons";
import ModelDialogContent from "../ModelDialogContent";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelDialogActionDelete = ({
  context = "delete",
  value,
  action,
  entity: Entity,
  loading: customLoading,
  skeleton,
  danger,
  onSubmit,
  onFinish,
  onClose,
  children,
  __options,
  __display,
}) => {
  // Props
  const {
    input,
    resources,
    languages,
    optimisticUI,
    options = {},
  } = useDeepMemo(() => __options, [__options]);
  const {
    dataMapper,
    onFinish: __onFinish,
    onClose: __onClose,
  } = useDeepMemo(() => __display ?? {}, [__display]);
  // Framework
  const { translate } = useTranslate();
  // Model
  const entityRead = useEntityRead(Entity, {
    fetchPolicy: "cache-first",
    input,
    resources,
    languages,
  });
  const entityDelete = useEntityDelete(Entity, {
    resources,
    languages,
  });
  // State
  const [disabled, setDisabled] = useState(danger);
  // Memo
  const entityReadData = useMemo(() => {
    const { data } = entityRead;
    return dataMapper ? dataMapper(data) : data;
  }, [entityRead, dataMapper]);
  const loading = useMemo(() => {
    return entityDelete.loading || customLoading;
  }, [entityDelete, customLoading]);
  const finished = useMemo(() => {
    const { called, error, loading } = entityDelete;
    if (called && loading && optimisticUI) {
      return true;
    } else {
      return called && !loading && !error;
    }
  }, [entityDelete, optimisticUI]);
  // Callbacks
  const getContent = useCallback(() => {
    if (isFunction(children)) {
      return children({
        input,
        action,
        data: entityReadData,
        options,
        loading,
      });
    }
    return children;
  }, [children, input, options, action, entityReadData, loading]);
  // Handlers
  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit();
    } else {
      const inputValues = getInput({}, input);
      entityDelete.delete({
        input: inputValues,
        optimistic: optimisticUI && {
          ...entityReadData,
          ...inputValues,
        },
      });
    }
  };
  const handleChange = useCallback(
    (event) => {
      const { value } = event.target;
      setDisabled(
        toLower(value) !==
          toLower(translate("core:model.dialog.danger.placeholder"))
      );
    },
    [translate]
  );
  const handleFinish = useCallback(
    (payload) => {
      __onFinish?.(payload);
      onFinish?.(payload);
    },
    [__onFinish, onFinish]
  );
  const handleClose = useCallback(() => {
    __onClose?.();
    onClose?.();
  }, [__onClose, onClose]);
  // Effects
  useEffect(() => {
    const { error } = entityRead;
    if (error) {
      handleClose();
    }
  }, [entityRead, handleClose]);
  useEffect(() => {
    if (finished) {
      handleFinish({ data: entityReadData });
    }
  }, [finished, entityReadData, handleFinish]);
  // Render
  return (
    <Section context={context} value={value}>
      {entityRead.loading && <CoverSpinner size="small" />}
      {!entityRead.error && (
        <>
          <ModelDialogContent>
            <DialogContentText variant="subtitle2" component="div">
              {getContent()}
            </DialogContentText>
            {danger && (
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Typography variant="subtitle2">
                    {translate("core:model.dialog.danger.title")}
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    autoFocus
                    variant="outlined"
                    size="small"
                    placeholder={translate(
                      "core:model.dialog.danger.placeholder"
                    )}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            )}
          </ModelDialogContent>
          <ModelDialogButtons
            skeleton={skeleton}
            loading={loading}
            onClose={handleClose}
          >
            <FormSubmitButton
              danger
              value="delete"
              size="medium"
              type="button"
              loading={!optimisticUI && loading}
              disabled={disabled}
              finished={finished}
              onClick={handleSubmit}
            >
              {translate("core:model.dialog.delete.button.submit")}
            </FormSubmitButton>
          </ModelDialogButtons>
        </>
      )}
    </Section>
  );
};

ModelDialogActionDelete.displayName = "ModelDialogActionDelete";

ModelDialogActionDelete.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  entity: EntityPropType,
  danger: PropTypes.bool,
  loading: PropTypes.bool,
  skeleton: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onSubmit: PropTypes.func,
  onNext: PropTypes.func,
  onFinish: PropTypes.func,
  onClose: PropTypes.func,
};

export default ModelDialogActionDelete;
