// React
import React, { forwardRef, useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
// Framework
import ScrollBar from "react-scrollbars-custom";
import { makeStyles, alpha } from "ui/components";
import { useTheme } from "theme";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles(() => ({
  scroller: {
    // Firefox
    scrollbarWidth: "none",
    // Chrome and rest
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Scrollable = forwardRef(
  (
    {
      appearance = "default",
      autoHide = true,
      disableGutter,
      disableScroll,
      disableScrollX,
      disableScrollY,
      trackStyle,
      thumbStyle,
      wrapperStyle,
      scrollerStyle,
      contentStyle,
      scrollDetectionThreshold = 500,
      onScroll,
      children,
    },
    ref
  ) => {
    // Styles
    const classes = useStyles();
    // Framework
    const theme = useTheme();
    // State
    const [isScrolling, setScrolling] = useState(false);
    const [isMouseOverTrack, setMouseOverTrack] = useState(false);
    const [isMouseOverWrapper, setMouseOverWrapper] = useState(false);
    // Memo
    const isVisible = useMemo(() => {
      if (!autoHide) {
        return true;
      }
      return isScrolling || isMouseOverWrapper || isMouseOverTrack;
    }, [autoHide, isScrolling, isMouseOverWrapper, isMouseOverTrack]);
    // Callbacks
    const handleScrollStart = useCallback(() => {
      setScrolling(true);
    }, []);
    const handleScrollStop = useCallback(() => {
      setScrolling(false);
    }, []);
    const handleMouseEnterTrack = useCallback(() => {
      setMouseOverTrack(true);
    }, []);
    const handleMouseLeaveTrack = useCallback(() => {
      setMouseOverTrack(false);
    }, []);
    const handleMouseEnterWrapper = useCallback(() => {
      setMouseOverWrapper(true);
    }, []);
    const handleMouseLeaveWrapper = useCallback(() => {
      setMouseOverWrapper(false);
    }, []);
    // Memo
    const trackProps = useMemo(
      () => ({
        renderer: ({ elementRef, style, ...props }) => (
          <div
            {...props}
            ref={elementRef}
            style={{
              ...style,
              opacity: isVisible ? (isMouseOverTrack ? 1 : 0.8) : 0,
              transition: "all 0.4s ease-in-out",
              transitionProperty: "opacity",
              width: theme.spacing(1),
              backgroundColor: "transparent",
              right: disableGutter ? 0 : 4,
              ...trackStyle,
            }}
            onMouseEnter={handleMouseEnterTrack}
            onMouseLeave={handleMouseLeaveTrack}
          />
        ),
      }),
      [
        theme,
        isVisible,
        isMouseOverTrack,
        disableGutter,
        trackStyle,
        handleMouseEnterTrack,
        handleMouseLeaveTrack,
      ]
    );
    const thumbProps = useMemo(
      () => ({
        renderer: ({ elementRef, style, ...props }) => (
          <div
            {...props}
            ref={elementRef}
            style={{
              ...style,
              width: theme.spacing(1),
              transition: "all 0.4s ease-in-out",
              transitionProperty: "width",
              zIndex: theme.zIndex.appBar,
              backgroundColor:
                appearance === "light"
                  ? theme.palette.grey[300]
                  : alpha(theme.palette.common.black, 0.65),
              ...thumbStyle,
            }}
          />
        ),
      }),
      [appearance, theme, thumbStyle]
    );
    const wrapperProps = useMemo(
      () => ({
        renderer: ({ elementRef, style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              // Ignore `right` inset.
              // We already set inset via track position.
              right: 0,
              ...wrapperStyle,
            }}
            ref={elementRef}
            onMouseEnter={handleMouseEnterWrapper}
            onMouseLeave={handleMouseLeaveWrapper}
          />
        ),
      }),
      [handleMouseEnterWrapper, handleMouseLeaveWrapper, wrapperStyle]
    );
    const scrollerProps = useMemo(
      () => ({
        renderer: ({ elementRef, style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              // Remove `marginRight` and `paddingRight` props.
              // We are hiding the scrollbar via css props in
              // `scroller` class so there's no need to shift
              // the scroller div to hide scrollbar anymore.
              marginRight: 0,
              paddingRight: 0,
              height: "100%",
              ...scrollerStyle,
            }}
            className={classes.scroller}
            ref={elementRef}
          />
        ),
      }),
      [classes.scroller, scrollerStyle]
    );
    const contentProps = useMemo(
      () => ({
        renderer: ({ elementRef, style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              // Originally, there's just min-height: "100%".
              // We need also the height to be "100%" in order to make e.g. the
              // sticky footer work. Without that the "fill" option doesn't work.
              height: "100%",
              ...contentStyle,
            }}
            ref={elementRef}
          />
        ),
      }),
      [contentStyle]
    );
    // Render
    return (
      <ScrollBar
        ref={ref}
        noScrollX={disableScroll || disableScrollX}
        noScrollY={disableScroll || disableScrollY}
        trackXProps={trackProps}
        trackYProps={trackProps}
        thumbXProps={thumbProps}
        thumbYProps={thumbProps}
        wrapperProps={wrapperProps}
        scrollerProps={scrollerProps}
        contentProps={contentProps}
        scrollDetectionThreshold={scrollDetectionThreshold}
        onScroll={onScroll}
        onScrollStart={handleScrollStart}
        onScrollStop={handleScrollStop}
      >
        {children}
      </ScrollBar>
    );
  }
);

Scrollable.propTypes = {
  appearance: PropTypes.oneOf(["default", "light"]),
  autoHide: PropTypes.bool,
  disableGutter: PropTypes.bool,
  disableScroll: PropTypes.bool,
  disableScrollX: PropTypes.bool,
  disableScrollY: PropTypes.bool,
  trackStyle: PropTypes.object,
  thumbStyle: PropTypes.object,
  wrapperStyle: PropTypes.object,
  scrollerStyle: PropTypes.object,
  scrollDetectionThreshold: PropTypes.number,
  onScroll: PropTypes.func,
};

export default Scrollable;
