// Helpers
import {
  isEmpty,
  isNil,
  filter,
  forEach,
  first,
  drop,
  reduce,
  size,
  floor,
  min,
  noop,
  toNumber,
  isNaN,
} from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";

export class UploadQueue extends StackDependency {
  #storage;
  #source;
  #isRunning = false;
  #jobs = [];
  #currentJob = null;
  #completedJobs = [];
  #progressSubscribers = [];
  #errorSubscribers = [];

  onInitialized() {
    const { storage } = this.context;
    this.#storage = storage;
    this.#source = storage.cancelTokenSource();
    this.clear();
  }

  /**
   * Subscribe to upload progress changes.
   * Returns unsubscribe function you need to
   * call in order to stop the event observation
   */
  onProgressChange(callback) {
    this.#progressSubscribers.push(callback);
    // Call right away
    callback(this.currentProgress);
    return () => {
      this.#progressSubscribers = filter(
        this.#progressSubscribers,
        (s) => s !== callback
      );
    };
  }

  /**
   * Call to notify about progress change
   */
  #handleProgressChange(done = false) {
    const progress = this.currentProgress;
    // Notify all subscribers
    forEach(this.#progressSubscribers, (callback) =>
      callback({
        ...progress,
        done,
      })
    );
  }

  /**
   * Returns current upload progress
   */
  get currentProgress() {
    const completed = reduce(
      this.#completedJobs,
      (result, { file: { size = 0 } = {} }) => size + result,
      0
    );
    const { total, progress } = reduce(
      this.#jobs,
      (result, { progress, file: { size = 0 } = {} }) => {
        result.total = result.total + size;
        result.progress = result.progress + floor(size * (progress / 100));
        return result;
      },
      {
        total: 0,
        progress: 0,
      }
    );
    const current = floor(((progress + completed) / (completed + total)) * 100);
    const batchSize = size(this.#jobs) + size(this.#completedJobs);
    return {
      isUploading: !isEmpty(this.#jobs),
      jobs: this.#jobs,
      currentJob: this.#currentJob,
      completedJobs: this.#completedJobs,
      batchSize,
      total: min([99, isNaN(current) ? 0 : current]),
    };
  }

  /**
   * Subscribe to error.
   * Returns unsubscribe function you need to
   * call in order to stop the event observation
   */
  onError(callback) {
    this.#errorSubscribers.push(callback);
    return () => {
      this.#errorSubscribers = filter(
        this.#errorSubscribers,
        (s) => s !== callback
      );
    };
  }

  /**
   * Call to notify about an error
   */
  #handleError(error) {
    // Notify all subscribers
    forEach(this.#errorSubscribers, (callback) => callback(error));
  }

  /**
   * Adds upload task
   */
  addJob({ file, signedUrl, silent, onFinished = noop }) {
    this.#jobs.push({ file, signedUrl, silent, onFinished, progress: 0 });
    // Start right away
    if (!this.#isRunning) {
      this.#isRunning = true;
      // Take a job
      const job = this.#takeJob();
      // Run the job
      // noinspection JSIgnoredPromiseFromCall
      this.#runJob(job);
    }
  }

  /**
   * Reloads stopped queue
   */
  reload() {
    if (!this.#isRunning) {
      // Take a job
      const job = this.#takeJob();
      // Run the job
      // noinspection JSIgnoredPromiseFromCall
      this.#runJob(job);
    }
  }

  /**
   * Clears the queue
   */
  clear() {
    this.#isRunning = false;
    this.#completedJobs = [];
    this.#jobs = [];
  }

  /**
   * Takes first available job from the queue.
   * Returns `null` if there's no available job at the moment.
   */
  #takeJob() {
    if (isEmpty(this.#jobs)) {
      return null;
    }
    return first(this.#jobs);
  }

  /**
   * Runs next job
   */
  async #runJob(job) {
    try {
      // Run the job
      this.#currentJob = job;
      const { file, signedUrl, onFinished } = job;
      const { headers } = await this.#storage.upload({
        url: signedUrl.write,
        file,
        name: file.name,
        contentType: file.type,
        // TODO: Define
        expiration: 6450000,
        cancelToken: this.#source.token,
        throwOnError: true,
        progress: ({ loaded, total }) => {
          job.progress = floor((loaded / total) * 100);
          this.#handleProgressChange();
        },
      });
      // Get the file metadata information from the headers
      const {
        ["x-goog-generation"]: generation,
        ["x-goog-stored-content-length"]: size,
      } = headers;
      // Success
      this.#currentJob = null;
      this.#jobs = drop(this.#jobs);
      this.#completedJobs.push(job);
      await onFinished({
        file,
        name: file.name,
        signedUrl,
        contentType: file.type,
        generation,
        size: toNumber(size),
      });
      // Get next job
      const nextJob = this.#takeJob();
      if (!isNil(nextJob)) {
        // Run it
        await this.#runJob(nextJob);
      } else {
        // Clear the queue since there are no jobs left
        this.clear();
        this.#handleProgressChange(true);
      }
    } catch (error) {
      this.#currentJob = null;
      const { canceled } = error;
      // Canceled
      if (canceled) {
        this.#jobs = drop(this.#jobs);
        // Get next job
        const nextJob = this.#takeJob();
        if (!isNil(nextJob)) {
          // Run it
          await this.#runJob(nextJob);
        } else {
          // Clear the queue since there are no jobs left
          this.clear();
        }
      }
      this.#isRunning = false;
      this.#handleError(error);
    }
  }
}
