// React
import React, { useCallback } from "react";
import PropTypes from "prop-types";
// Helpers
import { noop, isArray, first, isFunction } from "@mefisto/utils";
// Framework
import { usePortal } from "stack";
import { makeStyles, classnames, CoverSpinner, Box } from "ui";
// Utils
import { sanitizeInput } from "./utils/sanitizeInput";
// Components
import { object, lazy } from "yup";
import { Formik, Form as FormikForm } from "formik";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles({
  dialog: {
    // This is a hack in order to make the inner elements
    // scroll in dialog
    // https://github.com/mui-org/material-ui/issues/13253
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    overflowY: "inherit",
    height: "100%",
  },
  loading: {
    position: "relative",
  },
});

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Form = ({
  innerRef,
  schema = {},
  defaults = {},
  disabled,
  loading,
  dialog,
  spinner = <CoverSpinner size="small" />,
  validateOnBlur = false,
  onSubmit = noop,
  onChange = noop,
  children,
}) => {
  // Styles
  const classes = useStyles();
  // Framework
  const { log } = usePortal();
  // Callbacks
  const getSchema = useCallback(
    (values = {}) => {
      const shape = isFunction(schema)
        ? schema({
            ...defaults,
            ...values,
          })
        : schema;
      return isArray(shape) ? first(shape) : shape;
    },
    [schema, defaults]
  );
  // Initial values
  const initialValues = sanitizeInput(getSchema(), defaults, false);
  // Handlers
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const cleanedValues = sanitizeInput(getSchema(values), values, true);
    log.info("📔", "Submit", cleanedValues);
    onSubmit(cleanedValues, { resetForm });
    // Set submitting to false, because we use our own `loading` handler
    setSubmitting(false);
  };
  // Render
  return (
    <Formik
      validateOnBlur={validateOnBlur}
      enableReinitialize
      innerRef={innerRef}
      initialValues={initialValues}
      validationSchema={() =>
        lazy((values) => {
          const shape = isFunction(schema)
            ? schema({
                ...defaults,
                ...values,
              })
            : schema;
          if (isArray(shape)) {
            const [fields, noSortEdges] = shape;
            return object().shape(fields, noSortEdges);
          } else {
            return object().shape(shape);
          }
        })
      }
      onSubmit={handleSubmit}
    >
      {(form) => {
        // TODO: This should be moved to effect #310
        setTimeout(() => onChange(form.values, form), 0);
        return (
          <FormikForm
            className={classnames({
              [classes.dialog]: dialog,
              [classes.loading]: loading,
            })}
          >
            {loading && spinner}
            {children(form)}
            {/* TODO: This is temporary solution. All the elements within the form should be disabled */}
            {disabled && (
              <Box
                bgcolor="transparent"
                position="absolute"
                top={0}
                bottom={0}
                left={0}
                right={0}
                zIndex={1}
              />
            )}
          </FormikForm>
        );
      }}
    </Formik>
  );
};

Form.propTypes = {
  schema: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  loading: PropTypes.bool,
  spinner: PropTypes.element,
  defaults: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  dialog: PropTypes.bool,
};

export default Form;
