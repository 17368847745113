import { useContext } from "react";
import { AppContext } from "./AppContext";

/**
 * @return {{
     interceptor: import("../../../interceptor/core").Interceptor,
     model: import("../../../model/core").Model,
     plugin: import("../../../plugin/core").Plugin,
 * }}
 */
export const useApp = () => {
  return useContext(AppContext);
};
