// React
import React, { Children, isValidElement, cloneElement } from "react";
import PropTypes from "prop-types";
// Framework
import { Box, Grid } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderSection = ({
  variant = "leading",
  disableGutter,
  itemSpacing,
  boxProps,
  children,
}) => (
  <Box
    pr={[0, variant === "leading" && !disableGutter ? 2 : 0]}
    pl={[0, variant === "trailing" && !disableGutter ? 2 : 0]}
    {...boxProps}
  >
    <Grid
      container
      justifyContent={variant === "trailing" ? "flex-end" : "flex-start"}
      alignItems="center"
      wrap="nowrap"
      spacing={itemSpacing}
    >
      {Children.map(
        children,
        (child) =>
          isValidElement(child) &&
          cloneElement(child, {
            section: variant,
          })
      )}
    </Grid>
  </Box>
);

HeaderSection.propTypes = {
  /**
   * Header section variant.
   * Section `leading` is positioned at header start, `center` fills the
   * center space and `trailing` is moved to header end.
   */
  variant: PropTypes.oneOf(["leading", "center", "trailing"]).isRequired,
  /**
   * Set to `true` to disable section gutter
   */
  disableGutter: PropTypes.bool,
  /**
   * Spacing between header items
   */
  itemSpacing: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  /**
   * Props passed to Box
   */
  boxProps: PropTypes.object,
  /**
   * Children
   */
  children: PropTypes.node,
};

export default HeaderSection;
