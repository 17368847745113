import React from "react";
import { createSvgIcon } from "icon/utils";

export default createSvgIcon(
  <>
    <path d="M2 3h9v9H2V3m9 19H2v-9h9v9M21 3v9h-9V3h9m0 19h-9v-9h9v9z" />
  </>,
  "Microsoft",
  {
    viewBox: "0 0 24 24",
  }
);
