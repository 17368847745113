// React
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, useTheme, Typography } from "@material-ui/core";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.radius.small,
    padding: theme.spacing(0.5, 1),
  },
  text: {
    userSelect: "none",
    fontWeight: 500,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Tag = forwardRef(({ label, color }, ref) => {
  // Styles
  const classes = useStyles();
  const theme = useTheme();
  // Render
  return (
    <div
      ref={ref}
      style={{
        color: theme.palette.getContrastText(color),
        backgroundColor: color,
      }}
      className={classes.root}
    >
      <Typography
        noWrap
        color="inherit"
        variant="caption"
        component="div"
        display="block"
        className={classes.text}
      >
        {label}
      </Typography>
    </div>
  );
});

Tag.propTypes = {
  /**
   * Tag's title
   */
  label: PropTypes.string.isRequired,
  /**
   * Color of the tag
   */
  color: PropTypes.string.isRequired,
};

export default Tag;
