const Snippets = {
  tags: function ({ id, advancedMatching, title, data }) {
    // Script
    const script = `!(function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", "${id}" ${advancedMatching ? `,${advancedMatching}` : ""});`;
    // No Script
    const noscript = `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1" />`;
    // Page view
    const pageView = `fbq("track", "PageView");`;
    // Track
    const track = `fbq("track", "${title}", ${JSON.stringify(data)});`;

    return {
      script,
      noscript,
      pageView,
      track,
    };
  },
};

export class Pixel {
  #snippets = {};
  #enabled = false;

  constructor(config, log) {
    const { pixelEnabled, pixelId } = config.values;
    if (pixelEnabled) {
      this.#enabled = true;
      this.#initialize({ id: pixelId });
      this.pageView();
      log.info("👾 Meta Pixel [On]");
    }
  }

  /**
   * Constructs pixel init code
   * @param args
   * @return {{noScript: function, script: function}}
   */
  #pixelCode = (args) => {
    this.#snippets = Snippets.tags(args);
    // Script code
    const script = (s) => {
      const script = document.createElement("script");
      script.innerHTML = s || this.#snippets.script;
      return script;
    };
    // No script code (i.e. the pixel)
    const noScript = () => {
      const noscript = document.createElement("noscript");
      noscript.innerHTML = this.#snippets.noscript;
      return noscript;
    };

    return { script, noScript };
  };

  /**
   * Initializes Pixel
   * @param id
   * @param advancedMatching
   */
  #initialize = ({ id, advancedMatching }) => {
    const pixelCode = this.#pixelCode({ id, advancedMatching });
    document.head.insertBefore(pixelCode.script(), document.head.childNodes[0]);
    document.head.insertBefore(
      pixelCode.noScript(),
      document.head.childNodes[0]
    );
  };

  /**
   * Call to track PageView
   */
  pageView() {
    if (this.#enabled) {
      const pixelCode = this.#pixelCode({});
      document.head.insertBefore(
        pixelCode.script(this.#snippets.pageView),
        document.head.childNodes[0]
      );
    }
  }

  /**
   * Call to track custom event
   * @param title {string} Name of the event
   * @param data {object} Data passed
   */
  track({ title, data }) {
    if (this.#enabled) {
      const pixelCode = this.#pixelCode({ title, data });
      document.head.insertBefore(
        pixelCode.script(this.#snippets.track),
        document.head.childNodes[0]
      );
    }
  }
}
