// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, Chip } from "@material-ui/core";
import { classnames } from "ui/classnames";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: 0.9,
    minWidth: theme.spacing(4),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[200],
  },
  small: {
    height: theme.spacing(2.25),
    minWidth: theme.spacing(2.25),
    ...theme.typography.caption,
    lineHeight: 1,
  },
  medium: {
    height: theme.spacing(4),
    minWidth: theme.spacing(3),
    ...theme.typography.caption,
    lineHeight: 1,
  },
  labelSmall: {
    padding: theme.spacing(0, 1),
  },
  avatar: {
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: theme.radius.rounded,
  },
  avatarSmall: {
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(0),
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  avatarMedium: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  deleteIcon: {
    color: "inherit",
    opacity: 0.8,
  },
  hidden: {
    visibility: "hidden",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ColorChip = ({
  label,
  color,
  size = "medium",
  hidden,
  onDelete,
  ...rest
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Chip
      label={label}
      icon={
        color ? (
          <div
            className={classnames(
              classes.avatar,
              size === "small" && classes.avatarSmall,
              size === "medium" && classes.avatarMedium
            )}
            style={{ backgroundColor: color }}
          />
        ) : null
      }
      classes={{
        root: classnames(
          classes.root,
          hidden && classes.hidden,
          size === "small" && classes.small,
          size === "medium" && classes.medium
        ),
        label: classnames(size === "small" && classes.labelSmall),
        deleteIcon: classes.deleteIcon,
      }}
      onDelete={onDelete}
      {...rest}
    />
  );
};

ColorChip.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  hidden: PropTypes.bool,
  onDelete: PropTypes.func,
};

export default ColorChip;
