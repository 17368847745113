// React
import React, { useContext } from "react";
import PropTypes from "prop-types";
// Helpers
import { keys, pick } from "@mefisto/utils";
// Helpers
import { forEach } from "@mefisto/utils";
// Components
import { FormatContext } from "format/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Format = ({ props, children }) => {
  // Context
  const context = useContext(FormatContext);
  // Set values
  forEach(pick(props, keys(Format.propTypes)), (value, key) => {
    context[key] = value;
  });
  // Render
  return <>{children}</>;
};

Format.defaultProps = {
  __TYPE: "Format",
};

Format.propTypes = {
  /**
   * Override locale for all the formatting.
   * By default, the locale is taken from the current localization context.
   */
  locale: PropTypes.string,
  /**
   * Other formatting options (such as FormatPrice, etc.)
   */
  children: PropTypes.node,
};

export default Format;
