// React
import { useMemo, useCallback } from "react";
// Helpers
import { reduce } from "@mefisto/utils";
// Framework
import { getData, useApolloClient, useMutation } from "model/core";
import { usePortal } from "stack";
import { useResources } from "resource";

export const useEntityDelete = (
  Entity,
  {
    input,
    resources: customResources,
    languages,
    files,
    fetchPolicy,
    nextFetchPolicy,
    optimistic,
    tags,
  } = {}
) => {
  // Framework
  const { log, uploadQueue: queue } = usePortal();
  const client = useApolloClient();
  // Resources
  const { resources: defaultResources } = useResources();
  const resources = useMemo(() => {
    return customResources ?? defaultResources;
  }, [customResources, defaultResources]);
  // Model
  const Tags = useMemo(() => {
    const { DELETE } = tags ?? Entity.Tags;
    if (!DELETE) {
      throw TypeError("[DELETE] tags must be defined");
    }
    return { DELETE };
  }, [Entity, tags]);
  const entity = useMemo(() => {
    return new Entity(client, { queue });
  }, [Entity, queue, client]);
  // Callbacks
  const getFiles = useCallback((files) => {
    return reduce(
      files,
      (result, value, key) => {
        result[key] = {
          name: value.name,
          size: value.size,
          contentType: value.type,
        };
        return result;
      },
      {}
    );
  }, []);
  const getVariables = useCallback(
    ({ input, resources, languages, files } = {}) => ({
      ...((input || files) && {
        input: { ...input, ...getFiles(files) },
      }),
      ...(resources && { resources }),
      ...(languages && { languages }),
    }),
    [getFiles]
  );
  // Mutation
  const [_delete, mutation] = useMutation(Tags.DELETE, {
    fetchPolicy,
    nextFetchPolicy,
    notifyOnNetworkStatusChange: true,
  });
  const { loading, called, error } = mutation;
  // Memo
  const data = useMemo(() => {
    return getData(mutation);
  }, [mutation]);
  // Callbacks
  const performDelete = useCallback(
    async (options = {}) => {
      const variables = getVariables({
        input: options.input ?? input,
        resources: options.resources ?? resources,
        languages: options.languages ?? languages,
        files: options.files ?? files,
      });
      const optimisticResponse =
        (options.optimistic ?? optimistic) &&
        entity.optimisticResponse(
          entity.tagName(Tags.DELETE),
          options.optimistic ?? optimistic
        );
      log.info("🔴", "Delete", { variables, optimisticResponse });
      try {
        const result = await _delete({
          variables,
          optimisticResponse,
          update: (cache, { data }) => {
            entity.onDelete({
              ...variables,
              files: options.files ?? files,
              data,
            });
          },
        });
        return getData(result);
      } catch (error) {
        if (options.throwOnError) {
          throw error;
        }
      }
    },
    [
      log,
      Tags,
      getVariables,
      input,
      resources,
      languages,
      files,
      optimistic,
      _delete,
      entity,
    ]
  );
  // Render
  return {
    called,
    data,
    error,
    loading,
    delete: performDelete,
  };
};
