// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { Box } from "ui/components";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileFixedContent = ({ display = "fill", children }) => (
  <Box mx={-3} mb={-3} height={display === "fill" ? "100%" : "auto"}>
    {children}
  </Box>
);

TileFixedContent.propTypes = {
  display: PropTypes.oneOf(["fill", "fit"]),
  children: PropTypes.node,
};

export default TileFixedContent;
