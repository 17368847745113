// React
import React, { useContext, useCallback } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal, useApp } from "stack/core";
import { useStackDependency } from "stack/dependency";
// Components
import { InterceptorContext } from "./InterceptorContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({ source, children }) => {
  // Context
  const options = useContext(InterceptorContext);
  // Framework
  const { interceptor: portalInterceptor } = usePortal();
  const { interceptor: appInterceptor } = useApp();
  const interceptor = source === "portal" ? portalInterceptor : appInterceptor;
  // Init
  useStackDependency(interceptor, options);
  // Callbacks
  const hideLoader = useCallback(() => {
    const loader = document.getElementById(options.loaderId);
    if (loader) {
      loader.remove();
    }
  }, [options]);
  // Handlers
  const handleFinished = useCallback(() => {
    if (source === "app") {
      hideLoader();
    }
  }, [hideLoader, source]);
  // Render
  return (
    <>
      {interceptor.makeInterceptors(children, {
        onFinished: handleFinished,
      })}
    </>
  );
};

const InterceptorProvider = ({ source, options, children }) => (
  <InterceptorContext.Provider value={{ loaderId: "loader", interceptors: [] }}>
    {options}
    <Component source={source}>{children}</Component>
  </InterceptorContext.Provider>
);

InterceptorProvider.propTypes = {
  source: PropTypes.oneOf(["portal", "app"]),
  options: PropTypes.node,
};

export { InterceptorProvider };
