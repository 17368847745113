// React
import React, { useContext } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";
// Components
import { FoggleContext } from "./FoggleContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({ children }) => {
  // Framework
  const { foggle } = usePortal();
  // Context
  const options = useContext(FoggleContext);
  // Init
  useStackDependency(foggle, options);
  // Render
  return <>{children}</>;
};

const FoggleProvider = ({ options, children }) => (
  <FoggleContext.Provider value={{ prefix: "foggle" }}>
    {options}
    <Component>{children}</Component>
  </FoggleContext.Provider>
);

FoggleProvider.propTypes = {
  options: PropTypes.element,
};

export { FoggleProvider };
