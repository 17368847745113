// React
import React, { cloneElement, isValidElement } from "react";
import PropTypes from "prop-types";
// Helpers
import { map, noop } from "@mefisto/utils";
// Framework
import { makeStyles, alpha, ToggleButton, ToggleButtonGroup } from "ui";
import { useTheme } from "theme";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[0],
  },
  selected: {},
  button: {
    textTransform: "none",
    margin: theme.spacing(0, 0.75),
    height: theme.spacing(4),
    borderWidth: 0,
    borderRadius: theme.radius.large,
    "&$selected": {
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.25),
      },
    },
    "&:hover": {
      backgroundColor: alpha(theme.palette.text.primary, 0.08),
    },
    "&:not(:first-child)": {
      borderRadius: theme.radius.large,
    },
    "&:not(:last-child)": {
      borderRadius: theme.radius.large,
    },
  },
  label: {
    margin: theme.spacing(0, 0.5),
    lineHeight: "normal",
  },
  icon: {
    margin: theme.spacing(0, 0.5, 0, -0.5),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const OptionsGroup = ({ value, options, exclusive, onChange = noop }) => {
  // Styles
  const classes = useStyles();
  const theme = useTheme();
  // Render
  return (
    <ToggleButtonGroup
      size="small"
      value={value}
      exclusive={exclusive}
      onChange={onChange}
      classes={{ root: classes.root }}
    >
      {map(
        options,
        ({ value: optionValue, title, icon: Icon, color }, index) => {
          let icon = null;
          if (Icon && isValidElement(Icon)) {
            icon = cloneElement(Icon, {
              className: classes.icon,
            });
          }
          const selected = value === optionValue;
          return (
            <ToggleButton
              key={index}
              value={optionValue}
              classes={{
                root: classes.button,
                label: classes.label,
                selected: classes.selected,
              }}
              style={{
                ...(color && selected
                  ? {
                      backgroundColor: color,
                      color: theme.palette.getContrastText(color),
                    }
                  : {}),
              }}
            >
              {icon}
              {title}
            </ToggleButton>
          );
        }
      )}
    </ToggleButtonGroup>
  );
};

OptionsGroup.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.element,
      color: PropTypes.string,
    })
  ).isRequired,
  exclusive: PropTypes.bool,
  onChange: PropTypes.func,
};

export default OptionsGroup;
