// Helpers
import { noop } from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";
// Components
import axios from "axios";
import { fileSave } from "browser-fs-access";

export class Storage extends StackDependency {
  #cache;

  onInitialized() {
    const { cache } = this.context;
    this.#cache = cache;
  }

  /**
   * Stores url under the given path in cache
   */
  setCachedUrl(path, url, generation = "0") {
    this.#cache.store(path, { url, generation });
  }

  /**
   * Returns url cached under the given path
   */
  getCachedUrl(path) {
    return this.#cache.retrieve(path);
  }

  /**
   * Removes url from cache
   */
  removeCachedUrl(path) {
    this.#cache.remove(path);
  }

  /**
   * Token source for cancel request
   */
  cancelTokenSource() {
    const CancelToken = axios.CancelToken;
    return CancelToken.source();
  }

  /**
   * Uploads file to storage
   */
  async upload({
    url,
    file,
    contentType = file.type,
    expiration = 0,
    cacheVisibility = "private",
    cancelToken,
    throwOnError = false,
    progress = noop,
  } = {}) {
    try {
      return await axios.put(url, file, {
        headers: {
          "Content-Type": contentType,
          "Cache-Control": `${cacheVisibility}, max-age=${expiration}`,
        },
        cancelToken,
        onUploadProgress: progress,
      });
    } catch (error) {
      const response = { canceled: axios.isCancel(error) };
      if (throwOnError) {
        throw { exception: error, message: error.message, ...response };
      } else {
        return { error, ...response };
      }
    }
  }

  /**
   * Downloads file at the given `url` and forces browser to download it
   * instead of opening the file in a new tab.
   * @param url URL to the file
   * @param name Name of the file as it will be stored on the disk
   * @param extensions
   * @param progress Callback with {loaded, total} values
   * @param cancelToken Token used for cancellation
   * @param throwOnError If set to `true` the function will throw on error
   */
  async download(
    url,
    {
      name,
      extensions,
      progress = noop,
      cancelToken,
      throwOnError = false,
    } = {}
  ) {
    try {
      const { data } = await axios.get(url, {
        responseType: "blob",
        cancelToken,
        onDownloadProgress: progress,
      });
      await fileSave(data, {
        fileName: name,
        extensions,
      });
    } catch (error) {
      const response = { canceled: axios.isCancel(error) };
      if (throwOnError) {
        throw { exception: error, message: error.message, ...response };
      } else {
        return { error, ...response };
      }
    }
  }
}
