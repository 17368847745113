// React
import React, { useCallback } from "react";
// Framework
import { usePortal } from "stack";
import { IconButton } from "ui";
import { ArrowBackIosRounded as BackIcon } from "icon/material";
// Components
import HeaderItem from "../HeaderItem";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderBackButton = () => {
  // Framework
  const { navigation } = usePortal();
  // Handlers
  const handleClick = useCallback(() => {
    navigation.goBack();
  }, [navigation]);
  // Render
  return (
    <HeaderItem value="back">
      <IconButton edge="start" color="inherit" onClick={handleClick}>
        <BackIcon />
      </IconButton>
    </HeaderItem>
  );
};

export default HeaderBackButton;
