// React
import React, { Children, isValidElement, cloneElement } from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, Scrollable, Hidden } from "ui";
import { NavigationLayout } from "navigation";
import { SceneLoader } from "layout/components";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100dvh",
    fallbacks: [{ height: "100vh" }],
  },
  carousel: {
    width: "30%",
    background: theme.palette.background.default,
    backgroundSize: "cover",
    backgroundPosition: "center",
    appRegion: "drag",
    overflow: "hidden",
  },
  content: {
    position: "relative",
    background: theme.palette.common.white,
    width: "70%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const AuthLayout = ({ image, carousel, loader = SceneLoader, children }) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <NavigationLayout>
      <div className={classes.root}>
        <Hidden smDown>
          <div
            className={classes.carousel}
            style={{ backgroundImage: image && `url(${image})` }}
          >
            {carousel}
          </div>
        </Hidden>
        <div className={classes.content}>
          <Scrollable disableScrollX>
            {Children.map(
              children,
              (scene) =>
                isValidElement(scene) &&
                cloneElement(scene, {
                  loader,
                })
            )}
          </Scrollable>
        </div>
      </div>
    </NavigationLayout>
  );
};

AuthLayout.propTypes = {
  image: PropTypes.string,
  carousel: PropTypes.any,
  loader: PropTypes.any,
  children: PropTypes.node,
};

export default AuthLayout;
