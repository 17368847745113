// Helpers
import {
  reduce,
  isEmpty,
  includes,
  join,
  trim,
  get,
  has,
  isObject,
  map,
  compact,
  forEach,
} from "@mefisto/utils";
import { serialize, deserialize } from "./transform";

/**
 * Builds path for the given elements
 */
const makePath = (elements, separator = ".") => {
  return trim(join(elements, separator), separator);
};

export const sanitizeInput = (schemas, values, isSerialized, path = "") => {
  return reduce(
    schemas,
    (result, schema, key) => {
      // Build the path to the value
      const valuePath = makePath([path, key]);
      // Schema is not set, add a default value in input
      if (!schema) {
        if (!isSerialized) {
          result[key] = "";
        }
      }
      // Check if schema has some fields. If so, we need to recursively
      // call the sanitization for all the fields.
      else if (!isEmpty(schema.fields)) {
        const value = get(values, valuePath);
        if (value) {
          result[key] = sanitizeInput(
            schema.fields,
            values,
            isSerialized,
            valuePath
          );
        } else {
          result[key] = null;
        }
      } else {
        // Get transformers if set
        const {
          innerType = {},
          meta: { transformers = [] } = {},
        } = schema.describe();
        const type = schema._type;
        const subSchema = schema._subType;
        // When we have the particular value sanitize it
        if (isSerialized) {
          // Serialize
          const value = get(values, valuePath, "");
          if (type === "string" && isObject(value)) {
            if (has(value, "key") || has(value, "__typename")) {
              // Entity object
              result[key] = value.key;
            } else {
              // Any other object (e.g. blob)
              result[key] = value;
            }
          } else if (type === "array" && subSchema) {
            const value = get(values, valuePath);
            if (value) {
              result[key] = [];
              forEach(get(values, valuePath), (value) => {
                result[key].push(
                  sanitizeInput(subSchema.fields, value, isSerialized)
                );
              });
            } else {
              result[key] = null;
            }
          } else if (type === "array") {
            const arrayResult = compact(
              map(value, (val) => {
                if (val?.["__typename"] === "LocalizedString") {
                  return val;
                }
                // If this is a database entity, return only the key
                else if (
                  (has(val, "key") || has(val, "__typename")) &&
                  innerType.type !== "object"
                ) {
                  return val.key;
                } else {
                  return val;
                }
              })
            );
            result[key] = isEmpty(arrayResult) ? null : arrayResult;
          } else {
            result[key] = serialize(value, transformers);
          }
        } else {
          // Deserialize
          const value = get(values, valuePath, "");
          if (includes(["string", "object"], type) && isObject(value)) {
            result[key] = value;
          } else if (type === "array" && subSchema) {
            const value = get(values, valuePath);
            if (value) {
              result[key] = [];
              forEach(get(values, valuePath), (value) => {
                result[key].push(
                  sanitizeInput(subSchema.fields, value, isSerialized)
                );
              });
            } else {
              result[key] = null;
            }
          } else if (type === "array") {
            result[key] = isEmpty(value) ? [] : value;
          } else {
            result[key] = deserialize(value, transformers);
          }
        }
      }
      return result;
    },
    {}
  );
};
