import { Entity } from "model/core";

/**
 * Check if the prop is of the `Entity` type
 */
export const makeEntityPropType = (isRequired) => (props, propName) => {
  const entity = props[propName];
  if (isRequired && !entity) {
    throw TypeError(`${propName} is required.`);
  }
  if (entity && !Entity.isPrototypeOf(entity)) {
    throw TypeError(`${propName} must inherit from Entity class.`);
  }
};

const EntityPropType = makeEntityPropType(false);
EntityPropType.isRequired = makeEntityPropType(true);

export { EntityPropType };
