// React
import React, { memo } from "react";
import PropTypes from "prop-types";
// Framework
import { Route } from "react-router-dom";
import { usePortal } from "stack";
import { Helmet } from "helmet";
// Components
import Redirect from "./components/Redirect";
import RoutePublic from "./components/RoutePublic";
import RouteAuthenticated from "./components/RouteAuthenticated";
import RouteUnauthenticated from "./components/RouteUnauthenticated";
import RouteUnverified from "./components/RouteUnverified";
import RouteVerified from "./components/RouteVerified";
import RouteOrganization from "./components/RouteOrganization";
import RouteAdmin from "./components/RouteAdmin";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const routes = {
  ["public"]: RoutePublic,
  ["unauthenticated"]: RouteUnauthenticated,
  ["authenticated"]: RouteAuthenticated,
  ["unverified"]: RouteUnverified,
  ["verified"]: RouteVerified,
  ["organization"]: RouteOrganization,
  ["admin"]: RouteAdmin,
};

const NavigationScene = ({
  title,
  description,
  access,
  disabled,
  path,
  redirectPath,
  scene,
  sceneProps,
  activity,
  errorBoundary,
  helmetProps,
  loaderProps,
  loader: Loader,
}) => {
  // Framework
  const { navigation } = usePortal();
  // Render
  return (
    <Route exact path={path}>
      <Helmet title={title} description={description} {...helmetProps} />
      {disabled ? (
        <Redirect replace to={redirectPath ?? navigation.routes.default} />
      ) : (
        <Loader
          scene={scene}
          sceneProps={sceneProps}
          route={routes[access]}
          activity={activity}
          errorBoundary={errorBoundary}
          {...loaderProps}
        />
      )}
    </Route>
  );
};

NavigationScene.propTypes = {
  /**
   * Title of the scene.
   * The title will be added to head tag.
   */
  title: PropTypes.string,
  /**
   * Description of the scene.
   * The description will be added to head tag.
   */
  description: PropTypes.string,
  /**
   * Defines level of access
   */
  access: PropTypes.oneOf([
    /**
     * The scene is accessible by all the users without any authentication
     */
    "public",
    /**
     * The scene is not accessible by the user that is already authenticated.
     * User that enters the scene is redirected to the `default` scene.
     */
    "unauthenticated",
    /**
     * The scene is accessible to the users that are authenticated but not
     * necessarily verified. User that doesn't have access to the given scene
     * is redirected to the `Sign In` scene.
     */
    "authenticated",
    /**
     * The scene is not accessible by the user that is already verified.
     * User that enters the scene is redirected to `default` scene.
     */
    "unverified",
    /**
     * Only a user that is authenticated and verified can access the scene.
     * User that is not authenticated is redirected to the `notAuthenticated` scene.
     * User that is not verified is redirected to the `notVerified` scene.
     */
    "verified",
    /**
     * Only a user with organization permission can access the scene.
     */
    "organization",
    /**
     * Only a user with admin permission can access the scene.
     */
    "admin",
  ]).isRequired,
  /**
   * Unique scene path
   */
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /**
   * When `disabled` is set to `true` the scene automatically
   * redirects to the given path.
   *
   * Default: routes.default
   */
  redirectPath: PropTypes.string,
  /**
   * When set to `true` the scene is not rendered
   */
  disabled: PropTypes.bool,
  /**
   * Scene import function
   */
  scene: PropTypes.func,
  /**
   * Props passed to scene component
   */
  sceneProps: PropTypes.object,
  /**
   * Custom activity shown during the loading
   */
  activity: PropTypes.element,
  /**
   * Custom error boundary shown during the error
   */
  errorBoundary: PropTypes.element,
  /**
   * Props passed to Helmet component
   */
  helmetProps: PropTypes.object,
};

export default memo(NavigationScene);
