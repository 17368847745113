import { useState, useCallback } from "react";

/**
 * Forces component to rerender when `forceRefresh` function is called.
 * Also returns number of already provider refresh counts.
 *
 * @return {{refreshCount: number, forceRefresh: Function}}
 */
export const useForceRefresh = () => {
  // State
  const [refreshCount, setRefreshCount] = useState(0);
  // Callback
  /** @type {function} */
  const forceRefresh = useCallback(() => {
    setRefreshCount((count) => count + 1);
  }, []);
  // render
  return { refreshCount, forceRefresh };
};
