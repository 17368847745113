import React from "react";
import { createSvgIcon } from "icon/utils";

export default createSvgIcon(
  <path
    fill="#6AAB20"
    d="M13.4,8.488H11.172V7.349H13.4v1.14Zm0,0.651H11.172v1.14H13.4V9.14Zm0-5.372H11.172v1.14H13.4V3.767Zm0,1.791H11.172V6.7H13.4V5.558Zm0,5.372H11.172v1.14H13.4V10.93Zm1.537,2.247a0.643,0.643,0,0,1-.727.358H9.264V15H8.277L1,13.7V2.3L8.319,1H9.264V2.3h4.774a1.435,1.435,0,0,1,.8.156A1.526,1.526,0,0,1,15,3.277l-0.006,8.475A7.612,7.612,0,0,1,14.935,13.177Zm-8.1-2.554C6.391,9.711,5.944,8.806,5.507,7.894Q6.156,6.563,6.79,5.226q-0.541.027-1.081,0.067C5.44,5.962,5.127,6.613,4.917,7.3c-0.2-.653-0.455-1.281-0.692-1.918Q3.7,5.417,3.176,5.449C3.545,6.283,3.938,7.1,4.3,7.943,3.874,8.757,3.48,9.582,3.072,10.4c0.348,0.015.7,0.029,1.045,0.034,0.248-.648.556-1.271,0.773-1.932a19.494,19.494,0,0,0,.793,2.045C6.065,10.575,6.447,10.6,6.83,10.623Zm7.407-7.552H9.264v0.7h1.272v1.14H9.264V5.558h1.272V6.7H9.264V7.349h1.272v1.14H9.264V9.14h1.272v1.14H9.264V10.93h1.272v1.14H9.264v0.753h4.973V3.071Z"
    transform="translate(-1 -1)"
  />,
  "ExcelIcon",
  { viewBox: "-1 -1 16 16" }
);
