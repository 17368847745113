// React
import React from "react";
// Framework
import { makeStyles, Grid } from "@material-ui/core";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Spacer = ({ children }) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Grid item className={classes.root}>
      {children}
    </Grid>
  );
};

export default Spacer;
