// React
import React, { useMemo } from "react";
import PropTypes from "prop-types";
// Framework
import {
  Archive,
  Blank,
  Csv,
  Excel,
  Image,
  Powerpoint,
  Pdf,
  Text,
  Word,
} from "icon/file";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const FileIcon = ({ type, ...rest }) => {
  // Memo
  const Component = useMemo(() => {
    switch (type) {
      case "application/zip":
      case "application/x-rar":
      case "application/x-rar-compressed":
      case "application/x-tar":
      case "application/x-7z-compressed":
        return Archive;
      case "text/csv":
        return Csv;
      case "application/vnd.ms-excel":
      case "application/vnd.oasis.opendocument.spreadsheet":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.apple.numbers":
        return Excel;
      case "image/jpeg":
      case "image/png":
      case "image/svg+xml":
      case "image/tiff":
        return Image;
      case "application/pdf":
        return Pdf;
      case "application/vnd.ms-powerpoint":
      case "application/vnd.oasis.opendocument.presentation":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "application/vnd.apple.keynote":
        return Powerpoint;
      case "text/plain":
        return Text;
      case "application/msword":
      case "application/vnd.oasis.opendocument.text":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/x-iwork-pages-sffpages":
      case "application/vnd.apple.pages":
        return Word;
      default:
        return Blank;
    }
  }, [type]);
  // Render
  return <Component {...rest} />;
};

FileIcon.propTypes = {
  type: PropTypes.string,
};

export default FileIcon;
