// React
import React from "react";
// Framework
import { usePortal } from "stack";
import { useUser, AuthState } from "authentication";
// Components
import Redirect from "../Redirect";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const RouteUnauthenticated = ({ children }) => {
  // Framework
  const { navigation } = usePortal();
  const {
    user: { state },
  } = useUser();
  // User is authenticated, so she doesn't have
  // access to unauthenticated routes
  if (state === AuthState.authenticated) {
    const { unauthenticated: route } = navigation.routes;
    const continueUrl = navigation.continueUrl;
    return <Redirect replace to={continueUrl ? continueUrl : route} />;
  }
  // Do not redirect
  return children;
};

export default RouteUnauthenticated;
