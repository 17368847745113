// React
import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { noop } from "@mefisto/utils";
// Framework
import { ModalDialog } from "ui/components";
import { useTranslate } from "localization/hooks";
import {
  LayersClearOutlined as ClearIcon,
  RotateLeftRounded as RotateLeftIcon,
  RotateRightRounded as RotateRightIcon,
} from "icon/material";
// Components
import DialogContent from "./components/DialogContent";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ImageDialog = forwardRef(
  (
    {
      title,
      aspect,
      display,
      zoneWidth = 552,
      zoneHeight = 345,
      previewWidth = 80,
      previewHeight = 80,
      maxWidth = 2048,
      maxHeight = 2048,
      minWidth = 800,
      minHeight = 800,
      onFinished = noop,
      onClose = noop,
    },
    ref
  ) => {
    // Framework
    const { translate } = useTranslate();
    // State
    const [edited, setEdited] = useState(false);
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [warning, setWarning] = useState(null);
    // Ref
    const imageCropRef = useRef(null);
    useImperativeHandle(ref, () => ({
      open(file) {
        setFile(file);
        setWarning(null);
        setOpen(true);
      },
    }));
    // Handlers
    const handleClose = () => {
      setOpen(false);
      setFile(null);
      onClose();
    };
    const handleClear = () => {
      imageCropRef.current.clear();
      setEdited(false);
    };
    const handleRotateLeft = () => {
      imageCropRef.current.rotateLeft(90);
      setEdited(true);
    };
    const handleRotateRight = () => {
      imageCropRef.current.rotateRight(90);
      setEdited(true);
    };
    const handleEdit = () => {
      setEdited(true);
    };
    const handleCrop = (crop, image) => {
      const scale = image.naturalWidth / image.width;
      const cropWidth = crop.width * scale;
      const cropHeight = crop.height * scale;
      const hasWarning = cropWidth < minWidth || cropHeight < minHeight;
      const message = translate("core:image.dialog.label.lowResolution", {
        params: { width: minWidth, height: minHeight },
      });
      setWarning(hasWarning ? message : null);
    };
    const handleSubmit = async () => {
      const result = await imageCropRef.current.finalize({
        maxWidth,
        maxHeight,
      });
      setOpen(false);
      setFile(null);
      onFinished(result);
    };
    // Render
    return (
      <ModalDialog
        open={open}
        title={title ?? translate("core:image.dialog.title")}
        options={[
          {
            title: translate("core:image.dialog.option.clear"),
            icon: <ClearIcon color={edited ? "action" : "disabled"} />,
            disabled: !edited,
            onClick: handleClear,
          },
          {
            title: translate("core:image.dialog.option.rotateLeft"),
            icon: <RotateLeftIcon color="action" />,
            onClick: handleRotateLeft,
          },
          {
            title: translate("core:image.dialog.option.rotateRight"),
            icon: <RotateRightIcon color="action" />,
            onClick: handleRotateRight,
          },
        ]}
        actions={[
          {
            title: translate("core:image.dialog.action.save"),
            onClick: handleSubmit,
          },
        ]}
        warning={warning}
        onClose={handleClose}
      >
        <DialogContent
          ref={imageCropRef}
          file={file}
          aspect={aspect}
          display={display}
          zoneWidth={zoneWidth}
          zoneHeight={zoneHeight}
          previewWidth={previewWidth}
          previewHeight={previewHeight}
          onCrop={handleCrop}
          onEdit={handleEdit}
        />
      </ModalDialog>
    );
  }
);

ImageDialog.propTypes = {
  title: PropTypes.string,
  aspect: PropTypes.number,
  display: PropTypes.oneOf(["square", "circle"]),
  zoneWidth: PropTypes.number,
  zoneHeight: PropTypes.number,
  previewWidth: PropTypes.number,
  previewHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  minHeight: PropTypes.number,
  onFinished: PropTypes.func,
  onClose: PropTypes.func,
};

export default ImageDialog;
