// Framework
import { StackDependency } from "stack/dependency";

export class Externals extends StackDependency {
  #values;

  onInitialized() {
    const { externals } = this.options;
    this.#values = externals;
  }

  /**
   * Returns all config values
   * @return {object}
   */
  get values() {
    return this.#values;
  }

  /**
   * Get particular value
   * @param key {string} - Config Value key
   * @param pathSet {[{ extension:string, pixelRation:number }]} - Object with
   * @param throwWhenMissing {boolean} If set to `true` the functions throws an error when no external is found.
   * @return {*}
   */
  get(key, { pathSet = [], throwWhenMissing = true } = {}) {
    const value = this.#values[key];
    if (value === undefined) {
      if (throwWhenMissing) {
        throw Error(`${key} external was not found`);
      }
      return null;
    }
    return value.path;
  }
}
