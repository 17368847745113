// React
import React from "react";
// Framework
import { useUser, AuthState } from "authentication";
import { usePortal } from "stack";
import { useResources } from "resource";
// Components
import Redirect from "../Redirect";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const RouteOrganization = ({ children }) => {
  // Framework
  const { navigation } = usePortal();
  const {
    user: { state },
  } = useUser();
  const {
    resources: { organization },
  } = useResources();
  // Not authenticated
  if (state === AuthState.notAuthenticated) {
    return (
      <Redirect replace continueUrl to={navigation.routes.notAuthenticated} />
    );
  }
  // Not verified
  if (!organization) {
    return <Redirect replace to={navigation.routes.notOrganization} />;
  }
  // Do not redirect
  return children;
};

export default RouteOrganization;
