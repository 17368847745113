// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";
// Helpers
import { compact } from "@mefisto/utils";
// Framework
import { usePortal } from "stack";
import { Helmet as Component } from "react-helmet-async";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Helmet = ({ description, meta = [], children, ...props }) => {
  // Framework
  const { helmet } = usePortal();
  // Effect
  useEffect(() => {
    helmet.set(props);
  }, [helmet, description, meta, props]);
  return (
    <Component
      meta={compact([
        description && { name: "description", content: description },
        ...meta,
      ])}
      {...props}
    >
      {children}
    </Component>
  );
};

Helmet.defaultProps = {
  __TYPE: "Helmet",
};

Helmet.propTypes = {
  /**
   * Used as a fallback when a template exists but a title is not defined
   */
  defaultTitle: PropTypes.string,
  /**
   * Set to `false` to not use requestAnimationFrame and instead update
   * the DOM as soon as possible.
   * Useful if you want to update the title when the tab is out of focus.
   */
  defer: PropTypes.bool,
  /**
   * Set to `true` to encode special characters
   */
  encodeSpecialCharacters: PropTypes.bool,
  /**
   * Adds link tags to head.
   * e.g. [{"rel": "canonical", "href": "http://mysite.com/example"}]
   */
  link: PropTypes.arrayOf(PropTypes.object),
  /**
   * Adds meta tags to head.
   * e.g. [{"name": "description", "content": "Test description"}]
   */
  meta: PropTypes.arrayOf(PropTypes.object),
  /**
   * Adds noscript tags to head.
   * e.g. [{"innerHTML": "<img src='http://mysite.com/js/test.js'"}]
   */
  noscript: PropTypes.arrayOf(PropTypes.object),
  /**
   * Adds script tags to head.
   * e.g. [{"type": "text/javascript", "src": "http://mysite.com/js/test.js"}]
   */
  script: PropTypes.arrayOf(PropTypes.object),
  /**
   * Adds style tags to head.
   * e.g. [{"type": "text/css", "cssText": "div { display: block; color: blue; }"}]
   */
  style: PropTypes.arrayOf(PropTypes.object),
  /**
   * Will set the title inside the head tag.
   */
  title: PropTypes.string,
  /**
   * Useful when you want titles to inherit from a template.
   * titleTemplate="%s | MyAwesomeWebsite.com" outputs
   * Title | MyAwesomeWebsite.com
   */
  titleTemplate: PropTypes.string,
  /**
   * Using the prioritizeSeoTags flag on any <Helmet> component allows
   * the server render of react-helmet-async to expose a method for
   * prioritizing relevant SEO tags.
   *
   * @see https://github.com/staylor/react-helmet-async#prioritizing-tags-for-seo
   */
  prioritizeSeoTags: PropTypes.bool,
  /**
   * Callback that tracks DOM changes
   */
  onChangeClientState: PropTypes.func,
  /**
   * Children will appear inside the head tag.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Helmet;
