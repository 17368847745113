// React
import React from "react";
// Context
import { PortalContext } from "./PortalContext";

/**
 * HOC which appends apps to the given component
 */
export const withPortal = (Component) => (props) => (
  <PortalContext.Consumer>
    {(portal) => <Component portal={portal} {...props} />}
  </PortalContext.Consumer>
);
