// React
import React, { Suspense, useEffect, useState } from "react";
// Framework
import { usePortal } from "stack/core";
import { ActivityComponent, ActivityPlaceholder } from "activity";
import { useMounted } from "hooks";
// Components
import PortalLayout from "../PortalLayout";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const PortalLoader = ({ layoutPlugins, children }) => {
  // Framework
  const { apps } = usePortal();
  const { callWhenMounted } = useMounted();
  // State
  const [loader, setLoader] = useState(false);
  // Effect
  useEffect(() => {
    return apps.onChange(
      callWhenMounted(() => {
        setLoader(apps.current?.loader);
      }),
      true
    );
  }, [callWhenMounted, apps]);
  // Render
  return (
    <>
      <ActivityComponent />
      <Suspense fallback={<ActivityPlaceholder token="ui:portal:loader" />}>
        <PortalLayout layoutPlugins={layoutPlugins}>{loader}</PortalLayout>
        {children}
      </Suspense>
    </>
  );
};

export default PortalLoader;
