import React from "react";
// Helpers
import {
  filter,
  find,
  forEach,
  get,
  has,
  isEmpty,
  isNil,
  omitBy,
  reduce,
  replace as replaceString,
  split,
  values,
} from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";
// Components
import queryString from "qs";
import { join } from "path-browserify";
import { matchPath } from "react-router-dom";
import {
  createBrowserHistory,
  createHashHistory,
  createMemoryHistory,
} from "history";

export class Navigation extends StackDependency {
  #history;
  #path;
  #navigation;
  #listener;
  #subscribers = [];
  #blockLeave = false;
  #blockSubscribers = [];

  onInitialized() {
    this.#makeHistory();
    this.#path = this.#pathFor();
    this.#bind();
  }

  #makeHistory = () => {
    const { type, basename } = this.options;
    const options = { basename };
    switch (type) {
      case "browser":
        this.#history = createBrowserHistory(options);
        break;
      case "hash":
        this.#history = createHashHistory(options);
        break;
      case "memory":
        this.#history = createMemoryHistory(options);
        break;
      default:
        throw Error(`Unknown history type: ${type}`);
    }
  };

  /**
   * Binds listeners
   */
  #bind = () => {
    const { apps } = this.context;
    apps.onChange(() => {
      this.#makeNavigation();
      this.#path = this.#pathFor();
    });
  };

  #makeNavigation = () => {
    const { apps } = this.context;
    if (!this.#navigation) {
      this.#navigation = reduce(
        apps.all,
        (result, { id, navigation }) => {
          const { basename, paths, routes } = navigation ?? {};
          result[id] = {
            paths: reduce(
              paths,
              (result, path, key) => {
                result[key] = join("/", basename, path);
                return result;
              },
              {}
            ),
            routes: reduce(
              routes,
              (result, route, key) => {
                result[key] = join("/", basename, route);
                return result;
              },
              {}
            ),
          };
          return result;
        },
        {}
      );
    }
  };

  /**
   * Subscribe to change.
   * Returns unsubscribe function you need to call in order to
   * stop the event observation in e.g. `useEffect` function.
   * @param callback {function} Callback called when state changes
   * @param callOnBind {boolean} If `true` the callback is called right after the callback is bound.
   * @return {function}
   */
  onChange(callback, callOnBind = false) {
    this.#subscribers.push(callback);
    if (callOnBind) {
      callback(this.location);
    }
    return () => {
      this.#subscribers = filter(this.#subscribers, (s) => s !== callback);
    };
  }

  /**
   * Called when location changes
   */
  #handleLocationChange = ({ pathname }) => {
    this.#path = this.#pathFor(pathname);
    forEach(this.#subscribers, (callback) => callback(this.location));
  };

  ////////////////////////////////////////////////////
  /// Block Leave
  ////////////////////////////////////////////////////

  setBlockLeave = (blockLeave) => {
    this.#blockLeave = blockLeave;
  };

  onBlockLeave = (callback) => {
    this.#blockSubscribers.push(callback);
    return () => {
      this.#blockSubscribers = filter(
        this.#blockSubscribers,
        (s) => s !== callback
      );
    };
  };

  // handleBlockLeave = (url) => {
  //   // Notify all subscribers
  //   forEach(this.#blockSubscribers, (callback) => callback(url));
  // };

  ////////////////////////////////////////////////////
  /// Routing
  ////////////////////////////////////////////////////

  /**
   * Returns path for the given pathname
   * @example for /some/route/12345 returns /some/route/:id
   */
  #pathFor(pathname = this.pathname) {
    return find(values(this.paths()), (path) => {
      const { isExact } = matchPath(pathname, { path }) || {};
      return isExact;
    });
  }

  /**
   * Returns history object
   * @return {object}
   */
  get history() {
    return this.#history;
  }

  /**
   * Returns all routes
   * @return {object}
   */
  get routes() {
    const { apps } = this.context;
    const { routes } = this.options;
    return {
      ...routes,
      ...this.#navigation?.[apps.current?.id]?.routes,
    };
  }

  /**
   * Returns all paths
   * @return {object}
   */
  paths(app) {
    const { apps } = this.context;
    return this.#navigation?.[app ?? apps.current?.id]?.paths;
  }

  /**
   * Returns current path
   * @example /some/route/:uid
   */
  get path() {
    return this.#path;
  }

  /**
   * Returns current pathname
   * @example /some/route/12345
   */
  get pathname() {
    return this.#history.location.pathname;
  }

  /**
   * Builds the pathname
   */
  buildPathname(
    path,
    { params = {}, query = {}, hash = {}, nullableParams = true } = {}
  ) {
    const { nullParameter } = this.options;
    const pathname = replaceString(path, /:\w+/g, (part) => {
      const key = part.substring(1);
      const value = get(params, key, nullParameter);
      // Part is not in list of params
      if (!nullableParams && !has(params, key)) {
        return part;
      }
      return !isNil(value) ? value : nullParameter;
    });
    return {
      pathname: join("/", pathname),
      search: !isEmpty(query)
        ? queryString.stringify(query, { addQueryPrefix: true })
        : null,
      hash: !isEmpty(hash)
        ? "#" + queryString.stringify({ ...this.hash, ...hash })
        : null,
    };
  }

  /**
   * Returns URL params
   */
  get params() {
    const { nullParameter } = this.options;
    const { params = {} } = matchPath(this.pathname, { path: this.path }) || {};
    // Clear null params
    return reduce(
      params,
      (result, param, key) => {
        result[key] = param === nullParameter ? null : param;
        return result;
      },
      {}
    );
  }

  /**
   * Returns continue url parameter if set.
   * Note that only valid continue url is returned. When the `continue` param is
   * invalid (i.e. not in the domain), returns null;
   * @return {string|null}
   */
  get continueUrl() {
    const { continueUrl } = this.query;
    if (isNil(continueUrl)) {
      return null;
    }
    // Validate continue url
    return continueUrl;
  }

  /**
   * Returns query parameters object.
   * @return {object}
   */
  get query() {
    return queryString.parse(this.#history.location.search, {
      ignoreQueryPrefix: true,
    });
  }

  /**
   * Returns hash parameters
   * @return {object}
   */
  get hash() {
    const hash = replaceString(this.#history.location.hash, "#", "");
    if (isEmpty(hash)) {
      return {};
    }
    const pairs = split(hash, "&");
    const params = reduce(
      pairs,
      (result, pair) => {
        const [key, value] = split(pair, "=");
        result[key] = value;
        return result;
      },
      {}
    );
    return params;
  }

  /**
   * Stores params in URL hash
   * @param params {object}
   */
  setHash({ params = {} } = {}) {
    if (window?.history) {
      const hashString = queryString.stringify({ ...this.hash, ...params });
      const hash = `#${hashString}`;
      // We use browser's history API directly because router's push
      // function reloads route even though the path name doesn't change
      window.history.replaceState(null, null, hash);
      this.#history.location.hash = hash;
      // Since we are not setting the hash via router API we need
      // to manually trigger the change event.
      this.#handleLocationChange(this.#history.location);
    }
  }

  /**
   * Current location
   */
  get location() {
    return {
      path: this.path,
      pathname: this.pathname,
      params: this.params,
      hash: this.hash,
      query: this.query,
    };
  }

  /**
   * Lazy load the history listener. Call the functions after
   * the Router is mounted, so we get the callback after the route
   * is rendered.
   */
  startLocationListener = () => {
    if (!this.#listener) {
      this.#listener = this.#history.listen(this.#handleLocationChange);
    }
  };

  /**
   * Goes back in history
   */
  goBack() {
    this.#history.goBack();
  }

  /**
   * Returns the number of entries in the history stack
   */
  get length() {
    return this.#history.length;
  }

  /**
   * Directs browser to the given path.
   * @param path {string} Resulting path
   * @param params {object?} Map with path params e.g { param1: "one" }
   * @param query {object?} Map with query params e.g. { query1: "one" }
   * @param hash {object?} Map with hash params e.g. { hash1: "one" }
   * @param nullableParams {boolean?} Set to `false` to disable nullable params
   * @param replace {boolean?} Set to `true` to replace current path (remove from history)
   * @param keepQuery {boolean?} Set to `true` to keep query params when moving to different location
   * @param continueUrl {boolean?} Set to `true` to append `continueUrl` param to query
   */
  goTo(
    path,
    {
      params,
      query,
      hash,
      nullableParams,
      replace,
      keepQuery,
      continueUrl,
    } = {}
  ) {
    // TODO: Handle block leave properly
    // Block local and internal redirects when needed
    // if (this.#blockLeave && !force) {
    //   return this.handleBlockLeave(uri);
    // }

    const payload = this.buildPathname(path, {
      params: {
        ...this.params,
        ...params,
      },
      query: omitBy(
        {
          continueUrl,
          ...(keepQuery ? this.query : {}),
          ...query,
        },
        isNil
      ),
      hash,
      replace,
      nullableParams,
    });
    replace ? this.#history.replace(payload) : this.#history.push(payload);
  }

  /**
   * Opens url in a new tab.
   * Note that you can pass humanized URL (e.g. website.com) or
   * URLs with defined protocol (e.g. https://website.com)
   * @param url {string} Resulting URL
   */
  open(url) {
    const fullURL = this.#prependHttp(url);
    if (this.isValidURL(fullURL)) {
      window.open(fullURL, "_blank");
    }
  }

  /**
   * Returns `true` if the provided URL is valid
   * @param url {string} The URL to test
   * @returns {boolean}
   */
  isValidURL(url) {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Prepend https:// to humanized URLs like sindresorhus.com and localhost
   * @param url {string} The URL to prepend https:// to.
   * @param https {boolean} Prepend https:// instead of http://
   * @returns {string}
   */
  #prependHttp = (url, { https = true } = {}) => {
    if (typeof url !== "string") {
      throw new TypeError(
        `Expected \`url\` to be of type \`string\`, got \`${typeof url}\``
      );
    }
    url = url.trim();
    if (/^\.*\/|^(?!localhost)\w+?:/.test(url)) {
      return url;
    }
    return url.replace(/^(?!(?:\w+?:)?\/\/)/, https ? "https://" : "http://");
  };

  /**
   * Reloads window
   */
  reload() {
    if (!isNil(window)) {
      window.location.reload();
    }
  }
}
