import React from "react";
import { createSvgIcon } from "icon/utils";

export default createSvgIcon(
  <>
    <path
      d="M23.754,10.564c-0.124,0.025-0.388,0.169-0.797,0.429l-1.421,0.992l-1.775,1.311c-0.618,0.473-1.183,0.941-1.701,1.402
		c-0.518,0.463-0.974,0.868-1.366,1.22c-0.395,0.352-0.646,0.622-0.757,0.816c-0.024,0.099-0.037,0.378-0.037,0.839
		c0,0.46,0.006,0.965,0.02,1.511c0.014,0.546,0.024,1.062,0.037,1.547c0.014,0.485,0.029,0.809,0.057,0.966
		c0.101,0.025,0.352,0.037,0.765,0.037h1.284c0.46,0,0.896,0.007,1.305,0.02c0.412,0.014,0.689,0.032,0.84,0.06l-0.037,1.298
		c-0.148,0-0.481-0.007-1.002-0.019c-0.519-0.014-1.125-0.019-1.816-0.019H15.24h-1.986c-0.282,0-0.699,0.005-1.252,0.019
		c-0.548,0.012-1.135,0.024-1.763,0.036c-0.628,0.012-1.219,0.019-1.779,0.019H7.21l0.225-1.228h0.779
		c0.375,0,0.755-0.006,1.147-0.014c0.391-0.008,0.762-0.044,1.114-0.105c0.355-0.062,0.611-0.175,0.775-0.334
		c0.072-0.074,0.124-0.326,0.148-0.753c0.024-0.428,0.034-0.917,0.034-1.468s0.007-1.076,0.015-1.574
		c0.007-0.501,0.019-0.836,0.033-1.002c0.031-0.103-0.123-0.354-0.458-0.748c-0.338-0.397-0.749-0.884-1.239-1.461l-1.587-1.873
		l-1.645-1.942L5.042,9.004C4.584,8.548,4.223,8.236,3.965,8.07H0V6.433h13.06v0.124h0.037L13.06,6.838v1.231H9.073l1.497,1.876
		l1.534,1.918l1.362,1.7l1.062,1.333l5.317-4.502h-3.233l-0.414-1.635h11.525l-0.092,0.107h0.058l-0.856,1.092H26.79l-0.342,0.436
		h-2.011c-0.123,0.051-0.242,0.085-0.36,0.103C23.961,10.518,23.851,10.539,23.754,10.564z M29.481,12.518
		c-0.075,0-0.305-0.013-0.688-0.037c-0.385-0.025-0.808-0.055-1.272-0.094c-0.466-0.036-0.907-0.071-1.324-0.11
		c-0.418-0.036-0.68-0.065-0.79-0.091v7.619l2.012,0.166L29.481,12.518z M27.494,21.171l-0.998-0.038l-1.09-0.108v1.907l0.878,0.073
		l0.909,0.038L27.494,21.171z"
    />
  </>,
  "Yahoo",
  {
    viewBox: "0 0 29.481 29.481",
  }
);
