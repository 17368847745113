// React
import { useEffect } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Redirect = ({ to, replace, params, continueUrl }) => {
  // Framework
  const { navigation } = usePortal();
  // Effects
  useEffect(() => {
    navigation.goTo(to, {
      replace,
      params,
      continueUrl: continueUrl ? navigation.pathname : null,
    });
  }, [to, replace, params, continueUrl, navigation]);
  // Do not render on redirect
  return null;
};

Redirect.propTypes = {
  to: PropTypes.string.isRequired,
  replace: PropTypes.bool,
  continueUrl: PropTypes.bool,
  params: PropTypes.object,
};

export default Redirect;
