// Helpers
import { filter, forEach, isEqual } from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";

export class Helmet extends StackDependency {
  #subscribers = [];
  #values = {};

  /**
   * Subscribe to change.
   * Returns unsubscribe function you need to call in order to
   * stop the event observation in e.g. `useEffect` function.
   * @param callback {function} Callback called when state changes
   * @param callOnBind {boolean} If `true` the callback is called right after the callback is bound.
   * @return {function}
   */
  onChange(callback, callOnBind = false) {
    this.#subscribers.push(callback);
    if (callOnBind) {
      callback(this);
    }
    return () => {
      this.#subscribers = filter(this.#subscribers, (s) => s !== callback);
    };
  }

  /**
   * Call to notify all subscribers about the state change
   */
  #handleChange = (values) => {
    if (!isEqual(values, this.#values)) {
      this.#values = values;
      forEach(this.#subscribers, (callback) => callback(this));
    }
  };

  /**
   * Sets helmet values
   */
  set(values) {
    this.#handleChange(values);
  }

  /**
   * Current page title
   * @return {string}
   */
  get title() {
    return this.#values.title;
  }

  /**
   * All helmet values
   * @return {{}}
   */
  get values() {
    return this.#values;
  }
}
