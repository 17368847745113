// React
import React, { forwardRef } from "react";
// Framework
import {
  TilePopover,
  TileHeader,
  TileContent,
  TileFixedContent,
} from "ui/tile";
import { useTranslate } from "localization/hooks";
// Components
import HeaderPopoverList from "./components/HeaderPopoverList";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderPopover = forwardRef(({ onSelection, onClose }, ref) => {
  // Framework
  const { t } = useTranslate();
  // Render
  return (
    <TilePopover
      ref={ref}
      minWidth={270}
      maxWidth={340}
      popoverProps={{
        offset: { top: 2 },
      }}
      onClose={onClose}
    >
      <TileContent>
        <TileHeader
          size="large"
          title={t("core:localization.headerItem.title")}
        />
        <TileFixedContent>
          <HeaderPopoverList onSelection={onSelection} />
        </TileFixedContent>
      </TileContent>
    </TilePopover>
  );
});

export default HeaderPopover;
