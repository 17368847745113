// React
import React, { isValidElement, cloneElement } from "react";
import PropTypes from "prop-types";
// Helpers
import { map, isEmpty, compact } from "@mefisto/utils";
// Framework
import { TableBody as TableBodyComponent } from "@material-ui/core";
// Components
import TableErrorPlaceholder from "../TableErrorPlaceholder";
import TableEmptyPlaceholder from "../TableEmptyPlaceholder";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TableBody = ({
  rows,
  loading,
  error,
  emptyTitle,
  emptySubtitle,
  onRefresh,
}) => (
  <TableBodyComponent>
    {error && <TableErrorPlaceholder onRefresh={onRefresh} />}
    {!loading && !error && (
      <>
        {isEmpty(rows) ? (
          <TableEmptyPlaceholder title={emptyTitle} subtitle={emptySubtitle} />
        ) : (
          compact(
            map(rows, (row, index) => {
              return (
                isValidElement(row) &&
                cloneElement(row, {
                  key: index,
                  __renderProps: { index },
                })
              );
            })
          )
        )}
      </>
    )}
  </TableBodyComponent>
);

TableBody.propTypes = {
  rows: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.object,
  emptyTitle: PropTypes.string,
  emptySubtitle: PropTypes.string,
  onRefresh: PropTypes.func,
};

export default TableBody;
