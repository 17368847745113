// React
import React from "react";
// Framework
import { useLazy, usePortal } from "@mefisto/web";
// Context
import { PixelContext } from "../PixelContext";
import { Pixel } from "../Pixel";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const PixelInterceptor = ({ children }) => {
  // Framework
  const { config, log } = usePortal();
  // Dependency
  const pixel = useLazy(Pixel, config, log);
  // Render
  return (
    <PixelContext.Provider value={{ pixel }}>
      {children(true)}
    </PixelContext.Provider>
  );
};

export default PixelInterceptor;
