// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, Section, Box } from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  transition: {
    transition: theme.transitions.create("width"),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderSection = ({
  context = "section",
  value,
  fill,
  width,
  children,
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Section context={context} value={value}>
      <Box width={width} flexGrow={fill ? 1 : 0} className={classes.transition}>
        {children}
      </Box>
    </Section>
  );
};

HeaderSection.propTypes = {
  /**
   * Section context name
   */
  context: PropTypes.string,
  /**
   * Element specification value
   */
  value: PropTypes.string,
  /**
   * Set to `true` to let the section fill the horizontal space
   */
  fill: PropTypes.bool,
  /**
   * Section width
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The content of the section.
   */
  children: PropTypes.node,
};

export default HeaderSection;
