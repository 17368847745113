// React
import React, { Children, isValidElement, cloneElement } from "react";
import PropTypes from "prop-types";
// Framework
import { FormLabel, FormControl, FormGroup, FormHelperText } from "ui";
// Components
import FormFieldArray from "../FormFieldArray";

const FormCheckboxFieldArray = ({ name, legend, helperText, children }) => {
  return (
    <FormFieldArray
      name={name}
      render={(renderProps) => (
        <FormControl component="fieldset">
          {legend && <FormLabel component="legend">{legend}</FormLabel>}
          <FormGroup>
            {Children.map(
              children,
              (child) =>
                isValidElement(child) &&
                cloneElement(child, {
                  __renderProps: { name, ...renderProps },
                })
            )}
          </FormGroup>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

FormCheckboxFieldArray.propTypes = {
  name: PropTypes.string.isRequired,
  legend: PropTypes.string,
  helperText: PropTypes.string,
  children: PropTypes.node,
};

export default FormCheckboxFieldArray;
