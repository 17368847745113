// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { AttachMoney as Icon } from "icon/material";
// Components
import FormField from "../FormField";
import FormTextField from "../FormTextField";
import NumberFormat from "react-number-format";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const NumberFormatCustom = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    decimalScale={2}
    fixedDecimalScale
    thousandSeparator
    getInputRef={inputRef}
    onValueChange={(values) => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
  />
);

const Component = (props) => {
  return <FormTextField format={NumberFormatCustom} icon={Icon} {...props} />;
};

const FormPriceField = (props) => {
  return <FormField component={Component} {...props} />;
};

FormPriceField.propTypes = {
  currency: PropTypes.oneOf(["$"]),
};

FormPriceField.defaultProps = {
  currency: "$",
};

export default FormPriceField;
