// React
import React, { cloneElement } from "react";
import PropTypes from "prop-types";
// Helpers
import { reduce, reverse } from "@mefisto/utils";
// Framework
import { useLazy } from "hooks";
// Context
import { AppContext } from "./AppContext";
// Components
import { usePortal } from "./usePortal";
import { useStackContext } from "../useStackContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const AppProvider = ({
  dependencies: { Interceptor, Model, Plugin },
  providers,
  skeleton,
  children,
}) => {
  // Construct dependencies
  const interceptor = useLazy(Interceptor);
  const model = useLazy(Model);
  const plugin = useLazy(Plugin);
  // Initialize context
  const portalContext = usePortal();
  const context = useStackContext({
    // Merge portal and app context
    ...portalContext,
    interceptor,
    model,
    plugin,
  });
  // Render
  return (
    <>
      {skeleton}
      <AppContext.Provider value={context}>
        {reduce(
          reverse(providers),
          (result, provider) => {
            return cloneElement(provider, {
              children: result ?? children,
            });
          },
          null
        )}
      </AppContext.Provider>
    </>
  );
};

AppProvider.propTypes = {
  dependencies: PropTypes.object,
  providers: PropTypes.arrayOf(PropTypes.node),
  skeleton: PropTypes.any,
  children: PropTypes.node,
};

export { AppProvider };
