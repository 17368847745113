// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { classnames } from "ui/classnames";
import { makeStyles, TableCell, Typography } from "@material-ui/core";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles(() => ({
  sticky: {
    position: "sticky",
    whiteSpace: "nowrap",
    // A small trick to size the cell based on the content
    width: 1,
    right: 0,
    background: "inherit",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TableRowItem = ({ variant, align, size, wrap, sticky, children }) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <TableCell
      align={align}
      size={size}
      className={classnames({
        [classes.sticky]: sticky,
      })}
    >
      <Typography
        component="div"
        noWrap={!wrap}
        variant={variant === "highlight" ? "subtitle2" : "inherit"}
      >
        {children}
      </Typography>
    </TableCell>
  );
};

TableRowItem.propTypes = {
  /**
   * Specify the item type
   */
  variant: PropTypes.oneOf(["default", "highlight"]),
  /**
   * Set content align
   */
  align: PropTypes.oneOf(["center", "inherit", "justify", "left", "right"]),
  /**
   * Specify the size of the item.
   */
  size: PropTypes.oneOf(["medium", "small"]),
  /**
   * Set to `true` when content should wrap
   */
  wrap: PropTypes.bool,
  /**
   * Set to `true` when the table cell suppose to be sticky
   */
  sticky: PropTypes.bool,
  /**
   * The row item contents
   */
  children: PropTypes.node,
};

export default TableRowItem;
