// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const PermissionProvider = ({ options, children }) => {
  // Framework
  const { permission } = usePortal();
  // Init
  useStackDependency(permission, options);
  // Render
  return <>{children}</>;
};

PermissionProvider.propTypes = {
  options: PropTypes.object,
};

export { PermissionProvider };
