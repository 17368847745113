// React
import React, { Suspense, Fragment, lazy, useState } from "react";
import PropTypes from "prop-types";
// Framework
import { ActivityPlaceholder } from "activity";
// Components
import DefaultErrorBoundary from "../ErrorBoundary";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const SceneLoader = ({
  scene,
  sceneProps = {},
  route: Route = Fragment,
  errorBoundary: ErrorBoundary = DefaultErrorBoundary,
}) => {
  // State
  const [Scene] = useState(lazy(() => scene()));
  // Render
  return (
    <ErrorBoundary>
      <Suspense fallback={<ActivityPlaceholder token="ui:scene:loader" />}>
        <Route>
          <Scene {...sceneProps} />
        </Route>
      </Suspense>
    </ErrorBoundary>
  );
};

SceneLoader.propTypes = {
  scene: PropTypes.func.isRequired,
  sceneProps: PropTypes.object,
  route: PropTypes.any,
  errorBoundary: PropTypes.any,
};

export default SceneLoader;
