// React
import React, {
  Children,
  memo,
  isValidElement,
  cloneElement,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { size, filter } from "@mefisto/utils";
// Context
import { useNavigationMenuContext } from "../NavigationMenuContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const NavigationMenuSection = ({
  index,
  value,
  title,
  disabled,
  sectionComponent,
  itemComponent,
  children,
}) => {
  // Context
  const { sections, foldable, sectionFold } = useNavigationMenuContext();
  // Memo
  const isDisabled = useMemo(() => {
    const items = sections[value]?.items;
    return (
      disabled ||
      // All items are disabled => section is disabled too
      size(items) === size(filter(items, ({ disabled }) => disabled))
    );
  }, [disabled, sections, value]);
  const isFolded = useMemo(() => {
    return sections[value]?.folded ?? false;
  }, [sections, value]);
  // Callback
  const handleSelection = useCallback(() => {
    sectionFold(value);
  }, [sectionFold, value]);
  // Elements
  const items = Children.map(
    children,
    (item, index) =>
      isValidElement(item) &&
      cloneElement(item, {
        index,
        itemComponent,
        section: value,
      })
  );
  const section =
    isValidElement(sectionComponent) &&
    cloneElement(
      sectionComponent,
      {
        index,
        value,
        title,
        foldable,
        folded: isFolded,
        disabled: isDisabled,
        onSelection: handleSelection,
      },
      items
    );
  // Render
  return <>{section}</>;
};

NavigationMenuSection.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  sectionComponent: PropTypes.element,
  itemComponent: PropTypes.element,
};

export default memo(NavigationMenuSection);
