// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { classnames, makeStyles, DialogContent } from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  tabs: {
    // Keep the height of dialog with tabs constant.
    // If we don't do that the height will change between
    // tabs and make the UI jump.
    [theme.breakpoints.up("md")]: {
      height: "70vh",
    },
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelDialogContent = ({ display = "individual", children }) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <DialogContent className={classnames(display === "group" && classes.tabs)}>
      {children}
    </DialogContent>
  );
};

ModelDialogContent.propTypes = {
  display: PropTypes.oneOf(["individual", "group"]),
};

export default ModelDialogContent;
