// React
import React, { useContext } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack/core";
// Components
import { ThemeContext } from "theme/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Theme = ({ name, title, theme, overrides }) => {
  // Framework
  const { log } = usePortal();
  // Context
  const { themes } = useContext(ThemeContext);
  if (themes[name]) {
    log.warning(
      `${name} theme was already imported. Check you themes for duplicates.`
    );
  }
  themes[name] = { title, name, theme, overrides };
  return null;
};

Theme.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  theme: PropTypes.object,
  overrides: PropTypes.func,
};

export default Theme;
