// React
import React, { useCallback } from "react";
// Framework
import { MarkdownEditor } from "markdown";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({
  form: { setFieldValue },
  field: { name, value },
  ...props
}) => {
  // Handlers
  const handleChange = useCallback(
    ({ text }) => {
      setFieldValue(name, text);
    },
    [setFieldValue, name]
  );
  // Render
  return <MarkdownEditor value={value} onChange={handleChange} {...props} />;
};

const FormMarkdownEditorField = (props) => (
  <FormField fullWidth component={Component} {...props} />
);

export default FormMarkdownEditorField;
