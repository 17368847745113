// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { isNil } from "@mefisto/utils";
// Framework
import { classnames } from "ui/classnames";
import { makeStyles } from "@material-ui/core";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles({
  responsive: ({ aspectRatio }) => ({
    width: "100%",
    ...(aspectRatio && { aspectRatio }),
  }),
});

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const StaticImage = ({
  image,
  width,
  height,
  aspectRatio,
  description = "",
  className,
}) => {
  // Styles
  const classes = useStyles({ aspectRatio });
  // Render
  return (
    <img
      src={image}
      alt={description}
      className={classnames(
        className,
        !(width || height) && classes.responsive
      )}
      style={{
        maxWidth: isNil(width) ? "none" : width,
        maxHeight: isNil(height) ? "none" : height,
      }}
      width={width}
      height={height}
    />
  );
};

StaticImage.propTypes = {
  image: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  description: PropTypes.string,
};

export default StaticImage;
