// React
import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
// Components
import { AppsContext } from "apps/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const App = ({ name, ...props }) => {
  // Context
  const { apps } = useContext(AppsContext);
  // Set options
  apps.push({ id: name, ...props });
  // Render
  return <Fragment />;
};

App.defaultProps = {
  __TYPE: "App",
};

App.propTypes = {
  name: PropTypes.string,
  type: PropTypes.oneOf(["NATIVE", "USER", "ORGANIZATION"]),
  enabled: PropTypes.bool,
  title: PropTypes.string,
  color: PropTypes.string,
  priority: PropTypes.number,
  path: PropTypes.string,
  icon: PropTypes.node,
  loader: PropTypes.any,
  logo: PropTypes.object,
  theme: PropTypes.object,
  navigation: PropTypes.object,
  permissions: PropTypes.array,
  resource: PropTypes.object,
};

export default App;
