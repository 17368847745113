// React
import React from "react";
// Framework
import { IconButton } from "ui";
import { MenuRounded as MenuIcon } from "icon/material";
// Components
import HeaderItem from "../HeaderItem";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderMenuButton = ({ onClick }) => (
  <HeaderItem value="menu">
    <IconButton edge="start" color="inherit" onClick={onClick}>
      <MenuIcon />
    </IconButton>
  </HeaderItem>
);

export default HeaderMenuButton;
