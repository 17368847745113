// Helpers
import { isEmpty } from "@mefisto/utils";

export const toBoolean = (value) => {
  return value;
};

export const fromBoolean = (value) => {
  return !(isEmpty(value) || value === "false");
};
