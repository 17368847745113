// React
import React from "react";
// Framework
import { Apple as Icon } from "icon/social";
// Components
import ProviderButton from "../ProviderButton";

const AppleButton = (props) => (
  <ProviderButton
    name="Apple"
    color="#222222"
    icon={<Icon fontSize="inherit" />}
    {...props}
  />
);

export default AppleButton;
