// React
import React from "react";
// Framework
import { Yahoo as Icon } from "icon/social";
// Components
import ProviderButton from "../ProviderButton";

const YahooButton = (props) => (
  <ProviderButton
    name="Yahoo"
    color="#720e9e"
    icon={<Icon fontSize="inherit" />}
    {...props}
  />
);

export default YahooButton;
