// Framework
import { withStyles, Accordion } from "@material-ui/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default withStyles((theme) => ({
  root: {
    margin: `${theme.spacing(0, 0, 2)} !important`,
    boxShadow: theme.shadows[0],
    "&:before": {
      height: 0,
    },
  },
  expanded: {
    boxShadow: theme.shadows[0],
  },
}))(Accordion);
