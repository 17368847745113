// Helpers
import { isEmpty, toNumber, toString } from "@mefisto/utils";

export const toTimestamp = (value) => {
  if (isEmpty(value)) {
    return null;
  }
  return toNumber(value);
};

export const fromTimestamp = (value) => {
  if (isEmpty(toString(value))) {
    return "";
  }
  return toString(value);
};
