// React
import React, {
  forwardRef,
  memo,
  useState,
  useEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty } from "@mefisto/utils";
// Framework
import { useTranslate } from "localization";
import { useEntityListLazy } from "model/hooks";
import { EntityPropType } from "model/utils";
import { EntityDialog } from "ui/dialog";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelEntityDialog = forwardRef(
  (
    {
      entity: Entity,
      relation,
      input,
      resources,
      languages,
      pagination,
      fetchPolicy,
      nextFetchPolicy,
      tags,
      search: hasSearch,
      onChange,
      ...rest
    },
    ref
  ) => {
    // Framework
    const { translate } = useTranslate();
    // State
    const [search, setSearch] = useState("");
    // Model
    const {
      error,
      data: list,
      fetch,
      fetchMore,
      refetch,
      loading,
      loadingMore,
    } = useEntityListLazy(Entity, {
      input,
      resources,
      languages,
      relation,
      pagination,
      fetchPolicy,
      nextFetchPolicy,
      tags,
    });
    // Handlers
    const handleOpen = useCallback(() => {
      fetch();
    }, [fetch]);
    const handleSearch = useCallback(
      (search) => {
        setSearch(search);
        fetch({
          ...(!isEmpty(search) ? { search } : {}),
        });
      },
      [fetch]
    );
    const handleRefresh = useCallback(() => {
      refetch();
    }, [refetch]);
    const handleLoadMore = useCallback(() => {
      fetchMore();
    }, [fetchMore]);
    // Effects
    useEffect(() => {
      onChange?.(list);
    }, [onChange, list]);
    // Render
    return (
      <EntityDialog
        ref={ref}
        search={hasSearch}
        entities={list.data}
        loading={loading}
        loadingMore={loadingMore}
        hasMore={list.hasMore}
        error={error}
        emptyTitle={translate(
          isEmpty(search)
            ? "core:model.entityDialog.empty.title"
            : "core:model.entityDialog.empty.search.title"
        )}
        emptySubtitle={translate(
          isEmpty(search)
            ? "core:model.entityDialog.empty.subtitle"
            : "core:model.entityDialog.empty.search.subtitle"
        )}
        onOpen={handleOpen}
        onLoadMore={handleLoadMore}
        onRefresh={handleRefresh}
        onSearch={handleSearch}
        {...rest}
      />
    );
  }
);

ModelEntityDialog.propTypes = {
  /**
   * Model entity used for the feed
   */
  entity: EntityPropType,
  /**
   * Entity relation
   */
  relation: PropTypes.string,
  /**
   * Input data
   */
  input: PropTypes.object,
  /**
   * Resources data
   */
  resources: PropTypes.object,
  /**
   * List of requested languages
   */
  languages: PropTypes.array,
  /**
   * Pagination props
   */
  pagination: PropTypes.shape({
    pointer: PropTypes.string,
    limit: PropTypes.number,
    direction: PropTypes.oneOf(["ASC", "DESC"]),
    orderBy: PropTypes.string,
    equalTo: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  /**
   * Fetch policy of the list request
   */
  fetchPolicy: PropTypes.string,
  /**
   * Next fetch policy of the list request
   */
  nextFetchPolicy: PropTypes.string,
  /**
   * Custom tags used to list the data
   */
  tags: PropTypes.any,
  /**
   * Set to `true` when the user can search entities
   */
  search: PropTypes.bool,
};

export default memo(ModelEntityDialog);
