// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles } from "@material-ui/core";
import { classnames } from "ui/classnames";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles(() => ({
  hide: {
    visibility: "hidden",
  },
  remove: {
    display: "none",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Cloak = ({
  variant = "hide",
  enabled,
  component: Component = "div",
  children,
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return enabled ? (
    <>
      {variant === "unmount" ? null : (
        <Component
          className={classnames({
            [classes.hide]: variant === "hide",
            [classes.remove]: variant === "remove",
          })}
        >
          {children}
        </Component>
      )}
    </>
  ) : (
    children
  );
};

Cloak.propTypes = {
  variant: PropTypes.oneOf(["hide", "remove", "unmount"]),
  enabled: PropTypes.bool,
  component: PropTypes.elementType,
  children: PropTypes.node,
};

export default Cloak;
