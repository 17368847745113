// Helpers
import { forEach, keys, first, isEmpty } from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";
// Components
import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { AuthorizationLink } from "./links/AuthorizationLink";
import { MultiLink } from "./links/MultiLink";
import { ErrorLink } from "./links/ErrorLink";
import { cleanTypeName } from "./links/cleanTypeName";

export class Model extends StackDependency {
  #client;
  #cache;
  #link;

  onInitialized() {
    const { plugin } = this.context;
    const { cache, endpoints = {}, entities = [] } = this.options;
    // Props
    this.#cache = this.#createCache({
      cache,
    });
    this.#link = this.#createLink({
      endpoints: {
        ...plugin.endpoints,
        ...endpoints,
      },
    });
    // Client
    this.#client = new ApolloClient({
      cache: this.#cache,
      link: this.#link,
    });
    // Add entities type policies
    forEach([...plugin.entities, ...entities], (Entity) => {
      if (Entity.hasTypePolicies) {
        this.#cache.policies.addTypePolicies(
          new Entity(this.#client).typePolicies
        );
      }
    });
  }

  /**
   * Creates cache instance
   * @param cache
   * @return {InMemoryCache}
   */
  #createCache = ({ cache }) => {
    return cache ?? new InMemoryCache();
  };

  /**
   * Creates HTTP link
   * @param endpoints
   * @return {object|null}
   */
  #createLink = ({ endpoints }) => {
    // At least one endpoint must be defined
    if (isEmpty(endpoints)) {
      return null;
    }
    // Get first available endpoint
    const uri = endpoints[first(keys(endpoints))];
    // Prepare links
    const httpLink = new HttpLink({
      uri,
    });
    const authorizationLink = AuthorizationLink({
      context: this.context,
    });
    const errorLink = ErrorLink({
      context: this.context,
    });
    const multiLink = new MultiLink({
      endpoints,
      createHttpLink: () => {
        return from([cleanTypeName, authorizationLink, errorLink, httpLink]);
      },
    });
    return from([multiLink]);
  };

  /**
   * Apollo Client instance
   */
  get client() {
    return this.#client;
  }

  /**
   * Apollo Cache instance
   */
  get cache() {
    return this.#cache;
  }

  /**
   * Adds type policies to cache
   * @param typePolicies
   */
  addTypePolicies(typePolicies) {
    this.#cache.policies.addTypePolicies(typePolicies);
  }
}
