// React
import React, { memo, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty } from "@mefisto/utils";
// Framework
import { usePortal } from "stack/core";
import { useDebounce } from "hooks";
import { useTranslate } from "localization/hooks";
import { makeStyles, InputBase, Box, InputAdornment } from "ui/components";
import { Section } from "ui/section";
import { Search as SearchIcon } from "icon/material";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    ...theme.typography.subtitle2,
    width: "100%",
    padding: theme.spacing(1.25, 0),
    "&::placeholder": {
      color: theme.palette.text.secondary,
      opacity: 1,
    },
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileSearchBar = ({
  context = "searchBar",
  value,
  placeholder,
  debounce = 500,
  autoFocus = true,
  onChange,
}) => {
  // Styles
  const classes = useStyles();
  // Framework
  const { t } = useTranslate();
  const { analytics } = usePortal();
  // State
  const [search, setSearch] = useState();
  // Debounce
  const debouncedSearch = useDebounce(search, debounce);
  // Callbacks
  const handleChange = useCallback((event) => {
    setSearch(event.target.value);
  }, []);
  // Effect
  useEffect(() => {
    if (!isEmpty(debouncedSearch)) {
      analytics.logSearch({ searchTerm: debouncedSearch });
    }
    onChange?.(debouncedSearch);
  }, [debouncedSearch, analytics, onChange]);
  // Render
  return (
    <Section context={context} value={value}>
      <Box width="100%" ml={-2} px={1}>
        <InputBase
          fullWidth
          autoFocus={autoFocus}
          placeholder={placeholder ?? t("Search")}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          startAdornment={
            <InputAdornment position="start">
              <Box mt={0.25} color="text.primary" lineHeight="normal">
                <SearchIcon color="inherit" variant="remove" />
              </Box>
            </InputAdornment>
          }
          onChange={handleChange}
        />
      </Box>
    </Section>
  );
};

TileSearchBar.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default memo(TileSearchBar);
