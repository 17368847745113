import React from "react";
import { createSvgIcon } from "icon/utils";

export default createSvgIcon(
  <path
    fill="#546e7a"
    d="M15,13v1H1V1H2V13H15ZM6,12H4V7H6v5Zm3,0H7V3H9v9Zm3,0H10V5h2v7Z"
    transform="translate(0 -1)"
  />,
  "CsvIcon",
  { viewBox: "-1 -2 18 18" }
);
