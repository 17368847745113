// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { classnames } from "ui/classnames";
import { makeStyles, CardActionArea } from "ui/components";
import { Section } from "ui/section";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    overflow: "hidden",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileActionArea = ({
  context = "actionArea",
  value,
  disabled,
  disableSelection,
  className,
  children,
  ...rest
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Section context={context} value={value}>
      <CardActionArea
        disabled={disabled ?? disableSelection}
        className={classnames(classes.root, className)}
        {...rest}
      >
        {children}
      </CardActionArea>
    </Section>
  );
};

TileActionArea.propTypes = {
  /**
   * Section context name
   */
  context: PropTypes.string,
  /**
   * Element specification value
   */
  value: PropTypes.string,
  /**
   * Set to `true` to disable action area selection
   */
  disabled: PropTypes.bool,
  /**
   * Set to `true` to disable action area selection
   * @deprecated Use `disabled` instead
   */
  disableSelection: PropTypes.bool,
};

export default TileActionArea;
