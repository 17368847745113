// React
import React, {
  memo,
  isValidElement,
  cloneElement,
  useRef,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { isFunction, isArray, isString, includes } from "@mefisto/utils";
// Context
import { useNavigationMenuContext } from "../NavigationMenuContext";
// Components
import { useMounted } from "hooks";
import { usePortal } from "stack/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const NavigationMenuItem = ({
  value,
  title,
  path,
  icon,
  disabled,
  selected,
  section,
  index,
  itemComponent,
}) => {
  // Framework
  const { navigation } = usePortal();
  const { callWhenMounted } = useMounted();
  // Context
  const context = useNavigationMenuContext();
  const register = useRef(context.register);
  // Memo
  const [isSelected, setSelected] = useState(false);
  // Effect
  useEffect(() => {
    return navigation.onChange(
      callWhenMounted(() => {
        const currentPath = navigation.path;
        if (isFunction(selected)) {
          return setSelected(selected(navigation));
        } else if (isArray(selected)) {
          return setSelected(includes(selected, currentPath));
        } else if (isString(selected)) {
          return setSelected(selected === currentPath);
        } else {
          return setSelected(currentPath === path);
        }
      }),
      true
    );
  }, [callWhenMounted, navigation, path, selected]);
  useEffect(() => {
    register.current({
      section,
      item: value,
      disabled,
    });
  }, [section, value, disabled]);
  // Render
  return (
    <>
      {isValidElement(itemComponent) &&
        cloneElement(itemComponent, {
          index,
          value,
          title,
          path,
          icon,
          disabled,
          selected: isSelected,
        })}
    </>
  );
};

NavigationMenuItem.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  section: PropTypes.string,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
};

export default memo(NavigationMenuItem);
