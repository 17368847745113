// React
import React, { useState } from "react";
import PropTypes from "prop-types";
// Helpers
import { isNil, get } from "@mefisto/utils";
// Framework
import { OptionsInput } from "form/input";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({
  label,
  options,
  exclusive,
  selected,
  form,
  field,
  fullWidth = true,
}) => {
  const { name, value } = field;
  const { touched, errors } = form;
  const fieldError = get(errors, field.name);
  const showError = get(touched, field.name) && !!fieldError;
  // State
  const [focused, setFocused] = useState(false);
  // Handlers
  const handleFocus = () => {
    setFocused(true);
  };
  const handleBlur = () => {
    setFocused(false);
  };
  const handleChange = (event, value) => {
    if (selected && isNil(value)) {
      // Don't deselect value when selected is set to `true`
      return;
    }
    form.setFieldValue(name, isNil(value) ? "" : value);
  };
  // Render
  return (
    <OptionsInput
      error={showError ? fieldError : null}
      label={label}
      value={value}
      options={options}
      fullWidth={fullWidth}
      exclusive={exclusive}
      focused={focused}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};

const FormOptionField = (props) => (
  <FormField component={Component} {...props} />
);

FormOptionField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.element,
      color: PropTypes.string,
    })
  ),
  selected: PropTypes.bool,
  exclusive: PropTypes.bool,
};

export default FormOptionField;
