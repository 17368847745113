// React
import React, { forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
// Helpers
import { noop } from "@mefisto/utils";
// Framework
import { UserInteraction } from "analytics";
import { withStyles, Box, Tab, Cloak } from "ui";
import { usePortal } from "stack";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = withStyles((theme) => ({
  root: {
    minWidth: "auto",
    paddingLeft: theme.spacing(2.75),
    paddingRight: theme.spacing(2.25),
  },
}))(Tab);

const SceneTab = forwardRef(
  (
    {
      value,
      title,
      disableGutter,
      disableTab,
      children,
      __display: { render, currentTab },
      ...props
    },
    ref
  ) => {
    // Framework
    const { navigation } = usePortal();
    // Effects
    useEffect(() => {
      if (disableTab && value === currentTab) {
        navigation.setHash({ params: { tab: undefined } });
      }
    }, [navigation, disableTab, value, currentTab]);
    // Render
    return (
      <>
        {render === "content" && value === currentTab && (
          <>
            {!disableGutter && <Box width="100%" pb={3} />}
            {children}
          </>
        )}
        {render === "tabs" && (
          <Cloak variant="remove" enabled={disableTab}>
            <UserInteraction
              ref={ref}
              element="tab"
              value={value}
              component={Component}
              componentProps={{
                value,
                label: title,
                onClick: noop,
                ...props,
              }}
            />
          </Cloak>
        )}
      </>
    );
  }
);

SceneTab.propTypes = {
  /**
   * Section context name
   */
  context: PropTypes.string,
  /**
   * Element specification value
   */
  value: PropTypes.string.isRequired,
  /**
   * Tab title
   */
  title: PropTypes.string,
  /**
   * Disables gutter around tabs
   */
  disableGutter: PropTypes.bool,
  /**
   * Disables the tab completely
   */
  disableTab: PropTypes.bool,
  /**
   * The content of the scene tab.
   */
  children: PropTypes.node,
};

export default SceneTab;
