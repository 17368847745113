// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, Section } from "ui";
// Components
import CoverTitle from "./components/CoverTitle";
import CoverSubtitle from "./components/CoverSubtitle";
import CoverProfileImage from "./components/CoverProfileImage";
import CoverTags from "./components/CoverTags";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  section: {
    background: theme.palette.common.white,
    paddingBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.divider}`,
    borderTop: "none",
    borderBottom: "none",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Cover = ({
  context = "cover",
  value,
  title,
  titleProps,
  subtitle,
  subtitleProps,
  coverImage,
  profileImage,
  tags,
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Section context={context} value={value}>
      <div className={classes.root}>
        {coverImage}
        <div className={classes.section}>
          <CoverTags tags={tags} />
          <CoverTitle title={title} titleProps={titleProps} />
          <CoverSubtitle subtitle={subtitle} subtitleProps={subtitleProps} />
        </div>
        <CoverProfileImage profileImage={profileImage} />
      </div>
    </Section>
  );
};

Cover.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  titleProps: PropTypes.object,
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitleProps: PropTypes.object,
  coverImage: PropTypes.node,
  profileImage: PropTypes.node,
  tags: PropTypes.arrayOf(PropTypes.node),
};

export default Cover;
