// React
import React, { useEffect, useState } from "react";
// Framework
import { usePortal } from "stack";
import { useTranslate } from "localization";
import { ModalDialog } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const UploadBlocker = ({ children }) => {
  // Framework
  const { uploadQueue: queue, navigation } = usePortal();
  const { translate } = useTranslate();
  // State
  const [url, setUrl] = useState(null);
  // Effects
  useEffect(() => {
    const handleBlockLeave = (url) => setUrl(url);
    const unsubscribe = navigation.onBlockLeave(handleBlockLeave);
    return () => unsubscribe();
  }, [navigation]);
  useEffect(() => {
    const handleProgressChange = (progress) => {
      if (progress.isUploading) {
        navigation.setBlockLeave(true);
      } else {
        setUrl(null);
        navigation.setBlockLeave(false);
      }
    };
    const unsubscribe = queue.onProgressChange(handleProgressChange);
    return () => unsubscribe();
  }, [navigation, queue]);
  // Handlers
  const handleClose = () => setUrl(null);
  const handleStopUpload = () => navigation.goTo(url, { force: true });
  // Render
  return (
    <>
      {url ? (
        <ModalDialog
          title={translate("core:storage.uploadBlocker.title")}
          onClose={handleClose}
          actions={[
            {
              title: translate("core:storage.uploadBlocker.actions.stop"),
              danger: true,
              onClick: handleStopUpload,
            },
          ]}
        >
          {translate("core:storage.uploadBlocker.subtitle")}
        </ModalDialog>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default UploadBlocker;
