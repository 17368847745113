// Framework
import { withStyles, AccordionActions } from "@material-ui/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default withStyles((theme) => ({
  root: {
    background: theme.palette.background.underlying,
    border: `1px solid ${theme.palette.divider}`,
    borderTop: "none",
    padding: theme.spacing(3),
    minHeight: 56,
  },
}))(AccordionActions);
