// React
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// Helpers
import { noop, debounce, includes, isNil } from "@mefisto/utils";
// Framework
import { Box, Spinner } from "ui/components";

////////////////////////////////////////////////////
/// Helpers
////////////////////////////////////////////////////

const upKeys = [
  33, // pageUp
  36, // home
  38, // arrowUp
];

const downKeys = [
  32, // space
  34, // pageDown
  35, // end
  40, // arrowDown
];

/**
 * Linearly maps value from one interval to another
 */
const linear = (value, from1, to1, from2, to2) =>
  ((Math.min(value, to1) - from1) / (to1 - from1)) * (to2 - from2) + from2;

/**
 * Calculates the position in animation
 */
const easeInOut = (currentTime, start, change, duration) => {
  currentTime /= duration / 2;
  if (currentTime < 1) {
    return (change / 2) * currentTime * currentTime + start;
  }
  currentTime -= 1;
  return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
};

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

class InfiniteScroll extends PureComponent {
  state = {
    runSpinner: false,
    pageLoaded: false,
  };

  componentDidMount() {
    const { pageStart, lockScroll, scrollToBottom } = this.props;
    // this.pageLoaded = pageStart;
    this.setState({ pageLoaded: pageStart });
    this.currentHeight = 0;

    // Setup API functions
    scrollToBottom(this.scrollToBottom);

    // Attach listeners
    this.attachScrollListener();

    if (lockScroll) {
      this.attachLockListeners();
    }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    // Props
    const { isReverse } = this.props;
    const { pageStart } = prevProps;

    if (isReverse) {
      this.handleReverseDataAppearance(pageStart);
    } else {
      this.handleRegularDataAppearance();
    }
  }

  componentWillUnmount() {
    this.detachScrollListener();
    this.detachLockListeners();
  }

  /**
   * Scrolls to bottom
   */
  scrollToBottom = (animated = false) => {
    const { scrollHeight, clientHeight } = this.scrollProps;
    this.scrollTo(Math.max(scrollHeight - clientHeight), animated);
  };

  /**
   * Scrolls to the given position
   */
  scrollTo = (position, animated = false) => {
    const { animation } = this.props;
    const { scrollTop } = this.scrollProps;

    // Do not animate and set the value right away
    if (!animated) {
      this.setScrollTop(position);
      return;
    }

    // Start position
    const start = scrollTop;
    // Diff between start and end position
    const change = position - start;

    // Calculate duration and increment intervals
    const increment = linear(
      change,
      0,
      animation.threshold,
      animation.increment.min,
      animation.increment.max
    );
    const duration = linear(
      change,
      0,
      animation.threshold,
      animation.duration.min,
      animation.duration.max
    );

    /**
     * Performs the animation
     */
    const animate = (elapsedTime) => {
      // Update timestamp
      elapsedTime += increment;
      // Jump to position
      this.setScrollTop(easeInOut(elapsedTime, start, change, duration));
      if (elapsedTime < duration) {
        // Wait before next jump
        setTimeout(() => animate(elapsedTime), increment);
      }
    };

    // Start
    animate(0);
  };

  ////////////////////////////////////////////////////
  /// Listeners
  ////////////////////////////////////////////////////

  /**
   * Starts a listening to scroll events
   * @private
   */
  attachScrollListener = () => {
    const { initialLoad, debounce: rate } = this.props;
    this.debounce = debounce(this.handleScroll, rate);
    if (!this.scrollListenerAttached) {
      this.scrollListenerAttached = true;
      this.addEventListener("scroll", this.debounce);
      this.addEventListener("resize", this.debounce);

      if (initialLoad) {
        this.handleScroll();
      }
    }
  };

  /**
   * Starts a listening to events that may result in the scroll lock
   * @private
   */
  attachLockListeners = () => {
    this.addEventListener("wheel", this.handleWheel);
    this.addEventListener("keydown", this.handleKeyDown);
  };

  /**
   * Stops listening to the scroll events
   * @private
   */
  detachScrollListener = () => {
    if (this.scrollListenerAttached) {
      this.scrollListenerAttached = false;
      // Remove listeners
      this.removeEventListener("scroll", this.debounce);
      this.removeEventListener("resize", this.debounce);
      // Cancel debounce event so we are no long going to receive the events
      this.debounce.cancel();
    }
  };

  /**
   * Stops listening to the lock scroll events
   * @private
   */
  detachLockListeners = () => {
    this.removeEventListener("wheel", this.handleWheel);
    this.removeEventListener("keydown", this.handleKeyDown);
  };

  ////////////////////////////////////////////////////
  /// Handlers
  ////////////////////////////////////////////////////

  handleRegularDataAppearance = () => {
    // Listen to scroll events again.
    // Since we detached the scroll listener during the `loadMore` phase
    // we can now attach it again while we received new data.
    this.attachScrollListener();
  };

  handleReverseDataAppearance = (pageStart) => {
    const { scrollHeight } = this.scrollProps;
    const { pageLoaded } = this.state;

    // Calculate the positions
    const position = scrollHeight - this.currentHeight;

    // At the first load, store the scroll height
    if (pageLoaded === pageStart) {
      this.currentHeight = scrollHeight;
    }

    // When some more content is added we need to jump to the right position.
    // Otherwise the content will be added to top with scroll position == 0
    // which we result in loading of more content
    if (pageLoaded > pageStart && !this.scrollListenerAttached) {
      if (position > 0) {
        this.scrollTo(position);
        // Listen to scroll events again.
        // Since we detached the scroll listener during the `loadMore` phase
        // we can now attach it again while we received new data.
        this.attachScrollListener();
      }
      // Store the current height
      this.currentHeight = scrollHeight;
    }
  };

  /**
   * Called when the scroll view port is being scrolled.
   * @private
   */
  handleScroll = () => {
    const { threshold, loadMore, isReverse, hasMore, onScroll } = this.props;
    const { scrollPosition, scrollTop } = this.scrollProps;
    const { pageLoaded } = this.state;

    // Calculate positions
    const offset = isReverse ? scrollTop : scrollPosition;

    // We have reached the threshold
    if (offset < Number(threshold) && this.scrollListenerAttached === true) {
      // Do not listen to scroll events until the data is loaded
      this.detachScrollListener();
      // Call loadMore after detachScrollListener to allow for
      // non-async loadMore functions
      if (hasMore) {
        loadMore(pageLoaded);
      }
    }

    // Disable spinner when it's not visible on the screen.
    // We need to do that otherwise the spinner would suck CPU event
    // though it would be not visible.
    this.setState({ runSpinner: offset < 70 });

    // Notify
    onScroll({
      top: offset,
      bottom: scrollPosition,
    });
  };

  /**
   * Called when the scroll view port is being scrolled by the mouse wheel
   * @private
   */
  handleWheel = (event) => {
    const { deltaY } = event;
    this.handleEventDelta(event, deltaY);
  };

  /**
   * Called when any button is pressed when scroll component is in focus
   * @private
   */
  handleKeyDown = (event) => {
    if (this.isScrollElement(event.target)) {
      // Up key
      if (includes(upKeys, event.keyCode)) {
        this.handleEventDelta(event, -1);
      }
      // Down key
      if (includes(downKeys, event.keyCode)) {
        this.handleEventDelta(event, 1);
      }
    }
  };

  /**
   * Contains logic that knows when to stop scrolling
   * @private
   */
  handleEventDelta = (event, delta) => {
    const { scrollTop, scrollPosition, scrollHeight } = this.scrollProps;
    const isDeltaPositive = delta > 0;

    // Bottom limit
    if (isDeltaPositive && delta > scrollPosition) {
      this.setScrollTop(scrollHeight);
    }
    // Top limit
    else if (!isDeltaPositive && -delta > scrollTop) {
      this.setScrollTop(0);
    }
  };

  ////////////////////////////////////////////////////
  /// Private Methods
  ////////////////////////////////////////////////////

  /**
   * Returns parent node of the scroll element
   */
  get parentNode() {
    return isNil(this.scrollElement) ? {} : this.scrollElement.parentNode;
  }

  /**
   * Returns props of the scroll element
   * @private
   * @return {{scrollTop, scrollHeight, clientHeight}}
   */
  get scrollProps() {
    const { scrollTop, scrollHeight, clientHeight } = this.parentNode;
    return {
      scrollPosition: scrollHeight - clientHeight - scrollTop,
      scrollTop,
      scrollHeight,
      clientHeight,
    };
  }

  /**
   * Returns `true` if the given element is scroll element
   * @private
   */
  isScrollElement = (element) => element === this.scrollElement;

  /**
   * Adds event listener to scroll node
   * @private
   */
  addEventListener = (type, func) => {
    const { useCapture } = this.props;
    this.parentNode.addEventListener(type, func, useCapture);
  };

  /**
   * Removes event listener from the scroll node
   * @private
   */
  removeEventListener = (type, func) => {
    const { useCapture } = this.props;
    this.parentNode.removeEventListener(type, func, useCapture);
  };

  /**
   * Sets the scroll top property of the scroll element
   * @private
   */
  setScrollTop = (top) => {
    this.parentNode.scrollTop = top;
  };

  render() {
    const { children, hasMore, isReverse } = this.props;
    const { runSpinner } = this.state;

    // Decide which loader should be displayed
    const showTopLoader = hasMore && isReverse;
    const showBottomLoader = hasMore && !isReverse;

    const Spin = (
      <Spinner running={runSpinner} size="small" position={"middle"} />
    );

    return (
      <Box
        tabIndex={-1}
        height="100%"
        outline="none"
        ref={(node) => (this.scrollElement = node)}
      >
        {showTopLoader && Spin}
        {children}
        {showBottomLoader && Spin}
      </Box>
    );
  }
}

InfiniteScroll.propTypes = {
  /**
   * Pass a method that will be called when new data is requested
   */
  loadMore: PropTypes.func,
  /**
   * Set to `true` when there's more content that can be loaded
   */
  hasMore: PropTypes.bool,
  /**
   * Set to `true` if this is the first time the content is displayed
   */
  initialLoad: PropTypes.bool,
  /**
   * Set to `true` if the scroll should be `bottom => top` instead of `top => bottom`
   */
  isReverse: PropTypes.bool,
  /**
   * Set to `true` if you want to automatically lock the scroll when the new data is being loaded
   */
  lockScroll: PropTypes.bool,
  /**
   * Current page position
   */
  pageStart: PropTypes.number,
  /**
   * Callback method for scroll events
   */
  onScroll: PropTypes.func,
  /**
   * Returns reference to a `scrollToBottom` method.
   * Thanks to that you can call this method on infinite scroll whenever you want.
   */
  scrollToBottom: PropTypes.func,
  /**
   * Threshold value describes number of pixel from the end of the list
   * where the `loadMore` method is triggered
   */
  threshold: PropTypes.number,
  /**
   * Set to `true` if you want to use capture in events
   */
  useCapture: PropTypes.bool,
  /**
   * Number of milliseconds used in debounce events.
   * This is for example used in `onScroll` callback.
   */
  debounce: PropTypes.number,
  /*
   * Animation options
   */
  animation: PropTypes.shape({
    threshold: PropTypes.number.isRequired,
    duration: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    }).isRequired,
    increment: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

InfiniteScroll.defaultProps = {
  loadMore: noop,
  hasMore: false,
  initialLoad: true,
  isReverse: false,
  lockScroll: false,
  pageStart: 0,
  onScroll: noop,
  scrollToBottom: noop,
  threshold: 100,
  useCapture: false,
  debounce: 100,
  animation: {
    threshold: 1000,
    duration: {
      min: 100,
      max: 300,
    },
    increment: {
      min: 5,
      max: 25,
    },
  },
};

export default InfiniteScroll;
