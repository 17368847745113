// React
import React, { useEffect, useState } from "react";
// Framework
import { usePortal } from "stack";
import { useMounted } from "hooks";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const NavigationRoutes = ({ children }) => {
  // Framework
  const { navigation } = usePortal();
  const { callWhenMounted } = useMounted();
  // State
  const [ready, setReady] = useState(false);
  // Effect
  useEffect(() => {
    return navigation.onChange(
      callWhenMounted(({ path }) => {
        // Redirect when path is not found
        if (navigation.routes.default && !path) {
          navigation.goTo(navigation.routes.default, {
            replace: true,
          });
        }
        setReady(true);
      }),
      true
    );
  }, [callWhenMounted, navigation]);
  // Render
  return <>{ready && children}</>;
};

export default NavigationRoutes;
