// React
import React, { forwardRef, useCallback } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack/core";
import { useSection } from "ui/section";

/**
 * Logs user interaction to analytics.
 * Automatically finds the current context.
 */
const useUserInteraction = () => {
  // Framework
  const { analytics } = usePortal();
  const { context } = useSection();
  // Callback
  const logUserInteraction = useCallback(
    ({ element, value, action, callback }) => (...props) => {
      analytics.logUserInteraction({
        element,
        value,
        action,
        context,
      });
      callback?.(...props);
    },
    [analytics, context]
  );
  // Render
  return { logUserInteraction };
};

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const UserInteraction = forwardRef(
  (
    { component: Component, componentProps = {}, element, value, children },
    ref
  ) => {
    // Framework
    const { logUserInteraction } = useUserInteraction();
    // Render
    return (
      <Component
        {...componentProps}
        ref={ref}
        onClick={
          componentProps.onClick &&
          logUserInteraction({
            callback: componentProps.onClick,
            action: "click",
            element,
            value,
          })
        }
      >
        {children}
      </Component>
    );
  }
);

UserInteraction.propTypes = {
  component: PropTypes.elementType.isRequired,
  componentProps: PropTypes.object,
  element: PropTypes.string.isRequired,
  value: PropTypes.string,
  children: PropTypes.node,
};

export default UserInteraction;
