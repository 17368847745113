// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, Chip, Avatar, DelayedTooltip } from "ui";
// Components
import FileIcon from "../FileIcon";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: 0.9,
    minWidth: 32,
    backgroundColor: theme.palette.grey[200],
    height: 34,
  },
  avatar: {
    border: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0.5),
    width: `34px !important`,
    height: `34px !important`,
    marginLeft: `0 !important`,
  },
  label: {
    maxWidth: 250,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "block",
  },
  deleteIcon: {
    color: "inherit",
    opacity: 0.8,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const FileChip = ({ label, type, onClick, onDelete }) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <DelayedTooltip title={label} helpCursor={!onClick}>
      <Chip
        label={label}
        avatar={
          <Avatar>
            <FileIcon type={type} />
          </Avatar>
        }
        classes={{
          root: classes.root,
          label: classes.label,
          avatar: classes.avatar,
          deleteIcon: classes.deleteIcon,
        }}
        onClick={onClick}
        onDelete={onDelete}
      />
    </DelayedTooltip>
  );
};

FileChip.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
};

export default FileChip;
