// React
import React, { forwardRef, cloneElement, isValidElement } from "react";
import PropTypes from "prop-types";
// Framework
import { UserInteraction } from "analytics";
import { Section } from "ui/section";
import { AddCircleTwoTone as AddIcon } from "icon/material";
import { makeStyles, alpha, Hidden, Fab as Component } from "@material-ui/core";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  fab: {
    boxShadow: theme.shadows[0],
    borderRadius: `${theme.radius.normal}px !important`,
    textTransform: "capitalize",
    color: theme.palette.action.active,
    backgroundColor: theme.palette.grey[200],
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[0],
    },
    "&$focusVisible": {
      boxShadow: theme.shadows[0],
    },
  },
  focusVisible: {},
  primary: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.light, 0.25),
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.light, 0.35),
    },
  },
  secondary: {
    color: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.secondary.light, 0.25),
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.light, 0.35),
    },
  },
  label: {
    whiteSpace: "nowrap",
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(1),
    },
  },
  icon: {
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(1),
    },
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const AddButton = forwardRef(
  (
    {
      context = "button",
      value,
      icon = <AddIcon />,
      color = "secondary",
      onClick,
      children,
    },
    ref
  ) => {
    // Styles
    const classes = useStyles();
    // Render
    return (
      <UserInteraction
        ref={ref}
        element="button"
        value={value}
        component={Component}
        componentProps={{
          variant: "extended",
          size: "small",
          color,
          classes: {
            root: classes.fab,
            label: classes.label,
            primary: classes.primary,
            secondary: classes.secondary,
            focusVisible: classes.focusVisible,
          },
          onClick,
        }}
      >
        <Section context={context} value={value}>
          {isValidElement(icon) &&
            cloneElement(icon, {
              className: classes.icon,
            })}
          <Hidden xsDown>{children}</Hidden>
        </Section>
      </UserInteraction>
    );
  }
);

AddButton.propTypes = {
  /**
   * Section context name
   */
  context: PropTypes.string,
  /**
   * Element specification value
   */
  value: PropTypes.string,
  /**
   * Button icon
   */
  icon: PropTypes.element,
  /**
   * The content of the add button.
   */
  children: PropTypes.node,
};

export default AddButton;
