// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, Avatar } from "ui";
import { AccountCircleRounded as Icon } from "icon/material";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[300],
    color: theme.palette.common.white,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const AnonymousAvatar = ({ size = 40, border = 0 }) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Avatar
      className={classes.root}
      style={{
        width: size,
        height: size,
        border: `${border}px solid white`,
      }}
    >
      <Icon />
    </Avatar>
  );
};

AnonymousAvatar.propTypes = {
  size: PropTypes.number,
  border: PropTypes.number,
};

export default AnonymousAvatar;
