// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, Avatar } from "ui";
import { DeleteOutline as Icon } from "icon/material";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[200],
    color: theme.palette.grey[800],
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const DeletedAvatar = ({ size = 40, border = 0 }) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Avatar
      className={classes.root}
      style={{
        width: size,
        height: size,
        border: `${border}px solid white`,
      }}
    >
      <Icon className={classes.icon} />
    </Avatar>
  );
};

DeletedAvatar.propTypes = {
  size: PropTypes.number,
  border: PropTypes.number,
};

export default DeletedAvatar;
