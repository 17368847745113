// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { useTranslate } from "localization/hooks";
import { makeStyles, Grid, Box, Badge, Typography } from "ui";
// Components
import Avatar from "../Avatar";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  badge: {
    padding: 0,
    background: theme.palette.common.white,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const AvatarRow = ({
  variant = "default",
  name,
  subtitle,
  image,
  badge,
  size,
}) => {
  // Framework
  const { t } = useTranslate();
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Grid container alignItems="center" spacing={2} wrap="nowrap">
      <Grid item>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={badge}
          classes={{ badge: classes.badge }}
        >
          <Avatar variant={variant} title={name} image={image} size={size} />
        </Badge>
      </Grid>
      <Grid item>
        <Typography
          noWrap
          variant="subtitle2"
          color={variant === "disabled" ? "textSecondary" : "textPrimary"}
        >
          {variant === "deleted"
            ? t("core:storage.avatarRow.deletedUser.label")
            : name}
        </Typography>
        {subtitle && (
          <Box mt={-0.5}>
            <Typography variant="subtitle2" color="textSecondary" noWrap>
              {subtitle}
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

AvatarRow.propTypes = {
  variant: PropTypes.oneOf([
    "default",
    "admin",
    "anonymous",
    "deleted",
    "disabled",
  ]),
  name: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  badge: PropTypes.element,
};

export default AvatarRow;
