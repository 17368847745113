// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles } from "ui/components";
import { Section } from "ui/section";
import { Image } from "image/components";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    background: theme.palette.grey[200],
  },
  children: {
    position: "absolute",
    width: "100%",
    top: 0,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileImage = ({
  context = "image",
  value,
  image,
  height = 120,
  children,
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Section context={context} value={value}>
      <div className={classes.root} style={{ height }}>
        <Image image={image} height={height} />
        {children && <div className={classes.children}>{children}</div>}
      </div>
    </Section>
  );
};

TileImage.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
};

export default TileImage;
