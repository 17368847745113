// React
import React, {
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { findDOMNode } from "react-dom";
import PropTypes from "prop-types";
// Framework
import {
  classnames,
  makeStyles,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
} from "ui";
// Components
import ChipList from "./components/ChipList";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  input: {
    padding: theme.spacing(1.5, 1.75),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ChipInput = ({
  value,
  label,
  icon,
  error,
  helperText,
  fullWidth,
  focused,
  nameMapper,
  colorMapper,
  tabIndex = 0,
  onClick,
  onUpdate,
  onDelete,
  onFocus,
  onBlur,
}) => {
  // Styles
  const classes = useStyles();
  // Refs
  const labelRef = useRef(null);
  // State
  const [labelWidth, setLabelWidth] = useState(0);
  // Effects
  useEffect(() => {
    const { offsetWidth } = findDOMNode(labelRef.current);
    setLabelWidth(offsetWidth);
  }, []);
  // Handlers
  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        onClick?.(event);
      }
    },
    [onClick]
  );
  // Render
  return (
    <FormControl
      variant="outlined"
      tabIndex={tabIndex}
      fullWidth={fullWidth}
      error={!!error}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      onKeyPress={handleKeyPress}
    >
      <InputLabel ref={labelRef} shrink>
        {label}
      </InputLabel>
      <OutlinedInput
        className={classnames(focused && "Mui-focused")}
        classes={{ root: classes.input }}
        labelWidth={labelWidth}
        inputComponent={ChipList}
        startAdornment={
          <>
            {icon && (
              <InputAdornment position="start">
                {isValidElement(icon) &&
                  cloneElement(icon, {
                    color: error ? "error" : "action",
                    fontSize: "small",
                  })}
              </InputAdornment>
            )}
          </>
        }
        inputProps={{
          value,
          nameMapper,
          colorMapper,
          onUpdate,
          onDelete,
        }}
      />
      {(error || helperText) && (
        <FormHelperText>{error ?? helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

ChipInput.propTypes = {
  value: PropTypes.array,
  label: PropTypes.string,
  icon: PropTypes.element,
  fullWidth: PropTypes.bool,
  error: PropTypes.string,
  helperText: PropTypes.string,
  focused: PropTypes.bool,
  tabIndex: PropTypes.number,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

export default ChipInput;
