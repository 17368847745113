// React
import { useEffect, useState } from "react";
// Framework
import { useMounted } from "hooks";
import { usePortal } from "stack/core";

export const useUser = () => {
  // Framework
  const { callWhenMounted } = useMounted();
  const { authentication } = usePortal();
  // State
  const [user, setUser] = useState(authentication.currentUser.toJSON());
  // Effects
  useEffect(() => {
    return authentication.onChange(
      callWhenMounted((user) => {
        setUser(user);
      })
    );
  }, [callWhenMounted, authentication]);
  // Render
  return { user };
};
