// React
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
  cloneElement,
  isValidElement,
} from "react";
import PropTypes from "prop-types";
// Helpers
import {
  isArray,
  uniqBy,
  isEmpty,
  isNil,
  get,
  pullAt,
  map,
  compact,
  first,
} from "@mefisto/utils";
// Framework
import { ChipInput } from "form/input";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const defaultValue = (value, isMulti) => {
  if (isMulti) {
    return value;
  } else if (isEmpty(value)) {
    return [];
  } else {
    return [value];
  }
};

/**
 * Same as `useEffect` but doesn't call effect on an initial render.
 */
const useDidMountEffect = (effect, inputs) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) effect();
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
};

const Component = ({
  picker,
  icon,
  label,
  helperText,
  fullWidth,
  relations,
  form,
  field,
  nameMapper,
  colorMapper,
}) => {
  const { dirty, values, touched, errors, setFieldValue } = form;
  const { name } = field;
  const fieldError = get(errors, field.name);
  const showError = get(touched, field.name) && !!fieldError;
  const isMulti = isArray(field.value);
  const value = defaultValue(field.value, isMulti);
  // Memo
  const relationValue = useMemo(() => {
    return first(
      compact(
        map(relations, (relation) => {
          const value = get(values, relation);
          return !isEmpty(value) ? value : null;
        })
      )
    );
  }, [relations, values]);
  const relationValueKey = (relationValue || {}).key;
  // Ref
  const dialogRef = useRef();
  // State
  const [focused, setFocused] = useState(false);
  // Effects
  useDidMountEffect(() => {
    if (!isNil(relations) && dirty) {
      handleSelection(relationValue);
    }
  }, [relationValueKey]);
  // Handlers
  const handleClick = useCallback(() => {
    dialogRef.current.open();
  }, []);
  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);
  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);
  const handleSelection = useCallback(
    (selectedValue) => {
      setFieldValue(
        name,
        isMulti
          ? uniqBy([...value, selectedValue], ({ key }) => key)
          : selectedValue
      );
    },
    [setFieldValue, isMulti, name, value]
  );
  const handleClear = useCallback(
    (index) => {
      if (isMulti) {
        let values = [...value];
        // Remove element at index
        pullAt(values, index);
        setFieldValue(name, values);
      } else {
        setFieldValue(name, "");
      }
    },
    [setFieldValue, isMulti, name, value]
  );
  // Render
  return (
    <>
      <ChipInput
        error={showError ? fieldError : null}
        helperText={helperText}
        value={value}
        label={label}
        icon={icon}
        focused={focused}
        fullWidth={fullWidth}
        nameMapper={nameMapper}
        colorMapper={colorMapper}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleClick}
        onDelete={handleClear}
      />
      {isValidElement(picker) &&
        cloneElement(picker, {
          ref: dialogRef,
          onSelection: handleSelection,
        })}
    </>
  );
};

const FormEntityField = (props) => (
  <FormField variant="outlined" fullWidth component={Component} {...props} />
);

FormEntityField.propTypes = {
  picker: PropTypes.any.isRequired,
  icon: PropTypes.element,
  relations: PropTypes.array,
  nameMapper: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  colorMapper: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default FormEntityField;
