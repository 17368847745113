// React
import React, { useState, useCallback, useEffect } from "react";
// Framework
import { usePortal } from "stack/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const LocalizationInterceptor = ({ children }) => {
  // Framework
  const { localization } = usePortal();
  // State
  const [ready, setReady] = useState(false);
  // Callback
  const fetchLocalizations = useCallback(async () => {
    await localization.fetchLocalizations();
    setReady(true);
  }, [localization]);
  // Effect
  useEffect(() => {
    fetchLocalizations();
  }, [fetchLocalizations]);
  // Render
  return <>{children(ready)}</>;
};

export default LocalizationInterceptor;
