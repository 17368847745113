class WindowEventBus {
  #wb;
  #handlers = [];

  constructor(wb) {
    wb.addEventListener("message", this.#eventListener);
    this.#wb = wb;
  }

  addMessageHandler = (handler) => {
    this.#handlers.push(handler);
  };

  removeMessageHandler = (handler) => {
    this.#handlers = this.#handlers.filter(
      (existingHandler) => existingHandler !== handler
    );
  };

  postMessage = ({ type, data, meta }) => {
    return this.#wb.messageSW({ type, data, meta });
  };

  #eventListener = (event) => {
    this.#handlers.forEach((handler) => handler(event));
  };
}

export default WindowEventBus;
