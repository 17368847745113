// React
import React, { Children, useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty, compact } from "@mefisto/utils";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const CoverButtons = ({ buttons = [] }) => {
  // Memo
  const hasChildren = useMemo(() => {
    return !isEmpty(compact(buttons));
  }, [buttons]);
  // Render
  return <>{hasChildren && Children.map(buttons, (child) => child)}</>;
};

CoverButtons.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.node),
};

export default CoverButtons;
