// React
import React, { useEffect, useState, useCallback, useContext } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";
// Components
import { FirebaseContext } from "./FirebaseContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({ children }) => {
  // Framework
  const { firebase, log } = usePortal();
  // Context
  const options = useContext(FirebaseContext);
  // Init
  useStackDependency(firebase, options);
  // State
  const [ready, setReady] = useState(false);
  // Callbacks
  const initializeApp = useCallback(async () => {
    try {
      await firebase.initialize();
      setReady(true);
    } catch (error) {
      log.error(error);
    }
  }, [firebase, log]);
  // Effect
  useEffect(() => {
    initializeApp();
  }, [initializeApp]);
  // Render
  return <>{ready && children}</>;
};

const FirebaseProvider = ({ options, children }) => (
  <FirebaseContext.Provider value={{ modules: [] }}>
    {options}
    <Component>{children}</Component>
  </FirebaseContext.Provider>
);

FirebaseProvider.propTypes = {
  options: PropTypes.element,
};

export { FirebaseProvider };
