// React
import { useEffect, useState } from "react";
// Framework
import { useMounted } from "hooks";
import { usePortal } from "stack/core";

export const useHelmet = () => {
  // Framework
  const { callWhenMounted } = useMounted();
  const { helmet } = usePortal();
  // State
  const [values, setValues] = useState(helmet.values);
  // Effects
  useEffect(() => {
    return helmet.onChange(
      callWhenMounted((helmet) => {
        setValues(helmet.values);
      })
    );
  }, [callWhenMounted, helmet]);
  // Render
  return { ...values };
};
