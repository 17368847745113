/**
 * Resize image manipulation
 */
export const resize = (maxWidth, maxHeight) => async (canvasImage) => {
  // Create resulting canvas
  const canvas = document.createElement("canvas");
  const canvasContext = canvas.getContext("2d");
  // Get the ratio for width and height
  const ratioWidth = canvasImage.width / maxWidth;
  const ratioHeight = canvasImage.height / maxHeight;
  // Calculate new width and height based on ratio
  if (ratioWidth > ratioHeight) {
    canvas.width = maxWidth;
    canvas.height = canvasImage.height / ratioWidth;
  } else {
    canvas.width = canvasImage.width / ratioHeight;
    canvas.height = maxHeight;
  }
  // Draw
  canvasContext.drawImage(canvasImage, 0, 0, canvas.width, canvas.height);
  return canvas;
};
