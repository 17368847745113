// Helpers
import {
  isArray,
  forEach,
  includes,
  has,
  join,
  first,
  values,
  compact,
  filter,
  endsWith,
} from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";

export class Permission extends StackDependency {
  #permissions = {};
  #subscribers = [];

  onInitialized() {
    this.#bind();
  }

  /**
   * Adds permission. Returns cached permission or calculates a new value.
   * @return {object}
   */
  push(permission, { app, resource, scopes } = {}) {
    const {
      apps,
      resource: {
        resources: { organization, location },
      },
    } = this.context;
    // Make options
    const options = {
      app: app ?? apps.current.id,
      resource: resource ?? location ?? organization,
      scopes,
    };
    if (this.#has(permission, options)) {
      // Return cached value
      return this.#get(permission, options);
    } else {
      // Calculate and return the value
      const result = this.#isAuthorized(permission, options);
      return this.#add(permission, result, options);
    }
  }

  /**
   * Subscribe to change.
   * Returns unsubscribe function you need to call in order to
   * stop the event observation in e.g. `useEffect` function.
   * @param callback {function} Callback called when state changes
   * @param callOnBind {boolean} If `true` the callback is called right after the callback is bound.
   * @return {function}
   */
  onChange(callback, callOnBind = false) {
    this.#subscribers.push(callback);
    if (callOnBind) {
      callback(this);
    }
    return () => {
      this.#subscribers = filter(this.#subscribers, (s) => s !== callback);
    };
  }

  /**
   * Called when bag state changes
   */
  #handleChange = () => {
    forEach(this.#subscribers, (callback) => callback(this));
  };

  /**
   * Binds dependencies listeners
   */
  #bind = () => {
    // Handler
    const handleChange = () => {
      forEach(this.#permissions, ({ permission, result, options }, key) => {
        const newResult = this.#isAuthorized(permission, options);
        if (result !== newResult) {
          this.#permissions[key] = {
            permission,
            result: newResult,
            options,
          };
        }
      });
      this.#handleChange();
    };
    // Bind
    const { authorization, authentication, resource } = this.context;
    authentication.onChange(handleChange);
    authorization.onChange(handleChange);
    resource.onChange(handleChange);
  };

  /**
   * Returns `true` if the permission is authorized
   * @return {boolean}
   */
  #isAuthorized = (permission, options) => {
    // Check state
    if (this.#hasArchivedState(permission, options)) {
      return false;
    }
    // Check scopes
    return (
      this.#hasAdminScope(permission, options) ||
      this.#hasOrganizationScope(permission, options) ||
      this.#hasLocationScope(permission, options) ||
      this.#hasUserScope(permission, options)
    );
  };

  /**
   * Returns `true` when the permission is of a `MUTATION` type and
   * resource is in `ARCHIVED` state.
   * @param permission {string} Permission key
   * @param options {object} Permission options
   * @return {boolean}
   */
  #hasArchivedState = (permission, options) => {
    const { app, resource } = options;
    const isMutation = endsWith(permission, "mutation");
    if (isMutation) {
      const state = this.#getState(resource);
      if (state === "ARCHIVED" && app !== "PORTAL") {
        return true;
      }
    }
    return false;
  };

  /**
   * Returns `true` if the permission is authorized in admin scope
   * @param permission {string} Permission key
   * @param options {object} Permission options
   * @return {boolean}
   */
  #hasAdminScope = (permission, options) => {
    const { scopes } = options;
    if (includes(scopes, "ADMIN")) {
      const { authentication } = this.context;
      const {
        data: { isAdmin = false },
      } = authentication.currentUser.toJSON();
      return isAdmin;
    }
    return false;
  };

  /**
   * Returns `true` if the permission is authorized in organization scope
   * @param permission {string} Permission key
   * @param options {object} Permission options
   * @return {boolean}
   */
  #hasOrganizationScope = (permission, options) => {
    const { app, scopes } = options;
    if (includes(scopes, "ORGANIZATION")) {
      const role = this.#getOrganizationRole();
      // Organization scope should have superior role
      if (!role) {
        return false;
      }
      // Handle portal app scope
      if (app === "PORTAL" && role?.app === "PORTAL") {
        // Validate permissions
        const permissions = isArray(permission) ? permission : [permission];
        for (const permission of permissions) {
          if (includes(role.permissions, permission)) {
            return true;
          }
        }
        return false;
      }
      // Only role with no descendants has full access
      return !role.descendant;
    }
    return false;
  };

  /**
   * Returns `true` if the permission is authorized in location scope
   * @param permission {string} Permission key
   * @param options {object} Permission options
   * @return {boolean}
   */
  #hasLocationScope = (permission, options) => {
    const { authorization } = this.context;
    const { app, resource, scopes } = options;
    if (includes(scopes, "LOCATION")) {
      const role = this.#getOrganizationRole();
      // Location scope must have organization role
      if (!role) {
        return false;
      }
      // Handle portal app scope
      if (app === "PORTAL") {
        // Check if location resource belongs to organization
        const { descendants } = role;
        if (!includes(descendants, resource)) {
          return false;
        }
        // Validate permissions
        const permissions = isArray(permission) ? permission : [permission];
        for (const permission of permissions) {
          if (includes(role.permissions, permission)) {
            return true;
          }
        }
        return false;
      }
      // Check if role has descendants. If not, the user has full access
      if (!role.descendant) {
        return true;
      }
      // Validate descendant permission
      const permissions = isArray(permission) ? permission : [permission];
      for (const permission of permissions) {
        if (has(authorization.userPermissions, [app, resource, permission])) {
          return true;
        }
      }
    }
    return false;
  };

  /**
   * Returns `true` if the permission is authorized in user scope
   * @param permission {string} Permission key
   * @param options {object} Permission options
   * @return {boolean}
   */
  #hasUserScope = (permission, options) => {
    const { resource, scopes } = options;
    if (includes(scopes, "USER")) {
      const { authentication } = this.context;
      const {
        data: { uid },
      } = authentication.currentUser.toJSON();
      return uid === resource;
    }
    return false;
  };

  /**
   * Returns superior role
   * @return {object}
   */
  #getOrganizationRole = () => {
    const { authorization, resource } = this.context;
    const { organization } = resource.resources;
    return first(values(authorization.userResources[organization]));
  };

  /**
   * Returns resource state
   * @return {string|null}
   */
  #getState = (resource) => {
    const role = this.#getOrganizationRole();
    return role?.states?.[resource] ?? null;
  };

  /**
   * Composes unique key from permission, app and resource
   * @return {string}
   */
  #makeKey = (permission, { app, resource, scopes }) => {
    return join(compact([permission, app, resource, scopes]));
  };

  /**
   * Adds item to bag
   * @return {object}
   */
  #add = (permission, result, options) => {
    const key = this.#makeKey(permission, options);
    const value = { permission, result, options };
    this.#permissions[key] = value;
    return value;
  };

  /**
   * Returns `true` if bag contains the item
   * @return {boolean}
   */
  #has = (permission, options) => {
    const key = this.#makeKey(permission, options);
    return has(this.#permissions, key);
  };

  /**
   * Returns item from the bag
   * @return {object}
   */
  #get = (permission, options) => {
    const key = this.#makeKey(permission, options);
    return this.#permissions[key];
  };
}
