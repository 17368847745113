// React
import React, {
  memo,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { set, upperFirst } from "@mefisto/utils";
// Framework
import { usePortal } from "stack/core";

////////////////////////////////////////////////////
/// Context
////////////////////////////////////////////////////

const NavigationMenuContext = createContext({});

export const useNavigationMenuContext = () => {
  return useContext(NavigationMenuContext);
};

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const NavigationMenuProvider = ({ foldable, children }) => {
  // Framework
  const { ui } = usePortal();
  // State
  const [sections, setSections] = useState({});
  // Callback
  const getSectionId = useCallback((section) => {
    return `drawerSection${upperFirst(section)}Folded`;
  }, []);
  const register = useCallback(
    ({ section, item, disabled }) => {
      setSections((sections) => {
        let result = { ...sections };
        set(result, [section, "items", item, "disabled"], disabled);
        set(result, [section, "folded"], ui.get(getSectionId(section)));
        return result;
      });
    },
    [ui, getSectionId]
  );
  const sectionFold = useCallback(
    (section) => {
      setSections((sections) => {
        const folded = !sections[section]?.folded;
        const result = set({ ...sections }, [section, "folded"], folded);
        ui.set(getSectionId(section), folded);
        return result;
      });
    },
    [ui, getSectionId]
  );
  // Render
  return (
    <NavigationMenuContext.Provider
      value={{
        foldable,
        register,
        sections,
        sectionFold,
      }}
    >
      {children}
    </NavigationMenuContext.Provider>
  );
};

NavigationMenuProvider.propTypes = {
  foldable: PropTypes.bool,
  children: PropTypes.node,
};

export default memo(NavigationMenuProvider);
