// React
import React from "react";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";

const UIProvider = ({ children }) => {
  // Framework
  const { ui } = usePortal();
  // Init
  useStackDependency(ui);
  // Render
  return <>{children}</>;
};

export { UIProvider };
