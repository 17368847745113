// React
import React, { useEffect, useCallback } from "react";
// Helpers
import { isNil } from "@mefisto/utils";
// Framework
import { useMounted } from "hooks";
import { usePortal } from "stack";
import { Button } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const SnackbarBridge = () => {
  // Framework
  const { snackbar } = usePortal();
  const { callWhenMounted } = useMounted();
  // Handlers
  const handleAction = useCallback(
    (key, onAction) => {
      snackbar.closeSnackbar(key);
      onAction(key);
    },
    [snackbar]
  );
  // Effect
  useEffect(() => {
    return snackbar.onChange(
      callWhenMounted((type, message, action, onAction, key) => {
        snackbar.enqueueSnackbar(message, {
          key,
          preventDuplicate: !!key,
          variant: type,
          persist: !isNil(onAction),
          action: action
            ? (key) => (
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => handleAction(key, onAction)}
                >
                  {action}
                </Button>
              )
            : null,
        });
      })
    );
  }, [callWhenMounted, handleAction, snackbar]);
  return null;
};

export default SnackbarBridge;
