// React
import React from "react";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const AuthenticationProvider = ({ children }) => {
  // Framework
  const { authentication } = usePortal();
  // Init
  useStackDependency(authentication);
  // Render
  return <>{children}</>;
};

export { AuthenticationProvider };
