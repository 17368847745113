// React
import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
// Helpers
import { forEach, keys, pick } from "@mefisto/utils";
// Components
import { FormatContext } from "format/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const FormatPrice = (props) => {
  // Context
  const context = useContext(FormatContext);
  // Set values
  forEach(pick(props, keys(FormatPrice.propTypes)), (value, key) => {
    context.price = { ...context.price, [key]: value };
  });
  // Render
  return <Fragment />;
};

FormatPrice.defaultProps = {
  __TYPE: "FormatPrice",
};

FormatPrice.propTypes = {
  /**
   * Override locale for pricing.
   * By default, the locale is taken from the current localization context.
   */
  locale: PropTypes.string,
  /**
   * How to display the currency in currency formatting. The default is "symbol".
   *
   * "symbol": use a localized currency symbol such as €.
   * "narrowSymbol": use a narrow format symbol ("$100" rather than "US$100").
   * "code": use the ISO currency code.
   * "name": use a localized currency name such as "dollar".
   */
  currencyDisplay: PropTypes.oneOf(["symbol", "narrowSymbol", "code", "name"]),
  /**
   * In many locales, accounting format means to wrap the number with parentheses instead of appending a minus sign.
   * You can enable this formatting by setting the currencySign option to "accounting".
   * The default value is "standard".
   */
  currencySign: PropTypes.oneOf(["standard", "accounting"]),
  /**
   * The locale matching algorithm to use. Possible values are "lookup" and "best fit"; the default is "best fit".
   * For information about this option, see the
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locale_identification_and_negotiation
   */
  localeMatcher: PropTypes.oneOf(["best fit", "lookup"]),
  /**
   * The maximum number of fraction digits to use.
   * Possible values are from 0 to 20; the default for plain number formatting is the largest
   * of minimumFractionDigits and 3; the default for currency formatting is the largest of minimumFractionDigits
   * and the number of minor unit digits provided by the ISO 4217 currency code list
   * (2 if the list doesn't provide that information); the default for percent formatting is the larger of
   * minimumFractionDigits and 0.
   */
  maximumFractionDigits: PropTypes.number,
  /**
   * The maximum number of significant digits to use. Possible values are from 1 to 21; the default is 21.
   */
  maximumSignificantDigits: PropTypes.number,
  /**
   * The minimum number of integer digits to use.
   * A value with a smaller number of integer digits than this number will be left-padded with zeros
   * (to the specified length) when formatted. Possible values are from 1 to 21; the default is 1.
   */
  minimumIntegerDigits: PropTypes.number,
  /**
   * The minimum number of significant digits to use. Possible values are from 1 to 21; the default is 1.
   */
  minimumSignificantDigits: PropTypes.number,
  /**
   * Whether to use grouping separators, such as thousands separators or a thousand/lakh/crore separators.
   * The default is auto.
   *
   * "always": display grouping separators even if the locale prefers otherwise.
   * "auto": display grouping separators based on the locale preference, which may also be dependent on the currency.
   * false: do not display grouping separators.
   * "min2": display grouping separators when there are at least 2 digits in a group.
   * true: alias for always.
   */
  useGrouping: PropTypes.oneOf(["auto", "always", "min2", true, false]),
};

export default FormatPrice;
