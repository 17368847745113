// React
import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useCallback,
} from "react";
import PropTypes from "prop-types";
// Framework
import { Box, Popover } from "ui/components";
import { Section } from "ui/section";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TilePopover = forwardRef(
  (
    {
      context = "popover",
      value,
      width,
      minWidth,
      maxWidth,
      height,
      minHeight,
      maxHeight,
      popoverProps = {},
      children,
      onClose,
    },
    ref
  ) => {
    // State
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    // Ref
    useImperativeHandle(ref, () => ({
      open({ anchorEl }) {
        setAnchorEl(anchorEl);
        setOpen(true);
      },
      close() {
        setOpen(false);
      },
    }));
    // Callbacks
    const handleClose = useCallback(() => {
      setOpen(false);
      if (onClose) {
        onClose();
      }
    }, [onClose]);
    // Render
    return (
      <Section context={context} value={value}>
        <Popover
          open={open}
          anchorEl={anchorEl}
          transitionDuration={open ? 300 : 0}
          onClose={handleClose}
          {...popoverProps}
        >
          <Box
            width={width}
            minWidth={minWidth}
            maxWidth={maxWidth}
            height={height}
            minHeight={minHeight}
            maxHeight={maxHeight}
          >
            {children}
          </Box>
        </Popover>
      </Section>
    );
  }
);

TilePopover.propTypes = {
  /**
   * Section context name
   */
  context: PropTypes.string,
  /**
   * Element specification value
   */
  value: PropTypes.string,
  /**
   * Popover width
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Popover min width
   */
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Popover max width
   */
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Popover height
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Popover max height
   */
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Popover min height
   */
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Props passed to `Popover` component
   */
  popoverProps: PropTypes.object,
  /**
   * Called when popover is closed
   */
  onClose: PropTypes.func,
  /**
   * Children node
   */
  children: PropTypes.node,
};

export default TilePopover;
