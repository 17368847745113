// React
import React from "react";
// Framework
import { forwardType, Themes } from "@mefisto/web";
// Components
import { LightTheme } from "./components/LightTheme";

export default forwardType(Themes, () => (
  <Themes defaultTheme="light">
    <LightTheme />
  </Themes>
));
