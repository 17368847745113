// React
import React, { useEffect, useCallback, useState, useContext } from "react";
import PropTypes from "prop-types";
// Helpers
import { toLower } from "@mefisto/utils";
// Framework
import { useMounted } from "hooks";
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";
// Components
import { IntlProvider } from "react-intl";
import { localizations } from "localization/translations";
import { LocalizationContext } from "./LocalizationContext";

const PortalComponent = ({ children }) => {
  // Framework
  const { localization } = usePortal();
  const { callWhenMounted } = useMounted();
  // Context
  const options = useContext(LocalizationContext);
  // Init
  useStackDependency(localization, options);
  // State
  const [ready, setReady] = useState(false);
  const [locale, setLocale] = useState(null);
  const [messages, setMessages] = useState(null);
  // Callback
  const register = useCallback(() => {
    if (!ready) {
      localization.register("core", localizations);
      setReady(true);
    }
  }, [ready, localization]);
  // Effect
  useEffect(() => {
    register();
  }, [register]);
  useEffect(() => {
    return localization.onChange(
      callWhenMounted((localization) => {
        setLocale(localization.locale);
        setMessages(localization.messages);
      }),
      true
    );
  }, [callWhenMounted, localization]);
  // Render
  return (
    <>
      {ready && (
        <IntlProvider messages={messages} locale={locale}>
          {children}
        </IntlProvider>
      )}
    </>
  );
};

const AppComponent = ({ children }) => {
  // Framework
  const { apps, localization } = usePortal();
  // Context
  const options = useContext(LocalizationContext);
  // Effects
  useEffect(() => {
    localization.register(
      options.app ?? toLower(apps.current?.id),
      options.localizations
    );
  }, [localization, options, apps]);
  // Render
  return <>{children}</>;
};

const LocalizationProvider = ({ source, options, children }) => (
  <>
    {source === "portal" && (
      <LocalizationContext.Provider value={{ fallback: "en" }}>
        {options}
        <PortalComponent>{children}</PortalComponent>
      </LocalizationContext.Provider>
    )}
    {source === "app" && (
      <AppComponent>
        {options}
        {children}
      </AppComponent>
    )}
  </>
);

LocalizationProvider.propTypes = {
  source: PropTypes.oneOf(["portal", "app"]),
  options: PropTypes.element,
};

export { LocalizationProvider };
