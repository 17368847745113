// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, classnames, Section, Grid } from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles(() => ({
  root: {
    appRegion: "none",
  },
  fill: {
    flexGrow: 1,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderItem = ({ context = "item", value, fill, children }) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Section context={context} value={value}>
      <Grid
        item
        className={classnames(classes.root, {
          [classes.fill]: fill,
        })}
      >
        {children}
      </Grid>
    </Section>
  );
};

HeaderItem.propTypes = {
  /**
   * Section context name
   */
  context: PropTypes.string,
  /**
   * Element specification value
   */
  value: PropTypes.string,
  /**
   * Set to `true` to let the section fill the horizontal space
   */
  fill: PropTypes.bool,
  /**
   * The content of the header item.
   */
  children: PropTypes.node,
};

export default HeaderItem;
