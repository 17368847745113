// React
import React, { useCallback } from "react";
import PropTypes from "prop-types";
// Helpers
import { map, get, isFunction } from "@mefisto/utils";
// Framework
import { ColorChip, makeStyles, Grid } from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: theme.spacing(4),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ChipList = ({
  value = [],
  nameMapper = "name",
  colorMapper = "color",
  onUpdate,
  onDelete,
}) => {
  // Styles
  const classes = useStyles();
  // Callbacks
  const handleUpdate = useCallback(
    (event, index) => {
      if (onUpdate) {
        event.stopPropagation();
        onUpdate(index);
      }
    },
    [onUpdate]
  );
  const handleDelete = useCallback(
    (event, index) => {
      if (onDelete) {
        event.stopPropagation();
        onDelete(index);
      }
    },
    [onDelete]
  );
  // Render
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {map(value, (entity, index) => (
          <Grid item key={index}>
            <ColorChip
              clickable={!!onUpdate}
              size="medium"
              label={
                isFunction(nameMapper)
                  ? nameMapper(entity)
                  : get(entity, nameMapper)
              }
              color={
                isFunction(colorMapper)
                  ? colorMapper(entity)
                  : get(entity, colorMapper)
              }
              tabIndex={-1}
              hidden={false}
              onClick={(event) => handleUpdate(event, index)}
              onDelete={(event) => handleDelete(event, index)}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

ChipList.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
    })
  ),
};

export default ChipList;
