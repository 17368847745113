// React
import React, { Fragment } from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty, map, range } from "@mefisto/utils";
// Framework
import {
  Box,
  Button,
  Spinner,
  EmptyPlaceholder,
  ErrorPlaceholder,
} from "ui/components";
// Components
import InfiniteList from "../InfiniteList";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Feed = ({
  display = "default",
  loading,
  loadingMore,
  error,
  skeleton,
  skeletonCount = 20,
  emptyDisabled,
  emptyTitle,
  emptySubtitle,
  items,
  hasMore,
  contentProps,
  onLoadMore,
  onRefresh,
}) => {
  // Handlers
  const handleLoadMore = () => {
    if (!loadingMore) {
      onLoadMore();
    }
  };
  // Render
  return (
    <Box
      height="100%"
      position={display === "default" ? "relative" : "inherit"}
    >
      {loading ? (
        <>
          {skeleton ? (
            <InfiniteList overflowY="hidden" contentProps={contentProps}>
              {map(range(skeletonCount), (key) => (
                <Fragment key={key}>{skeleton}</Fragment>
              ))}
            </InfiniteList>
          ) : (
            <Spinner position="center" size="small" />
          )}
        </>
      ) : (
        <>
          {error ? (
            <ErrorPlaceholder position="center" onAction={onRefresh} />
          ) : (
            <>
              {isEmpty(items) ? (
                <EmptyPlaceholder
                  position="center"
                  disabled={emptyDisabled}
                  title={emptyTitle}
                  subtitle={emptySubtitle}
                />
              ) : (
                <>
                  {display === "default" && (
                    <InfiniteList
                      isEmpty={isEmpty(items)}
                      loadMore={handleLoadMore}
                      hasMore={hasMore}
                      overflowX="hidden"
                      contentProps={contentProps}
                    >
                      {items}
                    </InfiniteList>
                  )}
                  {display === "incremental" && (
                    <>
                      {items}
                      {hasMore && (
                        <Box mt={2} display="flex" justifyContent="center">
                          <Button
                            variant="outlined"
                            size="small"
                            disabled={loadingMore}
                            onClick={handleLoadMore}
                          >
                            Load More
                          </Button>
                        </Box>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

Feed.propTypes = {
  display: PropTypes.oneOf(["default", "incremental"]),
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  items: PropTypes.any,
  skeleton: PropTypes.node,
  skeletonCount: PropTypes.number,
  hasMore: PropTypes.bool,
  error: PropTypes.object,
  emptyTitle: PropTypes.string,
  emptySubtitle: PropTypes.string,
  onLoadMore: PropTypes.func,
  onRefresh: PropTypes.func,
};

export default Feed;
