// Framework
import { StackDependency } from "stack/dependency";

export class Messaging extends StackDependency {
  #messaging;
  #log;
  #isEnabled = false;

  onInitialized() {
    const { firebase, log } = this.context;
    this.#log = log;
    if (firebase.messaging && firebase.messaging.isSupported()) {
      this.#messaging = firebase.messaging();
      log.info("📨", "Messaging [On]");
    }
  }

  /**
   * Returns messaging instance
   */
  get instance() {
    return this.#messaging;
  }

  /**
   * Returns `true` if the messaging is enabled and initialized.
   */
  get isEnabled() {
    return this.#isEnabled;
  }

  /**
   * Set service worker responsible for the incoming message processing.
   * If no service worker is specified, the messaging is not enabled.
   */
  async useServiceWorker() {
    try {
      if (!this.#messaging || !("serviceWorker" in navigator)) {
        return;
      }
      const registration = await navigator.serviceWorker.ready;
      if (!registration) {
        this.#log.warning(
          "Messaging service worker cannot be set because no service worker was registered"
        );
        return;
      }
      // TODO: `useServiceWorker` is not in messaging API anymore
      // this.#messaging.useServiceWorker(registration);
      this.#log.info("📨", "SW Initialized");
      this.#isEnabled = true;
    } catch (error) {
      this.#log.error(error);
    }
  }

  /**
   * Returns messaging token which should be stored on server
   */
  async getToken() {
    if (!this.#isEnabled) {
      return;
    }
    return this.#messaging.getToken();
  }

  /**
   * Deletes messaging token
   */
  deleteToken() {
    if (!this.#isEnabled) {
      return;
    }
    this.#messaging.deleteToken();
  }
}
