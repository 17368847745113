// React
import React, {
  Children,
  cloneElement,
  useEffect,
  useCallback,
  useMemo,
  isValidElement,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { intersection, isFunction, keys, size } from "@mefisto/utils";
// Framework
import { Section } from "ui";
import { useTranslate } from "localization";
import { Form, FormSubmitButton } from "form";
import { useDeepMemo } from "hooks";
import { useEntityCreate } from "model/hooks";
import { EntityPropType, getInput, getFiles } from "model/utils";
// Components
import ModelDialogButtons from "../ModelDialogButtons";
import ModelDialogContent from "../ModelDialogContent";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelDialogActionCreate = ({
  context = "create",
  value,
  action,
  entity: Entity,
  schema,
  loading: customLoading,
  skeleton,
  disableErrorCount,
  onSubmit,
  onNext,
  onFinish,
  onClose,
  children,
  __display,
  __options,
}) => {
  // Props
  const {
    input,
    resources,
    languages,
    silent,
    overrides = {},
    defaults = {},
    options = {},
  } = useDeepMemo(() => __options ?? {}, [__options]);
  const {
    display = "individual",
    dataMapper,
    tab = 0,
    tabData,
    isLast = false,
    isLastTab = false,
    onNext: __onNext,
    onFinish: __onFinish,
    onClose: __onClose,
  } = useDeepMemo(() => __display ?? {}, [__display]);
  // TODO: Optimistic UI is not yet implemented in the `create` action yet.
  const optimisticUI = false;
  // Framework
  const { t } = useTranslate();
  // Creates payload
  const getPayload = useCallback(
    ({ data, formData, tabData, form, loading }) => ({
      action,
      input,
      resources,
      languages,
      defaults,
      options,
      optimisticUI,
      form,
      loading,
      ...(data && { data }),
      ...(formData && { formData }),
      ...(tabData && { tabData }),
    }),
    [action, input, resources, languages, defaults, options, optimisticUI]
  );
  // Model
  const entityCreate = useEntityCreate(Entity, {
    input,
    resources,
    languages,
    silent,
  });
  // Memo
  const entityReadData = useMemo(() => {
    const { data } = entityCreate;
    return dataMapper ? dataMapper(data) : data;
  }, [entityCreate, dataMapper]);
  const loading = useMemo(() => {
    return entityCreate.loading || customLoading;
  }, [entityCreate, customLoading]);
  const finished = useMemo(() => {
    const { called, error, loading } = entityCreate;
    if (called && loading && optimisticUI) {
      return true;
    } else {
      return called && !loading && !error;
    }
  }, [entityCreate, optimisticUI]);
  // Callbacks
  const getContent = useCallback(
    ({ data, tabData, form }) => {
      const child = Children.toArray(children)[tab];
      return (
        isValidElement(child) &&
        cloneElement(child, {
          ...getPayload({
            data,
            formData: form.values,
            tabData,
            form,
            loading,
          }),
        })
      );
    },
    [children, getPayload, tab, loading]
  );
  const getSchema = useCallback(
    (data) => {
      return isFunction(schema)
        ? (formData, tabData) =>
            schema(getPayload({ data, formData, tabData, loading }))
        : schema;
    },
    [schema, getPayload, loading]
  );
  const errorCount = useCallback((form) => {
    return size(intersection(keys(form.errors), keys(form.touched)));
  }, []);
  // Handlers
  const handleSubmit = (formData) => {
    if (onSubmit) {
      onSubmit(
        getPayload({ data: entityReadData, formData, tabData, loading })
      );
    } else {
      const inputValues = getInput(formData, input);
      const fileValues = getFiles(formData);
      entityCreate.create({
        input: inputValues,
        files: fileValues,
      });
    }
  };
  const handleNext = useCallback(() => {
    __onNext?.();
    onNext?.();
  }, [__onNext, onNext]);
  const handleFinish = useCallback(
    (payload) => {
      __onFinish?.(payload);
      onFinish?.(payload);
    },
    [__onFinish, onFinish]
  );
  const handleClose = useCallback(() => {
    __onClose?.();
    onClose?.();
  }, [__onClose, onClose]);
  // Effects
  useEffect(() => {
    if (finished) {
      handleFinish(getPayload({ data: entityReadData }));
    }
  }, [finished, entityReadData, getPayload, handleFinish]);
  // Render
  return (
    <Section context={context} value={value}>
      <Form
        dialog
        schema={getSchema(entityReadData)}
        defaults={{
          // Default values before read is performed
          ...defaults,
          // Overrides, that will always be set
          ...overrides,
        }}
        disabled={loading}
        onSubmit={handleSubmit}
      >
        {(form) => (
          <>
            <ModelDialogContent display={display}>
              {getContent({
                data: entityReadData,
                formData: form.values,
                tabData,
                form,
              })}
            </ModelDialogContent>
            <ModelDialogButtons
              skeleton={skeleton}
              loading={loading}
              disableErrorCount={disableErrorCount}
              errorCount={errorCount(form)}
              onClose={handleClose}
            >
              {display === "individual" && (
                <FormSubmitButton
                  value="submit"
                  type="submit"
                  loading={loading}
                  finished={finished}
                >
                  {t("core:model.dialog.create.button.submit")}
                </FormSubmitButton>
              )}
              {display === "group" && (
                <>
                  {isLast ? (
                    <>
                      {isLastTab ? (
                        <FormSubmitButton
                          value="finish"
                          type="submit"
                          loading={loading}
                          finished={finished}
                        >
                          {t("core:model.dialog.create.button.finish")}
                        </FormSubmitButton>
                      ) : (
                        <FormSubmitButton
                          value="next"
                          type="submit"
                          loading={loading}
                          finished={finished}
                        >
                          {t("core:model.dialog.create.button.next")}
                        </FormSubmitButton>
                      )}
                    </>
                  ) : (
                    <FormSubmitButton
                      value="next"
                      type="button"
                      loading={loading}
                      finished={finished}
                      onClick={handleNext}
                    >
                      {t("core:model.dialog.create.button.next")}
                    </FormSubmitButton>
                  )}
                </>
              )}
            </ModelDialogButtons>
          </>
        )}
      </Form>
    </Section>
  );
};

ModelDialogActionCreate.displayName = "ModelDialogActionCreate";

ModelDialogActionCreate.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  entity: EntityPropType,
  schema: PropTypes.any,
  loading: PropTypes.bool,
  skeleton: PropTypes.bool,
  disableErrorCount: PropTypes.bool,
  onSubmit: PropTypes.func,
  onNext: PropTypes.func,
  onFinish: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default ModelDialogActionCreate;
