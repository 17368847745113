// React
import React from "react";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ServiceWorkerProvider = ({ children }) => {
  // Framework
  const { serviceWorker } = usePortal();
  // Init
  useStackDependency(serviceWorker);
  // Render
  return <>{children}</>;
};

export { ServiceWorkerProvider };
