// React
import React, { Children, isValidElement, cloneElement } from "react";
import PropTypes from "prop-types";
// Helpers
import { merge } from "@mefisto/utils";
// Framework
import { NavigationLayout } from "navigation";
import { OfflineContent, SceneLoader } from "layout/components";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const BasicLayout = ({ header, footer, loader, layoutProps, children }) => (
  <NavigationLayout
    layoutProps={merge(
      {
        headerProps: {
          height: 52,
          horizontalInnerFill: true,
        },
        sceneProps: {
          prependComponent: header,
          ...(footer && {
            fillAvailable: true,
            appendComponent: footer,
          }),
        },
      },
      layoutProps
    )}
  >
    <OfflineContent>
      {Children.map(
        children ?? [],
        (scene) =>
          isValidElement(scene) &&
          cloneElement(scene, {
            loader: loader ?? SceneLoader,
          })
      )}
    </OfflineContent>
  </NavigationLayout>
);

BasicLayout.propTypes = {
  /**
   * The header element to be displayed at the top of the drawer.
   */
  header: PropTypes.element,
  /**
   * Footer element
   */
  footer: PropTypes.element,
  /**
   * The loader element passed to scene.
   */
  loader: PropTypes.any,
  /**
   * Override layout props (e.g. headerProps, sceneProps, etc.)
   */
  layoutProps: PropTypes.object,
  /**
   * The scenes to be displayed in the layout.
   */
  children: PropTypes.node,
};

export default BasicLayout;
