// React
import React from "react";
// Framework
import { makeStyles, Button } from "ui/components";
import { classnames } from "ui/classnames";
import { InfiniteList } from "ui/list";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    position: "inherit",
    height: "100%",
  },
  loading: {
    overflow: "hidden",
  },
  infinite: {
    position: "relative",
  },
  loadMoreContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const CollectionComponent = ({
  scroll,
  loading,
  error,
  empty,
  hasMore,
  loadingMore,
  onLoadMore,
  children,
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <div
      className={classnames(classes.root, {
        [classes.infinite]: scroll === "infinite",
        [classes.loading]: loading,
      })}
    >
      {scroll === "infinite" && (
        <InfiniteList
          overflowX="hidden"
          overflowY={loading ? "hidden" : "auto"}
          isEmpty={Boolean(error || (!loading && empty))}
          hasMore={Boolean(error || loading ? false : hasMore)}
          loadMore={onLoadMore}
        >
          {children}
        </InfiniteList>
      )}
      {scroll === "incremental" && (
        <>
          {children}
          {hasMore && (
            <div className={classes.loadMoreContainer}>
              <Button
                variant="outlined"
                size="small"
                disabled={loadingMore}
                onClick={onLoadMore}
              >
                Load More
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CollectionComponent;
