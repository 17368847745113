export const createFilter = (canvasImage, getData) => {
  const canvas = document.createElement("canvas");
  const canvasContext = canvas.getContext("2d");
  // Maps size
  canvas.width = canvasImage.width;
  canvas.height = canvasImage.height;
  // Draw image
  canvasContext.drawImage(canvasImage, 0, 0);
  let imageData = canvasContext.getImageData(0, 0, canvas.width, canvas.height);
  imageData = getData(imageData);
  canvasContext.putImageData(imageData, 0, 0);
  return canvas;
};
