// Helpers
import { join, filter, forEach, get, set, toLower } from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";

export class UI extends StackDependency {
  #database;
  #cache;
  #log;
  #uid;
  #subscribers = [];
  #ui = {};

  onInitialized() {
    const { firebase, cache, log } = this.context;
    this.#database = firebase.database();
    this.#cache = cache;
    this.#log = log;
    // Take cached data first.
    // Whenever we get the user UID we trigger the sync function and read remote data.
    this.#ui = this.#readLocal();
  }

  /**
   * Subscribe to change.
   * Returns unsubscribe function you need to call in order to
   * stop the event observation in e.g. `useEffect` function.
   * @param callback {function} Callback called when state changes
   * @param callOnBind {boolean} If `true` the callback is called right after the callback is bound.
   * @return {function}
   */
  onChange(callback, callOnBind = false) {
    this.#subscribers.push(callback);
    if (callOnBind) {
      callback(this);
    }
    return () => {
      this.#subscribers = filter(this.#subscribers, (s) => s !== callback);
    };
  }

  /**
   * Call to notify all subscribers about the state change
   */
  #handleChange = () => {
    forEach(this.#subscribers, (callback) => callback(this));
  };

  /**
   * Synchronizes remote data with local storage
   * @param uid {string} Current user UID
   */
  async sync(uid) {
    try {
      this.#uid = uid;
      const data = await this.#readRemote();
      this.#ui = data;
      this.#storeLocal(data);
      this.#handleChange();
      this.#log.info("📓", data);
    } catch (error) {
      this.#log.error(error);
    }
  }

  /**
   * Returns property value
   * @param property {string} Property key
   * @param defaultValue {any} Default value returned when no property found
   * @param app {string} App identifier
   * @return {string|number|boolean|null}
   */
  get(property, defaultValue = null, { app = this.#app() } = {}) {
    return get(this.#ui, [app, property], defaultValue);
  }

  /**
   * Sets property
   * @param property {string} Property key
   * @param value {string|number|boolean|null} Property value
   * @param app {string} Optional app identifier. If not set, current app is used.
   */
  set(property, value, { app = this.#app() } = {}) {
    // Set only when the property has different value
    if (get(this.#ui, [app, property]) !== value) {
      set(this.#ui, [app, property], value);
      // Store locally
      this.#storeLocal(this.#ui);
      this.#handleChange();
      // Store remotely
      this.#storeRemote(app, property, value);
      this.#log.info("📓", { app, property, value });
    }
  }

  /**
   * Returns all properties that belong to the given app
   * @param app {string} App identifier
   * @return {object}
   */
  getProperties(app) {
    return this.#ui[app] ?? {};
  }

  /**
   * Returns current app id.
   * @returns {string|null}
   */
  #app = () => {
    const { apps } = this.context;
    const id = apps.current?.id;
    return id ? toLower(id) : null;
  };

  /**
   * Reads data from local storage
   * @return {object}
   */
  #readLocal = () => {
    return this.#cache.retrieve("UI", { namespace: "User" }) ?? {};
  };

  /**
   * Stores data in local storage
   * @param data {object}
   */
  #storeLocal = (data) => {
    this.#cache.store("UI", data, { namespace: "User" });
  };

  /**
   * Reads data from remote storage
   * @return {object}
   */
  #readRemote = async () => {
    const path = join(["ui", this.#uid], "/");
    const snapshot = await this.#database.ref(path).once("value");
    return snapshot.val() ?? {};
  };

  /**
   * Stores data in remote storage
   * @param app {string} App identifier
   * @param property {string} Property key
   * @param value {string|number} Property value
   */
  #storeRemote = (app, property, value) => {
    const path = join(["ui", this.#uid, app, property], "/");
    this.#database.ref(path).set(value, (error) => {
      if (error) {
        this.#log.error(error);
      }
    });
  };
}
