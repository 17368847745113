// React
import React, { useContext } from "react";
import PropTypes from "prop-types";
// Framework
import { useApp } from "stack/core";
import { useStackDependency } from "stack/dependency";
// Components
import { ModelContext } from "./ModelContext";
import { ApolloProvider } from "@apollo/client";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({ children }) => {
  // Framework
  const { model } = useApp();
  // Context
  const options = useContext(ModelContext);
  // Init
  useStackDependency(model, options);
  // Render
  return <ApolloProvider client={model.client}>{children}</ApolloProvider>;
};

const ModelProvider = ({ options, children }) => (
  <ModelContext.Provider value={{}}>
    {options}
    <Component>{children}</Component>
  </ModelContext.Provider>
);

ModelProvider.propTypes = {
  options: PropTypes.element,
};

export { ModelProvider };
