// React
import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";
// Framework
import { useTranslate } from "localization";
import { EntityPropType } from "model/utils";
import { FormLayout, FormItem, FormImageField, Schema } from "form";
// Components
import ModelDialog from "model/components/ModelDialog";
import ModelDialogActionUpdate from "model/components/ModelDialogActionUpdate";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelProfileImageDialog = forwardRef(
  ({ entity, titleName = "name", fileName = "coverImage" }, ref) => {
    // Framework
    const { t } = useTranslate();
    // Render
    return (
      <ModelDialog
        ref={ref}
        title={t("core:model.profileImage.title")}
        entity={entity}
        schema={{
          [fileName]: Schema.string().required(),
        }}
      >
        <ModelDialogActionUpdate>
          <FormLayout>
            <FormItem>
              <FormImageField
                name={fileName}
                label={t("core:model.profileImage.form.label")}
                display="circle"
                maxWidth={260}
                maxHeight={260}
                minWidth={260}
                minHeight={260}
                dropzoneWidth={260}
                dropzoneHeight={260}
                aspect={1}
              />
            </FormItem>
          </FormLayout>
        </ModelDialogActionUpdate>
      </ModelDialog>
    );
  }
);

ModelProfileImageDialog.propTypes = {
  entity: EntityPropType,
  titleName: PropTypes.string,
  fileName: PropTypes.string,
};

export default memo(ModelProfileImageDialog);
