// React
import React from "react";
// Framework
import { Google as Icon } from "icon/social";
// Components
import ProviderButton from "../ProviderButton";

const GoogleButton = (props) => (
  <ProviderButton
    name="Google"
    color="#cb3f22"
    icon={<Icon fontSize="inherit" />}
    {...props}
  />
);

export default GoogleButton;
