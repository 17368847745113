// React
import React, { useCallback } from "react";
import PropTypes from "prop-types";
// Helpers
import { replace } from "@mefisto/utils";
// Framework
import { makeStyles, classnames, Typography } from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: theme.radius.small,
    userSelect: "none",
    overflow: "hidden",
    transition: theme.transitions.create("background"),
    background: theme.palette.grey[100],
    "&:hover": {
      background: theme.palette.grey[200],
    },
  },
  rootCompact: {
    width: 44,
    borderRadius: "50%",
  },
  text: {
    marginLeft: theme.spacing(2),
    lineHeight: 1.2,
  },
  icon: {
    display: "flex",
    padding: theme.spacing(1.5),
    fontSize: 20,
    color: theme.palette.common.white,
    background: theme.palette.grey[300],
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ProviderButton = ({
  display = "full",
  providerId,
  name,
  text = "{name}",
  icon,
  color,
  email,
  disabled,
  onSelection,
}) => {
  // Styles
  const classes = useStyles();
  // Handlers
  const handleSelection = useCallback(() => {
    onSelection({ providerId, name, color, disabled });
  }, [onSelection, providerId, name, color, disabled]);
  // Render
  return (
    <div
      className={classnames(classes.root, {
        [classes.rootCompact]: display === "compact",
      })}
      onClick={handleSelection}
    >
      <div
        className={classes.icon}
        style={{ backgroundColor: !disabled && color }}
      >
        {icon}
      </div>
      {display === "full" && (
        <div>
          <Typography
            component="div"
            color={disabled ? "textSecondary" : "textPrimary"}
            variant="subtitle2"
            className={classes.text}
          >
            {replace(text, "{name}", name)}
          </Typography>
          {email && (
            <Typography
              component="div"
              color={disabled ? "textSecondary" : "textPrimary"}
              variant="caption"
              className={classes.text}
            >
              {email}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

ProviderButton.propTypes = {
  display: PropTypes.oneOf(["full", "compact"]),
  providerId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  color: PropTypes.string.isRequired,
  email: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  onSelection: PropTypes.func.isRequired,
};

export default ProviderButton;
