// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { useHelmet } from "helmet";
import { Typography, Tooltip } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderTitle = ({ size = "medium" }) => {
  // Framework
  const { title } = useHelmet();
  // Render
  return (
    <>
      {title && (
        <Tooltip title={title}>
          <Typography noWrap variant={size === "small" ? "h6" : "h5"}>
            {title}
          </Typography>
        </Tooltip>
      )}
    </>
  );
};

HeaderTitle.propTypes = {
  size: PropTypes.oneOf(["small", "medium"]),
};

export default HeaderTitle;
