// React
import React, { useState, useCallback, useRef } from "react";
// Crop
import ReactCrop from "../../../../../ImageCrop";

const getWidth = (image, aspect) => {
  if (!aspect) {
    return image.width;
  }
  return image.width / aspect < image.height * aspect
    ? image.width
    : image.height * aspect;
};

const getHeight = (image, aspect) => {
  if (!aspect) {
    return image.height;
  }
  return image.width / aspect > image.height * aspect
    ? image.height
    : image.width / aspect;
};

const getX = (image, aspect, width) => {
  return aspect ? (image.width - width) / 2 : 0;
};

const getY = (image, aspect, height) => {
  return aspect ? (image.height - height) / 2 : 0;
};

const Crop = ({ aspect, onCrop, ...rest }) => {
  // State
  const [crop, setCrop] = useState({
    aspect,
    x: 0,
    y: 0,
  });
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  // Ref
  const imageRef = useRef(null);
  // Callbacks
  const centerCrop = useCallback(
    (image) => {
      const width = getWidth(image, aspect);
      const height = getHeight(image, aspect);
      const x = getX(image, aspect, width);
      const y = getY(image, aspect, height);
      return {
        unit: "px",
        width,
        height,
        x,
        y,
        aspect,
      };
    },
    [aspect]
  );
  const hasDifferentDimensions = useCallback(
    (image) => {
      return image.width !== width || image.height !== height;
    },
    [width, height]
  );
  // Handlers
  const handleChange = (crop) => {
    const image = imageRef.current;
    if (image) {
      // Stop on Y axis overflow
      if (crop.y + crop.height >= image.height) {
        setCrop({ ...crop, y: image.height - crop.height });
      } else {
        setCrop(crop);
      }
    }
  };
  const handleImageLoaded = (loadedImage) => {
    imageRef.current = loadedImage;
    // Timeout is here because of Safari. Sometimes the resulting
    // image has wrong dimensions (full container width and height) and
    // sometimes it doesn't. It's possible that max-height: fill-available property
    // is calculated after the image is loaded so we get the correct dimensions a bit later.
    setTimeout(() => {
      const newCrop = hasDifferentDimensions(loadedImage)
        ? centerCrop(loadedImage)
        : crop;
      setCrop(newCrop);
      setWidth(loadedImage.width);
      setHeight(loadedImage.height);
      onCrop(newCrop, loadedImage);
    }, 100);
    // Return `false` because we set the crop already
    return false;
  };
  const handleComplete = (crop) => {
    onCrop(crop, imageRef.current);
  };
  // Render
  return (
    <ReactCrop
      crop={crop}
      onChange={handleChange}
      onComplete={handleComplete}
      onImageLoaded={handleImageLoaded}
      {...rest}
    />
  );
};

export default Crop;
