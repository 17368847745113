// React
import React, { useMemo } from "react";
// Framework
import { useTranslate, useUser, colors } from "@mefisto/web";
// Icons
import {
  SubscriptionIcon,
  MacrosIcon,
  MealPlanIcon,
  CheckmarkIcon,
} from "icons";
// View Model
import { useSubscribed } from "@plugins/billing";

export const useOnboarded = () => {
  // Framework
  const {
    user: {
      data: { metadata },
    },
  } = useUser();
  const { onboarded, macros = false, mealPlan = false } = metadata ?? {};
  const { subscribed } = useSubscribed();
  // Render
  return {
    // User hasn't started onboarding yet
    starting: !macros && !mealPlan && !subscribed,
    // User is fully onboarded
    onboarded: onboarded ?? (macros && mealPlan && subscribed),
    // User onboarded macros
    macros,
    // User onboarded meal plan
    mealPlan,
    // User onboarded subscription
    subscribed,
  };
};

export const useOnboardingSteps = () => {
  // Framework
  const { t } = useTranslate();
  // View Model
  const { subscribed } = useSubscribed();
  const { macros, mealPlan } = useOnboarded();
  // Memo
  const onboardingSteps = useMemo(() => {
    return [
      {
        title: t(
          subscribed
            ? "portal:viewModel.onboarding.step.subscription.title.done"
            : "portal:viewModel.onboarding.step.subscription.title"
        ),
        description:
          !subscribed &&
          t("portal:viewModel.onboarding.step.subscription.description"),
        icon: subscribed ? <CheckmarkIcon /> : <SubscriptionIcon />,
        color: subscribed ? colors.grey[200] : "#ef612e",
      },
      {
        title: t(
          macros
            ? "portal:viewModel.onboarding.step.macros.title.done"
            : "portal:viewModel.onboarding.step.macros.title"
        ),
        description:
          !macros && t("portal:viewModel.onboarding.step.macros.description"),
        icon: macros ? <CheckmarkIcon /> : <MacrosIcon />,
        color: macros ? colors.grey[200] : "#f8c013",
      },
      {
        title: t(
          mealPlan
            ? "portal:viewModel.onboarding.step.mealPlan.title.done"
            : "portal:viewModel.onboarding.step.mealPlan.title"
        ),
        description:
          !mealPlan &&
          t("portal:viewModel.onboarding.step.mealPlan.description"),
        icon: mealPlan ? <CheckmarkIcon /> : <MealPlanIcon />,
        color: mealPlan ? colors.grey[200] : "#2bb673",
      },
    ];
  }, [t, macros, mealPlan, subscribed]);
  // Render
  return { onboardingSteps };
};
