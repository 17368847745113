// React
import React, { useEffect, useState, useCallback } from "react";
// Framework
import { useMounted } from "hooks";
import { useTranslate } from "localization";
import { usePortal } from "stack/core";
import { makeStyles, Grid, Typography } from "ui";
import { OfflineBoltRounded } from "icon/material";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    height: "100%",
  },
  icon: {
    fontSize: 80,
    color: theme.palette.text.secondary,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const OfflineContent = ({ children }) => {
  // Styles
  const classes = useStyles();
  // Framework
  const { t } = useTranslate();
  const { navigation } = usePortal();
  const { callWhenMounted } = useMounted();
  // Callbacks
  const getStatus = useCallback(() => {
    return "onLine" in navigator ? navigator.onLine : true;
  }, []);
  // State
  const [online, setOnline] = useState(getStatus());
  // Handlers
  const handleOnline = useCallback(() => {
    setOnline(true);
  }, []);
  // Effects
  useEffect(() => {
    return navigation.onChange(
      callWhenMounted(() => {
        const online = getStatus();
        setOnline(online);
      }),
      true
    );
  }, [callWhenMounted, navigation, getStatus]);
  useEffect(() => {
    const event = "online";
    window.addEventListener(event, handleOnline);
    return () => window.removeEventListener(event, handleOnline);
  }, [handleOnline]);
  // Render
  return online ? (
    children
  ) : (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        <OfflineBoltRounded className={classes.icon} />
      </Grid>
      <Grid item>
        <Typography variant="h6">
          {t("core:layout.offlineContent.title")}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" color="textSecondary">
          {t("core:layout.offlineContent.subtitle")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OfflineContent;
