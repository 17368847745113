import { useContext } from "react";
import { PortalContext } from "./PortalContext";

/**
 * @return {{
     activity: import("../../../activity/core").Activity,
     agent: import("../../../agent/core").Agent,
     analytics: import("../../../analytics/core").Analytics,
     apps: import("../../../apps/core").Apps,
     authentication: import("../../../authentication/core").Authentication,
     authorization: import("../../../authorization/core").Authorization,
     cache: import("../../../cache/core").Cache,
     config: import("../../../config/core").Config,
     externals: import("../../../externals/core").Externals,
     firebase: import("../../../firebase/core").Firebase,
     foggle: import("../../../foggle/core").Foggle,
     format: import("../../../format/core").Format,
     helmet: import("../../../helmet/core").Helmet,
     interceptor: import("../../../interceptor/core").Interceptor,
     localization: import("../../../localization/core").Localization,
     log: import("../../../log/core").Log,
     messaging: import("../../../messaging/core").Messaging,
     navigation: import("../../../navigation/core").Navigation,
     performance: import("../../../performance/core").Performance,
     permission: import("../../../permission/core").Permission,
     pwa: import("../../../pwa/core").PWA,
     resource: import("../../../resource/core").Resource,
     serviceWorker: import("../../../sw/core").ServiceWorker,
     snackbar: import("../../../snackbar/core").Snackbar,
     storage: import("../../../storage/core").Storage,
     theme: import("../../../theme/core").Theme,
     time: import("../../../time/core").Time,
     ui: import("../../../ui/core").UI,
     uploadQueue: import("../../../uploadQueue/core").UploadQueue,
 * }}
 */
export const usePortal = () => {
  return useContext(PortalContext);
};
