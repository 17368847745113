// React
import { useEffect, useState } from "react";
// Framework
import { useMounted } from "hooks";
import { usePortal } from "stack/core";

export const useLocation = () => {
  // Framework
  const { callWhenMounted } = useMounted();
  const { navigation } = usePortal();
  // State
  const [location, setLocation] = useState(navigation.location);
  // Effects
  useEffect(() => {
    return navigation.onChange(
      callWhenMounted((location) => {
        setLocation(location);
      })
    );
  }, [callWhenMounted, navigation]);
  // Render
  return { ...location };
};
