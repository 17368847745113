// Helpers
import { compact, map } from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";

export class Firebase extends StackDependency {
  #app;

  /**
   * Loads modules and initializes Firebase App
   */
  async initialize() {
    const { config } = this.context;
    const { modules } = this.options;
    // Import main app
    const firebase = (await import("firebase/compat/app")).default;
    // Import other modules in parallel
    await Promise.all(
      compact(
        map(modules, (module) => {
          switch (module) {
            case "analytics":
              return import("firebase/compat/analytics");
            case "auth":
              return import("firebase/compat/auth");
            case "database":
              return import("firebase/compat/database");
            case "firestore":
              return import("firebase/compat/firestore");
            case "functions":
              return import("firebase/compat/functions");
            case "messaging":
              return import("firebase/compat/messaging");
            case "performance":
              return import("firebase/compat/performance");
            case "remoteConfig":
              return import("firebase/compat/remote-config");
            case "storage":
              return import("firebase/compat/storage");
            default:
              return false;
          }
        })
      )
    );
    firebase.initializeApp({
      appId: config.values.firebaseAppId,
      apiKey: config.values.firebaseApiKey,
      authDomain: config.values.firebaseAuthDomain,
      databaseURL: config.values.firebaseDatabaseUrl,
      measurementId: config.values.firebaseMeasurementId,
      projectId: config.values.firebaseProjectId,
      storageBucket: config.values.firebaseStorageBucket,
      messagingSenderId: config.values.firebaseMessagingSenderId,
      emulator: config.values.emulator,
    });
    this.#app = firebase;
  }

  get analytics() {
    return this.#app.analytics;
  }

  get auth() {
    return this.#app.auth;
  }

  get database() {
    return this.#app.database;
  }

  get firestore() {
    return this.#app.firestore;
  }

  get functions() {
    return this.#app.functions;
  }

  get messaging() {
    return this.#app.messaging;
  }

  get performance() {
    return this.#app.performance;
  }

  get remoteConfig() {
    return this.#app.remoteConfig;
  }

  get storage() {
    return this.#app.storage;
  }
}
