// Helpers
import { isEqual, find, isNil, toNumber, startsWith } from "@mefisto/utils";
// Components
import { AuthState } from "./AuthState";
import { ProviderId } from "./Provider";

class CurrentUser {
  #auth;
  #database;
  #cache;
  #log;
  #time;
  #timer;
  #callback;
  #entity = {
    state: AuthState.loading,
    data: null,
  };

  constructor(firebase, log, cache, time, callback) {
    this.#auth = firebase.auth();
    this.#database = firebase.database();
    this.#log = log;
    this.#cache = cache;
    this.#time = time;
    this.#callback = callback;
    this.#syncAuth();
  }

  /**
   * Returns current user state
   */
  get state() {
    return this.#entity.state;
  }

  /**
   * Updates user auth data
   */
  updateAuthData(user) {
    return this.#updateData({ authData: user });
  }

  /**
   * Updates user cached data
   */
  updateCachedData(user) {
    return this.#updateData({ cachedData: user });
  }

  /**
   * Stops the user data sync
   */
  unsync() {
    // Clear user in logging
    this.#log.setUser(null);
    // Stop sync
    this.#unsyncDatabase();
  }

  /**
   * Returns user password provider
   */
  get passwordProvider() {
    const { providerData } = this.#entity.data || {};
    return find(
      providerData,
      ({ providerId }) => providerId === ProviderId.Password
    );
  }

  /**
   * Returns current user data in JSON
   */
  toJSON() {
    return {
      state: this.#entity.state,
      data:
        this.#entity.state === AuthState.authenticated
          ? {
              ...this.#entity.data,
              hasPassword: !isNil(this.passwordProvider),
            }
          : {},
    };
  }

  ////////////////////////////////////////////////////////////////////
  /// Data Handling
  ////////////////////////////////////////////////////////////////////

  /**
   * Maps user data from firebase auth
   * @private
   */
  static #makeAuthData(user) {
    if (user) {
      const {
        uid,
        displayName: name,
        createdAt,
        email,
        emailVerified,
        isAnonymous,
        lastLoginAt,
        multiFactor,
        phoneNumber,
        providerData,
        redirectEventId,
      } = user.toJSON();
      return {
        uid,
        name,
        createdAt: toNumber(createdAt),
        email,
        emailVerified,
        phoneNumber,
        isAnonymous,
        lastLoginAt: toNumber(lastLoginAt),
        multiFactor,
        providerData,
        redirectEventId,
      };
    }
  }

  /**
   * Maps user data from firebase database
   * @private
   */
  static #makeDatabaseData(user) {
    if (user && user.exists()) {
      const {
        email,
        isAdmin,
        name,
        profileImage,
        disabled = false,
        metadata,
      } = user.val();
      return {
        email,
        isAdmin,
        name,
        profileImage,
        disabled,
        metadata,
      };
    }
  }

  /**
   * Sets one time timer which reloads data after the verification deadline is pass
   * @private
   */
  #setTimer(verificationDeadline, now) {
    if (!this.#timer && verificationDeadline && now < verificationDeadline) {
      // Add some extra time, because the past time might not be exact
      const extraTime = 10000;
      this.#timer = setTimeout(async () => {
        // Reload whenever the verification deadline expires
        if (!this.#entity.data.emailVerified) {
          await this.#auth.currentUser.reload();
          this.updateAuthData(this.#auth.currentUser);
        }
        this.#timer = null;
      }, verificationDeadline - now + extraTime);
    }
  }

  /**
   * Merges inner data with the given payload
   */
  #updateData(data = {}) {
    const tokenData = data.tokenData ?? {};
    const cachedData = data.cachedData ?? {};
    const authData = data.authData
      ? CurrentUser.#makeAuthData(data.authData)
      : {};
    const databaseData = data.databaseData
      ? CurrentUser.#makeDatabaseData(data.databaseData)
      : {};
    const result = {
      ...this.#entity.data,
      ...cachedData,
      ...authData,
      ...databaseData,
      ...tokenData,
    };
    const now = this.#time.toUtcTimestamp(this.#time.now());
    const { verificationDeadline = 0 } = result.claims ?? {};
    const { emailVerified } = result;
    const beforeVerificationDeadline = verificationDeadline > now;
    const partialVerification = !emailVerified && beforeVerificationDeadline;
    // Merge new data with the old state
    let entity = {
      ...this.#entity,
      ...(data.state ? { state: data.state } : {}),
      data: {
        ...result,
        partialVerification,
        isVerified: emailVerified || partialVerification,
      },
    };
    // Set timer which will reload after the possible verification deadline is past
    this.#setTimer(verificationDeadline, now);
    // When updating profile image from database data, the URL is not set.
    // That's a values set via `cachedData`. Thus merge the values but only
    // when the path are equal (otherwise the profile image is actually different)
    // TODO: https://github.com/mefisto-io/mefisto/issues/201
    if (
      entity.data?.profileImage &&
      this.#entity.data?.profileImage?.path ===
        databaseData?.profileImage?.path &&
      !startsWith(entity.data?.profileImage?.url, "blob")
    ) {
      entity.data.profileImage.url = this.#entity.data?.profileImage?.url;
    }
    if (!isEqual(this.#entity, entity)) {
      this.#entity = entity;
      const user = this.toJSON();
      this.#log.info("👨", user);
      if (user.state === AuthState.authenticated) {
        this.#cache.store("CurrentUser", user.data, { namespace: "User" });
      } else if (user.state === AuthState.notAuthenticated) {
        this.#cache.clean({ namespace: "User" });
      }
      this.#callback(user, data.error);
      return user;
    }
    return this.toJSON();
  }

  /**
   * Starts sync with auth
   * @private
   */
  #syncAuth() {
    this.#auth.onAuthStateChanged(async (user, error) => {
      if (error) {
        this.#updateData({ error });
      } else if (user) {
        // Store the user for logging
        this.#log.setUser(user.uid);
        // Get data from cache. This will speed up the rendering.
        // However, if the user is no longer authenticated it's fine
        // because we sync the auth anyway.
        const cachedData = this.#cache.retrieve("CurrentUser", {
          namespace: "User",
        });
        const tokenData = await this.#auth.currentUser.getIdTokenResult(false);
        if (cachedData) {
          this.#updateData({
            tokenData,
            cachedData,
            authData: user,
            state: AuthState.authenticated,
          });
        } else {
          this.#updateData({ tokenData, authData: user });
        }
        // Start database sync
        this.#syncDatabase(user.uid);
      } else {
        this.#updateData({ state: AuthState.notAuthenticated });
      }
    });
  }

  /**
   * Starts sync with database
   * @private
   */
  #syncDatabase(uid) {
    this.#unsyncDatabase();
    // Listen for changes
    this.userRef = this.#database.ref(`users/${uid}`);
    // Start listening for user changes
    this.userCallback = this.userRef.on("value", (user) => {
      this.#updateData({
        state: user.exists()
          ? AuthState.authenticated
          : AuthState.notAuthenticated,
        databaseData: user,
      });
    });
  }

  /**
   * Call to unsubscribe from the database sync
   * @private
   */
  #unsyncDatabase() {
    if (this.userRef && this.userCallback) {
      this.userRef.off("value", this.userCallback);
    }
  }
}

export { CurrentUser };
