// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { Typography, Grid, DialogTitle, TooltipButton } from "ui";
import { Close as CloseIcon } from "icon/material";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelDialogTitle = ({ title, disableClose, onClose }) => (
  <DialogTitle disableTypography>
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item xs={12}>
        {title && <Typography variant="h6">{title}</Typography>}
      </Grid>
      <Grid item>
        {!disableClose && (
          <TooltipButton edge="end" value="close" onClick={onClose}>
            <CloseIcon color="action" />
          </TooltipButton>
        )}
      </Grid>
    </Grid>
  </DialogTitle>
);

ModelDialogTitle.propTypes = {
  title: PropTypes.string,
  disableClose: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ModelDialogTitle;
