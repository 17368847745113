// React
import React, { useContext } from "react";
import PropTypes from "prop-types";
// Framework
import { useApp } from "stack/core";
import { useStackDependency } from "stack/dependency";
// Components
import { PluginContext } from "./PluginContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({ children }) => {
  // Framework
  const { plugin } = useApp();
  // Context
  const options = useContext(PluginContext);
  // Init
  useStackDependency(plugin, options);
  // Render
  return <>{children}</>;
};

const PluginProvider = ({ options, children }) => (
  <PluginContext.Provider value={{ plugins: {} }}>
    {options}
    <Component>{children}</Component>
  </PluginContext.Provider>
);

PluginProvider.propTypes = {
  options: PropTypes.arrayOf(PropTypes.node),
};

export { PluginProvider };
