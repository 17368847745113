// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { isNil } from "@mefisto/utils";
// Framework
import { usePortal } from "stack";
import { Typography, DelayedTooltip } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Timestamp = ({
  clockTime,
  display = "initial",
  noWrap,
  noTooltip,
  color = "textPrimary",
  component = "span",
  variant = "inherit",
  format = "lll",
  tooltipFormat = "LLL (z)",
  children,
}) => {
  // Framework
  const { time } = usePortal();
  if (isNil(children)) {
    return null;
  }
  const date = time.fromUtcTimestamp(children, { clockTime });
  return (
    <Typography
      component={component}
      noWrap={noWrap}
      display={display}
      variant={variant}
      color={color}
    >
      <DelayedTooltip title={noTooltip ? "" : date.format(tooltipFormat)}>
        <span>{date.format(format)}</span>
      </DelayedTooltip>
    </Typography>
  );
};

Timestamp.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  clockTime: PropTypes.bool,
  display: PropTypes.string,
  noWrap: PropTypes.bool,
  noTooltip: PropTypes.bool,
  variant: PropTypes.string,
  format: PropTypes.string,
  tooltipFormat: PropTypes.string,
};

export default Timestamp;
