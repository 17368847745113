// Helpers
import { get, isNil, omitBy } from "@mefisto/utils";
// Components
import { isFile } from "./isFile";

/**
 * Removes nil values and files from input.
 * Returns updated input object.
 * @param data {object}
 * @param input {object}
 * @return {object}
 */
export const getInput = (data, input) => {
  // Filter nil values
  const nonNilInput = omitBy(input, isNil);
  // Filter files
  const inputValues = omitBy(data, (value) => {
    const isFileEntity = get(value, "__typename") === "File";
    const isFileObject = isFile(value);
    return isFileObject || isFileEntity;
  });
  return { ...nonNilInput, ...inputValues };
};
