// React
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";
import { useMounted } from "hooks";
import { MuiThemeProvider, CssBaseline } from "ui/components";
// Components
import { ThemeContext } from "./ThemeContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({ children }) => {
  // Framework
  const { theme } = usePortal();
  const { callWhenMounted } = useMounted();
  // Context
  const options = useContext(ThemeContext);
  // Init
  useStackDependency(theme, options);
  // State
  const [currentTheme, currentCurrentTheme] = useState(theme.current);
  // Effect
  useEffect(() => {
    return theme.onChange(
      callWhenMounted((theme) => {
        currentCurrentTheme(theme.current);
      })
    );
  }, [callWhenMounted, theme]);
  // Render
  // noinspection JSValidateTypes
  return (
    <MuiThemeProvider theme={currentTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

const ThemeProvider = ({ options, children }) => (
  <ThemeContext.Provider value={{ themes: {} }}>
    {options}
    <Component>{children}</Component>
  </ThemeContext.Provider>
);

ThemeProvider.propTypes = {
  options: PropTypes.object,
};

export { ThemeProvider };
