import React from "react";
import { createSvgIcon } from "icon/utils";

export default createSvgIcon(
  <path
    fill="#546e7a"
    d="M8,5H4V4H8V5ZM4,8h7V7H4V8Zm0,2h7V9H4v1Zm0,2h7V11H4v1ZM14,4.5V14a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V2A1,1,0,0,1,3,1h7.5ZM13,5L10,2H3V14H13V5Z"
    transform="translate(0 -1)"
  />,
  "TextIcon",
  { viewBox: "-1 -2 18 18" }
);
