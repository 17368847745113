export const ProviderId = {
  Apple: "apple.com",
  Facebook: "facebook.com",
  Github: "github.com",
  Google: "google.com",
  Microsoft: "microsoft.com",
  Password: "password",
  Twitter: "twitter.com",
  Yahoo: "yahoo.com",
};

export const Providers = (firebase) => {
  const {
    OAuthProvider,
    FacebookAuthProvider,
    GithubAuthProvider,
    GoogleAuthProvider,
    EmailAuthProvider,
    TwitterAuthProvider,
  } = firebase.auth;
  return {
    [ProviderId.Apple]: {
      name: "Apple",
      provider: () => new OAuthProvider(ProviderId.Apple),
      credential: OAuthProvider.credential,
    },
    [ProviderId.Facebook]: {
      name: "Facebook",
      provider: () => new FacebookAuthProvider(),
      credential: FacebookAuthProvider.credential,
    },
    [ProviderId.Github]: {
      name: "Github",
      provider: () => new GithubAuthProvider(),
      credential: GithubAuthProvider.credential,
    },
    [ProviderId.Google]: {
      name: "Google",
      provider: () => new GoogleAuthProvider(),
      credential: GoogleAuthProvider.credential,
    },
    [ProviderId.Microsoft]: {
      name: "Microsoft",
      provider: () => new OAuthProvider(ProviderId.Microsoft),
      credential: OAuthProvider.credential,
    },
    [ProviderId.Password]: {
      name: "Password",
      provider: () => new EmailAuthProvider(),
      credential: EmailAuthProvider.credential,
    },
    [ProviderId.Twitter]: {
      name: "Twitter",
      provider: () => new TwitterAuthProvider(),
      credential: TwitterAuthProvider.credential,
    },
    [ProviderId.Yahoo]: {
      name: "Yahoo",
      provider: () => new OAuthProvider(ProviderId.Yahoo),
      credential: OAuthProvider.credential,
    },
  };
};
