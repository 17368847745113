// React
import React, { cloneElement, isValidElement } from "react";
import PropTypes from "prop-types";
// Framework
import {
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  DelayedTooltip,
} from "ui/components";
import { Section } from "ui/section";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  selectable: {
    ...theme.typography.subtitle2,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileInfoItem = ({
  context = "item",
  value,
  title,
  subtitle,
  icon,
  tooltip,
  dense,
  onClick,
}) => {
  // Style
  const classes = useStyles();
  // Render
  return (
    <Section context={context} value={value}>
      <ListItem dense={dense}>
        {icon && (
          <DelayedTooltip title={tooltip} placement="left">
            <ListItemIcon>
              {isValidElement(icon) &&
                cloneElement(icon, { fontSize: "small" })}
            </ListItemIcon>
          </DelayedTooltip>
        )}
        <ListItemText
          primary={title}
          secondary={subtitle}
          onClick={onClick}
          classes={{
            primary: onClick && classes.selectable,
          }}
        />
      </ListItem>
    </Section>
  );
};

TileInfoItem.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  icon: PropTypes.element,
  tooltip: PropTypes.string,
  dense: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TileInfoItem;
