export const paths = {
  // Auth
  action: "/action",
  forgottenPassword: "/forgotten-password",
  invitation: "/invitations/:invitation",
  signIn: "/sign-in",
  signUp: "/sign-up",
  verify: "/verify",
  // Portal
  connections: "/connections",
  discoveries: "/discovery",
  discovery: "/discovery/:type/:discovery",
  groups: "/groups",
  group: "/organizations/:organization/groups/:group",
  invitations: "/invitations",
  locations: "/locations",
  location: "/locations/:location",
  memberships: "/memberships",
  organizationGetStarted: "/organization/get-started",
  organizations: "/organizations",
  organization: "/organizations/:organization",
  personas: "/personas",
  persona: "/organizations/:organization/personas/:persona",
  placeholders: "/placeholders",
  profile: "/profile",
  requests: "/requests",
  roles: "/roles",
  role: "/roles/:role",
  users: "/users",
  user: "/users/:user",
};
