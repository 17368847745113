// React
import { useEffect, useState } from "react";
// Framework
import { usePortal } from "stack/core";
import { useMounted } from "hooks";

/**
 * Returns current resources. Rerenders on change.
 * @param force {boolean} - If set to `false` the resources are updated after the
          ResourceInterceptor lifecycle finishes. This is intentional because the resources may
          change multiple times until the proper resource combination is found.
          If the change is triggered during this process all the components using
          `useResource` hook will unnecessary rerender. However, force is needed to be set to `true`
          in the ResourceInterceptor because we need to listen to the resources changes.
          It is recommended to keep force set to `false` outside the ResourceInterceptor.
 */
export const useResources = ({ force = false } = {}) => {
  // Framework
  const { resource } = usePortal();
  const { callWhenMounted } = useMounted();
  // State
  const [resources, setResources] = useState(() => resource.resources);
  // Effects
  useEffect(() => {
    return resource.onChange(
      callWhenMounted(({ resources }) => {
        setResources(resources);
      }),
      false,
      force
    );
  }, [callWhenMounted, resource, force]);
  // Render
  return { resources };
};
