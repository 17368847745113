// React
import React, { useEffect, useState } from "react";
// Helpers
import { isNil } from "@mefisto/utils";
// Framework
import { ModalDialog } from "ui";
import { useTranslate } from "localization";
import { usePortal } from "stack";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const UploadError = () => {
  // Framework
  const { uploadQueue: queue } = usePortal();
  const { translate } = useTranslate();
  // State
  const [error, setError] = useState(null);
  // Effects
  useEffect(() => {
    const handleError = ({ message } = {}) => setError(message);
    const unsubscribe = queue.onError(handleError);
    return () => unsubscribe();
  });
  // Handlers
  const handleTryAgain = () => {
    queue.reload();
    setError(null);
  };
  const handleClose = () => {
    queue.clear();
    setError(null);
  };
  return (
    <ModalDialog
      open={!isNil(error)}
      title={translate("core:storage.uploadError.title")}
      actions={[
        {
          title: translate("core:storage.uploadError.actions.tryAgain"),
          onClick: handleTryAgain,
        },
      ]}
      onClose={handleClose}
    >
      {error}
    </ModalDialog>
  );
};

export default UploadError;
