// Helpers
import {
  reduce,
  join,
  isEmpty,
  startsWith,
  endsWith,
  trim,
  replace,
  isNumber,
} from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";
// Components
import prettyBytes from "pretty-bytes";

export class Format extends StackDependency {
  #countryMap;

  async load() {
    const { countryMap } = await import("./model/data");
    this.#countryMap = countryMap;
  }

  /**
   * Replaces content in `{}` with the given params
   * @param string
   * @param params
   * @returns {string}
   */
  #replace = (string = "", params = {}) => {
    return replace(
      string,
      /{(\w+)}/g,
      (key, placeholder) => params[placeholder] ?? ""
    );
  };

  /**
   * Returns list of countries and their format metadata
   * @returns {[]}
   */
  get countries() {
    return this.#countryMap;
  }

  /**
   * Formats address
   * @param address {object} Address object
   * @param inline {boolean} Set to `true` if the result should be a string
   * @return {[string]|string}
   */
  address(address, { inline } = {}) {
    const country = this.#countryMap[address.country];
    const result = reduce(
      country?.address?.format,
      (result, line) => {
        let replaced = trim(
          this.#replace(line, {
            ...address,
            // Amend country name (the address has only code)
            country: country?.label,
          })
        );
        if (startsWith(replaced, ",")) {
          replaced = replaced.substring(1);
        }
        if (endsWith(replaced, ",")) {
          replaced = replaced.slice(0, -1);
        }
        if (!isEmpty(replaced)) {
          result.push(replaced);
        }
        return result;
      },
      []
    );
    return inline ? join(result, ", ") : result;
  }

  /**
   * Formats price based on currency and locale.
   * @param value {number} Price to be formatted
   * @param currency {string} Price currency
   * @param options {object=} Pricing options
   * @returns {string}
   */
  price(value, currency, options) {
    if (isNumber(value) === false) {
      return "";
    }
    // First, get global locale, then locale for pricing, then locale
    // for the particular function call and default to localization locale.
    const locale =
      this.options.locale ??
      this.options.price?.locale ??
      options?.locale ??
      this.context.localization.locale;
    // Format using browser's Intl formatter
    const formatter = Intl.NumberFormat(locale, {
      ...this.options.price,
      ...options,
      style: "currency",
      currency,
    });
    return formatter.format(value / 100);
  }

  /**
   * Convert bytes to a human-readable string: 1337 → 1.34 kB
   *
   * Value that will be formatted in bits
   * @param value {number}
   * Include plus sign for positive numbers.
   * If the difference is exactly zero a space character will be prepended instead for better alignment.
   * @param signed {boolean}
   * - If `false`: Output won't be localized.
   * - If `true`: Localize the output using the system/browser locale.
   * - If `string`: Expects a [BCP 47 language tag](https://en.wikipedia.org/wiki/IETF_language_tag) (For example: `en`, `de`, …)
   * - If `string[]`: Expects a list of [BCP 47 language tags](https://en.wikipedia.org/wiki/IETF_language_tag) (For example: `en`, `de`, …)
   * @param locale {string}
   * Format the number as [bits](https://en.wikipedia.org/wiki/Bit)
   * instead of [bytes](https://en.wikipedia.org/wiki/Byte). This can be useful when,
   * for example, referring to [bit rate](https://en.wikipedia.org/wiki/Bit_rate).
   * @param bits {boolean}
   * Format the number using the Binary Prefix instead of the SI Prefix.
   * This can be useful for presenting memory amounts. However, this should not be used for presenting file sizes.
   * @param binary
   * The minimum number of fraction digits to display.
   * @param minimumFractionDigits
   * The minimum number of fraction digits to display.
   * @param maximumFractionDigits
   * Put a space between the number and unit.
   * @param space
   */
  bytes(
    value,
    {
      signed = false,
      locale = this.options.locale,
      bits = false,
      binary = false,
      minimumFractionDigits = undefined,
      maximumFractionDigits = undefined,
      space = true,
    } = {}
  ) {
    return prettyBytes(value, {
      signed,
      locale,
      bits,
      binary,
      minimumFractionDigits,
      maximumFractionDigits,
      space,
    });
  }
}
