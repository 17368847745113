// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, Tooltip } from "ui";
import { useTheme } from "theme";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles({
  popper: {
    maxWidth: 200,
    whiteSpace: "noWrap",
  },
  tooltip: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const AvatarTooltip = ({ title = "", placement = "right", children }) => {
  // Styles
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Tooltip
      title={title || ""}
      placement={placement}
      enterDelay={theme.transitions.duration.standard}
      classes={{ tooltip: classes.tooltip, popper: classes.popper }}
    >
      <div>{children}</div>
    </Tooltip>
  );
};

AvatarTooltip.propTypes = {
  title: PropTypes.string,
  placement: PropTypes.oneOf(["right", "bottom", "left", "top"]),
  delay: PropTypes.number,
};

export default AvatarTooltip;
