// React
import React from "react";
// Framework
import { forwardType, App } from "@mefisto/web";
// Loader
import { Loader } from "loader";
// Navigation
import { paths as portalPaths } from "@plugins/portal";

export default forwardType(App, () => (
  <App
    enabled
    name="AUTH"
    type="NATIVE"
    title="Auth"
    priority={1}
    loader={<Loader />}
    navigation={{
      basename: "auth",
      routes: {
        default: "/sign-in",
      },
      paths: {
        ...portalPaths,
      },
    }}
  />
));
