// React
import React, { cloneElement, useCallback } from "react";
import PropTypes from "prop-types";
// Framework
import { MenuItem, ListItemIcon, ListItemText } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const CoverTabsOption = ({
  context,
  value,
  title,
  subtitle,
  icon,
  color,
  onSelection,
  onClose,
}) => {
  // Handlers
  const handleClick = useCallback(() => {
    onClose && onClose();
    onSelection && onSelection();
  }, [onClose, onSelection]);
  // Render
  return (
    <MenuItem dense context={context} value={value} onClick={handleClick}>
      {icon && (
        <ListItemIcon>
          {cloneElement(icon, { fontSize: "small", color })}
        </ListItemIcon>
      )}
      <ListItemText
        primary={title}
        secondary={subtitle}
        color={color}
        primaryTypographyProps={{ color }}
        secondaryTypographyProps={{ color }}
      />
    </MenuItem>
  );
};

CoverTabsOption.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.element,
  onSelection: PropTypes.func,
  onClose: PropTypes.func,
};

export default CoverTabsOption;
