// React
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
// Framework
import { UserInteraction } from "analytics";
import { Section } from "ui/section";
import { MenuItem as Component } from "@material-ui/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const MenuItem = forwardRef(
  ({ context = "item", value, children, ...props }, ref) => (
    <UserInteraction
      ref={ref}
      element="button"
      value={value}
      component={Component}
      componentProps={props}
    >
      <Section context={context} value={value}>
        {children}
      </Section>
    </UserInteraction>
  )
);

MenuItem.propTypes = {
  /**
   * Section context name
   */
  context: PropTypes.string,
  /**
   * Element specification value
   */
  value: PropTypes.string,
  /**
   * The component used for the root node.
   * Either a string to use an HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, compact vertical padding designed for keyboard and mouse input will be used.
   */
  dense: PropTypes.bool,
  /**
   * If `true`, the left and right padding is removed.
   */
  disableGutters: PropTypes.bool,
  /**
   * The content of the menu item.
   */
  children: PropTypes.node,
};

export default MenuItem;
