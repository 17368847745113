// React
import React from "react";
import PropTypes from "prop-types";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Externals = ({ children }) => {
  // Render
  return <>{children}</>;
};

Externals.defaultProps = {
  __TYPE: "Externals",
};

Externals.propTypes = {
  children: PropTypes.node,
};

export default Externals;
