// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { classnames } from "ui/classnames";
import { makeStyles, CardActions } from "ui/components";
import { Section } from "ui/section";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: ({ justify }) => ({
    justifyContent: justify,
  }),
  gutter: {
    padding: theme.spacing(3),
  },
  dense: {
    padding: theme.spacing(1),
  },
  disableGutter: {
    padding: 0,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileActions = ({
  context = "actions",
  value,
  justify = "flex-end",
  dense,
  disableGutter,
  className,
  children,
}) => {
  // Styles
  const classes = useStyles({ justify });
  // Render
  return (
    <Section context={context} value={value}>
      <CardActions
        className={classnames(classes.root, className, {
          [classes.dense]: dense,
          [classes.disableGutter]: disableGutter,
          [classes.gutter]: !dense && !disableGutter,
        })}
      >
        {children}
      </CardActions>
    </Section>
  );
};

TileActions.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  justify: PropTypes.string,
  dense: PropTypes.bool,
  disableGutter: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default TileActions;
