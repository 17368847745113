// React
import { useMemo, useCallback, useEffect, useRef } from "react";
// Helpers
import { has, includes, noop } from "@mefisto/utils";
// Framework
import {
  getData,
  NetworkStatus,
  useLazyQuery,
  useApolloClient,
  useReactiveVar,
} from "model/core";
import { usePortal } from "stack";
import { useResources } from "resource";
import { useDeepMemo } from "hooks";

/**
 * Performs list operation on an entity in a lazy mode.
 * In other words, you need to call the `fetch` function
 * to perform the action.
 * @param Entity
 * @param options {{input, resources, languages, search, relation, pagination, fetchPolicy, tags, disableAutoRefresh}}
 */
export const useEntityListLazy = (Entity, options = {}) => {
  // Props
  const {
    input,
    resources: customResources,
    languages,
    search,
    relation,
    pagination,
    tags,
    disableAutoRefresh,
    fetchPolicy = "cache-and-network",
    nextFetchPolicy = "cache-first",
  } = useDeepMemo(() => options, [options]);
  // Framework
  const { log, uploadQueue: queue } = usePortal();
  const client = useApolloClient();
  // Resources
  const { resources: defaultResources } = useResources();
  const resources = useMemo(() => {
    return customResources ?? defaultResources;
  }, [customResources, defaultResources]);
  // Refresh
  const refreshToken = useReactiveVar(Entity.refreshToken);
  const refreshCount = useRef(-1);
  // Memo
  const Tags = useMemo(() => {
    const { LIST, READ } = tags ?? Entity.Tags;
    if (!READ && relation) {
      throw TypeError(
        `[READ] tags must be defined in entity in ` +
          `order to use the component with "relation" argument.`
      );
    }
    if (!LIST && !relation) {
      throw TypeError(
        `[LIST] tags must be defined in entity in ` +
          `order to use the component.`
      );
    }
    return { LIST: relation ? READ : LIST };
  }, [Entity, tags, relation]);
  const entity = useMemo(() => {
    const entity = new Entity(client, { queue });
    if (relation && !has(entity.relations, relation)) {
      throw Error(`Entity doesn't have ${relation} relation`);
    }
    return entity;
  }, [Entity, relation, queue, client]);
  // Callback
  const getVariables = useCallback(
    ({ input, resources, languages, search, pagination } = {}) => ({
      ...(input && { input }),
      ...(resources && { resources }),
      ...(languages && { languages }),
      ...(search && { query: search, search }),
      ...(relation && { [relation]: true }),
      [relation ? `${relation}Pagination` : "pagination"]: pagination,
    }),
    [relation]
  );
  // Query
  const [fetch, query] = useLazyQuery(Tags.LIST, {
    fetchPolicy,
    nextFetchPolicy,
    notifyOnNetworkStatusChange: true,
  });
  const { called, error, fetchMore, refetch, networkStatus } = query;
  // Memo
  const data = useMemo(() => {
    const data = getData(query);
    if (relation) {
      const { type, plural } = entity.relations[relation];
      if (type === "1:N" || type === "M:N") {
        return data[plural || `${relation}s`] || {};
      }
    }
    return data;
  }, [query, relation, entity]);
  const loading = useMemo(() => {
    return includes(
      [
        NetworkStatus.setVariables,
        NetworkStatus.loading,
        NetworkStatus.refetch,
      ],
      networkStatus
    );
  }, [networkStatus]);
  const loadingMore = useMemo(() => {
    return networkStatus === NetworkStatus.fetchMore;
  }, [networkStatus]);
  // Callbacks
  const performFetch = useCallback(
    async (options = {}) => {
      const variables = getVariables({
        input: options.input ?? input,
        resources: options.resources ?? resources,
        languages: options.languages ?? languages,
        search: options.search ?? search,
        pagination: options.pagination ?? pagination,
      });
      log.info("⚪", "ListLazy:Fetch", { variables });
      try {
        const result = await fetch({ variables });
        return getData(result);
      } catch (error) {
        if (options.throwOnError) {
          throw error;
        }
      }
    },
    [log, fetch, getVariables, input, resources, languages, search, pagination]
  );
  const performFetchMore = useCallback(
    async (options = {}) => {
      if (!loadingMore) {
        const variables = getVariables({
          input: options.input ?? input,
          resources: options.resources ?? resources,
          languages: options.languages ?? languages,
          search: options.search ?? search,
          pagination: options.pagination ?? {
            ...pagination,
            pointer: data.pointer,
          },
        });
        log.info("⚪", "ListLazy:FetchMore", { variables });
        try {
          const result = await fetchMore({ variables });
          return getData(result);
        } catch (error) {
          if (options.throwOnError) {
            throw error;
          }
        }
      }
    },
    [
      log,
      getVariables,
      fetchMore,
      input,
      resources,
      languages,
      search,
      pagination,
      loadingMore,
      data.pointer,
    ]
  );
  const performRefetch = useCallback(
    async (options = {}) => {
      if (refetch) {
        const variables = getVariables({
          input: options.input ?? input,
          resources: options.resources ?? resources,
          languages: options.languages ?? languages,
          search: options.search ?? search,
          pagination: options.pagination ?? pagination,
        });
        log.info("⚪", "ListLazy:Refetch", { variables });
        try {
          const result = await refetch(variables);
          return getData(result);
        } catch (error) {
          if (options.throwOnError) {
            throw error;
          }
        }
      }
    },
    [
      log,
      getVariables,
      refetch,
      input,
      resources,
      languages,
      search,
      pagination,
    ]
  );
  // Effects
  useEffect(() => {
    if (!disableAutoRefresh) {
      refreshCount.current += 1;
    }
  }, [disableAutoRefresh, refreshToken]);
  useEffect(() => {
    if (!disableAutoRefresh && refreshCount.current > 0) {
      performRefetch().catch(noop);
    }
  }, [disableAutoRefresh, refreshToken, performRefetch]);
  // Render
  return {
    called,
    data,
    error,
    loading,
    loadingMore,
    fetch: performFetch,
    fetchMore: performFetchMore,
    refetch: performRefetch,
  };
};
