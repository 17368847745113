// React
import React, { useEffect, useState } from "react";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";
import { useMounted } from "hooks";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({ options, children }) => {
  // Framework
  const { authorization } = usePortal();
  const { callWhenMounted } = useMounted();
  // Init
  useStackDependency(authorization, options);
  // State
  const [ready, setReady] = useState(false);
  // Effects
  useEffect(() => {
    const handle = authorization.onChange(
      callWhenMounted(() => {
        setReady(true);
      })
    );
    authorization.syncUserPermissions();
    return handle;
  }, [callWhenMounted, authorization]);
  // Render
  return <>{ready && children}</>;
};

const AuthorizationProvider = ({ children }) => {
  return <Component>{children}</Component>;
};

export { AuthorizationProvider };
