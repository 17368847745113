import React from "react";
import { createSvgIcon } from "icon/utils";

export default createSvgIcon(
  <path
    fill="#EA7130"
    d="M14.368,2.465h-5.1V1h-0.9L1,2.306V13.7L8.278,15H9.267V13.372H14.04a1.4,1.4,0,0,0,.806-0.158A1.955,1.955,0,0,0,15,12.229V3.1A0.577,0.577,0,0,0,14.368,2.465ZM5.665,8.54A2.3,2.3,0,0,1,4.5,8.69l0,1.91L3.55,10.518l0-5.217c0.864,0.042,1.91-.35,2.626.313A2.021,2.021,0,0,1,5.665,8.54ZM14.242,12.6H9.267V11.581h3.817V10.93H9.267V10.116h3.817V9.465H9.269s0-.638,0-0.957a2.188,2.188,0,0,0,1.91-.192,2.239,2.239,0,0,0,.98-1.778H10.063V4.412l-0.8.164V3.233h4.975V12.6ZM10.381,4.072a2.27,2.27,0,0,1,2.095,2.137H10.381V4.072ZM4.5,6.182a0.953,0.953,0,0,1,.913.228,1.206,1.206,0,0,1,.021,1.068,1.027,1.027,0,0,1-.908.342C4.488,7.274,4.491,6.729,4.5,6.182Z"
    transform="translate(-1 -1)"
  />,
  "PowerpointIcon",
  { viewBox: "-1 -1 16 16" }
);
