// Helpers
import { fileExtByMimeType } from "../helpers";

export const asBlob = (canvas, fileName, mimeType, quality) => {
  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        // edge doesnt support new File, create Blob and set file.name
        let fileOfBlob = new Blob([blob], { type: mimeType });
        fileOfBlob.name = fileExtByMimeType(fileName, mimeType);
        resolve(fileOfBlob);
      },
      mimeType,
      quality
    );
  });
};
