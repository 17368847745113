// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { isNil } from "@mefisto/utils";
// Framework
import { makeStyles, colors, Typography, Avatar } from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.white,
  },
  grey: {
    backgroundColor: colors.grey[300],
  },
  red: {
    backgroundColor: colors.red[500],
  },
  pink: {
    backgroundColor: colors.pink[500],
  },
  purple: {
    backgroundColor: colors.purple[500],
  },
  blue: {
    backgroundColor: colors.blue[500],
  },
  indigo: {
    backgroundColor: colors.indigo[500],
  },
  teal: {
    backgroundColor: colors.teal[500],
  },
  amber: {
    backgroundColor: colors.amber[500],
  },
  orange: {
    backgroundColor: colors.deepOrange[500],
  },
}));

////////////////////////////////////////////////////
/// Helpers
////////////////////////////////////////////////////

/**
 * Takes first and last letters of each words
 * and returns them in uppercase
 *
 * @param text
 * @return {string}
 */
const getLetter = (text = "") => {
  if (isNil(text)) {
    return " ";
  }

  // Take first letters of each word
  const letters = text.match(/\b(\w)/g);

  // Empty string, return space otherwise the avatar will disappear
  if (!letters) {
    return " ";
  }

  // Just one letter
  if (letters.length === 1) {
    return letters[0].toUpperCase();
  }

  // Take first and last letter
  let { 0: first = "", [letters.length - 1]: last = "" } = letters;
  // Join them
  return `${first}${last}`.toUpperCase();
};

/**
 * Returns color class based on the first letter of the title
 * @param title
 * @param classes
 * @return {*}
 */
const getColor = (title = "", classes) => {
  if (isNil(title)) {
    return classes.grey;
  }

  // List of all possible colors
  const colors = [
    classes.red,
    classes.pink,
    classes.purple,
    classes.blue,
    classes.indigo,
    classes.teal,
    classes.amber,
    classes.orange,
  ];

  // Just check title is not empty
  if (isNaN(title.charCodeAt(0))) {
    return classes.grey;
  }

  // Take first 5 characters of  title and calculate the color
  const color = [0, 1, 2, 3, 4, 5].reduce((result, value) => {
    const charCode = title.charCodeAt(value);
    return result + (isNaN(charCode) ? 0 : charCode);
  }, 0);

  return colors[color % colors.length];
};

/**
 * Returns typography variant based on avatar size
 * @param size
 * @return {string}
 */
const getVariant = (size) => {
  if (size <= 25) {
    return "caption";
  } else if (size <= 40) {
    return "subtitle2";
  } else if (size <= 60) {
    return "h6";
  } else if (size <= 100) {
    return "h5";
  } else {
    return "h3";
  }
};

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const LetterAvatar = ({ title, disableTitleCrop, disableColor, size = 40 }) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Avatar
      classes={{ colorDefault: !disableColor && getColor(title, classes) }}
      style={{ width: size, height: size }}
    >
      <Typography variant={getVariant(size)} className={classes.title}>
        {disableTitleCrop ? title : getLetter(title)}
      </Typography>
    </Avatar>
  );
};

LetterAvatar.propTypes = {
  title: PropTypes.string,
  disableTitleCrop: PropTypes.bool,
  disableColor: PropTypes.bool,
  size: PropTypes.number,
};

export default LetterAvatar;
