// Framework
import { StackDependency } from "stack/dependency";

export class Performance extends StackDependency {
  #performance;

  onInitialized() {
    const { firebase, log } = this.context;
    if (firebase.performance) {
      this.#performance = firebase.performance();
      log.info("🐢", "Performance Monitoring [On]");
    }
  }

  trace(traceName) {
    this.#performance?.trace(traceName);
  }
}
