import React from "react";
import { createSvgIcon } from "icon/utils";

export default createSvgIcon(
  <path
    fill="#497CE3"
    preserveAspectRatio="xMidYMid meet"
    d="M14.34,2.3H9.249V1H8.3L1,2.306V13.7L8.257,15H9.249V13.7h4.764a1.417,1.417,0,0,0,.8-0.161,1.942,1.942,0,0,0,.154-0.985V2.934A0.572,0.572,0,0,0,14.34,2.3Zm-8.046,7.91a2.672,2.672,0,0,1-.976.016C5.107,9.151,4.861,8.08,4.672,7c-0.186,1.052-.427,2.093-0.64,3.139q-0.457-.024-0.917-0.057C2.852,8.646,2.543,7.223,2.3,5.788c0.271-.013.544-0.024,0.816-0.034C3.276,6.79,3.461,7.823,3.6,8.861,3.828,7.8,4.056,6.732,4.279,5.667c0.3-.018.6-0.031,0.9-0.047,0.211,1.1.427,2.2,0.655,3.29,0.179-1.13.378-2.256,0.57-3.384,0.317-.011.635-0.029,0.951-0.049C7,7.054,6.686,8.645,6.294,10.212Zm7.919,2.717H9.249V11.907h4.126V11.256H9.249V10.442h4.126V9.791H9.249V8.977h4.126V8.326H9.249V7.512h4.126V6.86H9.249V6.047h4.126V5.4H9.249V4.581h4.126V3.93H9.249V3.071h4.964v9.859Z"
    transform="translate(-1 -1)"
  />,
  "WordIcon",
  { viewBox: "-1 -1 16 16" }
);
