// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { Image } from "image";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ImageAvatar = ({ image, size = 40, variant = "circle" }) => (
  <Image variant={variant} image={image} width={size} height={size} />
);

ImageAvatar.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      generation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      url: PropTypes.string,
    }),
  ]),
  variant: PropTypes.string,
  size: PropTypes.number,
};

export default ImageAvatar;
