// React
import { useMemo } from "react";
// Helpers
import { isNumber, entries } from "@mefisto/utils";
// Framework
import { useMediaQuery } from "ui/components";
import { useTheme } from "theme";
import { useDeepMemo } from "hooks";

/**
 * Returns size based on the current breakpoint.
 * When number is passed returns the number.
 * Fills in the missing sizes, so for e.g. {xs:1, md: 2} does {xs:1, sm: 1, md: 2, lg: 2, xl: 2}
 * @params sizes {{ xs: number, sm: number, md: number, lg: number, xl: number } | number}
 * @returns {{breakpoint: number}}
 */
export const useBreakpoint = (sizes, { noSsr = true } = {}) => {
  // Framework
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"), { noSsr });
  const sm = useMediaQuery(theme.breakpoints.only("sm"), { noSsr });
  const md = useMediaQuery(theme.breakpoints.only("md"), { noSsr });
  const lg = useMediaQuery(theme.breakpoints.only("lg"), { noSsr });
  const xl = useMediaQuery(theme.breakpoints.only("xl"), { noSsr });
  // Memo
  const breakpoints = useMemo(() => {
    return [xs, sm, md, lg, xl];
  }, [xs, sm, md, lg, xl]);
  const values = useDeepMemo(() => {
    return isNumber(sizes)
      ? sizes
      : [
          sizes.xs ?? sizes.sm ?? sizes.md ?? sizes.lg ?? sizes.xl,
          sizes.sm ?? sizes.xs ?? sizes.md ?? sizes.lg ?? sizes.xl,
          sizes.md ?? sizes.sm ?? sizes.xs ?? sizes.lg ?? sizes.xl,
          sizes.lg ?? sizes.md ?? sizes.sm ?? sizes.xs ?? sizes.xl,
          sizes.xl ?? sizes.lg ?? sizes.md ?? sizes.sm ?? sizes.xs,
        ];
  }, [sizes]);
  const breakpoint = useMemo(() => {
    if (isNumber(values)) {
      return values;
    }
    // Find first sizes based on entry
    for (const [index, breakpoint] of entries(breakpoints)) {
      if (breakpoint) {
        return values[index];
      }
    }
  }, [values, breakpoints]);
  // Render
  return { breakpoint };
};
