// React
import React, { createContext, useContext, useState } from "react";

////////////////////////////////////////////////////
/// Context
////////////////////////////////////////////////////

const TableContext = createContext({});

export const useTableContext = () => {
  return useContext(TableContext);
};

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TableProvider = ({ data, children }) => {
  // State
  const [prepend, setPrepend] = useState(false);
  const [append, setAppend] = useState(false);
  // Render
  return (
    <TableContext.Provider
      value={{
        data,
        prepend,
        append,
        setPrepend,
        setAppend,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export default TableProvider;
