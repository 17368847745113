// React
import React, { memo, useMemo, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
// Helpers
import { isFunction, isEmpty, noop } from "@mefisto/utils";
// Framework
import { useResources } from "resource";
import { useEntityRead } from "model/hooks";
import { EntityPropType } from "model/utils";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelEntityRead = forwardRef(
  (
    {
      entity: Entity,
      input,
      resources: customResources,
      languages,
      tags,
      skip,
      fetchPolicy,
      nextFetchPolicy,
      emptyMapper,
      disableRenderOnEmpty,
      disableRenderOnLoading,
      children,
    },
    ref
  ) => {
    // Resources
    const { resources: defaultResources } = useResources();
    // Memo
    const resources = useMemo(() => {
      return customResources ?? defaultResources;
    }, [customResources, defaultResources]);
    // Model
    const { data, loading, error, refetch } = useEntityRead(Entity, {
      skip,
      input,
      resources,
      languages,
      fetchPolicy,
      nextFetchPolicy,
      tags,
    });
    // Ref
    useImperativeHandle(ref, () => ({
      refresh() {
        refetch().catch(noop);
      },
    }));
    // Memo
    const disableRender = useMemo(() => {
      const disableEmpty = emptyMapper
        ? emptyMapper(data)
        : isEmpty(data) && disableRenderOnEmpty;
      const disableLoading = loading && disableRenderOnLoading;
      return disableEmpty || disableLoading;
    }, [
      emptyMapper,
      data,
      loading,
      disableRenderOnEmpty,
      disableRenderOnLoading,
    ]);
    // Render
    return (
      <>
        {!disableRender && (
          <>
            {isFunction(children)
              ? children({ input, resources, data, loading, error })
              : children}
          </>
        )}
      </>
    );
  }
);

ModelEntityRead.propTypes = {
  /**
   * Model entity used for the feed
   */
  entity: EntityPropType,
  /**
   * Input data
   */
  input: PropTypes.object,
  /**
   * Resources data
   */
  resources: PropTypes.object,
  /**
   * List of requested languages
   */
  languages: PropTypes.array,
  /**
   * Set to `true` if the read should skip on render
   */
  skip: PropTypes.bool,
  /**
   * Fetch policy of the list request
   */
  fetchPolicy: PropTypes.string,
  /**
   * Next fetch policy of the list request
   */
  nextFetchPolicy: PropTypes.string,
  /**
   * Func that returns `true` when the entity is considered empty
   */
  emptyMapper: PropTypes.func,
  /**
   * When set to `true` children are not rendered when data is empty
   */
  disableRenderOnEmpty: PropTypes.bool,
  /**
   * When set to `true` children is not rendered when loading
   */
  disableRenderOnLoading: PropTypes.bool,
};

export default memo(ModelEntityRead);
