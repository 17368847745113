// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles } from "@material-ui/core";
import { useTheme } from "theme";
// Components
import Spinner from "../Spinner";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: theme.zIndex.spinner,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const CoverSpinner = ({ title, size, color, disableSpinner }) => {
  // Styles
  const classes = useStyles();
  const theme = useTheme();
  // Render
  return (
    <div
      className={classes.root}
      style={{ background: color ? color : theme.palette.common.white }}
    >
      {!disableSpinner && (
        <Spinner position="modal" size={size} title={title} />
      )}
    </div>
  );
};

CoverSpinner.propTypes = {
  disableSpinner: PropTypes.bool,
  title: PropTypes.string,
  size: PropTypes.oneOf(["small", "normal", "large"]),
  color: PropTypes.string,
};

export default CoverSpinner;
