// React
import React, { useState } from "react";
import PropTypes from "prop-types";
// Helpers
import { noop, map } from "@mefisto/utils";
// Framework
import {
  makeStyles,
  Typography,
  Grid,
  Spacer,
  DialogTitle,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  TooltipButton,
} from "ui/components";
import { Close as CloseIcon, MoreVertTwoTone as MoreIcon } from "icon/material";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
  button: {
    color: theme.palette.grey[500],
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Header = ({ title, options, onClose = noop }) => {
  // Styles
  const classes = useStyles();
  // State
  const [anchorEl, setAnchorEl] = useState(null);
  // Handlers
  const handleMore = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleSelection = (onSelection = noop) => {
    setAnchorEl(null);
    onSelection();
  };
  // Render
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="h6" component="div" color="inherit">
            {title}
          </Typography>
        </Grid>
        <Spacer />
        <Grid item className={classes.button}>
          {options && (
            <>
              <TooltipButton onClick={handleMore}>
                <MoreIcon color="inherit" />
              </TooltipButton>
              <Menu
                disableAutoFocusItem
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {map(options, ({ type, icon, title, onSelection }, index) => {
                  switch (type) {
                    case undefined:
                    case "item":
                      return (
                        <MenuItem
                          onClick={() => handleSelection(onSelection)}
                          key={index}
                        >
                          {icon && <ListItemIcon>{icon}</ListItemIcon>}
                          {title && <ListItemText primary={title} />}
                        </MenuItem>
                      );
                    case "divider":
                      return (
                        <Divider className={classes.divider} key={index} />
                      );
                    default:
                      return null;
                  }
                })}
              </Menu>
            </>
          )}
        </Grid>
        <Grid item className={classes.button}>
          <TooltipButton edge="end" onClick={onClose}>
            <CloseIcon color="action" />
          </TooltipButton>
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["item", "divider"]),
      title: PropTypes.string,
      icon: PropTypes.element,
      onSelection: PropTypes.func,
    })
  ),
  onClose: PropTypes.func,
};

export default Header;
