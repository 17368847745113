// React
import React, {
  Children,
  isValidElement,
  cloneElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { reduce, size, compact } from "@mefisto/utils";
// Framework
import { makeStyles, Box, AppBar, Toolbar, Tabs } from "ui";
import { EntityPropType } from "model/utils";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: theme.shadows[0],
    background: "transparent",
  },
  bottomLine: {
    width: `calc(100% - ${theme.spacing(6)}px)`,
    position: "absolute",
    height: 2,
    bottom: 0,
    background: theme.palette.grey[200],
  },
  tabs: {
    width: "100%",
    color: theme.palette.action.active,
  },
  tabIndicatorLoading: {
    backgroundColor: "transparent",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelDialogGroupAction = ({
  tabs,
  tab,
  hideTabs,
  skeleton,
  children,
  ...props
}) => {
  // Styles
  const classes = useStyles();
  // State
  const [currentTab, setCurrentTab] = useState(tab ?? 0);
  const [tabData, setTabData] = useState([]);
  const [loading, setLoading] = useState(false);
  // Memo
  const actions = useMemo(() => {
    return reduce(
      Children.toArray(children),
      (result, child, outerIndex) => {
        if (isValidElement(child)) {
          Children.forEach(child.props.children, (innerChild, innerIndex) => {
            if (isValidElement(innerChild)) {
              result.elements.push(child);
              result.index.push(outerIndex);
              result.tab.push(innerIndex);
            }
          });
        }
        return result;
      },
      {
        elements: [],
        index: [],
        tab: [],
      }
    );
  }, [children]);
  const isLastTab = useMemo(() => {
    return currentTab === size(actions.index) - 1;
  }, [actions, currentTab]);
  const isLast = useMemo(() => {
    const isNextDifferent =
      actions.index[currentTab] !== actions.index[currentTab + 1];
    return isLastTab || isNextDifferent;
  }, [actions, currentTab, isLastTab]);
  // Callback
  const isDisabled = useCallback(
    (tab) => {
      return actions.index[currentTab] !== actions.index[tab];
    },
    [actions, currentTab]
  );
  // Handlers
  const handleLoading = (loading) => {
    setLoading(loading);
  };
  const handleTabChange = (event, tab) => {
    setCurrentTab(tab);
  };
  const handleNext = () => {
    setCurrentTab((currentTab) => currentTab + 1);
  };
  const handleFinish = (payload) => {
    if (isLastTab) {
      props.__display.onFinish(payload);
    } else {
      const { data } = payload;
      setTabData((tabData) => [...tabData, data]);
      setCurrentTab((currentTab) => currentTab + 1);
    }
  };
  // Render
  return (
    <>
      {!hideTabs && (
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Box className={classes.bottomLine} />
            <Tabs
              disabled={loading}
              value={currentTab}
              variant="scrollable"
              indicatorColor="primary"
              textColor="primary"
              classes={{
                root: classes.tabs,
                indicator: loading && classes.tabIndicatorLoading,
              }}
              onChange={handleTabChange}
            >
              {Children.map(
                compact(tabs),
                (tab, index) =>
                  isValidElement(tab) &&
                  cloneElement(tab, {
                    disabled: isDisabled(index),
                    loading,
                    skeleton,
                  })
              )}
            </Tabs>
          </Toolbar>
        </AppBar>
      )}
      {cloneElement(actions.elements[currentTab], {
        ...props,
        __display: {
          ...props.__display,
          display: "group",
          tab: actions.tab[currentTab],
          tabData,
          isLast,
          isLastTab,
          onLoading: handleLoading,
          onNext: handleNext,
          onFinish: handleFinish,
        },
      })}
    </>
  );
};

ModelDialogGroupAction.propTypes = {
  entity: EntityPropType,
  schema: PropTypes.any,
  action: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  tab: PropTypes.number,
  hideTabs: PropTypes.bool,
  skeleton: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default ModelDialogGroupAction;
