// React
import React from "react";
// Helpers
import { filter, isEqual, forEach, get } from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";
import { AuthState } from "authentication";

export class Resource extends StackDependency {
  #ui;
  #apps;
  #navigation;
  #authentication;
  #subscribers = [];
  #resources = { organization: null, location: null };
  #preferences = {};

  onInitialized() {
    const { ui, apps, authentication, navigation } = this.context;
    this.#apps = apps;
    this.#navigation = navigation;
    this.#ui = ui;
    this.#authentication = authentication;
    this.#bind();
  }

  /**
   * Subscribe to change.
   * Returns unsubscribe function you need to call in order to
   * stop the event observation in e.g. `useEffect` function.
   * @param callback {function} Callback called when state changes
   * @param callOnBind {boolean} If `true` the callback is called right after the callback is bound.
   * @param force {boolean} When set to `true` the callback is called outside the resource change lifecycle - immediately.
   * @return {function}
   */
  onChange(callback, callOnBind = false, force = false) {
    this.#subscribers.push([callback, force]);
    if (callOnBind) {
      callback(this);
    }
    return () => {
      this.#subscribers = filter(this.#subscribers, ([s]) => s !== callback);
    };
  }

  #handleChange = ({ forceChange } = {}) => {
    forEach(this.#subscribers, ([callback, force]) => {
      if (!forceChange || (forceChange && force)) {
        callback(this);
      }
    });
  };

  /**
   * Binds listeners
   */
  #bind = () => {
    // Apps
    this.#apps.onChange(() => {
      this.#preferences = this.#apps.current?.resource ?? {};
      this.#handleSourceChange();
    }, true);
    // UI
    this.#ui.onChange(this.#handleSourceChange);
    this.#navigation.onChange(this.#handleSourceChange);
    // Auth
    this.#authentication.onChange((user) => {
      if (user.state === AuthState.notAuthenticated) {
        const resources = { organization: null, location: null };
        if (!isEqual(this.#resources, resources)) {
          this.#resources = resources;
          this.#handleChange();
        }
      }
    });
  };

  #handleSourceChange = () => {
    const { source } = this.#preferences;
    if (source === "ui") {
      const resources = this.#mapResources(this.#ui.getProperties("portal"));
      if (!isEqual(resources, this.#resources)) {
        this.#resources = resources;
        this.#handleChange({ forceChange: true });
      }
    }
    if (source === "navigation") {
      const resources = this.#mapResources(this.#navigation.params);
      if (!isEqual(resources, this.#resources)) {
        this.#resources = resources;
        this.#handleChange({ forceChange: true });
      }
    }
  };

  #mapResources = (params) => {
    return {
      organization: get(params, "organization", null),
      location: get(params, "location", null),
    };
  };

  /**
   * Returns current resources
   * @return {{ organization: string, location: string }}
   */
  get resources() {
    return this.#resources;
  }

  /**
   * Returns `true` if the resources should redirect when changed
   * @returns {boolean}
   */
  get redirectOnChange() {
    return !!this.#preferences.redirectOnChange;
  }

  /**
   * Set resources. If `redirectOnChange` options is set to `true` redirects to
   * the appropriate scene. If not stores the resources in cache
   * @param resources {{ organization: string|null, location: string|null }}
   */
  set resources(resources) {
    if (this.#preferences.redirectOnChange) {
      // Redirect, the resources in UI will be set later
      const path = this.#preferences.redirectPath ?? this.#navigation.path;
      this.#navigation.goTo(path, {
        params: resources,
      });
    } else {
      this.cachedResources = resources;
    }
  }

  /**
   * Returns resources stored in cache
   * @return {{organization: *, location: *}}
   */
  get cachedResources() {
    return this.#mapResources(this.#ui.getProperties("portal"));
  }

  /**
   * Stores resources to cache. This call triggers the `onChange` event.
   * @param organization {string}
   * @param location {string}
   */
  set cachedResources({ organization, location }) {
    // Set resources right away
    if (organization !== undefined) {
      this.#ui.set("organization", organization, { app: "portal" });
    }
    if (location !== undefined) {
      this.#ui.set("location", location, { app: "portal" });
    }
    this.#handleChange({ forceChange: false });
  }
}
