// Helpers
import { filter, forEach, isNil, get } from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";

export class Snackbar extends StackDependency {
  #subscribers = [];

  /**
   * Subscribe to change.
   * Returns unsubscribe function you need to call in order to
   * stop the event observation in e.g. `useEffect` function.
   * @param callback {function} Callback called when state changes
   * @param callOnBind {boolean} If `true` the callback is called right after the callback is bound.
   * @return {function}
   */
  onChange(callback, callOnBind = false) {
    this.#subscribers.push(callback);
    if (callOnBind) {
      callback(this);
    }
    return () => {
      this.#subscribers = filter(this.#subscribers, (s) => s !== callback);
    };
  }

  /**
   * Called when state changes
   */
  #handleChange = (type, message, action, onAction, key) => {
    forEach(this.#subscribers, (callback) =>
      callback(type, message, action, onAction, key)
    );
  };

  enqueueSnackbar(...props) {
    return this.options.enqueueSnackbar(...props);
  }

  closeSnackbar(...props) {
    return this.options.closeSnackbar(...props);
  }

  /**
   * Displays success message
   */
  showSuccess({ message } = {}) {
    this.#handleChange("success", message);
  }

  /**
   * Displays persistent message
   */
  showPersistent({ key, message, action, onAction } = {}) {
    this.#handleChange("info", message, action, onAction, key);
  }

  /**
   * Displays error message
   */
  showError(error) {
    const message = get(error, "message");
    if (!isNil(message)) {
      this.#handleChange("error", message);
    }
  }
}
