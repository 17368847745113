// React
import { useCallback, useMemo } from "react";
// Helpers
import { has, reduce, compact } from "@mefisto/utils";

export const useAction = (actions = []) => {
  // Memo
  const actionMap = useMemo(() => {
    return reduce(
      compact(actions),
      (result, action) => {
        result[action] = true;
        return result;
      },
      {}
    );
  }, [actions]);
  // Callback
  const hasAction = useCallback(
    (action) => {
      return has(actionMap, action);
    },
    [actionMap]
  );
  // Render
  return {
    action: hasAction,
    a: hasAction,
  };
};
