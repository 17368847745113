// React
import React from "react";
// Providers
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Wrapper = ({ children }) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    {children}
  </MuiPickersUtilsProvider>
);

export default Wrapper;
