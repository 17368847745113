// React
import { useEffect, useState } from "react";
// Framework
import { useMounted } from "hooks";
import { usePortal } from "stack/core";

/**
 * Returns authorization data.
 * This hooks triggers re-render whenever the data changes.
 *
 * @return {{
 *    roles: {},
 *    permissions: {},
 *    userRoles: {},
 *    userPermissions: {},
 *    userResources: {}
 * }}
 */
export const useAuthorization = () => {
  // Framework
  const { authorization } = usePortal();
  const { callWhenMounted } = useMounted();
  // State
  const [roles, setRoles] = useState(authorization.roles);
  const [permissions, setPermissions] = useState(authorization.permissions);
  const [userRoles, setUserRoles] = useState(authorization.userRoles);
  const [userPermissions, setUserPermissions] = useState(
    authorization.userPermissions
  );
  const [userResources, setUserResources] = useState(
    authorization.userResources
  );
  // Effects
  useEffect(() => {
    return authorization.onChange(
      callWhenMounted((authorization) => {
        setRoles(authorization.roles);
        setPermissions(authorization.permissions);
        setUserRoles(authorization.userRoles);
        setUserPermissions(authorization.userPermissions);
        setUserResources(authorization.userResources);
      })
    );
  }, [callWhenMounted, authorization]);
  // Render
  return {
    roles,
    permissions,
    userRoles,
    userPermissions,
    userResources,
  };
};
