// React
import React, { useCallback, useState } from "react";
// UI
import { classnames, makeStyles, IconButton, Hidden, Grid } from "ui";
import {
  EventRounded as CalendarIcon,
  NavigateBeforeRounded as PreviousIcon,
  NavigateNextRounded as NextIcon,
} from "icon/material";
// Components
import { DatePicker } from "@material-ui/pickers";
import Wrapper from "../Wrapper";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    color: "inherit",
  },
  input: {
    textAlign: "center",
    cursor: "pointer",
    maxWidth: 120,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  button: {
    color: theme.palette.action.active,
    backgroundColor: theme.palette.grey[200],
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
    borderRadius: theme.radius.normal,
    height: 34,
    transition: theme.transitions.create(["background"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const InlineDatePicker = ({
  button,
  previousTooltip = "",
  previousDisabled,
  nextTooltip = "",
  nextDisabled,
  onPrevious,
  onNext,
  ...rest
}) => {
  // Styles
  const classes = useStyles();
  // State
  const [open, setOpen] = useState(false);
  // Handlers
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  // Render
  return (
    <Wrapper>
      <Grid spacing={1} container alignItems="center" wrap="nowrap">
        <Hidden xsDown>
          <Grid item>
            <IconButton
              onClick={onPrevious}
              tooltip={previousTooltip}
              disabled={previousDisabled}
            >
              <PreviousIcon />
            </IconButton>
          </Grid>
        </Hidden>
        <Grid item>
          <Hidden mdUp>
            <IconButton onClick={handleOpen}>
              <CalendarIcon color="action" />
            </IconButton>
          </Hidden>
          <DatePicker
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            InputProps={{
              classes: {
                root: classnames(classes.root, button && classes.button),
                input: classes.input,
              },
              disableUnderline: true,
            }}
            {...rest}
          />
        </Grid>
        <Hidden xsDown>
          <Grid item>
            <IconButton
              onClick={onNext}
              disabled={nextDisabled}
              tooltip={nextTooltip}
            >
              <NextIcon />
            </IconButton>
          </Grid>
        </Hidden>
      </Grid>
    </Wrapper>
  );
};

export default InlineDatePicker;
