// Helpers
import {
  filter,
  values,
  map,
  endsWith,
  difference,
  uniq,
  isEmpty,
} from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";
import { makeAssetPattern, getAssetManifest } from "sw/assets";
import { WindowEventBus, AppEventBus } from "sw/eventBus";
// Components
import { Workbox } from "workbox-window";

export class ServiceWorker extends StackDependency {
  #config;
  #log;
  #wb;
  #windowEventBus;
  #appEventBus;

  onInitialized() {
    const { config, log } = this.context;
    this.#config = config.values;
    this.#log = log;
    // Register service worker right away after initialization
    this.register();
  }

  /**
   * Registers Service Worker for the current window
   */
  register = () => {
    // Service worker must be set and provided by the browser
    if (!this.#config.swEnabled || !("serviceWorker" in navigator)) {
      return;
    }
    this.#log.info("🛠️", "SW Registration", this.#serviceWorkerUrl);
    // Create workbox instance
    this.#wb = new Workbox(this.#serviceWorkerUrl);
    // Create event buses
    this.#windowEventBus = new WindowEventBus(this.#wb);
    this.#appEventBus = new AppEventBus();
    this.#appEventBus.addMessageHandler(this.#appMessageHandler);
    // Attach event listeners
    this.#wb.addEventListener("controlling", this.#controllingHandler);
    this.#wb.addEventListener("waiting", this.#waitingHandler);
    // Register service worker
    this.#wb
      .register()
      .then((registration) => this.#registrationHandler(registration))
      .catch((error) => this.#log.error(error));
  };

  /**
   * Called after registration
   */
  #registrationHandler = async () => {
    // Precache assets
    if (this.#config.env === "production") {
      await this.#precache();
    }
    // Get version from service worker
    const version = await this.#windowEventBus.postMessage({
      type: "GET_VERSION",
    });
    // Post version to app
    await this.#appEventBus.postMessage({
      type: "VERSION",
      payload: { version },
    });
  };

  /**
   * Called when SW moves to `waiting` state
   */
  #waitingHandler = async (event) => {
    // `event.wasWaitingBeforeRegister` will be false if this is
    // the first time the updated service worker is waiting.
    // When `event.wasWaitingBeforeRegister` is true, a previously
    // updated same service worker is still waiting.
    const { wasWaitingBeforeRegister, isUpdate } = event;
    await this.#appEventBus.postMessage({
      type: "WAITING",
      payload: {
        wasWaitingBeforeRegister,
        isUpdate,
      },
    });
  };

  #controllingHandler = async () => {
    this.#log.info("🛠️", "Signal Received [Controlling]");
    // Assuming the user accepted the update, set up a listener
    // that will reload the page as soon as the previously waiting
    // service worker has taken control. To update changes, reload the windows.
    await this.#appEventBus.postMessage({ type: "RELOAD" });
  };

  /**
   * Handler for App events
   */
  #appMessageHandler = (event) => {
    const { type } = event;
    switch (type) {
      case "UPDATE": {
        // Send a message telling the service worker to skip waiting.
        // This will trigger the `controlling` event handler.
        return this.#windowEventBus.postMessage({ type: "SKIP_WAITING" });
      }
    }
  };

  /**
   * @deprecated
   */
  #precache = async () => {
    // Open app cache
    const cache = await window.caches.open(this.#cacheName);
    // Precache only app assets
    // TODO: App not available anymore
    // const pattern = makeAssetPattern([this.#app]);
    const pattern = makeAssetPattern();
    const { files } = await getAssetManifest(cache, this.#publicUrl);
    // Get file paths
    const remoteAssets = uniq(
      filter(
        map(values(files), (file) => new URL(file).pathname),
        (file) => file.match(pattern)
      )
    );
    // Get paths, that were already cached
    const cachedAssets = await this.#getPaths(cache);
    // Remove all the paths that are not in the manifest anymore
    const obsoleteAssets = filter(
      difference(cachedAssets, remoteAssets),
      // Do not delete manifest file
      (asset) => !endsWith(asset, "asset-manifest.json")
    );
    if (!isEmpty(obsoleteAssets)) {
      for (const asset of obsoleteAssets) {
        await cache.delete(asset);
      }
      this.#log.info("🛠️", "Outdated Assets Removed", {
        assets: obsoleteAssets,
      });
    }
    // New assets
    const newAssets = difference(remoteAssets, cachedAssets);
    if (!isEmpty(newAssets)) {
      await cache.addAll(newAssets);
      this.#log.info("🛠️", "New Assets Added", { assets: newAssets });
    }
  };

  #getPaths = async (cache) => {
    return map(await cache.keys(), ({ url }) => new URL(url).pathname);
  };

  get #cacheName() {
    return "";
    // TODO: App not available anymore
    // return `app-${this.#app}`;
  }

  get #publicUrl() {
    return this.#config.publicUrl;
  }

  get #serviceWorkerUrl() {
    return this.#config.serviceWorker ?? "/sw.js";
  }
}
