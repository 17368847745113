// Components
import {
  monochrome,
  pixelize,
  coral,
  darkify,
  vintage,
  ocean,
  radio,
  wood,
} from "./filters";
import { crop, resize, rotate } from "./manipulations";
import { loadCanvas, loadBlob } from "./loaders";
import { asBlob, asCanvas, asImage, asFile } from "./savers";

const Constants = {
  MANIPULATION: "MANIPULATION",
  LOADER: "LOADER",
  FILTER: "FILTER",
};

class ImageManipulation {
  #tasks = [];
  #canvas = null;
  #loadedCanvas = null;
  #lastCanvas = null;
  #fileName = null;

  /**
   * Loads blob from file
   * @param imageFile {File}
   * @returns {ImageManipulation}
   */
  loadBlob(imageFile) {
    this.#addToTask(Constants.LOADER, loadBlob(imageFile));
    return this;
  }

  /**
   * Loads canvas from file
   * @param canvas {HTMLCanvasElement}
   * @param fileName {String}
   * @returns {ImageManipulation}
   */
  loadCanvas(canvas, fileName = "canvas.png") {
    this.#addToTask(Constants.LOADER, loadCanvas(canvas, fileName));
    return this;
  }

  /**
   * Crops image
   * @param newWidth {number} New width after crop.
   * @param newHeight {number} New height after crop.
   * @param offsetX {number} If specified, cropping will start from that offset on X axis,
   *                         otherwise it will crop so result is centered in the source
   * @param offsetY {number} If specified, cropping will start from that offset on Y axis,
   *                         otherwise it will crop so result is centered in the source
   * @returns {ImageManipulation}
   */
  crop(newWidth, newHeight, offsetX = 0, offsetY = 0) {
    this.#addToTask(
      Constants.MANIPULATION,
      crop(newWidth, newHeight, offsetX, offsetY)
    );
    return this;
  }

  /**
   * Resizes image
   * @param maxWidth {number} Maximal possible width of the image
   * @param maxHeight {number} Maximal possible height of the image
   * @returns {ImageManipulation}
   */
  resize(maxWidth, maxHeight) {
    this.#addToTask(Constants.MANIPULATION, resize(maxWidth, maxHeight));
    return this;
  }

  /**
   * Rotates image
   * @param degrees
   * @param options {Object}
   * @returns {ImageManipulation}
   */
  rotate(degrees, options = {}) {
    this.#addToTask(Constants.MANIPULATION, rotate(degrees, options));
    return this;
  }

  /**
   * Applies filter
   * @param filter {string}
   * @param options {Object}
   * @returns {ImageManipulation}
   */
  filter(filter, options = {}) {
    let manipulation;
    switch (filter) {
      case "monochrome":
        manipulation = monochrome(options);
        break;
      case "pixelize":
        manipulation = pixelize(options);
        break;
      case "darkify":
        manipulation = darkify(options);
        break;
      case "coral":
        manipulation = coral(options);
        break;
      case "radio":
        manipulation = radio(options);
        break;
      case "vintage":
        manipulation = vintage(options);
        break;
      case "ocean":
        manipulation = ocean(options);
        break;
      case "wood":
        manipulation = wood(options);
        break;
    }
    if (manipulation) {
      this.#addToTask(Constants.FILTER, manipulation);
    }
    return this;
  }

  /**
   * Returns current canvas
   * @returns {HTMLCanvasElement}
   */
  getCanvas() {
    return this.#canvas || this.#lastCanvas;
  }

  /**
   * Returns file name
   * @returns {String}
   */
  getFileName() {
    return this.#fileName;
  }

  /**
   * Sets filename
   * @param newFileName {string} New filename to be used in mime type definition
   */
  setFileName(newFileName) {
    this.#fileName = newFileName;
  }

  /**
   * Save image as blob
   * @param mimeType
   * @param quality
   * @returns {Promise<Blob>}
   */
  saveAsBlob(mimeType = "image/jpeg", quality = "1.0") {
    return this.#runTasks().then(() =>
      asBlob(this.getCanvas(), this.getFileName(), mimeType, quality)
    );
  }

  /**
   * Save image as file
   * @param mimeType
   * @param quality
   * @returns {Promise<File>}
   */
  saveAsFile(mimeType = "image/jpeg", quality = "1.0") {
    return this.#runTasks().then(() =>
      asFile(this.getCanvas(), this.getFileName(), mimeType, quality)
    );
  }

  /**
   * Save image as canvas
   * @returns {Promise<HTMLCanvasElement>}
   */
  saveAsCanvas() {
    return this.#runTasks().then(() => asCanvas(this.getCanvas()));
  }

  /**
   * Saves image
   * @param mimeType
   * @param quality
   * @returns {Promise<HTMLImageElement.src>}
   */
  saveAsImage(mimeType = "image/jpeg", quality = "1.0") {
    return this.#runTasks().then(() =>
      asImage(this.getCanvas(), mimeType, quality)
    );
  }

  /**
   * Adds callback to tasks
   * @param type{String}
   * @param func{Function}
   */
  #addToTask(type = "manipulation", func) {
    this.#tasks.push({ type, func });
  }

  /**
   * Runs all tasks
   * @returns {Promise}
   */
  async #runTasks() {
    this.#canvas = null;
    for (let i = 0; i < this.#tasks.length; i++) {
      // if type loader, covert image to canvas and save in loadedCanvas
      if (this.#tasks[i].type === Constants.LOADER) {
        let data = await this.#tasks[i].func();
        this.#loadedCanvas = data.canvas;
        if (data.fileName) {
          this.#fileName = data.fileName;
        }
      } else {
        if (this.#canvas === null && this.#loadedCanvas === null) {
          throw new Error("use loadBlob first");
        }
        this.#canvas = await this.#tasks[i].func(
          this.#canvas || this.#loadedCanvas
        );
      }
    }
    this.#cleanTasks();
  }

  /**
   * Cleans all tasks
   */
  #cleanTasks() {
    this.#tasks = [];
    // save last canvas
    // for save image after all convert in same formats
    if (this.#canvas !== null) {
      this.#lastCanvas = this.#canvas;
    }
  }
}

export default ImageManipulation;
