// React
import React from "react";
// Components
import NumberFormatter from "react-number-format";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

/**
 * https://github.com/s-yadav/react-number-format
 * @constructor
 */
const NumberFormat = ({
  thousandSeparator,
  decimalSeparator,
  thousandsGroupStyle,
  decimalScale,
  fixedDecimalScale,
  prefix,
  suffix,
  format,
  mask,
  defaultValue,
  isNumericString,
  allowNegative = true,
  allowDecimal = true,
  allowZero = true,
  allowEmptyFormatting,
}) => ({ inputRef, onChange, ...rest }) => (
  <NumberFormatter
    thousandSeparator={thousandSeparator}
    decimalSeparator={decimalSeparator}
    thousandsGroupStyle={thousandsGroupStyle}
    decimalScale={allowDecimal ? decimalScale : 0}
    fixedDecimalScale={fixedDecimalScale}
    prefix={prefix}
    suffix={suffix}
    format={format}
    mask={mask}
    defaultValue={defaultValue}
    isNumericString={isNumericString}
    allowNegative={allowNegative}
    allowEmptyFormatting={allowEmptyFormatting}
    getInputRef={inputRef}
    isAllowed={(values) => {
      return !(!allowZero && values.floatValue === 0);
    }}
    onValueChange={(values) => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    {...rest}
  />
);

export default NumberFormat;
