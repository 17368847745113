// React
import React, { Children } from "react";
import PropTypes from "prop-types";
// Dependencies
import { Interceptor, InterceptorProvider } from "interceptor/core";
import { LocalizationProvider } from "localization/core";
import { Model, ModelProvider } from "model/core";
import { Plugin, PluginProvider } from "plugin/core";
// Component
import { AppProvider } from "stack/core";
import { NavigationRoutes } from "navigation";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const AppLoader = ({
  skeleton,
  interceptors,
  localization,
  layouts,
  model,
  children: plugins,
}) => (
  <AppProvider
    skeleton={skeleton}
    providers={[
      <PluginProvider options={Children.toArray(plugins)} />,
      <ModelProvider options={model} />,
      <LocalizationProvider source="app" options={localization} />,
      <InterceptorProvider source="app" options={interceptors} />,
    ]}
    dependencies={{
      Interceptor,
      Model,
      Plugin,
    }}
  >
    <NavigationRoutes>{Children.toArray(layouts)}</NavigationRoutes>
  </AppProvider>
);

AppLoader.propTypes = {
  skeleton: PropTypes.any,
  interceptors: PropTypes.element,
  model: PropTypes.element,
  localization: PropTypes.element,
  layouts: PropTypes.arrayOf(PropTypes.element),
  children: PropTypes.node,
};

export default AppLoader;
