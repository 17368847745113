export class StackDependency {
  context;
  options;

  /**
   * Called after context and options are set.
   * Add any after initialization code
   */
  onInitialized() {
    // Override
  }

  setContext = (context) => {
    this.context = context;
  };

  setOptions = (options) => {
    this.options = options;
  };
}
