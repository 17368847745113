// Helpers
import { filter, find, values, forEach, isEqual } from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";

export class Activity extends StackDependency {
  #subscribers = [];
  #activities = {};

  /**
   * Subscribe to change.
   * Returns unsubscribe function you need to call in order to
   * stop the event observation in e.g. `useEffect` function.
   * @param callback {function} Callback called when state changes
   * @param callOnBind {boolean} If `true` the callback is called right after the callback is bound.
   * @return {function}
   */
  onChange(callback, callOnBind = false) {
    this.#subscribers.push(callback);
    if (callOnBind) {
      callback(this);
    }
    return () => {
      this.#subscribers = filter(this.#subscribers, (s) => s !== callback);
    };
  }

  /**
   * Call to notify all subscribers about the state change
   */
  #handleChange = (activities) => {
    if (!isEqual(activities, this.#activities)) {
      this.#activities = activities;
      forEach(this.#subscribers, (callback) => callback(this));
    }
  };

  /**
   * Starts the activity on thread with the given token
   * @param token {string} Unique token describing the activity on thread
   * @param thread {string} Unique activity thread id
   */
  start(token, { thread = "global" } = {}) {
    this.#handleChange({
      ...this.#activities,
      [thread]: {
        ...(this.#activities[thread] ?? {}),
        [token]: true,
      },
    });
  }

  /**
   * Stops the thread
   * @param token {string} Unique token describing the activity on thread
   * @param thread {string} Unique activity thread id
   */
  stop(token, { thread = "global" } = {}) {
    this.#handleChange({
      ...this.#activities,
      [thread]: {
        ...(this.#activities[thread] ?? {}),
        [token]: false,
      },
    });
  }

  /**
   * Returns `true` if the thread is running
   * @param thread {string} Unique activity thread id
   * @returns {boolean}
   */
  running({ thread = "global" } = {}) {
    return find(values(this.#activities[thread]), (value) => value) ?? false;
  }
}
