import { createFilter } from "../helpers";

/**
 * Coral filter manipulation
 */
export const coral = ({ brightness = 20 }) => async (canvasImage) =>
  createFilter(canvasImage, (imageData) => {
    for (let i = 0; i < imageData.data.length; i += 4) {
      imageData.data[i] -= brightness;
      imageData.data[i + 1] -= brightness;
      imageData.data[i + 2] -= brightness;
    }
    return imageData;
  });
