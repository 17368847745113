import React, { Fragment } from "react";
import PropTypes from "prop-types";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Language = () => <Fragment />;

Language.propTypes = {
  locale: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
};

export default Language;
