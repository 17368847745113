// React
import React, { Component } from "react";
// Framework
import { withPortal } from "stack/core";
import { Box, ErrorPlaceholder } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  handleTryAgain = () => {
    const { portal } = this.props;
    portal.navigation.reload();
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return hasError ? (
      <Box p={3}>
        <ErrorPlaceholder position="center" onAction={this.handleTryAgain} />
      </Box>
    ) : (
      children
    );
  }
}

export default withPortal(ErrorBoundary);
