// Helpers
import { reduce, isObject, isString, values } from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";

export class Plugin extends StackDependency {
  #endpoints = {};
  #entities = [];

  onInitialized() {
    const { plugins } = this.options;
    this.#endpoints = this.#makeEndpoints(plugins);
    this.#entities = this.#makeEntities(plugins);
    this.#registerLocalizations(plugins);
  }

  /**
   * Creates endpoints from plugins
   * @param plugins
   * @return {object}
   */
  #makeEndpoints = (plugins) => {
    return reduce(
      plugins,
      (result, { id, endpoint }) => {
        if (isObject(endpoint)) {
          result = {
            ...result,
            ...endpoint,
          };
        } else if (isString(endpoint)) {
          result = {
            ...result,
            [id]: endpoint,
          };
        }
        return result;
      },
      {}
    );
  };

  /**
   * Creates entities from plugins
   * @param plugins
   * @return {array}
   */
  #makeEntities = (plugins) => {
    return reduce(
      plugins,
      (result, { entities }) => {
        result = [...result, ...entities];
        return result;
      },
      []
    );
  };

  /**
   * Registers all plugins localizations
   * @param plugins
   */
  #registerLocalizations = (plugins) => {
    const { localization } = this.context;
    for (const plugin of values(plugins)) {
      localization.register(plugin.id, plugin.localizations);
    }
  };

  /**
   * Returns preferences of a plugin with the given id
   * @param id {string} Plugin ID
   * @returns {object}
   */
  preferences = (id) => {
    const { plugins } = this.options;
    return plugins[id]?.preferences ?? {};
  };

  /**
   * All plugin's entities
   * @return {array}
   */
  get entities() {
    return this.#entities;
  }

  /**
   * All plugin's endpoints
   * @return {object}
   */
  get endpoints() {
    return this.#endpoints;
  }
}
