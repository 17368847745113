// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { classnames } from "ui/classnames";
import { makeStyles, Spinner, Grid } from "ui/components";
import { useBreakpoint } from "ui/hooks";
import { Section } from "ui/section";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
  fill: {
    height: "100%",
    minHeight: 200,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileCollection = ({
  context = "tileCollection",
  value,
  display = "default",
  spacing = { xs: 2, sm: 3 },
  loading,
  children,
}) => {
  // Styles
  const classes = useStyles();
  // Framework
  const { breakpoint = 0 } = useBreakpoint(spacing);
  // Render
  return (
    <Section context={context} value={value}>
      <Grid
        container
        spacing={breakpoint}
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        className={classnames(classes.root, {
          [classes.fill]: loading || display === "fill",
        })}
      >
        {loading ? (
          <Spinner size="small" color="inherit" position="center" />
        ) : (
          children
        )}
      </Grid>
    </Section>
  );
};

TileCollection.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  display: PropTypes.oneOf(["default", "fill"]),
  loading: PropTypes.bool,
  spacing: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
  children: PropTypes.node,
};

export default TileCollection;
