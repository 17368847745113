// React
import React, { useContext } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";
// Context
import { ConfigContext } from "./ConfigContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({ children }) => {
  // Framework
  const { config } = usePortal();
  // Context
  const options = useContext(ConfigContext);
  // Init
  useStackDependency(config, options);
  // Render
  return <>{children}</>;
};

const ConfigProvider = ({ options, children }) => (
  <ConfigContext.Provider
    value={{
      prefixEnv: "NODE_ENV",
      prefixPublicUrl: "PUBLIC_URL",
      prefixReact: "REACT_APP_",
    }}
  >
    {options}
    <Component>{children}</Component>
  </ConfigContext.Provider>
);

ConfigProvider.propTypes = {
  options: PropTypes.element,
};

export { ConfigProvider };
