// React
import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
// Framework
import { Box, Checkbox, FormControlLabel, FormHelperText } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const FormCheckboxFieldArrayItem = ({
  label,
  value,
  helperText,
  disabled,
  __renderProps: {
    name,
    form: { values, isSubmitting },
    push,
    remove,
  },
}) => {
  // Memo
  const checked = useMemo(() => {
    return values[name].includes(value);
  }, [values, value, name]);
  // Callbacks
  const handleChange = useCallback(
    (event) => {
      if (event.target.checked) {
        push(value);
      } else {
        remove(values[name].indexOf(value));
      }
    },
    [values, value, name, push, remove]
  );
  // Render
  return (
    <>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            name={name}
            disabled={disabled ?? isSubmitting}
            checked={checked}
            value={value}
            onChange={handleChange}
          />
        }
      />
      <FormHelperText>
        <Box display="block" component="span" mt={-1.5} ml={4}>
          {helperText}
        </Box>
      </FormHelperText>
    </>
  );
};

FormCheckboxFieldArrayItem.propTypes = {
  value: PropTypes.any.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormCheckboxFieldArrayItem;
