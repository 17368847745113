// React
import React, { Children, isValidElement } from "react";
import PropTypes from "prop-types";
// Framework
import { Grid, Box } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const CoverTags = ({ tags = [] }) => (
  <>
    {tags && (
      <Box position="absolute" right={0} m={2}>
        <Grid container spacing={1}>
          {Children.map(
            tags,
            (tag) => isValidElement(tag) && <Grid item>{tag}</Grid>
          )}
        </Grid>
      </Box>
    )}
  </>
);

CoverTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.node),
};

export default CoverTags;
