// React
import { useEffect } from "react";
// Components
import { injectCSS, detachCSS } from "css-style-inject";
import { identifier } from "safe-identifier";

export const useInjectStyle = (id, style) => {
  useEffect(() => {
    const uniqueId = identifier(id, true);
    injectCSS(style, uniqueId);
    return () => {
      detachCSS(uniqueId);
    };
  }, [style, id]);
};
