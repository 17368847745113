// Helpers
import { pickBy } from "@mefisto/utils";
// Components
import { isFile } from "./isFile";

/**
 * Return only files from the object
 * @param data {object}
 * @return {object}
 */
export const getFiles = (data) => pickBy(data, isFile);
