// React
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, classnames } from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  topSpacing: {
    marginTop: theme.spacing(3),
  },
  bottomSpacing: {
    marginBottom: theme.spacing(3),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const SceneSection = forwardRef(({ isFirst, isLast, children }, ref) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <div
      ref={ref}
      className={classnames({
        [classes.topSpacing]: !isFirst,
        [classes.bottomSpacing]: !isLast,
      })}
    >
      {children}
    </div>
  );
});

SceneSection.propTypes = {
  /**
   * Whether the section is first in the scene
   */
  isFirst: PropTypes.bool,
  /**
   * Whether the section is last in the scene
   */
  isLast: PropTypes.bool,
  /**
   * Children passed to scene
   */
  children: PropTypes.node,
};

export default SceneSection;
