// React
import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { get } from "@mefisto/utils";
// Framework
import {
  Switch,
  FormLabel,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "ui";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({
  label,
  description,
  legend,
  disabled,
  field: { name, value },
  form: { touched, errors, isSubmitting, setFieldValue },
}) => {
  // Memo
  const errorText = useMemo(() => {
    return get(errors, name);
  }, [errors, name]);
  const hasError = useMemo(() => {
    return get(touched, name) && Boolean(errorText);
  }, [touched, name, errorText]);
  // Callbacks
  const handleChange = useCallback(
    (event) => {
      const { checked } = event.target;
      setFieldValue(name, checked);
    },
    [setFieldValue, name]
  );
  // Render
  return (
    <FormControl fullWidth error={hasError} component="fieldset">
      {legend && <FormLabel component="legend">{legend}</FormLabel>}
      <Grid container wrap="nowrap" justifyContent="space-between" spacing={3}>
        <Grid item>
          {label && (
            <Typography variant="subtitle2" lineHeight="normal" component="div">
              {label}
            </Typography>
          )}
          <FormHelperText>{hasError ? errorText : description}</FormHelperText>
        </Grid>
        <Grid item>
          <Switch
            color="primary"
            name={name}
            disabled={disabled ?? isSubmitting}
            checked={value}
            value={value ? "checked" : ""}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </FormControl>
  );
};

const FormSwitchField = (props) => {
  return <FormField type="checkbox" component={Component} {...props} />;
};

FormSwitchField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  legend: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormSwitchField;
