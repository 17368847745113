// React
import React from "react";
// Framework
import { usePortal } from "stack";
import { useUser, AuthState } from "authentication";
// Components
import Redirect from "../Redirect";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

/**
 * Routes to given component if the user is verified and admin.
 * Otherwise, redirects to a sign in scene.
 */
const RouteAdmin = ({ children }) => {
  // Framework
  const { navigation } = usePortal();
  const {
    user: {
      state,
      data: { isVerified, isAdmin },
    },
  } = useUser();
  // Not authenticated
  if (state === AuthState.notAuthenticated) {
    return (
      <Redirect replace continueUrl to={navigation.routes.notAuthenticated} />
    );
  }
  // Not verified
  if (!isVerified) {
    return <Redirect replace continueUrl to={navigation.routes.notVerified} />;
  }
  // Not an admin
  if (state === AuthState.authenticated && !isAdmin) {
    return <Redirect to={navigation.routes.notAdmin} />;
  }
  // Do not redirect
  return children;
};

export default RouteAdmin;
