import { createFilter } from "../helpers";

/**
 * Monochrome filter manipulation
 */
export const monochrome = ({ rQ = 0.34, gQ = 0.5, bQ = 0.16 }) => async (
  canvasImage
) =>
  createFilter(canvasImage, (imageData) => {
    for (let i = 0; i < imageData.data.length; i += 4) {
      const brightness =
        rQ * imageData.data[i] +
        gQ * imageData.data[i + 1] +
        bQ * imageData.data[i + 2];
      imageData.data[i] = brightness;
      imageData.data[i + 1] = brightness;
      imageData.data[i + 2] = brightness;
    }
    return imageData;
  });
