// Helpers
import { isEqual, pick, keys } from "@mefisto/utils";
// Service
import { getChild as getChildren } from "react-nanny";

export const filterType = (components, props) => {
  return getChildren(components, (child) =>
    isEqual(pick(child.props, keys(props)), props)
  );
};
