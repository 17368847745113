// React
import React, {
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { noop, map, forEach, isNil, floor, max, min } from "@mefisto/utils";
// Framework
import {
  CoverSpinner,
  ModalBackdrop,
  makeStyles,
  Grid,
  Typography,
} from "ui/components";
import { classnames } from "ui/classnames";
import { useTranslate } from "localization/hooks";
import { ImageManipulation } from "image/core";
// Components
import Crop from "./components/Crop";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  zone: ({ zoneWidth, zoneHeight }) => ({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    background: theme.palette.grey[100],
    borderWidth: 2,
    borderRadius: theme.radius.small,
    borderColor: theme.palette.divider,
    borderStyle: "dashed",
    width: zoneWidth,
    height: zoneHeight,
  }),
  preview: ({ previewWidth, previewHeight }) => ({
    position: "relative",
    width: previewWidth,
    height: previewHeight,
    backgroundSize: "cover",
    cursor: "pointer",
  }),
  backdrop: {
    zIndex: 1,
    position: "absolute",
    color: theme.palette.common.white,
    backgroundColor: "rgb(0,0,0, 0.2)",
    backdropFilter: "blur(5px)",
  },
  filters: {
    overflow: "auto",
    margin: theme.spacing(1, 0, 0),
    padding: theme.spacing(0.25),
    minHeight: 110,
  },
  selected: {
    outline: `2px ${theme.palette.primary.main} double`,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const DialogContent = forwardRef(
  (
    {
      file,
      display = "square",
      aspect,
      quality = 0.8,
      zoneWidth = 600,
      zoneHeight = 400,
      previewWidth = 80,
      previewHeight = 80,
      filters: filterList = [
        "original",
        "darkify",
        "wood",
        "coral",
        "monochrome",
        "ocean",
        "radio",
        "vintage",
        "pixelize",
      ],
      backdropDelay = 200,
      onCrop = noop,
      onEdit = noop,
    },
    ref
  ) => {
    // Framework
    const { translate } = useTranslate();
    // Styles
    const classes = useStyles({
      zoneWidth,
      zoneHeight,
      previewWidth,
      previewHeight,
    });
    // State
    const [image, setImage] = useState(null);
    const [objectUrl, setObjectUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [crop, setCrop] = useState({
      aspect,
      height: zoneHeight,
      x: 0,
      y: 0,
    });
    const [rotation, setRotation] = useState(0);
    const [filter, setFilter] = useState("original");
    const [previews, setPreviews] = useState([]);
    const [filters] = useState(filterList);
    // Callbacks
    const reloadOriginal = useCallback(
      ({ rotation = 0, filter } = {}) => {
        if (!file) {
          return;
        }
        setLoading(true);
        // Clean previous image
        if (!isNil(objectUrl)) {
          URL.revokeObjectURL(objectUrl);
        }
        setTimeout(() => {
          // Prepare image manipulation
          new ImageManipulation()
            .loadBlob(file)
            .rotate(rotation)
            .filter(filter)
            .saveAsBlob()
            .then((blob) => {
              setTimeout(() => {
                setLoading(false);
              }, backdropDelay);
              setObjectUrl(URL.createObjectURL(blob));
            })
            .catch(() => {
              setLoading(false);
              setObjectUrl(null);
            });
        }, backdropDelay);
      },
      [file, objectUrl, backdropDelay]
    );
    const reloadPreviews = useCallback(
      async ({ rotation = 0 } = {}) => {
        if (!file) {
          return;
        }
        const images = map(filters, (filter) => {
          return new ImageManipulation()
            .loadBlob(file)
            .rotate(rotation)
            .resize(previewWidth * 2, previewHeight * 2)
            .filter(filter)
            .saveAsBlob();
        });
        const blobs = await Promise.all(images);
        const previews = map(blobs, (blob, key) => ({
          name: filters[key],
          image: URL.createObjectURL(blob),
        }));
        setPreviews(previews);
      },
      [file, filters, previewWidth, previewHeight]
    );
    const finalizeImage = useCallback(
      ({ image, rotation, crop, filter, maxWidth, maxHeight }) => {
        if (!file) {
          return;
        }
        const scale = image.naturalWidth / image.width;
        const cropWidth = floor(max([1, crop.width * scale]));
        const cropHeight = floor(max([1, crop.height * scale]));
        const offsetX = floor(max([0, crop.x * scale]));
        const offsetY = floor(max([0, crop.y * scale]));
        return new ImageManipulation()
          .loadBlob(file)
          .rotate(rotation)
          .crop(cropWidth, cropHeight, offsetX, offsetY)
          .filter(filter)
          .resize(
            min([maxWidth, image.naturalWidth]),
            min([maxHeight, image.naturalHeight])
          )
          .saveAsFile(file.type, quality);
      },
      [file, quality]
    );
    const clear = useCallback(() => {
      setImage(null);
      setRotation(0);
      setFilter(null);
      setCrop({ aspect, height: zoneHeight, x: 0, y: 0 });
    }, [aspect, zoneHeight]);
    const filterName = useCallback(
      (name) => {
        const title = translate(`core:image.dialog.filter.${name}`);
        return title ?? name;
      },
      [translate]
    );
    // Ref
    useImperativeHandle(ref, () => ({
      rotateLeft(degrees) {
        const rotate = (rotation - degrees) % 360;
        setRotation(rotate);
        reloadOriginal({ rotation: rotate, filter });
      },
      rotateRight(degrees) {
        const rotate = (rotation + degrees) % 360;
        setRotation(rotate);
        reloadOriginal({ rotation: rotate, filter });
      },
      finalize({ maxWidth, maxHeight }) {
        return finalizeImage({
          image,
          rotation,
          crop,
          filter,
          maxWidth,
          maxHeight,
        });
      },
      clear() {
        clear();
        reloadOriginal();
      },
    }));
    // Effect
    useEffect(() => {
      if (!objectUrl) {
        reloadOriginal();
        reloadPreviews().catch(noop);
      }
    }, [objectUrl, reloadOriginal, reloadPreviews]);
    useEffect(() => () => URL.revokeObjectURL(objectUrl), [objectUrl]);
    useEffect(
      () => () => {
        forEach(previews, (preview) => URL.revokeObjectURL(preview));
      },
      [previews]
    );
    // Handlers
    const handleEdit = () => {
      onEdit({ filter, rotation, crop });
    };
    const handleCrop = (crop, image) => {
      setCrop(crop);
      setImage(image);
      onCrop(crop, image);
    };
    const handleFilter = (filter) => {
      setFilter(filter);
      reloadOriginal({ filter, rotation, crop });
      handleEdit();
    };
    // Render
    return (
      <>
        {!image && <CoverSpinner size="small" />}
        <div className={classes.zone}>
          {objectUrl && (
            <Crop
              keepSelection
              circularCrop={display === "circle"}
              height={zoneHeight}
              aspect={aspect}
              src={objectUrl}
              disabled={loading}
              onCrop={handleCrop}
            />
          )}
          <ModalBackdrop open={loading} />
        </div>
        <div className={classes.filters}>
          {objectUrl && (
            <Grid container alignItems="center" spacing={2} wrap="nowrap">
              {map(previews, ({ name, image }) => (
                <Grid item key={name}>
                  <div
                    style={{ backgroundImage: `url(${image})` }}
                    title={filterName(name)}
                    onClick={() => handleFilter(name)}
                    className={classnames(
                      classes.preview,
                      name === filter && classes.selected
                    )}
                  />
                  <Typography
                    variant="caption"
                    component="div"
                    align="center"
                    color="textSecondary"
                  >
                    {filterName(name)}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      </>
    );
  }
);

DialogContent.propTypes = {
  file: PropTypes.object,
  aspect: PropTypes.number,
  display: PropTypes.oneOf(["square", "circle"]),
  quality: PropTypes.number,
  previewWidth: PropTypes.number,
  previewHeight: PropTypes.number,
  zoneWidth: PropTypes.number,
  zoneHeight: PropTypes.number,
  rotation: PropTypes.number,
  onCrop: PropTypes.func,
  onEdit: PropTypes.func,
};

export default DialogContent;
