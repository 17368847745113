// Schema
import * as Yup from "yup";

Yup.addMethod(Yup.string, "number", function () {
  const { meta: { transformers = [] } = {} } = this.describe();
  return this.meta({
    transformers: [...transformers, "number"],
  });
});

Yup.addMethod(Yup.string, "price", function () {
  const { meta: { transformers = [] } = {} } = this.describe();
  return this.meta({
    transformers: [...transformers, "price"],
  });
});

Yup.addMethod(Yup.string, "date", function () {
  const { meta: { transformers = [] } = {} } = this.describe();
  return this.meta({
    transformers: [...transformers, "date"],
  });
});

Yup.addMethod(Yup.string, "bool", function () {
  const { meta: { transformers = [] } = {} } = this.describe();
  return this.meta({
    transformers: [...transformers, "bool"],
  });
});

export const translate = (t) => {
  // prettier-ignore
  Yup.setLocale({
    mixed: {
      default: (params) => t("core:form.validation.mixed.default", { params }),
      required: (params) => t("core:form.validation.mixed.required", { params }),
      oneOf: (params) => t("core:form.validation.mixed.oneOf", { params }),
      notOneOf: (params) => t("core:form.validation.mixed.notOneOf", { params }),
      defined: (params) => t("core:form.validation.mixed.defined", { params }),
      length: (params) => t("core:form.validation.mixed.length", { params }),
    },
    string: {
      min: (params) => t("core:form.validation.string.min", { params }),
      max: (params) => t("core:form.validation.string.max", { params }),
      matches: (params) => t("core:form.validation.string.matches", { params }),
      email: (params) => t("core:form.validation.string.email", { params }),
      url: (params) => t("core:form.validation.string.url", { params }),
      uuid: (params) => t("core:form.validation.string.uuid", { params }),
      trim: (params) => t("core:form.validation.string.trim", { params }),
      lowercase: (params) => t("core:form.validation.string.lowercase", { params }),
      uppercase: (params) => t("core:form.validation.string.uppercase", { params }),
    },
    number: {
      min: (params) => t("core:form.validation.number.min", { params }),
      max: (params) => t("core:form.validation.number.max", { params }),
      lessThan: (params) => t("core:form.validation.number.lessThan", { params }),
      moreThan: (params) => t("core:form.validation.number.moreThan", { params }),
      positive: (params) => t("core:form.validation.number.positive", { params }),
      negative: (params) => t("core:form.validation.number.negative", { params }),
      integer: (params) => t("core:form.validation.number.integer", { params }),
    },
    date: {
      min: (params) => t("core:form.validation.date.min", { params }),
      max: (params) => t("core:form.validation.date.max", { params }),
    },
    boolean: {
      min: (params) => t("core:form.validation.boolean.isValue", { params }),
    },
    object: {
      min: (params) => t("core:form.validation.object.noUnknown", { params }),
    },
    array: {
      min: (params) => t("core:form.validation.array.min", { params }),
      max: (params) => t("core:form.validation.array.max", { params }),
      length: (params) => t("core:form.validation.array.length", { params }),
    },
  });
};

export const string = Yup.string;
export const array = Yup.array;
export const bool = Yup.bool;
export const number = Yup.number;
export const object = Yup.object;
