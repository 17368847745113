// Helpers
import { reduce, startsWith, replace, camelCase } from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";

export class Config extends StackDependency {
  #values;

  onInitialized() {
    this.#makeValues();
  }

  #makeValues = () => {
    const { prefixEnv, prefixPublicUrl, prefixReact } = this.options;
    this.#values = reduce(
      process.env,
      (result, variable, key) => {
        // Take care of boolean values
        if (variable === "true") {
          variable = true;
        } else if (variable === "false") {
          variable = false;
        }
        if (startsWith(key, prefixEnv)) {
          result["env"] = variable;
        } else if (startsWith(key, prefixPublicUrl)) {
          result["publicUrl"] = variable;
        } else if (startsWith(key, prefixReact)) {
          result[camelCase(replace(key, prefixReact, ""))] = variable;
        }
        return result;
      },
      []
    );
  };

  /**
   * Returns all config values
   * @return {object}
   */
  get values() {
    return this.#values;
  }

  /**
   * Get particular value
   * @param key {string} - Config Value key
   * @param defaultValue {*} - Value returned when no config is found
   * @return {*}
   */
  get(key, defaultValue) {
    const value = this.#values[key];
    if (value === undefined) {
      if (defaultValue === undefined) {
        throw Error(`Config option ${key} was not found`);
      }
      return defaultValue;
    }
    return value;
  }
}
