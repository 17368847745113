// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { Box } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const CoverProfileImage = ({ profileImage }) => (
  <>
    {profileImage && (
      <Box
        position="absolute"
        top={170}
        left="50%"
        style={{ transform: "translateX(-50%)" }}
      >
        {profileImage}
      </Box>
    )}
  </>
);

CoverProfileImage.propTypes = {
  profileImage: PropTypes.node,
};

export default CoverProfileImage;
