import { createFilter } from "../helpers";

/**
 * Radio filter manipulation
 */
export const radio = () => async (canvasImage) =>
  createFilter(canvasImage, (imageData) => {
    for (let i = 0; i < imageData.data.length; i += 4) {
      let average =
        (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
      imageData.data[i] = average + 5;
      imageData.data[i + 1] = average + 40;
      imageData.data[i + 2] = average + 20;
    }
    return imageData;
  });
