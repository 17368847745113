import { asBlob } from "./asBlob";

export const asFile = (canvas, fileName, mimeType, quality) => {
  return asBlob(canvas, fileName, mimeType, quality).then((blob) => {
    return new File([blob], blob.name, {
      lastModified: new Date().getTime(),
      type: blob.type,
    });
  });
};
