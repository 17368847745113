// Helpers
import { upperFirst } from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";

export class Foggle extends StackDependency {
  /**
   * Returns `true` when the foggle is on
   * @param foggle {string} Foggle name
   * @return {boolean}
   */
  has(foggle) {
    const { prefix } = this.options;
    const key = `${prefix}${upperFirst(foggle)}`;
    return this.context.config.get(key, false);
  }
}
