import { useRef } from "react";

/**
 * Memo hook for lists
 * @param list
 * @param compareFn
 * @return {*}
 */
export const useMemoList = (list, compareFn = (a, b) => a === b) => {
  const listRef = useRef(list);
  const listChanged =
    list.length !== listRef.current.length ||
    list.some((arg, index) => !compareFn(arg, listRef.current[index]));
  if (listChanged) {
    // We can't do this in effects, which run to late
    listRef.current = list;
  }
  return listRef.current;
};
