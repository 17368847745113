// React
import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { get } from "@mefisto/utils";
// Framework
import {
  Box,
  Checkbox,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from "ui";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({
  label,
  description,
  legend,
  disabled,
  field: { name, value },
  form: { touched, errors, isSubmitting, setFieldValue },
}) => {
  // Memo
  const errorText = useMemo(() => {
    return get(errors, name);
  }, [errors, name]);
  const hasError = useMemo(() => {
    return get(touched, name) && Boolean(errorText);
  }, [touched, name, errorText]);
  // Callbacks
  const handleChange = useCallback(
    (event) => {
      const { checked } = event.target;
      setFieldValue(name, checked);
    },
    [setFieldValue, name]
  );
  // Render
  return (
    <FormControl error={hasError} component="fieldset">
      {legend && <FormLabel component="legend">{legend}</FormLabel>}
      <FormGroup>
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              name={name}
              disabled={disabled ?? isSubmitting}
              checked={value}
              value={value ? "checked" : ""}
              onChange={handleChange}
            />
          }
        />
      </FormGroup>
      <FormHelperText>
        <Box display="block" component="span" mt={-1.5} ml={4}>
          {hasError ? errorText : description}
        </Box>
      </FormHelperText>
    </FormControl>
  );
};

const FormCheckboxField = (props) => {
  return <FormField type="checkbox" component={Component} {...props} />;
};

FormCheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  legend: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormCheckboxField;
