// React
import React, { forwardRef, useCallback, useState } from "react";
import PropTypes from "prop-types";
// Framework
import { UserInteraction } from "analytics";
import { Section } from "ui/section";
import { ChevronRightRounded as MoreIcon } from "icon/material";
import { Box, Button as Component } from "@material-ui/core";
// Components
import Cloak from "../Cloak";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ArrowButton = forwardRef(
  ({ context = "button", value, fullWidth, icon, children, onClick }, ref) => {
    // State
    const [hover, setHover] = useState(false);
    // Handlers
    const handleMouseOver = useCallback(() => {
      setHover(true);
    }, []);
    const handleMouseLeave = useCallback(() => {
      setHover(false);
    }, []);
    // Render
    return (
      <UserInteraction
        ref={ref}
        element="button"
        value={value}
        component={Component}
        componentProps={{
          fullWidth,
          component: "div",
          endIcon: (
            <Cloak enabled={!hover}>
              <Box display="flex" mr={1}>
                {icon ?? <MoreIcon color="action" />}
              </Box>
            </Cloak>
          ),
          onClick,
          onMouseOver: handleMouseOver,
          onMouseLeave: handleMouseLeave,
        }}
      >
        <Section context={context} value={value}>
          {children}
        </Section>
      </UserInteraction>
    );
  }
);

ArrowButton.propTypes = {
  /**
   * Section context name
   */
  context: PropTypes.string,
  /**
   * Element specification value
   */
  value: PropTypes.string,
  /**
   * If `true`, the button will take up the full width of its container.
   */
  fullWidth: PropTypes.bool,
  /**
   * Arrow icon
   */
  icon: PropTypes.element,
  /**
   * The content of the arrow button.
   */
  children: PropTypes.node,
};

export default ArrowButton;
