import { useRef } from "react";

/**
 * Creates new instance of the given class, passing the parameters.
 * Holds the reference to the given instance, so if called again returns
 * only the instance and doesn't create a new one.
 *
 * Example usage:
 * const storage = useLazy(Auth, backend);
 * @see https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
 * @param Class
 * @param props
 * @return {null|any}
 */
export const useLazy = (Class, ...props) => {
  const ref = useRef(null);
  if (ref.current === null) {
    ref.current = new Class(...props);
  }
  return ref.current;
};
