// React
import React, { useContext } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";
// Context
import { CacheContext } from "./CacheContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({ children }) => {
  // Framework
  const { cache } = usePortal();
  // Context
  const options = useContext(CacheContext);
  // Init
  useStackDependency(cache, options);
  // Render
  return <>{children}</>;
};

const CacheProvider = ({ options, children }) => (
  <CacheContext.Provider value={{ name: "DefaultCache" }}>
    {options}
    <Component>{children}</Component>
  </CacheContext.Provider>
);

CacheProvider.propTypes = {
  options: PropTypes.element,
};

export { CacheProvider };
