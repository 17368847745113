// React
import React, { Fragment, useEffect } from "react";
// Framework
import { useTranslate } from "localization";
// Components
import { Schema } from "form/schema";

const FormLocalization = () => {
  // Framework
  const { translate } = useTranslate();
  // Effect
  useEffect(() => {
    Schema.translate(translate);
  }, [translate]);
  // Render
  return <Fragment />;
};

export default FormLocalization;
