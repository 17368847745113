// React
import React from "react";
// Helpers
import {
  values,
  map,
  find,
  filter,
  sortBy,
  orderBy,
  startsWith,
  size,
  has,
  reduce,
  forEach,
  flatten,
  includes,
  isEqual,
} from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";
import { join } from "path-browserify";
// Components
import { AppType } from "./AppType";

export class Apps extends StackDependency {
  #current;
  #all = [];
  #user = [];
  #organization = [];
  #permissions = {};
  #subscribers = [];
  #byAppType = reduce(
    AppType,
    (result, type) => {
      result[type] = [];
      return result;
    },
    {}
  );
  #byId = {};
  #byBasename = [];

  onInitialized() {
    this.#makeBag();
    const {
      authentication,
      authorization,
      navigation,
      resource,
    } = this.context;
    authentication.onChange(this.#onContextChange, true);
    authorization.onChange(this.#onContextChange, true);
    resource.onChange(this.#onContextChange, true, true);
    navigation.onChange(this.#onNavigationChange, true);
  }

  /**
   * Subscribe to change.
   * Returns unsubscribe function you need to call in order to
   * stop the event observation in e.g. `useEffect` function.
   * @param callback {function} Callback called when state changes
   * @param callOnBind {boolean} If `true` the callback is called right after the callback is bound.
   * @return {function}
   */
  onChange(callback, callOnBind = false) {
    this.#subscribers.push(callback);
    if (callOnBind) {
      callback(this);
    }
    return () => {
      this.#subscribers = filter(this.#subscribers, (s) => s !== callback);
    };
  }

  /**
   * Called when current user changes state or data
   */
  #handleChange = () => {
    forEach(this.#subscribers, (callback) => callback(this));
  };

  /**
   * Creates bag which contains apps based on divided base on the type
   */
  #makeBag = () => {
    const { apps } = this.options;
    forEach(apps, (app) => {
      this.#byAppType[app.type]?.push(app);
      this.#byId[app.id] = app;
      if (app.permissions) {
        this.#permissions = {
          ...this.#permissions,
          [app.id]: app.permissions,
        };
      }
    });
    this.#byBasename = orderBy(apps, ["navigation.basename"], ["desc"]);
  };

  /**
   * Filters user apps authorized by the current user.
   * Result is sorted by app priority.
   * @return {array}
   */
  #filterUserApps = (apps) => {
    return sortBy(
      filter(apps, ({ id, type, enabled }) => {
        if (enabled && type === AppType.USER) {
          return !(id === "ADMIN" && !this.#isAdmin());
        }
      }),
      ({ priority }) => priority
    );
  };

  /**
   * Filters organization apps authorized by the current user.
   * Result is sorted by app priority.
   * @return {array}
   */
  #filterOrganizationApps = (apps) => {
    const { authorization } = this.context;
    return sortBy(
      filter(apps, ({ id, type, enabled }) => {
        if (enabled && type === AppType.ORGANIZATION) {
          const role = this.#getOrganizationRole();
          if (role && authorization.isSuperior(role)) {
            return this.#isAppAvailable(id);
          }
          return this.#isPermitted(id);
        }
      }),
      ({ priority }) => priority
    );
  };

  /**
   * Returns apps appended with preferences based on the current resources
   * @param apps {Array}
   * @returns {Array}
   */
  #appendPreferences = (apps) => {
    const { resource } = this.context;
    const { preferences } = this.#getOrganizationRole() ?? {};
    const { location } = resource.resources;
    return map(apps, (app) => {
      const value = preferences?.[location]?.[app.id]?.preferences;
      return {
        ...app,
        ...(value && { preferences: value }),
      };
    });
  };

  /**
   * Called when any of the dependency state is changed
   */
  #onContextChange = () => {
    const { apps } = this.options;
    const all = this.#appendPreferences(apps);
    const user = this.#appendPreferences(this.#filterUserApps(apps));
    const organization = this.#appendPreferences(
      this.#filterOrganizationApps(apps)
    );
    // Notify only if the content has actually changed
    if (
      !isEqual(all, this.#all) ||
      !isEqual(user, this.#user) ||
      !isEqual(organization, this.#organization)
    ) {
      this.#all = all;
      this.#user = user;
      this.#organization = organization;
      this.#handleChange();
    }
  };

  #onNavigationChange = () => {
    const { navigation } = this.context;
    // Find current app by the basename
    let current = find(this.#byBasename, (app) => {
      if (app.navigation) {
        const basename = join("/", app.navigation.basename);
        return startsWith(navigation.pathname, basename);
      }
    });
    if (!current) {
      current = find(this.#byBasename, (app) => {
        if (app.navigation) {
          const basename = join("/", app.navigation.basename);
          return startsWith(navigation.routes.default, basename);
        }
      });
    }
    if (this.#current?.id !== current?.id) {
      this.#current = current;
      this.#handleChange();
    }
  };

  /**
   * Returns user's role in organization
   * @return {object}
   */
  #getOrganizationRole = () => {
    const { authorization, resource } = this.context;
    const { organization } = resource.resources;
    return authorization.findRole(organization);
  };

  /**
   * Returns `true` if the user has at least one app permission
   * @param app {string} App ID
   * @return {boolean}
   */
  #isPermitted = (app) => {
    const { authorization } = this.context;
    // Get the superior role so we can read the descendants
    const role = this.#getOrganizationRole();
    if (role) {
      // Check if at least one descendant has app permission
      for (const descendant of role.descendants) {
        if (has(authorization.userPermissions, [app, descendant])) {
          return true;
        }
      }
    }
    return false;
  };

  /**
   * Returns `true` if the app is available
   * @param app
   * @return {boolean}
   */
  #isAppAvailable = (app) => {
    const { apps } = this.#getOrganizationRole() ?? {};
    if (!apps) {
      return false;
    }
    return includes(flatten(values(apps)), app);
  };

  /**
   * Returns `true` when the user is an admin
   * @return {boolean}
   */
  #isAdmin = () => {
    const { authentication } = this.context;
    const {
      data: { isAdmin = false },
    } = authentication.currentUser.toJSON();
    return isAdmin;
  };

  /**
   * Returns array of apps filtered by type
   * @param type {string} AppType
   * @return {array}
   */
  findByType(type) {
    return this.#byAppType[type] ?? [];
  }

  /**
   * Returns app with the given id
   * @param id {string} App ID
   * @return {object}
   */
  findById(id) {
    return this.#byId[id];
  }

  get permissions() {
    return this.#permissions;
  }

  /**
   * Returns current app
   * @return {object}
   */
  get current() {
    return this.#current;
  }

  /**
   * Returns all installed apps
   * @return {array}
   */
  get all() {
    return this.#all;
  }

  /**
   * Returns array of user's apps authorized by the current account
   * @return {array}
   */
  get user() {
    return this.#user;
  }

  /**
   * Returns array of organization's apps authorized by the current account
   * @return {array}
   */
  get organization() {
    return this.#organization;
  }

  /**
   * Returns count of apps authorized by the current account
   * @return {number}
   */
  get count() {
    return size(this.#user) + size(this.#organization);
  }
}
