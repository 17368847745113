// React
import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, LinearProgress } from "@material-ui/core";
import clsx from "clsx";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  rootSmall: {
    height: 2,
  },
  rootMedium: {
    height: 4,
  },
  rootLarge: {
    height: 8,
  },
  rootHidden: {
    visibility: "hidden",
  },
  colorPrimary: {
    backgroundColor: theme.palette.background.highlight[theme.palette.type],
  },
  colorPrimaryLight: {
    backgroundColor: theme.palette.background.highlight.light,
  },
  colorPrimaryDark: {
    backgroundColor: theme.palette.background.highlight.dark,
  },
  barColorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const LinearSpinner = forwardRef(
  (
    {
      appearance = "default",
      delayed = false,
      size = "medium",
      timeout = 1000,
    },
    ref
  ) => {
    // Styles
    const classes = useStyles();
    // State
    const [hidden, setHidden] = useState(true);
    // Effect
    useEffect(() => {
      if (delayed) {
        const handleTimeout = () => setHidden(false);
        const unsubscribe = setTimeout(handleTimeout, timeout);
        return () => clearTimeout(unsubscribe);
      }
    }, [delayed, timeout]);
    // Render
    return (
      <LinearProgress
        ref={ref}
        variant="indeterminate"
        classes={{
          root: clsx({
            [classes.rootSmall]: size === "small",
            [classes.rootMedium]: size === "medium",
            [classes.rootLarge]: size === "large",
            [classes.rootHidden]: delayed && hidden,
          }),
          colorPrimary: clsx({
            [classes.colorPrimary]: appearance === "default",
            [classes.colorPrimaryLight]: appearance === "light",
            [classes.colorPrimaryDark]: appearance === "dark",
          }),
          barColorPrimary: classes.barColorPrimary,
        }}
      />
    );
  }
);

LinearSpinner.propTypes = {
  appearance: PropTypes.oneOf(["default", "light", "dark"]),
  delayed: PropTypes.bool,
  timeout: PropTypes.number,
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

export default LinearSpinner;
