// Helpers
import { filter, forEach, merge, keys, first } from "@mefisto/utils";
// Framework
import { createTheme, colors } from "ui/components";
import { StackDependency } from "stack/dependency";

export class Theme extends StackDependency {
  #defaultTheme = createTheme({
    palette: {
      background: {
        underlying: "#fafafa",
        highlight: {
          dark: colors.grey[800],
          light: colors.grey[300],
        },
      },
      text: {
        link: colors.lightBlue[600],
      },
    },
    radius: {
      none: 0,
      small: 4,
      normal: 12,
      large: 16,
      rounded: "50%",
    },
    zIndex: {
      spinner: 1600,
    },
    sizes: {
      toolbar: 56,
      navbar: 52,
      tab: 48,
      header: 52,
      drawer: 200,
      popover: 250,
      contentMaxWidth: 1290,
      dashboardDrawer: {
        open: 280,
        closed: 96,
      },
    },
  });
  #current;
  #subscribers = [];

  onInitialized() {
    this.#bind();
  }

  /**
   * Current theme object
   * @returns {object}
   */
  get current() {
    return this.#current;
  }

  /**
   * Returns all available themes
   * @returns {object}
   */
  get all() {
    const { themes } = this.options;
    return themes;
  }

  /**
   * Call to change the theme
   * @param theme {string} Theme name
   */
  change(theme) {
    const { ui } = this.context;
    ui.set("theme", theme, { app: "portal" });
  }

  /**
   * Subscribe to theme changes.
   * Returns unsubscribe function you need to call in order to
   * stop the event observation in e.g. `useEffect` function.
   * @param callback {function} Callback called when state changes
   * @param callOnBind {boolean} If `true` the callback is called right after the callback is bound.
   * @return {function}
   */
  onChange(callback, callOnBind = false) {
    this.#subscribers.push(callback);
    if (callOnBind) {
      callback(this);
    }
    return () => {
      this.#subscribers = filter(this.#subscribers, (s) => s !== callback);
    };
  }

  /**
   * Notifies subscribers about state change
   */
  #handleChange = () => {
    const { log } = this.context;
    log.info("💅", { theme: this.current });
    forEach(this.#subscribers, (callback) => callback(this));
  };

  /**
   * Binds to changes
   */
  #bind = () => {
    const { apps, ui } = this.context;
    apps.onChange(this.#onAppsChange, true);
    ui.onChange(this.#onUIChange);
  };

  /**
   * Called when UI change
   */
  #onUIChange = () => {
    const { ui } = this.context;
    const { theme } = ui.getProperties("portal");
    if (theme?.name !== this.#current?.name) {
      this.#onAppsChange();
    }
  };

  /**
   * Called when apps change
   */
  #onAppsChange = () => {
    this.#makeTheme();
  };

  /**
   * Composes theme
   */
  #makeTheme = () => {
    const portalTheme = this.#portalTheme();
    const appTheme = this.#appTheme();
    // Merge themes
    const theme = merge(
      {},
      this.#defaultTheme,
      portalTheme.theme,
      appTheme.theme
    );
    // Merge overrides
    const overrides = merge(
      {},
      portalTheme.overrides?.(theme),
      appTheme.overrides?.(theme)
    );
    const current = { ...theme, overrides };
    // Notify on change only
    if (this.#themesChanged(this.#current, current)) {
      this.#current = current;
      this.#handleChange();
    }
  };

  /**
   * Returns `true` if there's a difference between theme1 and theme2.
   * @param theme1 {object}
   * @param theme2 {object}
   * @returns {boolean}
   */
  #themesChanged = (theme1, theme2) => {
    // Note we need to use json.stringify. The deep equal doesn't work
    // because the theme contains functions. Even though the stringify
    // method doesn't preserve order, it's fine since we only use it to
    // not call `handleChange` method when not necessary.
    return JSON.stringify(theme1) !== JSON.stringify(theme2);
  };

  /**
   * Current portal theme
   * @returns {{name, theme, overrides}}
   */
  #portalTheme = () => {
    const { ui } = this.context;
    const { themes, defaultTheme } = this.options;
    const { theme } = ui.getProperties("portal");
    let portalTheme;
    // Take from UI
    if (theme) {
      portalTheme = themes[theme];
    }
    // Take default theme set in Theme options
    else if (defaultTheme) {
      portalTheme = themes[defaultTheme];
    }
    // No default theme was set to pick the first available one
    if (!portalTheme) {
      const key = first(keys(themes));
      if (key) {
        portalTheme = themes[key];
      }
      ui.set("theme", key, { app: "portal" });
    }
    return (
      portalTheme && {
        theme: {
          name: portalTheme.name,
          title: portalTheme.title,
          ...portalTheme.theme,
        },
        overrides: portalTheme.overrides,
      }
    );
  };

  /**
   * Current app theme
   * @returns {{theme, overrides}}
   */
  #appTheme = () => {
    const { apps } = this.context;
    const { overrides, ...theme } = apps.current?.theme ?? {};
    return {
      theme,
      overrides,
    };
  };
}
