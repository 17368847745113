// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles } from "ui/components";
import { Section } from "ui/section";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(-7, 0, -2),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileAvatar = ({ context = "avatar", value, children }) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Section context={context} value={value}>
      <div className={classes.root}>{children}</div>
    </Section>
  );
};

TileAvatar.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.node,
};

export default TileAvatar;
