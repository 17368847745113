// React
import React, { useCallback } from "react";
import PropTypes from "prop-types";
// Helpers
import { map } from "@mefisto/utils";
// Framework
import {
  useTheme,
  usePortal,
  withStyles,
  alpha,
  Tile,
  Typography,
  Popper,
  List,
  ListItem as ListItemComponent,
  ListItemText,
  ListItemIcon as ListItemIconComponent,
} from "@mefisto/web";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ListItem = withStyles((theme) => ({
  button: {
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.2),
    },
  },
}))(ListItemComponent);

const ListItemIcon = withStyles((theme) => ({
  root: {
    minWidth: theme.spacing(5),
    color: theme.palette.common.white,
  },
}))(ListItemIconComponent);

const NavbarPopper = ({ open, anchorEl, navbarItem, onOpen, onClose }) => {
  // Framework
  const theme = useTheme();
  const { navigation } = usePortal();
  // Handlers
  const handleClose = useCallback(
    (path = null) =>
      (event) => {
        if (path) {
          navigation.goTo(path);
        }
        onClose(event, !!path);
      },
    [navigation, onClose]
  );
  // Render
  return (
    <Popper
      style={{ zIndex: theme.zIndex.tooltip }}
      transition
      open={open}
      anchorEl={anchorEl}
      popperOptions={{
        modifiers: {
          offset: {
            offset: "0,12",
          },
        },
      }}
    >
      <div onMouseEnter={() => onOpen()()} onMouseLeave={handleClose()}>
        {navbarItem && (
          <Tile display="glass" bgcolor={navbarItem.color}>
            <List dense>
              {map(navbarItem.menu, (item, key) => (
                <ListItem
                  dense
                  button
                  key={key}
                  onClick={handleClose(item.path)}
                >
                  {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography color="white" variant="subtitle2">
                        {item.title}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Tile>
        )}
      </div>
    </Popper>
  );
};

NavbarPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  navbarItem: PropTypes.object,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

export default NavbarPopper;
