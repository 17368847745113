// React
import React, { Children, memo, isValidElement, cloneElement } from "react";
import PropTypes from "prop-types";
// Components
import NavigationMenuContext from "../NavigationMenuContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const NavigationMenu = ({
  foldable,
  sectionComponent,
  itemComponent,
  children,
}) => (
  <NavigationMenuContext foldable={foldable}>
    {Children.map(
      children,
      (section, index) =>
        isValidElement(section) &&
        cloneElement(section, {
          index,
          sectionComponent,
          itemComponent,
        })
    )}
  </NavigationMenuContext>
);

NavigationMenu.propTypes = {
  foldable: PropTypes.bool,
  sectionComponent: PropTypes.element,
  itemComponent: PropTypes.element,
  children: PropTypes.node,
};

export default memo(NavigationMenu);
