/**
 * Wraps custom component in the given type
 * @param Type
 * @param Component
 * @return {Component}
 */
export const forwardType = (Type, Component) => {
  const { __TYPE } = Type.defaultProps ?? {};
  if (!__TYPE) {
    throw Error("Provided component is not forwardable");
  }
  Component.defaultProps = {
    ...Component.defaultProps,
    __TYPE,
  };
  return Component;
};
