// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { classnames } from "ui/classnames";
import { makeStyles, Tooltip } from "@material-ui/core";
// Components
import IconButton from "../IconButton";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  selected: {
    background: theme.palette.action.selected,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TooltipButton = ({
  color = "inherit",
  disabled,
  selected,
  tooltip = "",
  onClick,
  children,
  ...props
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Tooltip enterDelay={800} placement="bottom" title={tooltip}>
      <span>
        <IconButton
          color={color}
          onClick={onClick}
          disabled={disabled}
          className={classnames(selected && classes.selected)}
          {...props}
        >
          {children}
        </IconButton>
      </span>
    </Tooltip>
  );
};

TooltipButton.propTypes = {
  color: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TooltipButton;
