// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ActivityPlaceholder = ({ token, thread }) => {
  // Framework
  const { activity } = usePortal();
  // Effects
  useEffect(() => {
    activity.start(token, { thread });
    return () => activity.stop(token, { thread });
  }, [activity, token, thread]);
  // Render
  return null;
};

ActivityPlaceholder.propTypes = {
  token: PropTypes.string.isRequired,
  thread: PropTypes.string,
};

export default ActivityPlaceholder;
