// React
import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { lowerCase } from "@mefisto/utils";
// Framework
import {
  makeStyles,
  Typography,
  TableCell,
  TableSortLabel,
} from "ui/components";
// Components
import { useTableContext } from "../TableContext";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[100],
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    "&:first-of-type": {
      paddingLeft: theme.spacing(3),
      boxShadow: `${theme.palette.common.white} ${theme.spacing(
        1.5
      )}px 0px 0px inset`,
    },
    "&:last-of-type": {
      paddingRight: theme.spacing(3),
      boxShadow: `${theme.palette.common.white} ${theme.spacing(
        -1.5
      )}px 0px 0px inset`,
    },
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TableColumn = ({
  value,
  label,
  sortable,
  width,
  align,
  span = 1,
  __renderProps: { isFirst, isLast, direction, orderBy, onSort },
}) => {
  // Components
  const { prepend, append } = useTableContext();
  // Styles
  const classes = useStyles();
  // Memo
  /** @type {number} */
  const colSpan = useMemo(() => {
    if (isFirst && prepend) {
      return span + 1;
    } else if (isLast && append) {
      return span + 1;
    } else {
      return span;
    }
  }, [prepend, append, isFirst, isLast, span]);
  const sortDirection = useMemo(() => {
    return orderBy === value ? lowerCase(direction) : false;
  }, [value, orderBy, direction]);
  // Callbacks
  const handleSort = useCallback(
    (value) => {
      onSort && onSort(value);
    },
    [onSort]
  );
  // Render
  return (
    <TableCell
      className={classes.root}
      style={{ width }}
      align={align}
      colSpan={colSpan}
      sortDirection={sortDirection}
    >
      {label && (
        <>
          {sortable ? (
            <TableSortLabel
              active={orderBy === value}
              direction={lowerCase(direction)}
              onClick={() => handleSort(value)}
            >
              <Typography noWrap variant="inherit">
                {label}
              </Typography>
            </TableSortLabel>
          ) : (
            <Typography noWrap variant="inherit">
              {label}
            </Typography>
          )}
        </>
      )}
    </TableCell>
  );
};

TableColumn.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  sortable: PropTypes.bool,
  width: PropTypes.number,
  align: PropTypes.oneOf(["inherit", "left", "center", "right", "justify"]),
  colSpan: PropTypes.number,
  __renderProps: PropTypes.object,
};

export default TableColumn;
