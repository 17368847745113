// React
import React from "react";
// Components
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import Wrapper from "../Wrapper";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default ({ ...rest }) => (
  <Wrapper>
    <KeyboardDateTimePicker {...rest} />
  </Wrapper>
);
