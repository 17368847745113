// React
import { Children, isValidElement, cloneElement } from "react";
// Helpers
import { reduce, reverse, isEmpty, filter } from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";

export class Interceptor extends StackDependency {
  /**
   * Creates submerged structure of interceptors from the given array.
   * @example
   * For [<Interceptor1 />, <Interceptor2 />] returns:
   *  <Interceptor1>
   *    <Interceptor2>
   *      {children}
   *    </Interceptor2>
   * </Interceptor1>
   *
   * @param children {*} Interceptors inner content
   * @param onFinished {function} Called when all the interceptors are in the `ready` state.
   * @returns {*}
   */
  makeInterceptors = (children, { onFinished } = {}) => {
    // Filter valid interceptors
    const interceptors = reverse(
      filter(Children.toArray(this.options.interceptors), isValidElement)
    );
    // There are no interceptors available, continue
    if (isEmpty(interceptors)) {
      onFinished?.();
      return children;
    }
    // Render
    return reduce(
      interceptors,
      (result, interceptor) => {
        return cloneElement(interceptor, {
          children: (ready) => {
            if (!result) {
              if (ready) {
                onFinished?.();
                return children;
              } else {
                return null;
              }
            } else {
              return ready && result;
            }
          },
        });
      },
      null
    );
  };
}
