// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { TableRow, TableCell } from "@material-ui/core";
import { EmptyPlaceholder } from "ui/components";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TableEmptyPlaceholder = ({ title, subtitle }) => (
  <TableRow>
    <TableCell align="center">
      <EmptyPlaceholder title={title} subtitle={subtitle} />
    </TableCell>
  </TableRow>
);

TableEmptyPlaceholder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default TableEmptyPlaceholder;
