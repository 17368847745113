// React
import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
// Helpers
import { forEach } from "@mefisto/utils";
// Components
import { NavigationContext } from "navigation/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Navigation = (props) => {
  // Context
  const context = useContext(NavigationContext);
  // Set values
  forEach(props, (value, key) => {
    context[key] = value;
  });
  // Render
  return <Fragment />;
};

Navigation.defaultProps = {
  __TYPE: "Navigation",
};

Navigation.propTypes = {
  type: PropTypes.oneOf(["browser", "hash", "memory"]),
  nullParameter: PropTypes.string,
  basename: PropTypes.string,
  routes: PropTypes.object,
};

export default Navigation;
