// React
import React, { useContext } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";
// Components
import { ExternalsContext } from "./ExternalsContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({ children }) => {
  // Framework
  const { externals } = usePortal();
  // Context
  const options = useContext(ExternalsContext);
  // Init
  useStackDependency(externals, options);
  // Render
  return <>{children}</>;
};

const ExternalsProvider = ({ options, children }) => (
  <ExternalsContext.Provider value={{ externals: [] }}>
    {options}
    <Component>{children}</Component>
  </ExternalsContext.Provider>
);

ExternalsProvider.propTypes = {
  options: PropTypes.object,
};

export { ExternalsProvider };
