// React
import React from "react";
// Framework
import { Microsoft as Icon } from "icon/social";
// Components
import ProviderButton from "../ProviderButton";

const MicrosoftButton = (props) => (
  <ProviderButton
    name="Microsoft"
    color="#00ADEF"
    icon={<Icon fontSize="inherit" />}
    {...props}
  />
);

export default MicrosoftButton;
