// React
import React from "react";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const PerformanceProvider = ({ children }) => {
  // Framework
  const { performance } = usePortal();
  // Init
  useStackDependency(performance);
  // Render
  return <>{children}</>;
};

export { PerformanceProvider };
