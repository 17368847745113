// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { Check as CheckmarkIcon } from "icon/material";
import { makeStyles, Button, classnames, Spinner } from "ui";
import { useTheme } from "theme";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  label: {
    position: "relative",
    whiteSpace: "nowrap",
  },
  spinner: {
    top: 0,
    left: 0,
    transform: "unset",
  },
  hideText: {
    color: "transparent !important",
  },
  danger: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  icon: {
    position: "absolute",
    color: theme.palette.common.white,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const FormSubmitButton = ({
  context,
  value,
  type = "submit",
  size = "medium",
  loading,
  disabled,
  finished,
  danger,
  children,
  ...rest
}) => {
  // Styles
  const classes = useStyles();
  const theme = useTheme();
  // Render
  return (
    <Button
      context={context}
      value={value}
      variant="contained"
      color="primary"
      size={size}
      type={type}
      disabled={disabled || loading || finished}
      classes={{
        root: classnames({
          [classes.hideText]: loading || finished,
          [classes.danger]: danger,
        }),
        label: classes.label,
      }}
      {...rest}
    >
      {loading && (
        <Spinner
          className={classes.spinner}
          color={theme.palette.grey[500]}
          size="small"
          position="center"
        />
      )}
      {finished && <CheckmarkIcon className={classes.icon} />}
      {children}
    </Button>
  );
};

FormSubmitButton.propTypes = {
  value: PropTypes.string,
  type: PropTypes.oneOf(["submit", "button", "reset"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  loading: PropTypes.bool,
  finished: PropTypes.bool,
  disabled: PropTypes.bool,
  danger: PropTypes.bool,
  children: PropTypes.node,
};

export default FormSubmitButton;
