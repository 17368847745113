// React
import { useCallback } from "react";
// Helpers
import { has } from "@mefisto/utils";
// Components
import { useIntl } from "react-intl";

export const useTranslate = () => {
  // Framework
  const { messages, formatMessage } = useIntl();
  // Callback
  const translate = useCallback(
    (id, { params, defaultMessage, description } = {}) => {
      if (has(messages, id)) {
        return formatMessage({ id, defaultMessage, description }, params);
      } else {
        return id;
      }
    },
    [formatMessage, messages]
  );
  // Render
  return {
    translate,
    t: translate,
  };
};
