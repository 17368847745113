// Service
import {
  getMainDefinition,
  hasDirectives,
  removeDirectivesFromDocument,
} from "@apollo/client/utilities";
import { ApolloLink } from "@apollo/client";

class MultiLink extends ApolloLink {
  #config;
  #httpLink;
  #wsLinks = {};

  constructor(config, request) {
    super(request);
    this.#config = config;
    this.#httpLink = config.createHttpLink();
  }

  request(operation, forward) {
    if (!hasDirectives(["endpoint"], operation.query)) {
      if (forward) {
        return forward(operation);
      } else {
        return this.#httpLink.request(operation, forward);
      }
    }

    const endpointName = operation.query.definitions
      .find((definition) => definition.kind === "OperationDefinition")
      .directives.find((directive) => directive.name.value === "endpoint")
      .arguments.find((argument) => argument.name.value === "name").value.value;

    const query = removeDirectivesFromDocument(
      [{ name: "endpoint", remove: true }],
      operation.query
    );
    if (!query) {
      throw new Error("Error while removing directive endpoint");
    }

    operation.query = query;

    if (this.#config.endpoints[endpointName]) {
      operation.setContext({
        uri: `${this.#config.endpoints[endpointName]}${
          this.#config.httpSuffix || "/"
        }`,
      });
    } else if (process.env.NODE_ENV === "dev") {
      throw new Error(
        `${endpointName} is not defined in endpoints definitions`
      );
    }

    const definition = getMainDefinition(operation.query);
    if (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    ) {
      if (!this.#config.createWsLink) {
        throw new Error(
          `You tried to call a subscription without configuring "createWsLink" function:${operation.query}`
        );
      }
      if (!this.#wsLinks[endpointName]) {
        const endpoint = this.#config.endpoints[endpointName];
        const wsEndpoint = endpoint.startsWith("/")
          ? `${window.location.origin}${endpoint}`.replace("http", "ws")
          : endpoint.replace("http", "ws");
        this.#wsLinks[endpointName] = this.#config.createWsLink(
          `${wsEndpoint}${this.#config.wsSuffix || "/graphql/subscriptions"}`
        );
      }
      return this.#wsLinks[endpointName].request(operation, forward);
    }
    return this.#httpLink.request(operation, forward);
  }
}

export { MultiLink };
