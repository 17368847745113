// React
import React, { useEffect, useRef, useState } from "react";
import { findDOMNode } from "react-dom";
import PropTypes from "prop-types";
// Helpers
import { isNil, noop } from "@mefisto/utils";
// Framework
import {
  classnames,
  makeStyles,
  FormControl,
  FormHelperText,
  OutlinedInput,
  InputLabel,
} from "ui";
// Components
import OptionsGroup from "./components/OptionsGroup";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  label: {
    background: theme.palette.common.white,
    padding: `0 6px`,
  },
  input: {
    padding: "12px 14px",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const OptionsInput = ({
  error,
  label,
  value,
  options,
  fullWidth,
  exclusive,
  focused,
  onChange = noop,
  onFocus = noop,
  onBlur = noop,
}) => {
  // Styles
  const classes = useStyles();
  // Refs
  const labelRef = useRef(null);
  // State
  const [labelWidth, setLabelWidth] = useState(0);
  // Effects
  useEffect(() => {
    setLabelWidth(findDOMNode(labelRef.current).offsetWidth);
  }, []);
  // Handlers
  const handleFocus = () => onFocus();
  const handleBlur = () => onBlur();
  // Render
  return (
    <FormControl
      variant="outlined"
      fullWidth={fullWidth}
      error={!isNil(error)}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <InputLabel ref={labelRef} shrink className={classes.label}>
        {label}
      </InputLabel>
      <OutlinedInput
        className={classnames(focused && "Mui-focused")}
        classes={{ root: classes.input }}
        labelWidth={labelWidth}
        inputComponent={OptionsGroup}
        inputProps={{ value, options, exclusive, onChange }}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

OptionsInput.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.element,
      color: PropTypes.string,
    })
  ).isRequired,
  fullWidth: PropTypes.bool,
  exclusive: PropTypes.bool,
  onChange: PropTypes.func,
};

export default OptionsInput;
