// React
import React from "react";
// Helpers
import { map } from "@mefisto/utils";
// Framework
import { usePortal } from "stack/core";
import { Box, MenuList, MenuItem, ListItemText } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderPopoverList = ({ onSelection }) => {
  // Framework
  const { localization } = usePortal();
  // Render
  return (
    <Box mb={1}>
      <MenuList dense>
        {map(localization.languages, (language, key) => (
          <MenuItem
            key={key}
            dense
            disabled={language.locale === localization.locale}
            onClick={() => onSelection(language)}
          >
            <Box ml={1}>
              <ListItemText primary={language.title} />
            </Box>
          </MenuItem>
        ))}
      </MenuList>
    </Box>
  );
};

export default HeaderPopoverList;
