// React
import React, { useMemo } from "react";
// Helpers
import { compact } from "@mefisto/utils";
// Framework
import {
  useTheme,
  useUser,
  useTranslate,
  usePortal,
  colors,
} from "@mefisto/web";
// Icons
import {
  NavbarEducationIcon,
  NavbarMealPlanIcon,
  NavbarTrainingIcon,
  NavbarWorkoutPlansIcon,
  NavbarDashboardIcon,
  NavbarBodyIcon,
  NavbarAdminIcon,
  NavbarWeightIcon,
  NavbarHydrationIcon,
  NavbarMenstruationIcon,
  NavbarCourseIcon,
  NavbarShoppingListIcon,
} from "icons";
// Plugins
import { useSubscribed } from "@plugins/billing";
import { useOnboarded } from "@plugins/portal";

export const useNavbarItems = () => {
  // Framework
  const {
    user: {
      data: { isAdmin },
    },
  } = useUser();
  const { t } = useTranslate();
  const { navigation } = usePortal();
  // View Model
  const { subscribed } = useSubscribed();
  const { onboarded } = useOnboarded();
  // Styles
  const theme = useTheme();
  // Memo
  const navbarItems = useMemo(() => {
    return compact([
      isAdmin && {
        type: "item",
        icon: <NavbarAdminIcon />,
        color: colors.blueGrey["800"],
        path: navigation.paths("ADMIN").users,
      },
      {
        type: "item",
        icon: <NavbarDashboardIcon />,
        color: colors.blueGrey["800"],
        path: navigation.paths("PORTAL").dashboard,
      },
      ...(subscribed && onboarded
        ? [
            { type: "divider" },
            {
              type: "item",
              icon: <NavbarMealPlanIcon />,
              color: theme.palette.custom.blue,
              menu: [
                {
                  title: t(
                    "portal:viewModel.navbar.item.mealPlan.menu.mealPlan.title"
                  ),
                  path: navigation.paths("PORTAL").mealPlan,
                  icon: <NavbarMealPlanIcon />,
                },
                {
                  title: t(
                    "portal:viewModel.navbar.item.mealPlan.menu.shoppingList.title"
                  ),
                  path: navigation.paths("PORTAL").shoppingList,
                  icon: <NavbarShoppingListIcon />,
                },
              ],
            },
            {
              type: "item",
              icon: <NavbarTrainingIcon />,
              color: theme.palette.custom.green,
              menu: [
                {
                  title: t(
                    "portal:viewModel.navbar.item.workoutPlans.menu.workoutPlans.title"
                  ),
                  path: navigation.paths("PORTAL").workoutPlans,
                  icon: <NavbarWorkoutPlansIcon />,
                },
              ],
            },
            {
              type: "item",
              icon: <NavbarEducationIcon />,
              color: theme.palette.custom.red,
              menu: [
                {
                  title: t(
                    "portal:viewModel.navbar.item.courses.menu.courses.title"
                  ),
                  path: navigation.paths("PORTAL").courses,
                  icon: <NavbarCourseIcon />,
                },
              ],
            },
            {
              type: "item",
              icon: <NavbarBodyIcon />,
              color: theme.palette.custom.yellow,
              menu: [
                {
                  title: t(
                    "portal:viewModel.navbar.item.body.menu.weight.title"
                  ),
                  path: navigation.paths("PORTAL").weight,
                  icon: <NavbarWeightIcon />,
                },
                {
                  title: t(
                    "portal:viewModel.navbar.item.body.menu.hydration.title"
                  ),
                  path: navigation.paths("PORTAL").hydration,
                  icon: <NavbarHydrationIcon />,
                },
                {
                  title: t(
                    "portal:viewModel.navbar.item.body.menu.menstruation.title"
                  ),
                  path: navigation.paths("PORTAL").menstruation,
                  icon: <NavbarMenstruationIcon />,
                },
              ],
            },
          ]
        : []),
    ]);
  }, [t, navigation, theme, isAdmin, onboarded, subscribed]);
  // Render
  return { navbarItems };
};
