// React
import React, {
  Fragment,
  memo,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { isFunction, map, noop } from "@mefisto/utils";
// Framework
import { EntityPropType } from "model/utils";
import { useEntityListLazy } from "model/hooks";
import { Collection } from "ui/collection";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelCollection = forwardRef(
  (
    {
      entity: Entity,
      relation,
      input,
      resources,
      languages,
      search,
      pagination,
      fetchPolicy,
      nextFetchPolicy,
      tags,
      dataMapper,
      collectionProps,
      emptyPlaceholderProps,
      children,
      onChange,
    },
    ref
  ) => {
    // Model
    const {
      error,
      data: list,
      fetch,
      fetchMore,
      loading,
      loadingMore,
    } = useEntityListLazy(Entity, {
      input,
      resources,
      languages,
      search,
      relation,
      pagination,
      fetchPolicy,
      nextFetchPolicy,
      tags,
    });
    // Ref
    useImperativeHandle(ref, () => ({
      async refresh() {
        return fetch();
      },
    }));
    // Effects
    useEffect(() => {
      fetch().catch(noop);
    }, [fetch]);
    useEffect(() => {
      onChange?.(list, { loading, loadingMore, error, data: list });
    }, [onChange, loading, loadingMore, error, list]);
    // Handlers
    const handleRefresh = useCallback(() => {
      return fetch();
    }, [fetch]);
    const handleLoadMore = useCallback(() => {
      return fetchMore();
    }, [fetchMore]);
    return (
      <Collection
        loading={loading}
        loadingMore={loadingMore}
        error={error}
        hasMore={list.hasMore}
        emptyPlaceholderProps={emptyPlaceholderProps}
        onLoadMore={handleLoadMore}
        onRefresh={handleRefresh}
        {...collectionProps}
      >
        {map(dataMapper ? dataMapper(list.data) : list.data, (data, key) => (
          <Fragment key={key}>
            {isFunction(children) ? children(data) : children}
          </Fragment>
        ))}
      </Collection>
    );
  }
);

ModelCollection.propTypes = {
  /**
   * Model entity used for the feed
   */
  entity: EntityPropType,
  /**
   * Entity relation
   */
  relation: PropTypes.string,
  /**
   * Input data
   */
  input: PropTypes.object,
  /**
   * Resources data
   */
  resources: PropTypes.object,
  /**
   * List of requested languages
   */
  languages: PropTypes.array,
  /**
   * Search query
   */
  search: PropTypes.string,
  /**
   * Pagination props
   */
  pagination: PropTypes.shape({
    pointer: PropTypes.string,
    limit: PropTypes.number,
    direction: PropTypes.oneOf(["ASC", "DESC"]),
    orderBy: PropTypes.string,
    equalTo: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  /**
   * Fetch policy of the list request
   */
  fetchPolicy: PropTypes.string,
  /**
   * Next fetch policy of the list request
   */
  nextFetchPolicy: PropTypes.string,
  /**
   * Custom tags used to list the data
   */
  tags: PropTypes.any,
  /**
   * Data filtering function
   */
  dataMapper: PropTypes.func,
  /**
   * Props passed to Collection
   */
  collectionProps: PropTypes.object,
  /**
   * Empty placeholder data
   */
  emptyPlaceholderProps: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  /**
   * Children node or func. Use to display each feed item.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Called when data in feed change
   */
  onChange: PropTypes.func,
};

export default memo(ModelCollection);
