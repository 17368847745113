// React
import React, { useMemo, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
// Helpers
import { reduce } from "@mefisto/utils";
// Framework
import { useMounted } from "hooks";
import { usePortal } from "stack";
// Components
import NavigationLayoutProvider from "../NavigationLayoutContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const NavigationLayout = ({ layoutProps = {}, children }) => {
  // Framework
  const { navigation } = usePortal();
  const { callWhenMounted } = useMounted();
  // Memo
  const pathMap = useMemo(() => {
    return reduce(
      navigation.paths(),
      (result, path, key) => {
        result[path] = key;
        return result;
      },
      {}
    );
  }, [navigation]);
  // Callbacks
  const isCurrentPath = useCallback(
    (path) => {
      return !!pathMap[path];
    },
    [pathMap]
  );
  // State
  const [isCurrentLayout, setCurrentLayout] = useState(
    isCurrentPath(navigation.path)
  );
  // Effect
  useEffect(() => {
    return navigation.onChange(
      callWhenMounted(({ path }) => {
        setCurrentLayout(isCurrentPath(path));
      })
    );
  }, [callWhenMounted, navigation, isCurrentPath]);
  // Render
  return (
    <NavigationLayoutProvider layoutProps={layoutProps}>
      {isCurrentLayout && children}
    </NavigationLayoutProvider>
  );
};

NavigationLayout.propTypes = {
  layoutProps: PropTypes.object,
  children: PropTypes.node,
};

export default NavigationLayout;
