// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { isString } from "@mefisto/utils";
// Framework
import { withStyles, Box, Container } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = withStyles((theme) => ({
  maxWidthXs: {
    [theme.breakpoints.up("xs")]: {
      maxWidth: Math.max(
        theme.breakpoints.values.xs - theme.spacing(2 + 2),
        444
      ),
    },
  },
  maxWidthSm: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: theme.breakpoints.values.sm - theme.spacing(3 + 3),
    },
  },
  maxWidthMd: {
    [theme.breakpoints.up("md")]: {
      maxWidth: theme.breakpoints.values.md - theme.spacing(3 + 3),
    },
  },
  maxWidthLg: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: theme.breakpoints.values.lg - theme.spacing(3 + 3),
    },
  },
  maxWidthXl: {
    [theme.breakpoints.up("xl")]: {
      maxWidth: theme.breakpoints.values.xl - theme.spacing(3 + 3),
    },
  },
}))(Container);

const HeaderInnerContainer = ({ horizontalFill, children }) => (
  <Box flexGrow={1}>
    <Component
      disableGutters
      maxWidth={isString(horizontalFill) ? horizontalFill : !horizontalFill}
    >
      <Box
        display="flex"
        flexWrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        {children}
      </Box>
    </Component>
  </Box>
);

HeaderInnerContainer.propTypes = {
  horizontalFill: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.node,
};

export default HeaderInnerContainer;
