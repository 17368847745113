/**
 * List of app's asset files
 */
export const assetFiles = [
  "index.html",
  "asset-manifest.json",
  "runtime-main.*.js",
  "main.*.js",
  ".*.chunk.js",
  ".*.css",
];

/**
 * Creates pattern for assets path matching
 */
export const makeAssetPattern = () => {
  return new RegExp("/static/.*(" + assetFiles.join("|") + ")$");
};

/**
 * Returns asset manifest path for the given public URL.
 */
export const getAssetManifest = async (cache, publicURL) => {
  const manifestPath = `${publicURL}/asset-manifest.json`;
  // Get manifest from cache
  let cachedManifest = await cache.match(manifestPath);
  if (!cachedManifest) {
    // Add manifest to cache
    await cache.add(manifestPath);
    cachedManifest = await cache.match(manifestPath);
  }
  if (!cachedManifest.ok) {
    throw Error("Cannot read asset-manifest.json");
  }
  return cachedManifest.json();
};
