// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import {
  makeStyles,
  Backdrop,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { classnames } from "ui/classnames";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    borderRadius: "inherit",
    zIndex: theme.zIndex.modal,
  },
  modal: {
    position: "fixed",
    zIndex: theme.zIndex.tooltip,
  },
  backdropBlur: {
    backdropFilter: "blur(5px)",
    color: theme.palette.common.white,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModalBackdrop = ({
  modal,
  open,
  spinner,
  title,
  disableBackdropBlur,
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Backdrop
      open={open}
      className={classnames(classes.root, {
        [classes.modal]: modal,
        [classes.backdropBlur]: !disableBackdropBlur,
      })}
    >
      <Grid container alignItems="center" justifyContent="center" spacing={2}>
        {spinner && (
          <Grid item>
            <CircularProgress size={24} color="inherit" />
          </Grid>
        )}
        {title && (
          <Grid item>
            <Typography variant="subtitle2" color="inherit">
              {title}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Backdrop>
  );
};

ModalBackdrop.propTypes = {
  /**
   * If `true`, the backdrop blur is not applied.
   */
  disableBackdropBlur: PropTypes.bool,
  /**
   * If `true` the backdrop is modal
   */
  modal: PropTypes.bool,
  /**
   * If `true` the backdrop is visible
   */
  open: PropTypes.bool,
  /**
   * If `true` the spinner is added to center
   */
  spinner: PropTypes.bool,
  /**
   * Title displayed in the center
   */
  title: PropTypes.string,
};

export default ModalBackdrop;
