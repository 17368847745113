// Helpers
import { isEmpty, toNumber as _toNumber, toString } from "@mefisto/utils";

export const toNumber = (value) => {
  if (isEmpty(value)) {
    return null;
  }
  return _toNumber(value);
};

export const fromNumber = (value) => {
  if (isEmpty(toString(value))) {
    return "";
  }
  return toString(value);
};
