// React
import React from "react";
// Framework
import { Facebook as Icon } from "icon/social";
// Components
import ProviderButton from "../ProviderButton";

const FacebookButton = (props) => (
  <ProviderButton
    name="Facebook"
    color="#3b5998"
    icon={<Icon fontSize="inherit" />}
    {...props}
  />
);

export default FacebookButton;
