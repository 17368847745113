// React
import React, { useEffect, useState, useCallback } from "react";
// Framework
import { useMounted } from "hooks";
import { usePortal } from "stack/core";
import { useTranslate } from "localization";
import { ModalBackdrop } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const PWAVersionSnackbar = () => {
  // Framework
  const { t } = useTranslate();
  const { pwa, snackbar } = usePortal();
  const { callWhenMounted } = useMounted();
  // State
  const [backdrop, setBackdrop] = useState(false);
  const [state, setState] = useState(null);
  // Callbacks
  const handleAction = useCallback(() => {
    setBackdrop(true);
    pwa.update();
  }, [pwa]);
  // Effect
  useEffect(() => {
    return pwa.onChange(
      callWhenMounted((state) => {
        setState(state);
      }),
      true
    );
  }, [callWhenMounted, pwa]);
  useEffect(() => {
    if (state === "waiting") {
      snackbar.showPersistent({
        key: "pwa:versionUpdate",
        message: t("core:sw.versionUpdateSnackbar.message"),
        action: t("core:sw.versionUpdateSnackbar.action"),
        onAction: handleAction,
      });
    }
  }, [t, state, snackbar, handleAction]);
  // Render
  return (
    <ModalBackdrop
      modal
      spinner
      open={backdrop}
      title={t("core:sw.versionUpdateSnackbar.title")}
    />
  );
};

export default PWAVersionSnackbar;
