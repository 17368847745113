// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// Helpers
import { isFunction } from "@mefisto/utils";
// Framework
import { usePortal } from "stack";
import { Tooltip } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TimeAgo = ({
  timestamp,
  tooltipFormat = "LLL (z)",
  timeout = 15000,
  children,
}) => {
  // Framework
  const { time } = usePortal();
  // State
  const [value, setValue] = useState(time.timeAgo(timestamp));
  // Effects
  useEffect(() => {
    const handleInterval = () => setValue(time.timeAgo(timestamp));
    const interval = setInterval(handleInterval, timeout);
    handleInterval();
    return () => clearInterval(interval);
  }, [time, timeout, timestamp]);
  // Render
  return (
    <Tooltip title={time.fromUtcTimestamp(timestamp).format(tooltipFormat)}>
      <div>{isFunction(children) ? children(value) : value}</div>
    </Tooltip>
  );
};

TimeAgo.propTypes = {
  timestamp: PropTypes.number.isRequired,
  tooltipFormat: PropTypes.string,
  timeout: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default TimeAgo;
