// React
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
// Framework
import { UserInteraction } from "analytics";
import { Section } from "ui/section";
import { IconButton as Component } from "@material-ui/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const IconButton = forwardRef(
  ({ context = "button", value, children, ...props }, ref) => (
    <UserInteraction
      ref={ref}
      element="button"
      value={value}
      component={Component}
      componentProps={props}
    >
      <Section context={context} value={value}>
        {children}
      </Section>
    </UserInteraction>
  )
);

IconButton.propTypes = {
  /**
   * Section context name
   */
  context: PropTypes.string,
  /**
   * Element specification value
   */
  value: PropTypes.string,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  /**
   * If `true`, the button will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the  keyboard focus ripple will be disabled.
   */
  disableFocusRipple: PropTypes.bool,
  /**
   * If `true`, the ripple effect will be disabled.
   */
  disableRipple: PropTypes.bool,
  /**
   * If given, uses a negative margin to counteract the padding on one
   * side (this is often helpful for aligning the left or right
   * side of the icon with content above or below, without ruining the border
   * size and shape).
   */
  edge: PropTypes.oneOf(["start", "end", false]),
  /**
   * The size of the button.
   * `small` is equivalent to the dense button styling.
   */
  size: PropTypes.oneOf(["small", "medium"]),
  /**
   * The content of the button.
   */
  children: PropTypes.node,
};

export default IconButton;
