// React
import React, { useContext } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";
import { HelmetProvider as Provider } from "react-helmet-async";
// Context
import { HelmetContext } from "./HelmetContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({ children }) => {
  // Framework
  const { helmet } = usePortal();
  // Context
  const options = useContext(HelmetContext);
  // Init
  useStackDependency(helmet, options);
  // Render
  return <Provider>{children}</Provider>;
};

const HelmetProvider = ({ options, children }) => (
  <HelmetContext.Provider value={{}}>
    <Component>
      {options}
      {children}
    </Component>
  </HelmetContext.Provider>
);

HelmetProvider.propTypes = {
  options: PropTypes.element,
  children: PropTypes.node,
};

export { HelmetProvider };
