// React
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";
// Context
import { FormatContext } from "./FormatContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({ children }) => {
  // Framework
  const { format } = usePortal();
  // Context
  const options = useContext(FormatContext);
  // Init
  useStackDependency(format, options);
  // State
  const [ready, setReady] = useState(false);
  // Effect
  useEffect(() => {
    format
      .load()
      .then(() => setReady(true))
      .catch(() => setReady(true));
  }, [format]);
  // Render
  return <>{ready && children}</>;
};

const FormatProvider = ({ options, children }) => (
  <FormatContext.Provider value={{}}>
    {options}
    <Component>{children}</Component>
  </FormatContext.Provider>
);

FormatProvider.propTypes = {
  options: PropTypes.element,
};

export { FormatProvider };
