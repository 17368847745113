// React
import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { map, isEmpty, slice, first, get } from "@mefisto/utils";
// Framework
import { Dropzone, FileChip } from "storage";
import { FormControl, FormHelperText, Grid } from "ui";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({
  label,
  helperText,
  multiple,
  accept,
  dropzoneProps,
  field: { name, value },
  form: { touched, errors, setFieldValue },
}) => {
  // Memo
  const errorText = useMemo(() => {
    return get(errors, name);
  }, [errors, name]);
  const hasError = useMemo(() => {
    return get(touched, name) && Boolean(errorText);
  }, [touched, name, errorText]);
  // Handlers
  const handleDrop = useCallback(
    (files) => {
      const fieldValue = multiple ? [...value, ...files] : first(files);
      setFieldValue(name, fieldValue);
    },
    [setFieldValue, name, value, multiple]
  );
  const handleClear = useCallback(
    (index) => {
      setFieldValue(name, [
        ...slice(value, 0, index),
        ...slice(value, index + 1),
      ]);
    },
    [setFieldValue, name, value]
  );
  // Render
  return (
    <FormControl fullWidth component="fieldset" error={hasError}>
      <Grid container direction="column" spacing={1}>
        {!isEmpty(value) && (
          <Grid item>
            <Grid container spacing={1}>
              {map(multiple ? value : [value], (file, index) => (
                <Grid item key={index}>
                  <FileChip
                    label={file.name}
                    type={file.type ?? file.contentType}
                    onDelete={() => handleClear(index)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Dropzone
            multiple={multiple}
            label={label}
            accept={accept}
            error={hasError}
            onDrop={handleDrop}
            {...dropzoneProps}
          />
        </Grid>
      </Grid>
      <FormHelperText>{hasError ? errorText : helperText}</FormHelperText>
    </FormControl>
  );
};

const FormFileField = (props) => {
  return <FormField component={Component} {...props} />;
};

FormFileField.propTypes = {
  /**
   * The name used to reference the value of the control.
   * If you don't provide this prop, it falls back to a randomly generated name.
   */
  name: PropTypes.string,
  /**
   * Value of the selected radio button. The DOM API casts this to a string.
   */
  value: PropTypes.any,
  /**
   * Label passed to FormLabel
   */
  label: PropTypes.node,
  /**
   * The helper text content.
   */
  helperText: PropTypes.node,
  /**
   * List of formats that should be accepted.
   */
  accept: PropTypes.object,
  /**
   * Whether the form field should accept multiple files
   */
  multiple: PropTypes.bool,
  /**
   * Props passed to dropzone component
   */
  dropzoneProps: PropTypes.object,
};

export default FormFileField;
