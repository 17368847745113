// React
import React, { Children, cloneElement, useCallback } from "react";
import PropTypes from "prop-types";
// Framework
import { Section, Box } from "ui";
import { useTranslate } from "localization";
import { FormSubmitButton } from "form";
import { useDeepMemo } from "hooks";
// Components
import ModelDialogButtons from "../ModelDialogButtons";
import ModelDialogContent from "../ModelDialogContent";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelDialogActionVoid = ({
  context = "void",
  value,
  action,
  children,
  onNext,
  onFinish,
  onClose,
  __display,
  __options,
}) => {
  // Props
  const { input, resources, languages, options = {} } = useDeepMemo(
    () => __options ?? {},
    [__options]
  );
  const {
    display = "individual",
    tab = 0,
    tabData,
    isLastTab = false,
    onNext: __onNext,
    onFinish: __onFinish,
    onClose: __onClose,
  } = useDeepMemo(() => __display ?? {}, [__display]);
  // Framework
  const { translate } = useTranslate();
  // Creates payload
  const getPayload = useCallback(
    ({ tabData }) => ({
      action,
      input,
      resources,
      languages,
      options,
      ...(tabData && { tabData }),
    }),
    [action, input, resources, languages, options]
  );
  // Callbacks
  const getContent = useCallback(
    ({ tabData }) => {
      const child = Children.toArray(children)[tab];
      return cloneElement(child, getPayload({ tabData }));
    },
    [children, getPayload, tab]
  );
  // Handlers
  const handleNext = useCallback(() => {
    __onNext?.();
    onNext?.();
  }, [__onNext, onNext]);
  const handleFinish = useCallback(() => {
    const payload = getPayload({ tabData });
    __onFinish?.(payload);
    onFinish?.(payload);
  }, [__onFinish, onFinish, getPayload, tabData]);
  const handleClose = useCallback(() => {
    __onClose?.();
    onClose?.();
  }, [__onClose, onClose]);
  // Render
  return (
    <Section context={context} value={value}>
      <Box
        display="flex"
        flexDirection="column"
        flex="1 1 auto"
        overflow="inherit"
        height="100%"
      >
        <ModelDialogContent display={display}>
          {getContent({ tabData })}
        </ModelDialogContent>
        <ModelDialogButtons onClose={handleClose}>
          {display === "individual" && (
            <FormSubmitButton
              value="submit"
              type="button"
              onClick={handleFinish}
            >
              {translate("core:model.dialog.void.button.finish")}
            </FormSubmitButton>
          )}
          {display === "group" && (
            <>
              {isLastTab ? (
                <FormSubmitButton
                  value="finish"
                  type="button"
                  onClick={handleFinish}
                >
                  {translate("core:model.dialog.void.button.finish")}
                </FormSubmitButton>
              ) : (
                <FormSubmitButton
                  value="next"
                  type="button"
                  onClick={handleNext}
                >
                  {translate("core:model.dialog.void.button.next")}
                </FormSubmitButton>
              )}
            </>
          )}
        </ModelDialogButtons>
      </Box>
    </Section>
  );
};

ModelDialogActionVoid.displayName = "ModelDialogActionVoid";

ModelDialogActionVoid.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onNext: PropTypes.func,
  onFinish: PropTypes.func,
  onClose: PropTypes.func,
};

export default ModelDialogActionVoid;
