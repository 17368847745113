// Framework
import { StackDependency } from "stack/dependency";
// Helpers
import { reduce, map, keys, snakeCase, join, compact } from "@mefisto/utils";

// noinspection JSUnusedGlobalSymbols
export class Analytics extends StackDependency {
  #analytics;

  onInitialized() {
    const { firebase, log } = this.context;
    if (firebase.analytics) {
      this.#analytics = firebase.analytics();
      log.info("📈", "Analytics [On]");
    }
  }

  /**
   * Sends analytics event with given eventParams.
   * This method automatically associates this logged event with
   * this Firebase web app instance on this device.
   *
   * @see https://firebase.google.com/docs/analytics/events
   * @param eventName {string} Unique name of the event
   * @param eventParams {object?} List of event params
   */
  logEvent(eventName, eventParams) {
    this.#analytics?.logEvent(eventName, eventParams);
  }

  /**
   * Logs error as an exception
   *
   * @param error {Error} Error object
   * @param fatal {boolean} Set to `true` if error was fatal
   */
  logError(error, { fatal = false } = {}) {
    this.logEvent("exception", { description: error?.message, fatal });
  }

  /**
   * Logs user interaction with any element
   *
   * @param element {string} Type of element (e.g. button)
   * @param context {string} Context of the element {e.g. organization, location}
   * @param value {string} Element identifier value (e.g. refresh)
   * @param action {string} Interaction type (e.g. click)
   */
  logUserInteraction({ element, context, value, action }) {
    const { log } = this.context;
    const elementContext = join(compact([context, element]), ":");
    const elementValue = join(compact([elementContext, value]), "#");
    const elementAction = join(compact([elementValue, action]), "/");
    log.info("🖱️", elementAction);
    this.logEvent("select_content", {
      content_type: element,
      item_id: elementAction,
    });
  }

  /**
   * Send this event to signify that a user has logged in.
   *
   * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#login
   * @param method {string?} The method used to log in, e.g. Google
   */
  logLogin({ method } = {}) {
    this.logEvent("login", { method });
  }

  /**
   * This event signifies when one or more items is purchased by a user.
   *
   * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase
   * @param transactionId {string}
   * @param currency {string}
   * @param value {number}
   * @param affiliation {string?}
   * @param coupon {string?}
   * @param shipping {number?}
   * @param tax {number?}
   * @param items {array}
   */
  logPurchase({
    currency,
    transactionId,
    value,
    affiliation,
    coupon,
    shipping,
    tax,
    items,
  } = {}) {
    this.logEvent("purchase", {
      currency,
      transaction_id: transactionId,
      value,
      affiliation,
      shipping,
      tax,
      coupon,
      items: map(items, this.#mapItem),
    });
  }

  /**
   * This event indicates that a user has signed up for an account.
   * Use this event to understand the different behaviors of logged in and logged out users.
   *
   * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#sign_up
   * @param method {string?} The method used for sign up, e.g. Google
   */
  logSignUp({ method } = {}) {
    this.logEvent("sign_up", { method });
  }

  /**
   * Use this event to contextualize search operations.
   * This event can help you identify the most popular content in your app.
   *
   * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#search
   * @param searchTerm {string} The term that was searched for.
   */
  logSearch({ searchTerm } = {}) {
    this.logEvent("search", { search_term: searchTerm });
  }

  /**
   * This event signifies that a user has selected some content of a certain type.
   * This event can help you identify popular content and categories of content in your app.
   *
   * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#select_content
   * @param contentType {string} The type of selected content.
   * @param itemId {string} An identifier for the item that was selected.
   */
  logSelectContent({ contentType, itemId } = {}) {
    this.logEvent("select_content", {
      content_type: contentType,
      item_id: itemId,
    });
  }

  /**
   * Use this event when a user has shared content.
   *
   * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#share
   * @param method {string} The method in which the content is shared.
   * @param contentType {string} The type of shared content.
   * @param itemId {string} The ID of the shared content.

   */
  logShare({ method, contentType, itemId } = {}) {
    this.logEvent("share", {
      method,
      content_type: contentType,
      item_id: itemId,
    });
  }

  /**
   * Use gtag 'config' command to set 'user_id'.
   *
   * @see https://firebase.google.com/docs/analytics/userid
   * @param id {string} User ID

   */
  setUserId(id) {
    this.#analytics?.setUserId(id);
  }

  /**
   * User properties are attributes you define to describe segments of your user base,
   * such as language preference or geographic location.
   * These can be used to define audiences for your app.
   * @see https://firebase.google.com/docs/analytics/user-properties
   * @param properties {object}

   */
  setUserProperties(properties) {
    this.#analytics?.setUserProperties(properties);
  }

  /**
   * Google Analytics tracks screen transitions and attaches information about the current
   * screen to events, enabling you to track metrics such as user engagement or user behavior
   * per screen
   * @see https://firebase.google.com/docs/analytics/screenviews
   * @param screenName {string}

   */
  setCurrentScreen(screenName) {
    this.#analytics?.setCurrentScreen(screenName);
  }

  /**
   * Sets whether analytics collection is enabled for this app on this device
   * @param enabled {boolean}
   */
  setAnalyticsCollectionEnabled(enabled) {
    this.#analytics?.setAnalyticsCollectionEnabled(enabled);
  }

  /**
   * Maps items keys to snake case
   */
  #mapItem = (item) => {
    return reduce(
      keys(item),
      (result, key) => {
        result[snakeCase(key)] = item[key];
        return result;
      },
      {}
    );
  };
}
