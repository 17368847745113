// React
import React, { useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { get, map, replace, isEmpty } from "@mefisto/utils";
// Framework
import { usePortal } from "stack";
import { FormField } from "form/components";
import { Autocomplete, TextField, Typography } from "ui/components";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({
  label,
  placeholder,
  helperText,
  disabled,
  fullWidth,
  autoFocus,
  autoFillPath,
  textFieldProps = {},
  autocompleteProps = {},
  field: { name, value },
  form: { values, touched, errors, setFieldValue },
}) => {
  // Framework
  const { time } = usePortal();
  // Callbacks
  const formatTimezone = useCallback((timezone = "") => {
    return replace(replace(timezone, /\//g, " / "), "_", " ");
  }, []);
  // Memo
  const formattedValue = useMemo(() => {
    return value
      ? {
          timezone: value,
          label: formatTimezone(value),
        }
      : null;
  }, [formatTimezone, value]);
  const options = useMemo(() => {
    return map(time.timezones(), (timezone) => ({
      timezone,
      label: formatTimezone(timezone),
    }));
  }, [time, formatTimezone]);
  const errorText = useMemo(() => {
    return get(errors, name);
  }, [errors, name]);
  const hasError = useMemo(() => {
    return Boolean(get(touched, name) && errorText);
  }, [touched, name, errorText]);
  // Handlers
  const handleChange = useCallback(
    (event, value) => {
      setFieldValue(name, value.timezone);
    },
    [name, setFieldValue]
  );
  // Effect
  useEffect(() => {
    if (autoFillPath) {
      const autoFillValue = get(values, autoFillPath);
      if (!isEmpty(autoFillValue) && isEmpty(value)) {
        setFieldValue(name, autoFillValue);
      }
    }
  }, [values, value, name, autoFillPath, setFieldValue]);
  // Render
  return (
    <Autocomplete
      autoComplete
      disableClearable
      options={options}
      value={formattedValue}
      fullWidth={fullWidth}
      getOptionSelected={(option, value) => option.timezone === value.timezone}
      getOptionLabel={(option) => option?.label ?? ""}
      renderOption={(option) => (
        <Typography noWrap variant="subtitle2">
          {option.label}
        </Typography>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          autoFocus={autoFocus}
          label={label}
          placeholder={placeholder}
          helperText={hasError ? errorText : helperText}
          error={hasError}
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
          {...textFieldProps}
        />
      )}
      onChange={handleChange}
      {...autocompleteProps}
    />
  );
};

const TimezoneFormField = (props) => (
  <FormField fullWidth variant="outlined" component={Component} {...props} />
);

TimezoneFormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  fullWidth: PropTypes.bool,
  autoFillPath: PropTypes.string,
  textFieldProps: PropTypes.object,
  autocompleteProps: PropTypes.object,
};

export default TimezoneFormField;
