// React
import { useCallback, useEffect } from "react";
// Framework
import { usePortal } from "stack";
import { useForceRefresh, useMounted } from "hooks";

/**
 * Returns permission function that returns `true` whenever
 * the user has the given permission. You can also pass custom
 * `app` or `resource` parameter. If no options are passed the system
 * decides which app or resource to use.
 *
 * @summary The permission function forces rerender whenever the permission result changes.
 * @example The resulting object contains alias `p` function.
            So instead permission("foo.query") you can use p("foo.query") shorthand.
 * @return {{
     p: (function(permission: string, options?: {app?: string, resource?: string, scopes?: [string]}): boolean),
     permission: (function(permission: string, options?: {app?: string, resource?: string, scopes?: [string]}): boolean)
   }}
 */
export const usePermission = () => {
  // Framework
  const portal = usePortal();
  const { callWhenMounted } = useMounted();
  const { forceRefresh } = useForceRefresh();
  // Callbacks
  const permission = useCallback(
    (permission, options = {}) => {
      const { result } = portal.permission.push(permission, options);
      return result;
    },
    [portal.permission]
  );
  // Effects
  useEffect(() => {
    return portal.permission.onChange(
      callWhenMounted(() => {
        forceRefresh();
      })
    );
  }, [callWhenMounted, portal.permission, forceRefresh]);
  // Render
  return { p: permission, permission };
};
