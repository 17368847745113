// Helpers
import { degreesToRadian } from "../helpers";

/**
 * Rotation manipulation
 */
export const rotate = (
  degrees,
  { padding = 0, backgroundColor } = {}
) => async (canvasImage) => {
  // Create resulting canvas
  let canvas = document.createElement("canvas");
  let context = canvas.getContext("2d");
  // Get angle
  let calcAngle;
  if (degrees < 0) {
    degrees = 360 + degrees;
  }
  if (degrees >= 0 && degrees <= 90) {
    calcAngle = 90 - degrees;
  } else {
    calcAngle = 270 - degrees;
  }
  // To radians
  const degreesRad = degreesToRadian(degrees);
  const calcAngleRad = degreesToRadian(calcAngle);
  const angleRad = degreesToRadian(degrees);
  // Calculate sin cos
  const sinCos = {
    cosAngle: Math.cos(angleRad),
    cosCalcAngle: Math.cos(calcAngleRad),
    sinAngle: Math.sin(angleRad),
    sinCalcAngle: Math.sin(calcAngleRad),
  };
  // Get module of sin/cos
  for (let key in sinCos) {
    if (sinCos[key] < 0) {
      sinCos[key] = sinCos[key] * -1;
    }
  }
  // Calculate w/h on rotate changes
  canvas.width =
    canvasImage.width * sinCos.cosAngle +
    canvasImage.height * sinCos.cosCalcAngle +
    padding;
  canvas.height =
    canvasImage.width * sinCos.sinAngle +
    canvasImage.height * sinCos.sinCalcAngle +
    padding;
  // Set background color
  if (backgroundColor) {
    context.beginPath();
    context.rect(0, 0, canvas.width, canvas.height);
    context.fillStyle = backgroundColor;
    context.fill();
    context.save();
  }
  context.save();
  // Rotate
  context.translate(canvas.width / 2, canvas.height / 2);
  context.rotate(degreesRad);
  context.drawImage(
    canvasImage,
    -(canvasImage.width / 2),
    -(canvasImage.height / 2)
  );
  context.restore();

  return canvas;
};
