// React
import React from "react";
// Framework
import {
  Portal,
  Firebase,
  Navigation,
  Localization,
  Language,
  Format,
  FormatPrice,
  Interceptors,
  LocalizationInterceptor,
} from "@mefisto/web";
// Apps
import AdminApp from "@apps/admin";
import AuthApp from "@apps/auth";
import PortalApp from "@apps/portal";
import PresentationApp from "@apps/presentation";
// Plugins
import { PixelInterceptor, TagManagerInterceptor } from "@plugins/analytics";
import { NavbarPortalLayoutPlugin } from "@plugins/portal";
// Components
import { Externals } from "externals";
import { Helmet } from "helmet";
import { Themes } from "themes";
import { DashboardInterceptor } from "interceptors";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default () => (
  <Portal>
    <Externals />
    <Helmet />
    <Themes />
    <Firebase modules={["analytics", "auth", "database", "messaging"]} />
    <Localization
      fallback="es"
      languages={[
        <Language locale="es" title="Español" />,
        // <Language locale="en" title="English" />,
      ]}
    />
    <Format>
      <FormatPrice locale="en" maximumFractionDigits={0} />
    </Format>
    <Interceptors
      interceptors={[
        <LocalizationInterceptor />,
        <DashboardInterceptor />,
        <PixelInterceptor />,
        <TagManagerInterceptor />,
      ]}
    />
    <Navigation
      routes={{
        home: "/",
        authentication: "/auth",
        unauthenticated: "/portal/dashboard",
        dashboard: "/portal/dashboard",
        onboarding: "/portal/onboarding",
        regenerate: "/portal/regenerate",
        subscription: "/portal/billing/subscription/user",
        profile: "/portal/profile",
        organization: "/portal/organization",
        organizationCreate: "/portal/organization/get-started",
        location: "/portal/locations/:location",
        memberships: "/portal/memberships",
        connections: "/portal/connections",
        notVerified: "/auth/verify",
        notAuthenticated: "/auth/sign-in",
        notAdmin: "/portal",
        notOrganization: "/portal/organization/get-started",
      }}
    />
    {/* Apps */}
    <AdminApp />
    <AuthApp />
    <PortalApp />
    <PresentationApp />
    {/* Plugins */}
    <NavbarPortalLayoutPlugin position="bottom" />
  </Portal>
);
