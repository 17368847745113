import { createFilter } from "../helpers";

/**
 * Vintage filter manipulation
 */
export const vintage = () => async (canvasImage) =>
  createFilter(canvasImage, (imageData) => {
    for (let i = 0; i < imageData.data.length; i += 4) {
      imageData.data[i] += 120;
      imageData.data[i + 1] += 70;
      imageData.data[i + 2] += 13;
    }
    return imageData;
  });
