// React
import React, { useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { join, compact, toLower, isEmpty } from "@mefisto/utils";
// Framework
import { usePortal } from "stack/core";
// Components
import { useSection } from "./useSection";
import { SectionContext } from "./SectionContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Section = ({ context, value, children }) => {
  // Framework
  const { apps } = usePortal();
  // Context
  const { path: parentPath } = useSection();
  // Memo
  const result = useMemo(() => {
    const rootPath = isEmpty(parentPath)
      ? [`app#${toLower(apps.current?.id)}`]
      : parentPath;
    const path = compact([...rootPath, join(compact([context, value]), "#")]);
    return {
      path,
      context: join(path, ":"),
    };
  }, [parentPath, context, value, apps]);
  // Render
  return (
    <SectionContext.Provider value={result}>{children}</SectionContext.Provider>
  );
};

Section.propTypes = {
  /**
   * Section context name.
   * Defines unique position of section in the hierarchy.
   */
  context: PropTypes.string,
  /**
   * Element specification value.
   * Defines given element as uniquely as possible.
   */
  value: PropTypes.string,
  /**
   * Children
   */
  children: PropTypes.node,
};

export default Section;
