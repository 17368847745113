// React
import React, { useEffect } from "react";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const MessagingProvider = ({ children }) => {
  // Framework
  const { messaging } = usePortal();
  // Init
  useStackDependency(messaging);
  // Effect
  useEffect(() => {
    messaging.useServiceWorker();
  }, [messaging]);
  // Render
  return <>{children}</>;
};

export { MessagingProvider };
