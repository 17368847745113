import { ApolloLink } from "@apollo/client";

export const cleanTypeName = new ApolloLink((operation, forward) => {
  const omitTypename = (key, value) =>
    key === "__typename" ? undefined : value;
  if (operation.variables && !operation.getContext().hasUpload) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation);
});
