// React
import React, { useRef, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty, isNil, trimStart } from "@mefisto/utils";
// Framework
import { useTranslate } from "localization/hooks";
import { classnames } from "ui/classnames";
import { makeStyles, InputBase, IconButton } from "ui/components";
import { Search as SearchIcon, Close as ClearIcon } from "icon/material";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    borderRadius: theme.radius.large,
    background: theme.palette.grey[200],
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  clearIcon: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    width: "100%",
    padding: theme.spacing(1.25, 5, 1.25, 6),
    transition: theme.transitions.create("width"),
    color: theme.palette.text.primary,
    ...theme.typography.subtitle2,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Search = ({ autoFocus, delay = 350, onChange }) => {
  // Framework
  const { t } = useTranslate();
  // Styles
  const classes = useStyles();
  // State
  const [value, setValue] = useState(null);
  // Ref
  const inputRef = useRef(null);
  // Handlers
  const handleChange = useCallback((event) => {
    const { value } = event.target;
    setValue(trimStart(value));
  }, []);
  const handleClear = useCallback(() => {
    setValue("");
    inputRef.current.focus();
  }, []);
  // Effects
  useEffect(() => {
    if (!isNil(value)) {
      const timeout = setTimeout(() => {
        onChange(isEmpty(value) ? null : value);
      }, delay);
      return () => clearTimeout(timeout);
    }
  }, [onChange, delay, value]);
  // Render
  return (
    <div className={classnames(classes.root)}>
      <div className={classnames(classes.searchIcon)}>
        <SearchIcon color="action" />
      </div>
      <InputBase
        inputRef={inputRef}
        autoFocus={autoFocus}
        value={isEmpty(value) ? "" : value}
        placeholder={t("core:ui.dialog.entity.search.placeholder")}
        classes={{ root: classes.inputRoot, input: classes.inputInput }}
        onChange={handleChange}
      />
      {!isEmpty(value) && (
        <div className={classnames(classes.clearIcon)}>
          <IconButton tabIndex={-1} onClick={handleClear}>
            <ClearIcon fontSize="small" color="action" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

Search.propTypes = {
  autoFocus: PropTypes.bool,
  delay: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default Search;
