// Helpers
import { toNumber, toInteger, round, isEmpty, toString } from "@mefisto/utils";

export const toPrice = (value) => {
  if (isEmpty(toString(value))) {
    return null;
  }
  return toInteger(round(toNumber(value) * 100, 2));
};

export const fromPrice = (value) => {
  if (isEmpty(toString(value))) {
    return "";
  }
  return round(toNumber(value) / 100, 2);
};
