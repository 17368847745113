const disableSmooth = (canvasContext) => {
  canvasContext.webkitImageSmoothingEnabled = false;
  canvasContext.mozImageSmoothingEnabled = false;
  canvasContext.msImageSmoothingEnabled = false;
  canvasContext.imageSmoothingEnabled = false;
};

/**
 * Pixelize image manipulation
 */
export const pixelize = ({ threshold = 0.1 }) => async (canvasImage) => {
  const canvas = document.createElement("canvas");
  const canvasContext = canvas.getContext("2d");
  canvas.width = canvasImage.width;
  canvas.height = canvasImage.height;
  // Resize img to small
  const smallWidth = canvasImage.width * threshold;
  const smallHeight = canvasImage.height * threshold;
  disableSmooth(canvasContext);
  canvasContext.drawImage(canvasImage, 0, 0, smallWidth, smallHeight);
  // And resize to full
  disableSmooth(canvasContext);
  canvasContext.drawImage(
    canvas,
    0,
    0,
    smallWidth,
    smallHeight,
    0,
    0,
    canvasImage.width,
    canvasImage.height
  );
  return canvas;
};
