// React
import React from "react";
// Framework
import { usePortal, forwardType, Helmet } from "@mefisto/web";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default forwardType(Helmet, (props) => {
  // Framework
  const { config } = usePortal();
  const { title } = config.values;
  // Render
  return (
    <Helmet defaultTitle={title} titleTemplate={`%s | ${title}`} {...props} />
  );
});
