// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { TableRow, TableCell } from "@material-ui/core";
import { ErrorPlaceholder } from "ui/components";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TableErrorPlaceholder = ({ onRefresh }) => (
  <TableRow>
    <TableCell align="center">
      <ErrorPlaceholder position="center" onAction={onRefresh} />
    </TableCell>
  </TableRow>
);

TableErrorPlaceholder.propTypes = {
  onRefresh: PropTypes.func,
};

export default TableErrorPlaceholder;
