// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty } from "@mefisto/utils";
// Framework
import { makeStyles, Tooltip } from "@material-ui/core";
import { classnames } from "ui/classnames";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles({
  help: {
    "&:hover": {
      cursor: "help",
    },
  },
});

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const DelayedTooltip = ({
  title,
  delay = 800,
  helpCursor = true,
  children,
  ...rest
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Tooltip
      className={classnames(!isEmpty(title) && helpCursor && classes.help)}
      title={title ?? ""}
      enterDelay={delay}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};

DelayedTooltip.propTypes = {
  title: PropTypes.string,
  delay: PropTypes.number,
  helpCursor: PropTypes.bool,
};

export default DelayedTooltip;
