// React
import React, { useCallback, useMemo, useState } from "react";
// Helpers
import { map, find, startsWith } from "@mefisto/utils";
// Framework
import { usePortal } from "stack";
import { useLocation } from "navigation";
import {
  classnames,
  makeStyles,
  Popover,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Grid,
  Button,
  Hidden,
  Typography,
} from "ui";
import { ExpandMore as MoreIcon } from "icon/material";
// Components
import HeaderItem from "../HeaderItem";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  button: {
    height: "100%",
    color: "inherit",
    minWidth: "unset",
    background: "transparent",
    "&:hover": {
      color: theme.palette.common.black,
      background: "transparent",
    },
  },
  label: {
    backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main})`,
    backgroundSize: "0% 2px",
    backgroundRepeat: "no-repeat",
    transition: "all 0.5s",
    backgroundPosition: "0 100%",
    minHeight: theme.spacing(3),
    height: "100%",
  },
  selected: {
    backgroundSize: "70% 2px",
  },
  link: {
    lineHeight: 1,
  },
  arrowIcon: {
    transition: theme.transitions.create("transform"),
    transform: "rotate(0deg)",
    fontSize: "1.2rem",
    marginTop: theme.spacing(0.25),
  },
  arrowExpand: {
    transform: "rotate(-180deg)",
  },
  listItemText: {
    color: theme.palette.action.active,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderLinks = ({ links }) => {
  // Styles
  const classes = useStyles();
  // Framework
  const { navigation } = usePortal();
  const { path: currentPath, hash: currentHash } = useLocation();
  // State
  const [opened, setOpened] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  // Callbacks
  const currentLink = useMemo(() => {
    const isCurrent = (path) => {
      return startsWith(currentPath, path);
    };
    return find(links, (link) => {
      const { path, anchor, children } = link;
      if (path) {
        if (anchor) {
          return isCurrent(path) && currentHash.anchor === anchor;
        } else {
          return isCurrent(path);
        }
      }
      if (children) {
        return find(children, ({ path }) => {
          return isCurrent(path);
        });
      }
      return false;
    });
  }, [links, currentPath, currentHash]);
  const isSelected = useCallback(
    (link) => {
      return link.path === (currentLink || {}).path;
    },
    [currentLink]
  );
  const isOpened = useCallback(
    (link) => {
      return link.path === (opened || {}).path;
    },
    [opened]
  );
  // Handlers
  const handleClick = useCallback(
    (event, link) => {
      const { path, anchor, children } = link;
      if (children) {
        setOpened(link);
        setAnchorEl(event.currentTarget);
      }
      if (anchor) {
        if (navigation.hash.anchor === anchor) {
          navigation.setHash({
            params: { anchor: null },
          });
        }
        setTimeout(() => {
          navigation.goTo(path, {
            hash: { anchor },
          });
        }, 0);
      } else {
        navigation.goTo(path);
      }
    },
    [navigation]
  );
  const handleChildClick = useCallback(
    (child) => {
      setOpened(null);
      if (child.path) {
        navigation.goTo(child.path);
      }
    },
    [navigation]
  );
  const handleClose = useCallback(() => {
    setOpened(null);
  }, []);
  // Render
  return (
    <HeaderItem fill value="links">
      <Hidden smDown>
        <Box ml={1}>
          <Grid container spacing={1} wrap="nowrap">
            {map(links, (link, key) => (
              <Grid item key={key}>
                <Button
                  value={link.value}
                  disableRipple
                  classes={{
                    root: classes.button,
                    label: classnames(classes.label, {
                      [classes.selected]: isSelected(link),
                    }),
                  }}
                  onClick={(event) => handleClick(event, link)}
                >
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    wrap="nowrap"
                    className={classes.link}
                  >
                    <Grid item>{link.title}</Grid>
                    {link.children && (
                      <Grid item>
                        <MoreIcon
                          className={classnames(classes.arrowIcon, {
                            [classes.arrowExpand]: isOpened(link),
                          })}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Button>
                {link.children && (
                  <Popover
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    anchorEl={anchorEl}
                    open={!!opened}
                    offset={{ top: 2 }}
                    onClose={handleClose}
                  >
                    <MenuList dense>
                      {map(link.children, (child, key) => (
                        <MenuItem
                          dense
                          key={key}
                          onClick={() => handleChildClick(child)}
                        >
                          {child.icon && (
                            <ListItemIcon>{child.icon}</ListItemIcon>
                          )}
                          <ListItemText
                            primary={
                              <Typography
                                variant="subtitle2"
                                className={classes.listItemText}
                              >
                                {child.title}
                              </Typography>
                            }
                          />
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Popover>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Hidden>
    </HeaderItem>
  );
};

export default HeaderLinks;
