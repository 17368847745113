// React
import React, {
  Children,
  isValidElement,
  cloneElement,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useState,
  useMemo,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { has, noop } from "@mefisto/utils";
// Framework
import { useMediaQuery, makeStyles, Dialog, CoverSpinner } from "ui";
import { useTheme } from "theme";
import { usePortal } from "stack";
import { EntityPropType } from "model/utils";
// Components
import ModelDialogTitle from "../ModelDialogTitle";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles(() => ({
  paper: {
    minHeight: 200,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const getTypeAction = (type) => {
  switch (type.displayName) {
    case "ModelDialogActionCreate":
      return "create";
    case "ModelDialogActionUpdate":
      return "update";
    case "ModelDialogActionDelete":
      return "delete";
    default:
      return null;
  }
};

const ModelDialog = forwardRef(
  (
    {
      title: defaultTitle,
      entity,
      schema,
      dialogProps,
      loading,
      onFinish = noop,
      onClose = noop,
      children,
    },
    ref
  ) => {
    // Framework
    const { log } = usePortal();
    // Styles
    const classes = useStyles();
    const { breakpoints } = useTheme();
    const sm = useMediaQuery(breakpoints.down("sm"));
    // State
    const [action, setAction] = useState(null);
    const [options, setOptions] = useState(null);
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(defaultTitle);
    // Memo
    const actions = useMemo(() => {
      let actions = {};
      Children.forEach(children, (child) => {
        // noinspection JSCheckFunctionSignatures
        if (isValidElement(child)) {
          const { props = {}, type } = child;
          // Each dialog child must have an action so when we
          // open the dialog we know which action to show.
          // Either use action defined in the child or guess it from type.
          const action = props.action ?? getTypeAction(type);
          if (action) {
            // noinspection JSCheckFunctionSignatures
            actions[action] = cloneElement(
              child,
              {
                action,
                // Entity and schema might be defined for the whole dialog, so
                // we don't need to define it in each action.
                entity: props.entity ?? entity,
                schema: props.schema ?? schema,
              },
              props.children
            );
          }
        }
      });
      return actions;
    }, [children, entity, schema]);
    // Handlers
    const handleFinish = useCallback(
      (payload) => {
        setOpen(false);
        onFinish({ action, ...payload });
      },
      [onFinish, action]
    );
    const handleClose = useCallback(
      (event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        setOpen(false);
        onClose({ action });
      },
      [onClose, action]
    );
    // Ref
    useImperativeHandle(ref, () => ({
      /**
       * Opens dialog in `create` action
       * @param action {string} Current dialog action
       * @param options {object} Dialog options
       */
      open(action, options = {}) {
        if (!has(actions, action)) {
          log.error(
            `Given action [${action}] is not provided by the model dialog.`
          );
          return;
        }
        setAction(action);
        if (options.title) {
          setTitle(options.title);
        }
        setOptions({
          optimisticUI: true,
          ...options,
        });
        setOpen(true);
      },
      close() {
        setOpen(false);
      },
    }));
    // Render
    return (
      <Dialog
        fullWidth
        fullScreen={options?.fullScreen ?? sm}
        onClose={handleClose}
        open={open}
        classes={{ paper: classes.paper }}
        {...dialogProps}
      >
        {loading && <CoverSpinner size="small" />}
        <ModelDialogTitle title={title} onClose={handleClose} />
        {isValidElement(actions[action]) &&
          cloneElement(actions[action], {
            __options: options,
            __display: {
              action,
              onClose: handleClose,
              onFinish: handleFinish,
            },
          })}
      </Dialog>
    );
  }
);

ModelDialog.propTypes = {
  /**
   * Dialog entity class.
   * All the actions will inherit it.
   */
  entity: EntityPropType,
  /**
   * Dialog entity schema.
   * All the actions will inherit it.
   */
  schema: PropTypes.any,
  /**
   * Dialog title
   */
  title: PropTypes.string,
  /**
   * Adds spinner to dialog
   */
  loading: PropTypes.bool,
  /**
   * Called when dialog finished the action with success and closes.
   */
  onFinish: PropTypes.func,
  /**
   * Called when dialog is closed
   */
  onClose: PropTypes.func,
  /**
   * Children node
   */
  children: PropTypes.node,
};

export default ModelDialog;
