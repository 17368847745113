// React
import React, {
  forwardRef,
  useImperativeHandle,
  useCallback,
  useState,
} from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, alpha, Drawer } from "ui/components";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  paper: ({ bgcolor, fullWidth }) => ({
    width: fullWidth ? 600 : 360,
    maxWidth: `calc(100vw - 44px)`,
    height: "100%",
    top: 0,
    right: 0,
    border: "none",
    boxShadow: theme.shadows[2],
    backgroundColor: bgcolor ?? theme.palette.common.white,
  }),
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const DrawerLayoutFloatingTile = forwardRef(
  ({ fullWidth, bgcolor, children }, ref) => {
    // Styles
    const classes = useStyles({ bgcolor, fullWidth });
    // State
    const [open, setOpen] = useState(false);
    // Ref
    useImperativeHandle(ref, () => ({
      open() {
        setOpen(true);
      },
    }));
    // Handlers
    const handleClose = useCallback(() => {
      setOpen(false);
    }, []);
    // Render
    return (
      <Drawer
        open={open}
        anchor="right"
        ModalProps={{
          BackdropProps: {
            classes: {
              root: classes.backdrop,
            },
          },
        }}
        classes={{ paper: classes.paper }}
        onClose={handleClose}
      >
        {children}
      </Drawer>
    );
  }
);

DrawerLayoutFloatingTile.propTypes = {
  bgcolor: PropTypes.string,
  fullWidth: PropTypes.bool,
  children: PropTypes.any,
};

export default DrawerLayoutFloatingTile;
