// React
import React from "react";
// Framework
import { usePortal } from "stack/core";
import { useStackDependency } from "stack/dependency";
// Components
import { Snackbar as SnackbarComponent } from "snackbar/components";

const Component = ({ children, ...props }) => {
  // Framework
  const { snackbar } = usePortal();
  // Init
  useStackDependency(snackbar, props);
  // Render
  return <>{children}</>;
};

const SnackbarProvider = ({ children }) => (
  <SnackbarComponent>
    {(props) => <Component {...props}>{children}</Component>}
  </SnackbarComponent>
);

export { SnackbarProvider };
