// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { Box, Typography } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const CoverTitle = ({ title, titleProps }) => (
  <>
    {title && (
      <Typography variant="h5" align="center" component="div" {...titleProps}>
        <Box pt={6} px={5}>
          {title}
        </Box>
      </Typography>
    )}
  </>
);

CoverTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  titleProps: PropTypes.object,
};

export default CoverTitle;
