// Helpers
import { isNil, reduce, toString } from "@mefisto/utils";
// Transformers
import { fromPrice, toPrice } from "./price";
import { fromTimestamp, toTimestamp } from "./date";
import { fromNumber, toNumber } from "./number";
import { fromBoolean, toBoolean } from "./boolean";

const transformer = {
  serialize: {
    price: toPrice,
    date: toTimestamp,
    number: toNumber,
    bool: toBoolean,
  },
  deserialize: {
    price: fromPrice,
    date: fromTimestamp,
    number: fromNumber,
    bool: fromBoolean,
  },
};

export const serialize = (value = null, transformers = []) => {
  return reduce(
    transformers,
    (result, test) => {
      const func = transformer.serialize[test];
      if (!isNil(func)) {
        result = func(result);
      }
      return result;
    },
    value === "" ? null : value
  );
};

export const deserialize = (value = null, transformers = []) => {
  return reduce(
    transformers,
    (result, test) => {
      const func = transformer.deserialize[test];
      if (!isNil(func)) {
        result = func(result);
      }
      return result;
    },
    toString(value)
  );
};
