// React
import { useRef } from "react";
// Helpers
import { forEach } from "@mefisto/utils";

/**
 * Initializes portal context with list of dependencies and
 * injects `context` to each dependency;
 * Holds the reference to the context, so the initialization happens only once.
 * @param dependencies {object} List of dependencies
 */
export const useStackContext = (dependencies) => {
  const ref = useRef();
  if (!ref.current) {
    ref.current = dependencies;
    // Inject context to each dependency
    forEach(ref.current, (dependency) => dependency.setContext(ref.current));
  }
  return ref.current;
};
