// React
import React, {
  memo,
  useEffect,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { get, has, isEmpty, isFunction, isEqual } from "@mefisto/utils";
// Framework
import { useResources } from "resource";
import { useTranslate } from "localization";
import { useEntityRead } from "model/hooks";
import { EntityPropType } from "model/utils";
import { EmptyPlaceholder, ErrorPlaceholder } from "ui";
import { ActivityPlaceholder } from "activity";
import { Scene } from "layout";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelScene = forwardRef(
  (
    {
      entity: Entity,
      input,
      resources: customResources,
      languages,
      fetchPolicy,
      nextFetchPolicy,
      tags,
      renderOnLoading,
      disableBackOnResourceChange,
      emptyMapper,
      sceneProps,
      activityProps,
      emptyPlaceholderProps,
      errorPlaceholderProps,
      onBack,
      children,
    },
    ref
  ) => {
    // Framework
    const { translate } = useTranslate();
    // Resources
    const { resources: defaultResources } = useResources();
    const resources = useMemo(() => {
      return customResources ?? defaultResources;
    }, [customResources, defaultResources]);
    // Model
    const { data, loading, error, refetch } = useEntityRead(Entity, {
      input,
      resources,
      languages,
      fetchPolicy,
      nextFetchPolicy,
      tags,
    });
    // Ref
    useImperativeHandle(ref, () => ({
      refresh() {
        refetch();
      },
    }));
    // Returns `true` when the entity is not found
    const isEmptyEntity = useMemo(() => {
      const { entity } = emptyMapper ?? {};
      return entity ? entity(data) : isEmpty(data);
    }, [data, emptyMapper]);
    // Returns `true` when the list is empty
    const isEmptyList = useMemo(() => {
      const { list } = emptyMapper ?? {};
      return list
        ? list(data)
        : has(data, "data") && isEmpty(get(data, "data"));
    }, [data, emptyMapper]);
    const notFound = useMemo(() => {
      return isEmptyEntity || isEmptyList;
    }, [isEmptyEntity, isEmptyList]);
    // Returns `true` when resources passed in params match with
    // resources in entity. If the resources don't match it means
    // that e.g. user switched the location but the organization
    // remains the same. When this happens, the `onBack` callback
    // is triggered so the scene can return to its parent.
    const resourcesMatch = useMemo(() => {
      if (disableBackOnResourceChange || !resources || !data.resources) {
        return true;
      }
      // Check if resources match
      const entityResources = data.resources;
      const currentResources = {
        __typename: "Resources",
        organization: null,
        location: null,
        ...resources,
      };
      return isEqual(entityResources, currentResources);
    }, [resources, data, disableBackOnResourceChange]);
    // Handlers
    const handleReload = useCallback(() => {
      refetch();
    }, [refetch]);
    // Effects
    useEffect(() => {
      // Go back when resources don't match
      if (!loading && !error && !resourcesMatch && onBack) {
        onBack();
      }
    }, [resourcesMatch, loading, error, onBack]);
    // Render
    return (
      <>
        {/* Loading */}
        {!renderOnLoading && (loading || !resourcesMatch) ? (
          <ActivityPlaceholder token="ui:model:scene" />
        ) : (
          <Scene {...sceneProps}>
            {/* Not found */}
            {notFound && !loading && !error && (
              <EmptyPlaceholder
                position="center"
                title={
                  emptyPlaceholderProps?.title ??
                  translate("core:model.scene.empty.title")
                }
                subtitle={
                  emptyPlaceholderProps?.subtitle ??
                  translate("core:model.scene.empty.subtitle")
                }
                action={
                  emptyPlaceholderProps?.action ??
                  translate("core:model.scene.button.back")
                }
                onAction={onBack}
              />
            )}
            {/* Error */}
            {!loading && error && (
              <ErrorPlaceholder
                position="center"
                onAction={handleReload}
                {...errorPlaceholderProps}
              />
            )}
            {/* Content loaded */}
            {(renderOnLoading || (!loading && data && !notFound)) && (
              <>
                {isFunction(children)
                  ? children({ input, resources, data, loading })
                  : children}
              </>
            )}
          </Scene>
        )}
      </>
    );
  }
);

ModelScene.propTypes = {
  /**
   * Model entity used for the feed
   */
  entity: EntityPropType,
  /**
   * Input data
   */
  input: PropTypes.object,
  /**
   * Resources data
   */
  resources: PropTypes.object,
  /**
   * List of requested languages
   */
  languages: PropTypes.array,
  /**
   * Fetch policy of the list request
   */
  fetchPolicy: PropTypes.string,
  /**
   * Next fetch policy of the list request
   */
  nextFetchPolicy: PropTypes.string,
  /**
   * Custom tags used to list the data
   */
  tags: PropTypes.any,
  /**
   * When set to `true` the scene is mounted even though
   * the model is still loading the data.
   */
  renderOnLoading: PropTypes.bool,
  /**
   * When set to `true` the back action is not performed
   * whenever the resources change.
   */
  disableBackOnResourceChange: PropTypes.bool,
  /**
   * Mappers for empty state
   */
  emptyMapper: PropTypes.shape({
    entity: PropTypes.func,
    list: PropTypes.func,
  }),
  /**
   * Params passed to scene
   */
  sceneProps: PropTypes.object,
  /**
   * Params passed to activity
   */
  activityProps: PropTypes.object,
  /**
   * Params passed to empty placeholder
   */
  emptyPlaceholderProps: PropTypes.object,
  /**
   * Params passed to error placeholder
   */
  errorPlaceholderProps: PropTypes.object,
  /**
   * Callback called whenever the scene should "go back" to the parent scene.
   */
  onBack: PropTypes.func,
  /**
   * Scene content
   */
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default memo(ModelScene);
