// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const PriceFormatter = ({
  value,
  // TODO: Should be always defined and not optional
  currency = "USD",
  locale,
  children,
}) => {
  // Framework
  const { format } = usePortal();
  // Render
  return <>{children(format.price(value, currency, { locale }))}</>;
};

PriceFormatter.propTypes = {
  value: PropTypes.number.isRequired,
  currency: PropTypes.string,
  locale: PropTypes.string,
  children: PropTypes.func.isRequired,
};

export default PriceFormatter;
