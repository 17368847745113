// React
import React, { forwardRef, useMemo } from "react";
import PropTypes from "prop-types";
// Framework
import { Grid, Box } from "ui/components";
import { Section } from "ui/section";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileCollectionItem = forwardRef(
  (
    {
      context = "item",
      value,
      xs,
      sm,
      md,
      lg,
      xl,
      height,
      minHeight,
      maxHeight,
      children,
    },
    ref
  ) => {
    // Memo
    const tileHeight = useMemo(() => {
      if (height === undefined) {
        return "100%";
      } else if (height === "fixed") {
        return 500;
      } else {
        return height;
      }
    }, [height]);
    // Render
    return (
      <Section context={context} value={value}>
        <Grid
          item
          ref={ref}
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          xl={xl}
          style={{ width: "100%" }}
        >
          <Box height={tileHeight} minHeight={minHeight} maxHeight={maxHeight}>
            {children}
          </Box>
        </Grid>
      </Section>
    );
  }
);

TileCollectionItem.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([
    PropTypes.oneOf(["fixed"]),
    PropTypes.string,
    PropTypes.number,
  ]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
};

export default TileCollectionItem;
