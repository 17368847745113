// React
import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";
// Framework
import { useTranslate } from "localization";
import { useTheme } from "theme";
import { EntityPropType } from "model/utils";
import { FormLayout, FormItem, FormImageField, Schema } from "form";
// Components
import ModelDialog from "model/components/ModelDialog";
import ModelDialogActionUpdate from "model/components/ModelDialogActionUpdate";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelCoverImageDialog = forwardRef(
  ({ entity, fileName = "coverImage" }, ref) => {
    // Framework
    const { t } = useTranslate();
    // Styles
    const { radius } = useTheme();
    // Render
    return (
      <ModelDialog
        ref={ref}
        title={t("core:model.coverImage.title")}
        entity={entity}
        schema={{
          [fileName]: Schema.string().required(),
        }}
      >
        <ModelDialogActionUpdate>
          <FormLayout>
            <FormItem>
              <FormImageField
                name={fileName}
                label={t("core:model.coverImage.form.label")}
                maxWidth={2400}
                maxHeight={1200}
                minWidth={1200}
                minHeight={600}
                dropzoneWidth={550}
                dropzoneHeight={550 / (16 / 8)}
                aspect={16 / 8}
                borderRadius={`${radius.large}px ${radius.large}px 0 0`}
              />
            </FormItem>
          </FormLayout>
        </ModelDialogActionUpdate>
      </ModelDialog>
    );
  }
);

ModelCoverImageDialog.propTypes = {
  entity: EntityPropType,
  fileName: PropTypes.string,
};

export default memo(ModelCoverImageDialog);
