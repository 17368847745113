// Components
import GTM from "react-gtm-module";

export class TagManager {
  #enabled = false;

  constructor(config, log) {
    const { tagManagerEnabled, tagManagerId } = config.values;
    if (tagManagerEnabled) {
      this.#enabled = true;
      GTM.initialize({
        gtmId: tagManagerId,
      });
      log.info("👾 Google Tag Manager [On]");
    }
  }
}
