/**
 * Crop image manipulation
 */
export const crop = (newWidth, newHeight, offsetX, offsetY) => async (
  canvasImage
) => {
  // Create resulting canvas
  const canvas = document.createElement("canvas");
  const canvasContext = canvas.getContext("2d");
  // Calculate crop area
  const dx = 0;
  const dy = 0;
  const width = canvasImage.width;
  const height = canvasImage.height;
  const sy = offsetY || (height - newHeight) / 2;
  const sx = offsetX || (width - newWidth) / 2;
  // Resulting image aspects
  canvas.width = newWidth;
  canvas.height = newHeight;
  // Draw
  canvasContext.drawImage(
    canvasImage,
    sx,
    sy,
    newWidth,
    newHeight,
    dx,
    dy,
    newWidth,
    newHeight
  );
  return canvas;
};
