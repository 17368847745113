// React
import React from "react";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

/**
 * Routes to given component
 */
const RoutePublic = ({ children }) => {
  // Do not redirect
  return children;
};

export default RoutePublic;
