// React
import React, { createContext, useMemo, useContext } from "react";
import PropTypes from "prop-types";
// Helpers
import { merge } from "@mefisto/utils";

////////////////////////////////////////////////////
/// Context
////////////////////////////////////////////////////

const Context = createContext({});

export const usePortalLayout = (componentProps = {}) => {
  const contextProps = useContext(Context);
  const props = useMemo(() => {
    return merge({}, contextProps, componentProps);
  }, [contextProps, componentProps]);
  return props;
};

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const PortalLayoutProvider = ({ layoutProps = {}, children }) => (
  <Context.Provider value={layoutProps}>{children}</Context.Provider>
);

PortalLayoutProvider.propTypes = {
  layoutProps: PropTypes.object,
  children: PropTypes.node,
};

export default PortalLayoutProvider;
