// React
import React, { useCallback, useRef, useMemo, useState } from "react";
// Helpers
import { size, noop } from "@mefisto/utils";
// Framework
import { usePortal } from "stack/core";
import { ModalBackdrop } from "ui";
import { HeaderItem } from "layout/header";
// Components
import HeaderButton from "./components/HeaderButton";
import HeaderPopover from "./components/HeaderPopover";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const LocalizationHeaderItem = () => {
  // Framework
  const { localization } = usePortal();
  // State
  const [loading, setLoading] = useState(false);
  // Ref
  const popoverRef = useRef();
  // Memo
  const enabled = useMemo(() => {
    return size(localization.availableLanguages) > 1;
  }, [localization]);
  // Callbacks
  const handleOpen = useCallback((event) => {
    popoverRef.current.open({ anchorEl: event.currentTarget });
  }, []);
  const handleSelection = useCallback(
    ({ locale }) => {
      popoverRef.current.close();
      setLoading(true);
      localization
        .setLocale(locale)
        .then(() => setLoading(false))
        .catch(noop);
    },
    [localization]
  );
  // Render
  return (
    <>
      {enabled && (
        <HeaderItem value="language">
          <ModalBackdrop modal spinner open={loading} />
          <HeaderButton onClick={handleOpen} />
          <HeaderPopover ref={popoverRef} onSelection={handleSelection} />
        </HeaderItem>
      )}
    </>
  );
};

export default LocalizationHeaderItem;
